import Meta from "../../../../components/common/Meta";
import MainDesign from "../../../MainDesign";
import React, {Suspense} from "react";
import LoadingPage from "../../../common/LoadingPage";
import {Await, Params, useLoaderData} from "react-router-dom";
import ErrorContent from "../../../../components/common/error/ErrorContent";
import {SchedulePhoneNotificationRequest, WebinarDetails} from "../../../../model/user/webinar/WebinarDetails";
import WebinarOptInConfirmation from "../../../../components/funnel/after-optin/webinar/WebinarOptInConfirmation";
import {WebinarService} from "../../../../service/WebinarService";
import ProdukacjaCopyrightNoteWithPhoneNumber
    from "../../../../components/funnel/ProdukacjaCopyrightNoteWithPhoneNumber";

type LoaderData = {
    webinarDetails: Promise<WebinarDetails>;
};

export default function WebinarOptInConfirmationPage() {
    const {webinarDetails} = useLoaderData() as LoaderData;

    return <>
        <Meta title="Potwierdzenie zapisu"/>
        <MainDesign containerStyles='container py-20' minHeight='100vh'>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={webinarDetails} errorElement={<ErrorContent/>}>
                    {(loadedDetails: WebinarDetails) => <>
                        <WebinarOptInConfirmation webinarDetails={loadedDetails}/>
                        <ProdukacjaCopyrightNoteWithPhoneNumber/>
                    </>}
                </Await>
            </Suspense>
        </MainDesign>
    </>
}

export async function action({request, params}: { request: Request, params: Params<"ksuid"> }) {
    const formData = await request.formData();
    const webinarId = params.ksuid!!;
    const schedulePhoneNotification = {
        phoneNumber: formData.get('phoneNumber') as string,
        webinarId: webinarId
    } as SchedulePhoneNotificationRequest;

    try {
        const {message} = await WebinarService.schedulePhoneNotification(schedulePhoneNotification);
        return {
            status: 200,
            body: message
        }
    } catch (error) {
        const apiError = error as Error
        return {
            status: 500,
            body: apiError.message
        }
    }
}