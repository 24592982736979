export const kedDescription = <>
    <p className='text-secondary-light fw-medium'>
        <div className='fs-10'>Kurs składa się z 9 tygodni.</div>
        <br/>
        Na samym początku zaczniemy od zbudowania świadomości, na której będą bazowały wszystkie kolejne tygodnie.
        Następnie zajmiemy się podstawami efektywnego działania, aby być w stanie działać systematycznie i
        konsekwentnie realizować swoje cele. <b>Dwa pierwsze tygodnie są najważniejsze.</b><br/> Bez
        wykonania ćwiczeń i wdrożenia w życie
        zmian,
        przejście do kolejnych tygodni tylko Cię przytłoczy i niczego się nie nauczysz.
        <br/><br/>
        W trzecim tygodniu zajmiemy się podstawami efektywnej nauki i zrozumiemy, co tak naprawdę oznacza efektywna
        nauka i w jaki sposób można zmienić swoje aktualne nawyki - by spędzać mniej czasu nad książkami, a
        zapamiętywać i rozumieć więcej.
        <br/><br/>
        W czwartym tygodniu zoptymalizujemy nasze środowisko pracy/nauki, aby być maksymalnie produktywnym i nigdy
        więcej się nie rozpraszać.<br/><br/>
        W piątym tygodniu wejdziemy na średniozaawansowany poziom efektywnej nauki. Nauczymy się, w jaki sposób
        uczyć się w szkole, jak skutecznie pisać sprawdziany na topowe oceny, jak robić efektywne notatki oraz jak
        stworzyć swój własny system nauki. <br/><br/>
        W szóstym tygodniu skupimy się na optymalizacji naszego życia i szczegółowo omówimy tematy związane ze snem,
        sportem, jedzeniem i odpoczynkiem.<br/><br/>
        W siódmym tygodniu skupimy się na bardziej zaawansowanych aspektach efektywnej nauki, takich jak nauka
        poprzez dociekanie, tworzenie przemyślanych map myśli, kolejność nauki, sposoby nauki do przedmiotów
        ścisłych oraz omówimy wykorzystanie AI jako naszego pomocnika. <br/><br/>
        W ósmym tygodniu zajmiemy się wchodzeniem w stan flow, by nauka stała się bardzo przyjemna i aby w jeden
        dzień zrobić więcej, niż większość osób przez tydzień lub nawet miesiąc! <br/><br/>
        Zakończymy rozdziałem poświęconym na wejście poziom wyżej, dzięki któremu
        już nigdy nie wrócisz do swoich starych nawyków i nie zboczysz ze zwycięskiej ścieżki!<br/><br/>
        Pełny program Kursu wraz z opisem każdego
        odcinka, <a className='text-success text-decoration-underline' target="_blank"
                    href="https://docs.google.com/document/d/17eQ5cB8ZD7NsbcsHteTOztJVvydv4rPBPXL2dW2XeRI/edit?usp=sharing">znajdziesz
        pod tym linkiem</a>.<br/><br/>
        Jeżeli masz jakieś sugestie odnośnie filmów, śmiało dodaj swój komentarz w powyższym arkuszu.
    </p>
    <p className='text-secondary-light fw-medium'>Zalecamy oglądać filmy po kolei, aby niczego nie ominąć. Często
        kolejne lekcje bazują na poprzednich.</p>
    <p className='text-secondary-light fw-medium'>Kolejne tygodnie będą odblokowywane automatycznie - więcej
        informacji w Tygodniu 0.</p>
</>

export const pedDescription = <>
    <p className='text-secondary-light fw-medium'>
        Zaczniemy od stworzenia "Najlepszej wersji siebie", dzięki której zdasz sobie sprawę, dokąd dążysz i jakie są
        Twoje cele. Staniesz się znacznie bardziej świadomą osobą i zobaczysz sens w tym, co robisz.
        <br/><br/>
        Następnie, wykorzystamy narzędzie "Roczny Plan Dominacji" do skutecznego zarządzania czasem i planowania.
        Nauczymy się, jak właściwie planować naukę i na co zwracać uwagę.
        <br/><br/>
        W kolejnym filmie zagłębimy się w temat Systematycznego Działania. Wiele osób wie, że powinni coś robić, ale
        jakoś nie mogą zmobilizować się do działania...<br/>
        Weźmiemy pod lupę temat prokrastynacji (odkładania rzeczy na później) i nauczymy się, jak pokonać ją raz na
        zawsze.
        <br/><br/>
        Zoptymalizujemy nasze miejsce pracy. Upewnimy się, że będziemy pracować w miejscu wolnym od
        rozpraszaczy, które sprzyja rozwojowi i skupieniu.
        <br/><br/>
        Później, spojrzymy na temat Efektywnej Nauki i nauczymy się solidnych podstaw. Dowiemy się, na czym polega POP
        (Powtórki, Odzyskiwanie, Przeplatanie) - oraz jak go wprowadzić w życie.
        <br/><br/>
        Na końcu, ułożymy jasny i konkretny plan działania, w którym wykorzystamy w praktyce wszystko, czego się
        nauczyliśmy.
    </p>
</>