import React, {useEffect, useState} from "react";
import {WebinarDetails} from "../../../../model/user/webinar/WebinarDetails";
import {useSubmitFormAction} from "../../../../hooks/useSubmitFormAction";
import {TraceType} from "../../../../model/user/general/Trace";
import {TraceService} from "../../../../service/TraceService";
import {KED_WEBINAR_EXPERIMENT_AB_9} from "../../../../util/ExperimentUtil";
import DependentOnScreenSizeElement from "../../../ui/element/DependentOnScreenSizeElement";
import ResponsivePhotoWithText from "../../../ui/element/ResponsivePhotoWithText";
import AnimatedLink from "../../../ui/motion/AnimatedLink";
import ResponsiveElement from "../../../ui/element/ResponsiveElement";
import SimpleTestimonial from "../webinar/sales-page/SimpleTestimonial";
import AdvantageItem from "../../../ui/item/AdvantageItem";
import Heading from "../../../common/header/Heading";
import SalesPageCTA from "../webinar/sales-page/SalesPageCTA";
import SalesPageNumbersItem from "../webinar/sales-page/SalesPageNumbersItem";
import FaqList from "../../../ui/faq/FaqList";
import {useSubmit} from "react-router-dom";

interface PedPurchaseProps {
    webinarDetails: WebinarDetails | null
}

export default function PedPurchase({webinarDetails}: PedPurchaseProps) {
    const [transactionUrl, setTransactionUrl] = useState<string | null>(null);

    const {isSubmitting} = useSubmitFormAction(
        "whatever",
        null,
        (body) => {
            window.open(body, "_blank");
            setTransactionUrl(body);
        },
        false,
        undefined,
        undefined,
        true
    );

    useEffect(() => {
        if (webinarDetails && webinarDetails.experimentId) {
            TraceService.addTraceWithExperiment(TraceType.PedSalesPageLoad, webinarDetails.experimentId, webinarDetails?.variation, webinarDetails?.source, webinarDetails?.id, webinarDetails?.email)
        } else {
            // OLD ONE - BEFORE NEW CHANGES. ALL NEW PEOPLE SHOULD HAVE EXPERIMENT ID SET. -> Changed to the current one.
            TraceService.addTraceWithExperiment(TraceType.PedSalesPageLoad, KED_WEBINAR_EXPERIMENT_AB_9, webinarDetails?.variation, webinarDetails?.source, webinarDetails?.id, webinarDetails?.email)
        }
    }, [webinarDetails?.source, webinarDetails?.variation, webinarDetails?.experimentId, webinarDetails?.id, webinarDetails?.email, webinarDetails]);

    const submit = useSubmit();

    function submitForm() {
        submit({}, {
            method: "POST"
        });
    }

    return (
        <>
            <div className="mw-md-6xl mx-auto text-center text-white">
                <DependentOnScreenSizeElement mobileElement={<>
                    <h2 className='mb-0 text-center mw-md-5xl mx-auto font-heading mb-1 fs-4 text-white'>Pomóż swojemu
                        dziecku zostać</h2>
                    <h2 className='mb-0 text-center mw-md-5xl mx-auto font-heading mb-1 fs-3 text-white'><span
                        className='text-success'>NAJLEPSZYM </span>
                        uczniem.</h2>
                    <p className='text-warning fw-medium mw-md-3xl mx-auto fs-11 mt-3'>Nawet jeżeli nie ma motywacji,
                        nie potrafi się uczyć lub spędza za dużo czasu na telefonie.

                    </p>
                </>} desktopElement={<>
                    <h2 className='mb-0 text-center mw-md-5xl mx-auto font-heading mb-1 fs-5 text-white'>Pomóż <u>swojemu
                        dziecku</u> zostać</h2>
                    <h2 className='mb-0 text-center mw-md-5xl mx-auto font-heading mb-1 fs-4 text-white'><span
                        className='text-success'>NAJLEPSZYM </span>uczniem</h2>
                    <p className='text-warning fw-medium mw-md-5xl mx-auto fs-9 mt-3'>Nawet jeżeli nie ma motywacji, nie
                        potrafi się uczyć lub spędza za dużo czasu na telefonie.

                    </p>
                </>}/>
                <div className='mt-12'/>

                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-8 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/ped-showcase-sales-page.jpg'
                    paddingPhoto='56.25%'
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Zapisz swoje dziecko już teraz <img
                                    style={{height: '32px'}}
                                    src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/ikony/quality.svg'/>
                                </h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>Możesz dołączyć już tylko za <b>1/30</b> ‼️ regularnej
                                    ceny
                                    kursu.</p>
                                <p className='fw-medium fs-10'>Twoje dziecko nauczy się solidnych podstaw Efektywnego
                                    Działania, dzięki którym <b>dwukrotnie poprawi swoją produktywność</b>.</p>
                            </div>
                            <AnimatedLink
                                hashLink={true}
                                to='#kup-teraz'
                                style={{
                                    maxWidth: '380px'
                                }}
                                className='btn btn-lg btn-success fs-10 py-4 w-100 text-success-light shadow mt-3'>Zapisz
                                swoje dziecko
                                do Kursu <br/>
                                "Podstawy Efektywnego Działania"</AnimatedLink>

                        </>
                    }/>

                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-16 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/md-800-1080.png'
                    paddingPhoto='135%'
                    customColText='col-md-8'
                    customColImage='col-md-4'
                    imageBelowText={true}
                    swap={true}
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Wiadomość od Twórcy Kursu
                                </h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>Wierzę, że każdy ma w sobie nieograniczony potencjał,
                                    który tylko czeka na wykorzystanie. </p>
                                <p className='fw-medium fs-10'>Na Produkacji, pomagamy przede wszystkim nastolatkom w
                                    jego odkryciu.</p>
                                <p className='fw-medium fs-10'>Jestem bardzo dumny, że pomogliśmy już setkom uczniów i
                                    wciąż możemy pomagać młodzieży w Efektywnym Działaniu.</p>
                                <p className='fw-medium fs-10'>Naszą misją jest stworzenie platformy na miarę XXI wieku,
                                    gdzie uczniowie z całej Polski będą mogli stawać się najlepszą wersją siebie każdego
                                    dnia.</p>
                                <p className='fw-medium fs-10'>Zapraszam Cię na niesamowitą przygodę.</p>
                                <p className='fw-medium fs-11'><i>~Mikołaj Dyblik, założyciel Produkacji i twórca Kursu
                                    Efektywnego Działania</i></p>
                            </div>

                        </>
                    }/>
                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-16 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/ped-devices.png'
                    paddingPhoto='74.89%'
                    imageBelowText={true}
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Czym są <u>Podstawy Efektywnego Działania</u>? <img
                                    style={{height: '32px'}}
                                    src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/ikony/quality.svg'/>
                                </h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>Podstawy Efektywnego Działania to
                                    stworzony <b>specjalnie dla nastolatków</b> program,
                                    który znacząco pomoże im podnieść produktywność. </p>
                                <p className='fw-medium fs-10'>Jest to 1.5-godziny Kurs online budujący solidne podstawy
                                    Efektywnego Działania, dzięki któremu już po kilku dniach Twoje dziecko zobaczy
                                    wymierne rezultaty
                                    w swoim życiu.</p>
                                <p className='fw-medium fs-10'>Kurs jest dostępny na całe życie, z każdego miejsca na
                                    świecie i o każdej porze.</p>
                            </div>

                        </>
                    }/>

                <div className='mt-10'/>

                <ResponsivePhotoWithText
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/ped-sp-happy.png'
                    paddingPhoto='62.5%'
                    swap={true}
                    imageBelowText={true}
                    textPart={<>
                        <ResponsiveElement mobileClasses='fs-5 text-start mt-8 mt-md-0'
                                           desktopClasses='fs-7 text-start' element={
                            <h3>Czego nauczy się Twoje dziecko?
                            </h3>
                        }/>
                        <div className='text-start'>
                            <AdvantageItem
                                title='Jak lepiej poznać siebie, jasno określić swój cel i stworzyć konkretny PLAN DZIAŁANIA.'/>
                            <AdvantageItem
                                title='Jak skutecznie planować naukę.'/>
                            <AdvantageItem
                                title='Jak pokonać prokrastynację i zacząć działać SYSTEMATYCZNIE.'/>
                            <AdvantageItem
                                title='Jak stworzyć środowisko do nauki wolne od rozpraszaczy, które sprzyja rozwojowi.'/>
                            <AdvantageItem
                                title='Co składa się na podstawy Efektywnej Nauki - i jak wdrożyć to w życie.'/>
                        </div>
                    </>}/>


                <div className='mt-12'/>
                <SimpleTestimonial name='Magdalena'
                                   quote='Widzę u córki dużą poprawę w nauce. Przestała uczyć się na ostatnią chwilę, chodzi szybciej spać i stała się bardziej samodzielna. Dostaje dużo lepsze oceny i widzę, że jest bardziej radosna.'
                                   description='Mama Agaty z I klasy Technikum'
                                   imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/anna-incognito.png'/>

                <div id='kup-teraz'>
                    <Heading badgeText='Pozostał ostatni krok' title='Uzyskaj dostęp do Kursu już teraz 📚'/>
                    <SalesPageCTA isSubmitting={isSubmitting} submit={submitForm} fromWebinar={false}
                                  transactionUrl={transactionUrl} hideDiscount={true} miniCourse={true}/>
                </div>

                <hr className='text-white mt-8 mt-md-16'/>
                <div className='row'>
                    <h3 className='fs-5 mb-md-8'>Spójrzmy na liczby...
                    </h3>
                    <SalesPageNumbersItem metricTitle='Lata doświadczenia' metricValue={5}/>
                    <SalesPageNumbersItem metricTitle='Zadowoleni uczniowie' metricValue={'3.000+'}/>
                    <SalesPageNumbersItem metricTitle='Zadowoleni rodzice' metricValue={'98.3%'}/>

                </div>
                <hr className='text-white mb-0 mb-md-16'/>

                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-0 mt-md-8 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/best-self.png'
                    paddingPhoto='56.25%'
                    imageBelowText={true}
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Autorski dokument "BEST Self"
                                </h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>Ten dokument pomoże Twojemu dziecku stworzyć jasny plan
                                    działania do realizacji swoich celów.</p>
                                <p className='fw-medium fs-10'>Specjalnie stworzone pytania skłonią je do myślenia i po
                                    pewnym czasie, odpowiedzi przyjdą zupełnie naturalnie.</p>
                                <p className='fw-medium fs-10'>Dostępny w dwóch wersjach - podstawowa (26 pytań) i
                                    rozbudowana (+-100 pytań). </p>
                            </div>

                        </>
                    }/>
                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-8 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/plan-dominacji.png'
                    paddingPhoto='56.25%'
                    swap={true}
                    imageBelowText={true}
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Autorski dokument "Roczny Plan Dominacji"
                                </h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>Jest to specjalny planer do wygrania 2025 roku.</p>
                                <p className='fw-medium fs-10'>Jego przejrzystość i prostota sprawiają, że nawet
                                    najbardziej oporni uczniowie chętnie zaczynają z niego korzystać.</p>
                                <p className='fw-medium fs-10'>Koniec nauki na ostatnią chwilę, zapominania o
                                    sprawdzianach, kartkówkach czy urodzinach swoich rodziców.</p>
                            </div>

                        </>
                    }/>


                <div className='mt-16'/>

                <SimpleTestimonial name='Tymon'
                                   quote='Juz po kilku dniach od rozpoczecia kursu widze poprawe. Nie spedzam juz tak duzo czasu na telefonie, potrafie sobie zaplanowac nauke i rzeczywiscie zrealizowac ten plan. To dla mnie naprawde duzo. Dziekuje panu'
                                   imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/tymon-incognito.png'
                                   smallerQuote={true}
                                   description='Uczeń II klasy Technikum'
                />

                <Heading badgeText='Pozostał ostatni krok' title='Uzyskaj dostęp do Kursu już teraz 📚'/>
                <SalesPageCTA isSubmitting={isSubmitting} submit={submitForm} fromWebinar={false}
                              transactionUrl={transactionUrl} hideDiscount={true} miniCourse={true}/>

                <div className='fw-medium mw-md-3xl mx-auto text-start'>
                    <h3 className='mt-10 fs-8'>Umiejętności Efektywnego Działania uczone w każdej szkole!</h3>
                    <p>Jedynym sposobem na zrealizowanie naszej misji <i>"Umiejętności Efektywnego Działania uczone w
                        każdej szkole"</i>, jest najpierw pomaganie pojedynczym uczniom w poprawie ich życia.</p>

                    <p>Kiedy część uczniów będzie działała znacznie efektywniej od innych, słowo wyjdzie na jaw i system
                        szkolnictwa będzie musiał zostać uaktualniony.</p>

                    <p>Podstawy Efektywnego Działania zostały stworzone, aby pomóc:</p>
                    <ul>
                        <li className='mt-2'>jeszcze jednemu nastolatkowi w odkryciu swojej drogi życiowej.</li>
                        <li className='mt-2'>jeszcze jednemu uczniowi w skutecznym planowaniu sobie nauki oraz uczeniu
                            się.
                        </li>
                        <li className='mt-2'>jeszcze jednemu podopiecznemu w systematycznym działaniu.</li>
                        <li className='mt-2'>jeszcze jednemu podopiecznemu w stworzeniu produktywnego miejsca pracy,
                            wolnego od rozpraszaczy i sprzyjającego rozwojowi.
                        </li>
                        <li className='mt-2'>jeszcze jednemu rodzicowi w niemartwieniu się już o edukację swojego
                            dziecka.
                        </li>
                    </ul>

                    <p>Ostatecznym celem jest stworzenie platformy, która pomoże każdemu uczniowi w Polsce w pełni
                        wykorzystać
                        swój potencjał, Działać Efektywniej i Osiągać Więcej.</p>
                </div>
                <SimpleTestimonial name='Małgorzata'
                                   quote='Panie Mikołaju, dzięki Pana pomocy Maciek zaczął w końcu uczyć się regularnie i nauczył się planować naukę. Dzięki dokumentowi najlepszej wersji siebie, zdał sobie sprawę do jakiego liceum chce iść i co robić dalej. Dziękuję bardzo.'
                                   imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/malgorzata-incognito.png'
                                   smallerQuote={true}
                                   description='Mama Maćka z VII klasy'
                />

                <Heading badgeText='Pozostał ostatni krok' title='Uzyskaj dostęp do Kursu już teraz 📚'/>

                <SalesPageCTA isSubmitting={isSubmitting} submit={submitForm} fromWebinar={false}
                              transactionUrl={transactionUrl} hideDiscount={true} miniCourse={true}/>

                <h3 className='mt-10 fs-6'>Najczęściej zadawane pytania</h3>

                <div className='text-start'>
                    <FaqList items={[
                        {
                            question: "Dla kogo jest ten Kurs?",
                            answer: 'Ten Kurs jest dla wszystkich uczniów w wieku od 12 lat do końca studiów, którzy chcą nauczyć się podstaw Efektywnego Działania.'
                        },
                        {
                            question: "Czym różnią się Podstawy Efektywnego Działania od Kursu Efektywnego Działania?",
                            answer: 'Podstawy Efektywnego Działania są znacznie mniejszym Kursem, który skupia się przede wszystkim na zbudowaniu solidnych podstaw, dzięki którym Twoje dziecko znacząco poprawi swoją produktywność.\n' +
                                'Ten Kurs jest świetnym rozwiązaniem dla osób, które nie mogą sobie pozwolić na zakup głównego Kursu lub chcą najpierw przetestować nasz sposób przekazywania wiedzy.\n'
                        },
                        {
                            question: "Ile czasu należy poświęcić na Kurs?",
                            answer: 'Obejrzenie wszystkich filmów zajmuje około 1.5h. Wykonanie ćwiczeń i wdrożenie w życie zmian zajmuje od 4h do 10h, w zależności od obecnego poziomu zaawansowania.'
                        },
                        {
                            question: "Co jest zawarte w Kursie?",
                            answer: 'W Kursie zawarte są filmy online wraz z materiałami i ćwiczeniami do pracy własnej. Obejrzenie wszystkich filmów zajmuje około 1.5h, a wykonanie ćwiczeń od 4h do 10h. '
                        },
                        {
                            question: "Czy mogę potem wykupić główny Kurs?",
                            answer: 'Oczywiście. Nic nie stoi na przeszkodzie, aby najpierw przerobić solidne Podstawy Efektywnego Działania, a następnie kompleksowy Kurs Efektywnego Działania.'
                        }
                    ]}/>
                </div>


                <div className='mt-16'/>
                <SimpleTestimonial name='Bohdi Sanders' quote='Brak podjęcia decyzji to również podjęcie decyzji.'
                                   description='Znany autor, filozof, trener życia i ekspert w dziedzinie filozofii sztuk walki.'
                                   imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/bohdi-sanders.png'/>
            </div>
        </>
    );
}