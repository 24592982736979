import {ContentType, HttpMethod, HttpService} from "./HttpService";
import {
    AdminNewsletterResponse,
    AdminSendNewsletterRequest,
    AdminToggleNewsletterRequest,
    CancelNewsletterRequest,
    ConfirmNewsletterRequest,
    ConfirmNewsletterResponse,
    NewsletterCampaign,
    NewsletterEntity,
    NewsletterFollowUp,
    SubscribeNewsletterRequest,
    SubscribeNewsletterResponse
} from "../model/user/general/Newsletter";
import {NewsletterType} from "../components/user/newsletter/NewsletterForm";

export class NewsletterService {

    public static sendNewsletter(requestBody: AdminSendNewsletterRequest): Promise<AdminNewsletterResponse> {
        return HttpService.sendRequest<AdminNewsletterResponse>(
            HttpMethod.POST,
            '/api/newsletter/send',
            JSON.stringify(requestBody),
            ContentType.JSON
        );
    }

    public static toggleNewsletter(requestBody: AdminToggleNewsletterRequest): Promise<AdminNewsletterResponse> {
        return HttpService.sendRequest<AdminNewsletterResponse>(
            HttpMethod.POST,
            '/api/newsletter/toggle',
            JSON.stringify(requestBody),
            ContentType.JSON
        );
    }

    public static getMailingList(newsletterType: NewsletterType): Promise<NewsletterEntity[]> {
        return HttpService.sendRequest<NewsletterEntity[]>(
            HttpMethod.GET,
            `/api/newsletter?newsletterType=${newsletterType}`,
        );
    }

    public static getNewsletterCampaigns(): Promise<NewsletterCampaign[]> {
        return HttpService.sendRequest<NewsletterCampaign[]>(
            HttpMethod.GET,
            `/api/newsletter/campaigns`,
        );
    }

    public static getNewsletterFollowUps(newsletterType: NewsletterType): Promise<NewsletterFollowUp[]> {
        return HttpService.sendRequest<NewsletterFollowUp[]>(
            HttpMethod.GET,
            `/api/newsletter/followups?newsletterType=${newsletterType}`,
        );
    }

    public static subscribeNewsletter(requestBody: SubscribeNewsletterRequest): Promise<SubscribeNewsletterResponse> {
        return HttpService.sendRequest<SubscribeNewsletterResponse>(
            HttpMethod.POST,
            '/api/newsletter/add',
            JSON.stringify(requestBody),
            ContentType.JSON
        );
    }

    public static cancelNewsletter(requestBody: CancelNewsletterRequest): Promise<void> {
        return HttpService.sendRequest<void>(
            HttpMethod.POST,
            '/api/newsletter/remove',
            JSON.stringify(requestBody),
            ContentType.JSON
        );
    }

    public static confirmNewsletter(requestBody: ConfirmNewsletterRequest): Promise<ConfirmNewsletterResponse> {
        return HttpService.sendRequest<ConfirmNewsletterResponse>(
            HttpMethod.POST,
            '/api/newsletter/confirm',
            JSON.stringify(requestBody),
            ContentType.JSON
        );
    }
}