import {Outlet} from "react-router-dom";
import ScrollToTop from "../../util/ScrollToTop";
import React, {useEffect} from "react";
import ReactPixel from "react-facebook-pixel";

export default function FunnelRootLayout() {
    useEffect(() => {
        ReactPixel.init('1721779321916461', undefined, {debug: false, autoConfig: true});
        ReactPixel.pageView();
    }, []);

    return <>
        <ScrollToTop/>
        <Outlet/>
    </>
}