import Vimeo from "@u-wave/react-vimeo";
import React, {useRef, useState} from "react";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";

export interface VideoWithPlayButtonProps {
    onPlay?: (playerRef: any) => void;
    vimeoVideoId: string;
    id: string;
    customDivWrapperClasses?: string;
    trackStatistics?: boolean;
    callAfterTime?: CallAfterTime;
    hidePlayButton?: boolean;
    autoPlay?: boolean;
    start?: number;
    background?: boolean
    keepPlayButtonAlways?: boolean
    ignoreTraces?: boolean
}

interface CallAfterTime {
    time: number;
    action: (playerRef: any) => void;
}

export default function VideoWithPlayButton(props: VideoWithPlayButtonProps) {
    const {
        onPlay,
        vimeoVideoId,
        id,
        customDivWrapperClasses,
        trackStatistics,
        callAfterTime,
        hidePlayButton,
        start,
        background,
        keepPlayButtonAlways,
        ignoreTraces
    } = props;

    const [playButtonVisible, setPlayButtonVisible] = useState(!hidePlayButton);
    const [overlayVisible, setOverlayVisible] = useState(!hidePlayButton);
    const [paused, setPaused] = useState(false);
    const [ended, setEnded] = useState(false);
    const [lastWatchedTime, setLastWatchedTime] = useState<number | null>(null);

    const playerRef = useRef<any>(null);
    const intervalRef = useRef<number | null>(null);


    function handlePlayerReady(player: any) {
        player.on("pause", handlePlayerPause);
        player.on("play", handlePlayerPlay);
        player.on("ended", handlePlayerEnd);
        console.log('player-ready')

        // Cleanup function
        return () => {
            player.off("pause", handlePlayerPause);
            player.off("play", handlePlayerPlay);
            player.off("ended", handlePlayerEnd);
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current);
            }
        };
    }

    function handlePlayerEnd() {
        if (!ignoreTraces) {
            TraceService.addTrace(TraceType.PlayVideo, "Video has ended.");
        }
        setEnded(true);
    }

    function handlePlayerPlay() {
        if (ended) return;
        setPaused(false);

        // Clear any old intervals
        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
        }

        intervalRef.current = window.setInterval(async () => {
            if (playerRef.current?.player) {
                const time = await playerRef.current.player.getCurrentTime();
                const hadEnded = await playerRef.current.player.getEnded();

                if (ended) return;

                // Only send trace if current time changed
                if (lastWatchedTime !== time && time > 0) {
                    if (!ignoreTraces) {
                        TraceService.addTrace(
                            TraceType.PlayVideo,
                            `Watched time: ${time}, ended: ${hadEnded}, paused: ${paused}`
                        );
                    }
                    setLastWatchedTime(time);
                }

                if (callAfterTime) {
                    if (time >= callAfterTime.time) {
                        try {
                            callAfterTime.action(playerRef);
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }

                if (hadEnded && intervalRef.current !== null) {
                    setEnded(true);
                    clearInterval(intervalRef.current);
                }
            }
        }, 5000);
    }

    async function handlePlayerPause() {
        // Normal pause behavior
        setPaused(true);
        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
        }

        if (!ignoreTraces) {
            TraceService.addTrace(TraceType.PlayVideo, "Stopped video.");
        }
    }

    const handlePlayClick = () => {
        if (!keepPlayButtonAlways) {
            setPlayButtonVisible(false);
        }
        playVideo();
    };

    const playVideo = () => {
        if (!keepPlayButtonAlways) {
            setOverlayVisible(false);
            setPlayButtonVisible(false);
        }

        // Command the iframe to "play" via postMessage
        const iframe = document.querySelector(`#${id} .vimeo-iframe iframe`);
        if (iframe) {
            // @ts-ignore
            iframe.contentWindow.postMessage({method: "play"}, "*");
        }

        if (onPlay) {
            onPlay(playerRef);
        }
    };

    /**
     * The @u-wave/react-vimeo props that hide speed, hide title/byline/portrait,
     * show normal controls (volume + quality), and attempt autoplay if liveSimulation or autoPlay is set.
     */
    const showControls = true; // we need volume & quality
    const vimeoProps = {
        video: `https://vimeo.com/${vimeoVideoId}?share=copy&autoplay=1`,
        // Hide speed controls:
        speed: false,
        showByline: false, // or set false if you always want to hide them
        showTitle: false,
        showPortrait: false,
        // Show the main controls bar (volume, etc.):
        controls: showControls,
        // Let the video be responsive
        responsive: true,
        // If tracking is off, set dnt to true
        dnt: !trackStatistics,
        // Autoplay if liveSimulation or autoPlay
        // Potentially start from a given second offset
        start,
        // The "paused" state (only relevant if not in liveSimulation)
        paused,
        // Try not to autopause if you have multiple videos
        autopause: false,
        background: background,
        loop: background,

        // Attempt to start with sound
        // This event fires once the Vimeo player is ready
        onReady: (player: any) => handlePlayerReady(player),
    };

    return (
        <div
            className={
                customDivWrapperClasses
                    ? customDivWrapperClasses
                    : "mw-md-3xl mx-auto mb-8 position-relative"
            }
            id={id}
        >
            <Vimeo
                ref={playerRef}
                className="h-100 vimeo-iframe"
                {...vimeoProps}
            />

            {/* Gray overlay to simulate "click to play" if user hasn't pressed play yet.
          We skip it in live mode since it's always playing. */}
            {overlayVisible && (
                <div
                    className="overlay position-absolute top-0 start-0 w-100 h-100"
                    onClick={playVideo}
                    style={{background: "rgba(0,0,0,0.2)", cursor: "pointer"}}
                />
            )}

            {/* The original big play button if not in liveSimulation */}
            {playButtonVisible && (
                <div
                    className="position-absolute top-50 start-50 translate-middle text-success link-success play-button"
                    onClick={handlePlayClick}
                    style={{zIndex: 1, cursor: "pointer"}}
                >
                    <svg
                        width={64}
                        height={64}
                        viewBox="0 0 64 64"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx={32} cy={32} r={32} fill="currentColor"/>
                        <path
                            d="M40.5 31.13L26.5 23.05C26.348 22.9622 26.1755 22.916 26 22.916C25.8245 22.916 25.652 22.9622 25.5 23.05C25.3474 23.1381 25.2208 23.265 25.133 23.4177C25.0452 23.5705 24.9993 23.7438 25 23.92V40.08C24.9993 40.2562 25.0452 40.4295 25.133 40.5823C25.2208 40.735 25.3474 40.8619 25.5 40.95C25.652 41.0378 25.8245 41.084 26 41.084C26.1755 41.084 26.348 41.0378 26.5 40.95L40.5 32.87C40.6539 32.7828 40.7819 32.6563 40.871 32.5035C40.96 32.3506 41.007 32.1769 41.007 32C41.007 31.8231 40.96 31.6494 40.871 31.4965C40.7819 31.3437 40.6539 31.2172 40.5 31.13ZM27 38.35V25.65L38 32L27 38.35Z"
                            fill="white"
                        />
                    </svg>
                </div>
            )}
        </div>
    );
}