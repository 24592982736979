import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import ProductsSelectOption from "../../../components/user/products/ProductsSelectOption";
import {DeferredData} from "@remix-run/router/dist/utils";
import {Await, defer, useLoaderData} from "react-router-dom";
import {
    getGroupsFromAuthSession,
    hasEffectiveActionCourseSubscriberGroup,
    hasEffectiveActionMiniCourseSubscriberGroup,
    redirectIfNotLoggedIn
} from "../../../util/AuthUtil";
import {AuthService} from "../../../service/AuthService";
import React, {Suspense} from "react";
import LoadingPage from "../../common/LoadingPage";
import ErrorContent from "../../../components/common/error/ErrorContent";

interface LoaderData {
    refreshToken: Promise<any>
}

export default function ProductsPage() {
    const {
        refreshToken
    } = useLoaderData() as LoaderData;
    return <>
        <Meta title='Produkty'/>
        <MainDesign containerStyles='container py-12'>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={refreshToken} errorElement={<ErrorContent/>}>
                    {(loaded: any) => {
                        const groups = getGroupsFromAuthSession(loaded);
                        return <>
                            <ProductsSelectOption
                                isEffectiveActionCourseSubscriber={hasEffectiveActionCourseSubscriberGroup(groups)}
                                isEffectiveActionMiniCourseSubscriber={hasEffectiveActionMiniCourseSubscriberGroup(groups)}/>
                        </>
                    }}
                </Await>
            </Suspense>
        </MainDesign>
    </>
}

export async function loader(): Promise<DeferredData | Response> {
    const redirect = await redirectIfNotLoggedIn();
    if (redirect) {
        return redirect;
    }

    return defer({
        refreshToken: AuthService.refreshUserSession()
    });
}
