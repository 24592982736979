import Meta from "../../../components/common/Meta";
import React, {useEffect} from "react";
import Footer from "../../../components/common/Footer";
import ErrorContent from "../../../components/common/error/ErrorContent";
import UserHeader from "../../../components/user/UserHeader";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";
import {v4 as uuidv4} from "uuid";

export default function Error404() {

    useEffect(() => {
        const sessionId = localStorage.getItem('sessionId');
        if (!sessionId) {
            localStorage.setItem('sessionId', uuidv4());
        }
        TraceService.addTrace(TraceType.ErrorPage, undefined);
    }, []);

    return <>
        <Meta title='Produkacja - Nowoczesna platforma do nauki online' noIndex={true}/>
        <UserHeader/>
        <ErrorContent messageProps="Strona nie została znaleziona."/>
        <Footer/>
    </>
}