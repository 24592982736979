import {getRawIdTokenAsString} from "../util/AuthUtil";
import {TraceService} from "./TraceService";
import {TraceType} from "../model/user/general/Trace";

export interface ApiError {
    message: string;
    httpsStatus: string;
}

export enum HttpMethod {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
}

export enum ContentType {
    JSON = "application/json",
}

export class HttpService {
    private static API_URL = process.env.REACT_APP_API_GATEWAY_URL;

    public static sendRequest = async <T>(
        httpMethod: HttpMethod,
        url: string,
        body?: any,
        contentType?: ContentType,
        responseType?: 'json' | 'blob'
    ): Promise<T> => {
        const apiUrl = `${this.API_URL}${url}`
        if (process.env.NODE_ENV === 'development') {
            console.log(`Sending ${httpMethod} request to the API: ${apiUrl}`);
        }
        // it has to be handled separately to avoid infinite loop in case of error
        if (url !== '/api/trace' && url !== '/api/diagnostic' && url !== '/api/metrics' &&
            url !== '/api/update_episode_notes' && url !== "/api/webinar/update_webinar_statistics" &&
            !url.includes("/api/get_week_and_episodes") && !url.includes('/api/blog/random') &&
            url !== '/api/update_watch_time') {
            TraceService.addTrace(TraceType.RequestSend, `${httpMethod}: ${url}. Body: ${body}`);
        }
        return fetch(apiUrl, {
                method: httpMethod,
                headers: await this.getRequestHeaders(contentType),
                body: body
            }
        ).then((response) => this.processResponse(response, responseType ?? 'json'))
            .catch((error) => this.handleError(error, url));
    }

    private static processResponse = async (response: Response, responseType: 'json' | 'blob') => {
        let result;

        try {
            result = responseType === 'blob' ? await response.blob() : await response.json();
        } catch (error) {
            throw new Error(JSON.stringify({message: `Wystąpił błąd: ${error}`, httpsStatus: 500}));
        }

        if (response.ok) {
            return result;
        } else {
            throw new Error(JSON.stringify({message: result.message, httpsStatus: response.status}));
        }
    }

    private static handleError = (error: Error, url: string): Promise<ApiError> => {
        // it has to be handled separately to avoid infinite loop in case of error
        if (url !== '/api/trace' && url !== '/api/diagnostic') {
            TraceService.addTrace(TraceType.Error, `URL ${url}: ${error}`);
        }
        if (error && error.message && error.message === 'Failed to fetch') {
            return Promise.reject({
                message: 'Serwer jest aktualnie wyłączony. Spróbuj ponownie za chwilę.',
                httpsStatus: '503'
            });
        }
        try {
            const errorData = JSON.parse(error.message);
            return Promise.reject(errorData);
        } catch (error) {
            return Promise.reject({message: `Wystąpił błąd: ${error}`, httpsStatus: '500'});
        }
    }

    private static async getRequestHeaders(contentType?: ContentType): Promise<Headers> {
        let requestHeaders = new Headers();

        if (contentType) {
            requestHeaders.append("Content-Type", contentType);
        }

        const idToken = await getRawIdTokenAsString();

        if (idToken !== '') {
            requestHeaders.set("Authorization", `Bearer ${idToken}`);
        } else {
            requestHeaders.set("Authorization", `Bearer PUBLIC`);
        }

        return requestHeaders;
    }
}