import {NewsletterFollowUp} from "../../../model/user/general/Newsletter";
import MainDesign from "../../MainDesign";
import AdminNewsletterNavbar from "../../../components/admin/newsletter/AdminNewsletterNavbar";
import React, {Suspense} from "react";
import Meta from "../../../components/common/Meta";
import Heading from "../../../components/common/header/Heading";
import {NewsletterService} from "../../../service/NewsletterService";
import {Await, defer, useLoaderData} from "react-router-dom";
import {NewsletterType} from "../../../components/user/newsletter/NewsletterForm";
import AdminNewsletterFollowUpsStatistics
    from "../../../components/admin/newsletter/followup/AdminNewsletterFollowUpsStatistics";
import LoadingPage from "../../common/LoadingPage";
import ErrorContent from "../../../components/common/error/ErrorContent";

type LoaderData = {
    followUps: Promise<NewsletterFollowUp[]>
}

export default function AdminNewsletterFollowUpsStatisticsPage() {
    const {followUps} = useLoaderData() as LoaderData;

    return <>
        <Meta title='Statystyki Follow-upów'/>
        <MainDesign containerStyles='container py-28'>
            <Heading title={'Statystyki Auto Follow-upów'}/>
            <AdminNewsletterNavbar/>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={followUps} errorElement={<ErrorContent/>}>
                    {(loadedFollowUps: NewsletterFollowUp[]) => <>
                        <AdminNewsletterFollowUpsStatistics followUps={loadedFollowUps}/>
                    </>}
                </Await>
            </Suspense>
        </MainDesign>
    </>
}

async function loadNewsletterFollowUps(newsletterKey: NewsletterType): Promise<NewsletterFollowUp[]> {
    return await NewsletterService.getNewsletterFollowUps(newsletterKey);
}

export async function loader({request}: { request: Request }) {
    const url = new URL(request.url);
    const mailingList = url.searchParams.get('mailingList') ?? NewsletterType.WebinarKedFollowUpV1;

    return defer({
        followUps: loadNewsletterFollowUps(mailingList as NewsletterType)
    })
}

