import React, {useEffect, useRef, useState} from "react";
import {Link, useNavigate, useSearchParams, useSubmit} from "react-router-dom";
import {AnimatePresence, motion} from "framer-motion";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";
import {useToast} from "../../ui/toast/ToastContext";

import Heading from "../../common/header/Heading";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import AdvantageItem from "../../ui/item/AdvantageItem";
import PossibleUniversities from "../../user/other-logos/PossibleUniversities";
import {BubbleNotificationAboutTraining} from "../../ui/motion/BubbleNotificationAboutTraining";
import ResponsivePhotoWithText from "../../ui/element/ResponsivePhotoWithText";
import DependentOnScreenSizeElement from "../../ui/element/DependentOnScreenSizeElement";
import VideoWithPlayButton from "../../ui/video/VideoWithPlayButton";
import {getWebinarIdFromLocalStorage} from "../../../util/WebinarUtil";

// -------------------------------
// Utility / time-based functions
// -------------------------------
function isMobileDevice() {
    return /Android|iPhone|iPad|iPod|BlackBerry|Opera Mini|IEMobile|WPDesktop/i.test(
        navigator.userAgent
    );
}

function getMinutesDiff(a: Date, b: Date): number {
    return Math.floor((b.getTime() - a.getTime()) / 60000);
}

function getClosestQuarterHour(date: Date): Date {
    const clone = new Date(date);
    const minutes = clone.getMinutes();
    const remainder = minutes % 15;
    if (remainder !== 0) {
        clone.setMinutes(minutes + (15 - remainder));
    }
    clone.setSeconds(0, 0);
    return clone;
}

function isNight() {
    const now = new Date();
    const hour = now.getHours();
    return hour < 9 || hour >= 21;
}

/**
 * Main date-generation logic.
 * If the current hour is < 8, we offer 11:00 & 19:00 *today*.
 * If >= 21, we offer 11:00 & 19:00 *tomorrow*.
 * Otherwise:
 *   1) Next quarter-hour
 *   2) 11:00 today (if 2+ hours away), otherwise 11:00 tomorrow
 *   3) 19:00 today (if 2+ hours away), otherwise 19:00 tomorrow
 */
function getTimeOptions(ignoreClosestHour: boolean): Date[] {
    const now = new Date();
    const hour = now.getHours();

    const elevenToday = new Date(now);
    elevenToday.setHours(11, 0, 0, 0);

    const nineteenToday = new Date(now);
    nineteenToday.setHours(19, 0, 0, 0);

    const tomorrow = new Date(now);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const elevenTomorrow = new Date(tomorrow);
    elevenTomorrow.setHours(11, 0, 0, 0);

    const nineteenTomorrow = new Date(tomorrow);
    nineteenTomorrow.setHours(19, 0, 0, 0);

    const times: Date[] = [];

    if (hour < 9) {
        // Before 9: show 11:00 and 19:00 today
        times.push(elevenToday, nineteenToday);
    } else if (hour >= 21) {
        // After 21: show 11:00 and 19:00 tomorrow
        times.push(elevenTomorrow, nineteenTomorrow);
    } else {
        // Otherwise...
        const nextQuarter = getClosestQuarterHour(now);

        if (getWebinarIdFromLocalStorage() == null && !ignoreClosestHour) {
            times.push(nextQuarter);
        }

        const diffTo11 = getMinutesDiff(now, elevenToday);
        if (diffTo11 >= 120) {
            times.push(elevenToday);
        } else {
            times.push(elevenTomorrow);
        }

        const diffTo19 = getMinutesDiff(now, nineteenToday);
        if (diffTo19 >= 120) {
            times.push(nineteenToday);
        } else {
            times.push(nineteenTomorrow);
        }
    }

    times.sort((a, b) => a.getTime() - b.getTime());
    return times;
}

// -------------------------------
// Component
// -------------------------------
export default function WebinarOptIn() {
    const navigate = useNavigate();
    const {showMessage} = useToast();

    function handleSuccess(body: string) {
        // Convert your selected date string to a Date object
        const chosenDate = new Date(selectedDate);
        const now = new Date();

        // If it's 15 min or less until the chosenDate, navigate to /webinar/<body>, otherwise /webinar/potwierdzenie/<body>
        if (chosenDate.getTime() - now.getTime() <= 15 * 60 * 1000) {
            navigate(`/webinar/potwierdzenie-jit/${body}`);
        } else {
            navigate(`/webinar/potwierdzenie/${body}`);
        }
    }

    // Our custom form submission hook
    const {isSubmitting} = useSubmitFormAction(
        "/whatever",
        null,
        handleSuccess,
        false,
        undefined,
        (body) => {
            // On error → show toast
            showMessage(body);
        },
        true
    );

    // -------------------------------
    // Multi-step state
    // -------------------------------
    // step=1 → ask for email
    // step=2 → ask for name
    // step=3 → select date & submit
    const [step, setStep] = useState(1);

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [selectedDate, setSelectedDate] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [dateOptions, setDateOptions] = useState<Date[]>([]);

    const [searchParams] = useSearchParams();

    // For quick email validation
    const isEmailValid = email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    const submit = useSubmit();

    // Prepare date options on mount
    useEffect(() => {
        const allOptions = getTimeOptions(!!(searchParams.get("source") && searchParams.get("source")?.includes("Newsletter")));
        if (!isMobileDevice()) {
            setDateOptions(allOptions.slice(0, isNight() ? 2 : (allOptions.length === 2 ? 2 : 1)));
        } else {
            // On mobile → all date options
            setDateOptions(allOptions);
        }
    }, []);

    // Basic name validation
    const nameIsValid = (val: string) => !!val && val.trim().length > 1;

    // This button is "Next" on steps 1 & 2, but "Zapisz się" on step 3
    const nextButtonLabel = step < 3 ? "Przejdź dalej" : "Zapisz się";

    function handleNext() {
        if (step === 1) {
            if (!selectedDate) {
                setErrorMessage("Wybierz prawidłową datę szkolenia.");
                createRedBorderOnInput(dateInputRef);
                return;
            } else {
                setErrorMessage("");
                setStep(2);
            }
        } else if (step === 2) {
            if (!!isEmailValid) {
                setErrorMessage("");
                setStep(3);
            } else {
                setErrorMessage("Wprowadź prawidłowy adres email.");
                createRedBorderOnInput(emailInputRef);
            }
        } else if (step === 3) {
            if (!nameIsValid(name)) {
                setErrorMessage("Wprowadź prawidłowe imię (minimum 2 znaki).");
                createRedBorderOnInput(nameInputRef);
                return;
            }

            // Final step → submit
            const payload = {
                email,
                name,
                webinarDate: selectedDate,
            };
            submit(payload, {
                method: "POST",
            });
        }
    }

    // -------------------------------
    // Countdown Timer
    // -------------------------------
    const [hoursLeft, setHoursLeft] = useState<number | null>(null);
    const [minutesLeft, setMinutesLeft] = useState<number | null>(null);
    const [secondsLeft, setSecondsLeft] = useState<number | null>(null);
    const [showStartedMessage, setShowStartedMessage] = useState(false);

    useEffect(() => {
        if (!dateOptions.length) return;

        const firstAvailable = dateOptions[0];
        const tick = () => {
            const now = new Date();
            const diffSeconds = Math.floor((firstAvailable.getTime() - now.getTime()) / 1000);

            if (diffSeconds <= 0) {
                // Time's up → show "started" message
                setShowStartedMessage(true);
                setHoursLeft(null);
                setMinutesLeft(null);
                setSecondsLeft(null);
                return;
            }

            const h = Math.floor(diffSeconds / 3600);
            const m = Math.floor((diffSeconds % 3600) / 60);
            const s = diffSeconds % 60;

            setHoursLeft(h);
            setMinutesLeft(m);
            setSecondsLeft(s);
            setShowStartedMessage(false);
        };

        // Run immediately, then every second
        tick();
        const intervalId = setInterval(tick, 1000);

        return () => clearInterval(intervalId);
    }, [dateOptions]);

    function pad(num: number) {
        return num < 10 ? `0${num}` : `${num}`;
    }

    const emailInputRef = useRef<HTMLInputElement>(null); // Ref for email input

    const nameInputRef = useRef<HTMLInputElement>(null); // Ref for email input

    const dateInputRef = useRef<HTMLSelectElement>(null); // Ref for email input

    const createRedBorderOnInput = (ref: React.RefObject<HTMLInputElement | HTMLSelectElement>) => {
        if (ref.current) {
            ref.current.scrollIntoView({behavior: "smooth", block: "center"});
            ref.current.focus();
            ref.current.style.border = "3px solid #d30000";
            ref.current.style.transition = "border 0.3s ease-in-out";
        }
    };

    return (
        <>
            {/* Header (Mobile) */}
            <div className="d-block d-md-none">
                <p className="text-white text-center fs-12 mb-5">
                    Bezpłatne szkolenie dla rodziców <b>NASTOLATKÓW</b>
                </p>
                <Heading
                    customStyles="mb-0 text-center mw-md-5xl mx-auto"
                    title={"Jak zostać"}
                    customTitleStyles="font-heading mb-1 fs-2 text-white"
                />
                <Heading
                    customStyles="mb-0 text-center mw-md-5xl mx-auto mt-0"
                    title={"NAJLEPSZYM"}
                    customTitleStyles="font-heading mb-1 fs-2 text-warning"
                />
                <Heading
                    customStyles="mb-0 text-center mw-md-5xl mx-auto"
                    title={"uczniem?"}
                    customTitleStyles="font-heading mb-6 fs-2 text-white"
                />
                <p className="text-warning fs-11 fw-medium text-center mx-1">
                    Bez ciągłego polegania na rodzicach, motywacji czy korepetycjach.
                </p>
            </div>

            {/* Header (Desktop) */}
            <div className="d-none d-md-block">
                <p className="text-white text-center fs-11">
                    Bezpłatne szkolenie dla rodziców <b>NASTOLATKÓW</b>
                </p>
                <Heading
                    customStyles="mb-0 text-center mw-md-5xl mx-auto"
                    title={"Jak zostać"}
                    customTitleStyles="font-heading mb-1 fs-3 text-white"
                />
                <Heading
                    customStyles="mb-0 text-center mw-md-5xl mx-auto mt-0"
                    title={"NAJLEPSZYM"}
                    customTitleStyles="font-heading mb-1 fs-3 text-warning"
                />
                <Heading
                    customStyles="mb-0 text-center mw-md-5xl mx-auto"
                    title={"uczniem?"}
                    customTitleStyles="font-heading mb-6 fs-3 text-white"
                />
                <p className="text-warning fs-10 fw-medium text-center">
                    Bez ciągłego polegania na rodzicach, motywacji czy korepetycjach.
                </p>
            </div>

            {/* ------------------------------
          Multi-step section
      ------------------------------ */}
            <div className="mw-md-2xl mx-auto mt-6 mb-6 p-2">
                <form
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            handleNext();
                        }
                    }}
                >
                    <div style={{maxWidth: "480px"}} className="mx-auto">
                        <AnimatePresence mode="wait">
                            {/* STEP 2: EMAIL */}
                            {step === 2 && (
                                <motion.div
                                    key="step1"
                                    initial={{opacity: 0, x: -50}}
                                    animate={{opacity: 1, x: 0}}
                                    exit={{opacity: 0, x: 50}}
                                    transition={{duration: 0.3}}
                                >
                                    <div className="form-group mb-4">
                                        <label className="mb-2 fw-bold text-white" htmlFor="step1-email">
                                            Podaj swój adres email:
                                        </label>
                                        <input
                                            id="step1-email"
                                            type="email"
                                            ref={emailInputRef}
                                            className="input-lg form-control text-secondary-dark shadow p-3 px-4"
                                            placeholder="np. jan.kowalski@gmail.com"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                </motion.div>
                            )}

                            {/* STEP 3: NAME */}
                            {step === 3 && (
                                <motion.div
                                    key="step2"
                                    initial={{opacity: 0, x: -50}}
                                    animate={{opacity: 1, x: 0}}
                                    exit={{opacity: 0, x: 50}}
                                    transition={{duration: 0.3}}
                                >
                                    <div className="form-group mb-4">
                                        <label className="mb-2 fw-bold text-white" htmlFor="step2-name">
                                            Twoje imię:
                                        </label>
                                        <input
                                            id="step2-name"
                                            type="text"
                                            className="input-lg form-control text-secondary-dark shadow p-3"
                                            placeholder="np. Jan"
                                            value={name}
                                            ref={nameInputRef}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                </motion.div>
                            )}

                            {/* STEP 1: DATE */}
                            {step === 1 && (
                                <motion.div
                                    key="step3"
                                    initial={{opacity: 0, x: -50}}
                                    animate={{opacity: 1, x: 0}}
                                    exit={{opacity: 0, x: 50}}
                                    transition={{duration: 0.3}}
                                >
                                    <div className="form-group mb-4">
                                        <DependentOnScreenSizeElement mobileElement={<label
                                            className="mb-2 fw-bold text-white text-center" htmlFor="step3-date">
                                            Odbierz BEZPŁATNE szkolenie oraz GOTOWE do wdrożenia w życie materiały.
                                        </label>} desktopElement={<label
                                            style={{maxWidth: "800px", width: "auto"}}
                                            className="mb-2 fw-bold text-white text-center"
                                            htmlFor="step3-date">
                                            Odbierz BEZPŁATNE szkolenie oraz GOTOWE materiały.
                                        </label>}/>

                                        <select
                                            id="step3-date"
                                            className="input-lg form-control text-secondary-dark shadow p-3"
                                            value={selectedDate}
                                            ref={dateInputRef}
                                            onChange={(e) => setSelectedDate(e.target.value)}
                                        >
                                            <option value="" disabled hidden>
                                                -- Wybierz datę szkolenia --
                                            </option>
                                            {dateOptions.map((opt) => {
                                                const iso = opt.toISOString();
                                                const dayOfWeek = opt.toLocaleString("pl-PL", {
                                                    weekday: "long",
                                                });
                                                const day = opt.getDate();
                                                const month = opt.getMonth() + 1;
                                                const hours = opt.getHours();
                                                const minutes = opt.getMinutes();

                                                const dd = day < 10 ? `0${day}` : day.toString();
                                                const mm = month < 10 ? `0${month}` : month.toString();
                                                const HH = hours < 10 ? `0${hours}` : hours.toString();
                                                const MM = minutes < 10 ? `0${minutes}` : minutes.toString();

                                                const capitalizedDayOfWeek =
                                                    dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);

                                                const label = `${capitalizedDayOfWeek}, ${dd}.${mm} ${HH}:${MM}`;
                                                return (
                                                    <option key={iso} value={iso}>
                                                        {label}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>
                        {
                            errorMessage && <>
                                <p className="text-left mb-0 text-danger"
                                   style={{marginTop: "-12px"}}>{errorMessage}</p>
                            </>
                        }

                        {/* Single button controlling "next" or "submit" */}
                        <div className="text-center mt-4">
                            <AnimatedButton
                                type="button" // ensures we do NOT auto-submit on Enter
                                className="mx-auto btn btn-lg fw-bold btn-success text-success-light shadow w-100"
                                onClick={handleNext}
                                disabled={
                                    isSubmitting
                                }
                            >
                                {isSubmitting ? "Trwa zapisywanie..." : nextButtonLabel}
                            </AnimatedButton>
                        </div>

                        {step === 3 && (
                            <p className="mb-1 fs-14 fw-medium text-secondary-light text-left mt-2">
                                Przechodząc dalej akceptujesz{" "}
                                <Link className="text-success link-success" to="/polityka-prywatnosci">
                                    politykę prywatności
                                </Link>{" "}
                                i zapisujesz się do newslettera, z którego w każdej chwili możesz się wypisać.
                            </p>
                        )}
                    </div>
                </form>

                {/* ===========================
            Countdown Timer TODO: Automatic transition !
        ============================ */}
                {dateOptions.length > 0 && (
                    <div className="text-center text-white mt-5">
                        {showStartedMessage ? (
                            <DependentOnScreenSizeElement mobileElement={<motion.div
                                key="startedMessage"
                                initial={{opacity: 1}}
                                transition={{
                                    duration: 1.5, // Duration of one complete pulse
                                    repeat: Infinity, // Infinite loop
                                    ease: "easeInOut", // Smooth easing
                                }}
                                style={{
                                    maxWidth: "480px",
                                    fontSize: "1.2rem",
                                    fontWeight: "bold",
                                    color: "#f39c12", // highlight color
                                    padding: "1rem",
                                    border: "2px solid #f39c12",
                                    borderRadius: "10px",
                                    backgroundColor: "#2a3342",
                                    display: "inline-block",
                                }}
                                onClick={handleNext}
                            >
                                Szkolenie właśnie się rozpoczęło. Zapisz się na powyżej.
                            </motion.div>} desktopElement={<motion.div
                                key="startedMessage"
                                initial={{opacity: 1}}
                                transition={{
                                    duration: 1.5, // Duration of one complete pulse
                                    repeat: Infinity, // Infinite loop
                                    ease: "easeInOut", // Smooth easing
                                }}
                                style={{
                                    maxWidth: "480px",
                                    fontSize: "1.5rem",
                                    fontWeight: "bold",
                                    color: "#f39c12", // highlight color
                                    padding: "1rem",
                                    border: "2px solid #f39c12",
                                    borderRadius: "10px",
                                    backgroundColor: "#2a3342",
                                    display: "inline-block",
                                }}
                                onClick={handleNext}

                            >
                                Szkolenie właśnie się rozpoczęło. Zapisz się na powyżej.
                            </motion.div>}/>
                        ) : (
                            <>
                                <p className="fs-9 fw-bold text-warning mb-3">
                                    Następne szkolenie rozpocznie się za:
                                </p>
                                <div className="d-flex justify-content-center gap-8">
                                    {/* HOURS */}
                                    <div>
                                        <div className="fs-2 fw-bold">
                                            {hoursLeft !== null ? pad(hoursLeft) : "00"}
                                        </div>
                                        <div className="fs-8">Godz.</div>
                                    </div>
                                    {/* MINUTES */}
                                    <div>
                                        <div className="fs-2 fw-bold">
                                            {minutesLeft !== null ? pad(minutesLeft) : "00"}
                                        </div>
                                        <div className="fs-8">Min.</div>
                                    </div>
                                    {/* SECONDS */}
                                    <div>
                                        <div className="fs-2 fw-bold">
                                            {secondsLeft !== null ? pad(secondsLeft) : "00"}
                                        </div>
                                        <div className="fs-8">Sek.</div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>

            {/* ------------------------------------
          Rest of your content
      ------------------------------------ */}
            {/*<VideoWithPlayButton vimeoVideoId='1054114829' id='video' background={true} autoPlay={true} onPlay={() => {*/}
            {/*    handleNext()*/}
            {/*}} keepPlayButtonAlways={true}/>*/}
            <ResponsivePhotoWithText
                customComponentInsteadOfPhoto={<>
                    <div style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                    }}>
                        <VideoWithPlayButton vimeoVideoId='1054258988' id='video' background={true} autoPlay={true}
                                             ignoreTraces={true}
                                             onPlay={() => {
                                                 handleNext()
                                             }} keepPlayButtonAlways={true}/>
                    </div>
                </>}
                photoUrl="https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/opt-in-photo.webp"
                paddingPhoto="66.67%"
                textPart={
                    <>
                        <Heading
                            customTitleStyles="font-heading mb-4 fs-6 text-white mt-3 mt-md-0"
                            customStyles="mb-6 text-md-left mw-md-4xl mx-auto"
                            title="Na szkoleniu pokażę Ci:"
                        />
                        <AdvantageItem
                            title="Jak Twoje dziecko może uczyć się efektywniej i zdobywać najlepsze oceny."
                        />
                        <AdvantageItem
                            title="Jak z łatwością pomóc swojemu dziecku w systematycznej i regularnej nauce."
                        />
                        <AdvantageItem
                            title="Jak poradzić sobie z uzależnieniem od telefonu lub komputera."
                        />
                        <AdvantageItem
                            title="Sprawdzony proces na wiarę w swoje możliwości oraz całkowitą redukcję stresu przed sprawdzianem/kartkówką."
                        />
                        <AdvantageItem
                            title="Dlaczego korepetycje nie są rozwiązaniem – i jak sprawić, by nie były więcej potrzebne."
                        />
                    </>
                }
            />

            {/* CTA button on mobile to scroll back to email field */}
            <div className="d-block d-md-none p-3">
                <button className="btn btn-lg btn-success w-100" onClick={handleNext}>
                    Zapisz się
                </button>
            </div>

            {/* Logos / Universities */}
            <PossibleUniversities/>

            {/* Small popup notifications */}
            <BubbleNotificationAboutTraining
                message="Zapisał/a się na bezpłatne szkolenie"
                delay={3000}   // Show after 3 seconds
                duration={6000} // Hide after 5 seconds
            />
        </>
    );
}