import React from "react";
import {ApiError} from "../../../service/HttpService";
import MainDesign from "../../MainDesign";
import Meta from "../../../components/common/Meta";
import {AdminSendNewsletterRequest} from "../../../model/user/general/Newsletter";
import {NewsletterService} from "../../../service/NewsletterService";
import AdminNewsletterNavbar from "../../../components/admin/newsletter/AdminNewsletterNavbar";
import Heading from "../../../components/common/header/Heading";
import AdminSendNewsletter from "../../../components/admin/newsletter/AdminSendNewsletter";

export enum SendNewsletterType {
    TestEmails = "Testowy email (I)",
    DailyMathExercises = "Subskrybenci codziennych zadań z matematyki",
    Newsletter = "Subskrybenci newslettera",
    Planner = "Subskrybenci planera",
    KedOptInParent = "Rodzice OptIn KED (I)",
    KedOptInStudent = "Uczniowie OptIn KED (I)",
    KedOptInMaturaParent = "Rodzice maturzystów (I) - Auto FE",
    KedOptInAll = "OptIn KED - wszyscy (I) - Auto FE",
    MWOptInParent = "Rodzice uczniów do Mistrzowskiego Wsparcia (I) - Auto FE",
    KedScheduledSalesCall = "Umówieni na sales call KED (wszyscy) (I)",
    KedMaturaParentScheduledSalesCall = "Umówieni na sales call KED (rodzice maturzystów) (I)",
    MWParentScheduledSalesCall = "Umówieni na sales call MW (rodzice) (I)",
    WebinarKedOptInV1 = 'OptIn Webinar (wszyscy) (I)',
    WebinarKedNoShowV1 = 'NoShow Webinar (I) - Auto FE',
    WebinarKedFollowUpV1 = 'FollowUp Webinar (I) - Auto FE',
    WebinarKedPurchaserV1 = 'Klient KED (płatnik) (I - może być inne...)',
    PedPurchaserV1 = 'Klient PED (płatnik) (I - może być inne...)',
    RegisteredUsersNewsletterContent = "Zarejestrowani użytkownicy (zgoda na marketing) (I)",
    RegisteredUsersAll = "Zarejestrowani użytkownicy (wszyscy) (I)",
    KedUsers = "Użytkownicy KED (wszyscy) (I)",
    PedUsers = "Użytkownicy PED (wszyscy) (I)"
}

export default function AdminSendNewsletterPage() {


    return <>
        <Meta title="Newsletter"/>
        <MainDesign containerStyles="container py-20" minHeight="92vh">
            <Heading title='Wyślij Newsletter'/>
            <AdminNewsletterNavbar/>
            <AdminSendNewsletter/>
        </MainDesign>
    </>
}

export async function action({request}: { request: Request }) {
    const formData = await request.formData();

    try {
        const rawTypes = formData.get('types') as string;
        const rawTypesToExclude = formData.get('typesToExclude') as string;
        const types = rawTypes.split(",") as SendNewsletterType[];
        const typesToExclude = rawTypesToExclude.split(",") as SendNewsletterType[];
        const sendNewsletterData = {
            subject: formData.get('subject') as string,
            message: formData.get('message') as string,
            email: formData.get('email') as string,
            joinedBefore: formData.get('joinedBefore') as string,
            source: formData.get('source') as string,
            preview: formData.get('preview') as string,
            name: formData.get('name') as string,
            types: types,
            typesToExclude: typesToExclude.length === 1 && typesToExclude[0] as any === "" ? [] : typesToExclude
        } as AdminSendNewsletterRequest;

        const response = await NewsletterService.sendNewsletter(sendNewsletterData);


        return {
            status: 200,
            body: response.message
        }
    } catch (error) {
        const apiError = error as ApiError;
        return {
            status: 500,
            body: apiError.message
        }
    }
}