import {useFetcher, useLoaderData} from "react-router-dom";
import React, {useState} from "react";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";
import {CancelNewsletterData} from "./CancelNewsletter";

export default function CancelNewsletterForm() {
    const {email, type, newsletterId, followUpName} = useLoaderData() as CancelNewsletterData;
    const fetcher = useFetcher();
    const {isSubmitting} = useSubmitFormAction('/', fetcher);

    const [reason, setReason] = useState('');

    return <fetcher.Form
        method='post'
        action='/anuluj-newsletter'
        className='mw-md mx-auto'
    >
        <div className='mb-4 row g-6'>
            <div className='col-12'>
                <div className='form-group'>
                    <input name='email' value={email} hidden readOnly/>
                    <input name='type' value={type} hidden readOnly/>
                    <input name='newsletterId' value={newsletterId ?? ''} hidden readOnly/>
                    <input name='followUpName' value={followUpName ?? ''} hidden readOnly/>
                    <textarea
                        className='form-control text-secondary-dark shadow'
                        id='signInInput3-1'
                        aria-describedby='emailHelp'
                        placeholder='Podaj powód, dlaczego odchodzisz'
                        name='reason'
                        rows={4}
                        value={reason}
                        onChange={(event) => setReason(event.target.value)}
                    />
                </div>
            </div>
        </div>
        <div className='mb-6 row g-4'>
            <div className='col-12'>
                <button
                    className='btn btn-lg btn-success fs-11 py-3 w-100 text-success-light shadow'
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Trwa anulowanie...' : 'Anuluj subskrypcję'}
                </button>
            </div>
        </div>
    </fetcher.Form>
}