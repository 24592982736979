import React from "react";

export default function ProdukacjaCopyrightNoteWithPhoneNumber() {
    return <>
        <hr className='text-white'/>
        <p className='text-white px-3 pt-3 mb-1 text-center fw-medium fs-10'>
            Masz pytania? +48 724 168 462
            <span className='d-none d-md-inline'> | </span>
            <br className='d-md-none'/>
            <a href="mailto:kontakt@produkacja.pl" className='text-white text-decoration-none'>
                kontakt@produkacja.pl
            </a>
        </p>
        <p className='text-secondary-light px-3 pt-3 mb-1 text-center'>
            © 2025 Produkacja sp. z o. o.
        </p>
    </>
}