import React from "react";
import Heading from "../../common/header/Heading";
import CommunityNavbar from "./CommunityNavbar";
import CalendlyInlineLazy from "../calendly/CalendlyInlineLazy";
import {TokenPayload} from "../../../util/AuthUtil";

const CALENDLY_LINK = "https://calendly.com/produkacja/cotygodniowe-konsultacje-ked";

interface CommunityWeeklyMeetingsProps {
    payload: TokenPayload
}

export default function CommunityWeeklyMeetings({payload}: CommunityWeeklyMeetingsProps) {

    return <>
        <Heading title='Cotygodniowe spotkania online' badgeText='Społeczność'/>
        <CommunityNavbar/>

        <div className="mb-0 mt-6 fs-11 text-center fw-medium text-white mw-md-xl mx-auto">
            <p>
                Spotkania odbywają się co tydzień w <span
                className='text-warning fw-bold'>sobotę o godzinie 17:00</span>.
            </p>
            <p>
                Zarejestruj się na spotkanie korzystając z poniższego kalendarza.
            </p>
            <p>Na podanego maila przyjdzie link do spotkania na zoomie.</p>
        </div>


        <CalendlyInlineLazy calendlyUrl={CALENDLY_LINK} email={payload.email} name={payload.name}/>
    </>
}