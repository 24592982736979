import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import Heading from "../../../components/common/header/Heading";
import {PurchaseOption} from "../../../model/user/purchase/PurchaseOption";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";
import {Await, defer, Params, redirect, useLoaderData} from "react-router-dom";
import {ApiError} from "../../../service/HttpService";
import {PurchaseService} from "../../../service/PurchaseService";
import {IndividualKedOffer} from "../../../model/user/purchase/IndividualKedOffer";
import LoadingPage from "../../common/LoadingPage";
import ErrorContent from "../../../components/common/error/ErrorContent";
import React, {Suspense} from "react";
import ExtraordinarySupportPurchase from "../../../components/user/extraordinary-support/ExtraordinarySupportPurchase";
import {getSessionId} from "../../../util/TrackingUtil";
import {encodeUrlIfExists} from "../../../util/Util";

type LoaderData = {
    individualKedOffer: Promise<IndividualKedOffer>;
};

export default function ExtraordinarySupportPurchasePage() {
    const {individualKedOffer} = useLoaderData() as LoaderData;

    return <>
        <Meta title="MISTRZOWSKIE Wsparcie - Produkacja"/>
        <MainDesign containerStyles='container py-28'>
            <Heading title='MISTRZOWSKIE Wsparcie'
                     subtitle="Jeszcze tylko jeden krok dzieli Cię od uzyskania Mistrzowskiego Wsparcia!"
                     badgeText='świetna decyzja'/>

            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={individualKedOffer} errorElement={<ErrorContent/>}>
                    {(loadedOffer: IndividualKedOffer) => <>
                        <ExtraordinarySupportPurchase individualKedOffer={loadedOffer}/>
                    </>}
                </Await>
            </Suspense>
        </MainDesign>
    </>
}

async function loadIndividualKedOffer(ksuid: string): Promise<IndividualKedOffer> {
    return await PurchaseService.getIndividualKedOffer(ksuid);
}


export async function loader({params}: { params: Params<"ksuid"> }) {
    return defer({
        individualKedOffer: params && params.ksuid ? loadIndividualKedOffer(params.ksuid!!) : null,
    });
}

export async function action({request, params}: { request: Request, params: any }) {
    const formData = await request.formData();
    const purchaseData = {
        name: formData.get('name') as string,
        email: formData.get('email') as string,
    };
    const {ksuid} = params;

    try {
        const redirectUrl = await PurchaseService.initPurchase({
            purchaseOptionName: PurchaseOption.EXTRAORDINARY_MONTHLY_SUPPORT.toString(),
            name: purchaseData.name,
            email: purchaseData.email,
            source: localStorage.getItem('firstJoinedFrom') ?? '',
            individualOfferId: ksuid,
            successRedirectPath: "/zakup/mistrzowskie-wsparcie/sukces",
            errorRedirectPath: "/zakup/mistrzowskie-wsparcie/blad",
            sessionId: getSessionId()
        });
        TraceService.addTrace(TraceType.Redirect, `Redirect url: ${encodeUrlIfExists(redirectUrl)}, purchaseOptionName: ${PurchaseOption.EXTRAORDINARY_MONTHLY_SUPPORT.toString()}`, undefined, undefined, undefined, purchaseData.email);
        return redirect(redirectUrl);
    } catch (error) {
        const apiError = error as ApiError;
        return {
            status: 500,
            body: apiError.message
        }
    }
}