import {fetchAuthSession, JWT} from 'aws-amplify/auth';
import {redirect} from "react-router-dom";
import {v4 as uuidv4} from 'uuid';
import {TraceService} from "../service/TraceService";
import {TraceType} from "../model/user/general/Trace";
import Clarity from "@microsoft/clarity";
import {generateSessionIdIfNotExists} from "./TrackingUtil";

const ADMIN_GROUP = process.env.REACT_APP_ADMIN_GROUP!!;

const EFFECTIVE_ACTION_COURSE_SUBSCRIBER = "Effective_Action_Course_Subscriber";

const EFFECTIVE_ACTION_MINI_COURSE_SUBSCRIBER = "Effective_Action_Mini_Course_Subscriber";

export interface TokenPayload {
    aud: string
    auth_time: number
    'cognito:groups': string[]
    'cognito:username': string
    'custom:confirmationCode': string
    email: string
    email_verified: boolean
    event_id: string
    exp: number
    iat: number
    iss: string
    jti: string
    name: string
    origin_jti: string
    sub: string
    token_use: string
}

async function getRawIdToken(): Promise<JWT | null> {
    try {
        const {idToken} = (await fetchAuthSession()).tokens ?? {};

        return idToken ? idToken : null;
    } catch (err) {
        console.log(err);
        return null;
    }
}

export async function getRawIdTokenAsString(): Promise<string> {
    try {
        const idToken = await getRawIdToken()

        return idToken ? idToken.toString() : '';
    } catch (err) {
        console.log(err);
        return '';
    }
}

export async function getRawIdTokenWithGroups(): Promise<[string, string[]]> {
    try {
        const idToken = await getRawIdToken();

        if (!idToken) {
            return ['', []];
        }

        const groups = idToken.payload['cognito:groups'];

        try {
            Clarity.identify(idToken.payload['sub'] ?? uuidv4());
        } catch (error) {
        }

        if (!groups) {
            return [idToken.toString(), []];
        }

        return [idToken.toString(), groups as string[]];
    } catch (err) {
        console.log(err);
        return ['', []];
    }
}

export async function tokenLoader({request}: { request: Request }): Promise<[string, string[]]> {
    generateSessionIdIfNotExists();
    TraceService.addTrace(TraceType.PageLoad, undefined, request.url);
    return await getRawIdTokenWithGroups();
}

export function loadTokenWithGroupsFromLoaderData(loaderData: any): [string, string[]] {
    let idToken: string;
    let groups: string[];
    if (loaderData) {
        [idToken, groups] = loaderData as [string, string[]];
    } else {
        idToken = '';
        groups = [];
    }

    return [idToken, groups];
}

export async function isLoggedIn(): Promise<boolean> {
    return (await getRawIdTokenAsString()) !== '';
}

export async function redirectIfLoggedIn(): Promise<Response | null> {
    const token = await getRawIdTokenAsString();

    if (token !== '') {
        return redirect('/');
    }
    return null;
}

export async function redirectIfNotLoggedIn(): Promise<Response | null> {
    const token = await getRawIdTokenAsString();

    if (!token) {
        return redirect('/logowanie');
    }
    return null;
}

export async function redirectIfNotAdmin(): Promise<Response | [string, string[]]> {
    const sessionId = localStorage.getItem('sessionId');
    if (!sessionId) {
        localStorage.setItem('sessionId', uuidv4());
    }
    const tokens = (await fetchAuthSession()).tokens;
    if (!tokens) {
        return redirect('/logowanie');
    }

    const rawGroups = tokens.accessToken.payload['cognito:groups'];

    if (!rawGroups) {
        return redirect('/');
    }

    const groups = rawGroups as string[]

    if (!groups.includes(ADMIN_GROUP)) {
        return redirect('/');
    }

    return await getRawIdTokenWithGroups();
}

export async function redirectIfNotAnyCourseSubscriber(): Promise<Response | null> {
    const [_, groups] = await getRawIdTokenWithGroups();
    if (!hasEffectiveActionCourseSubscriberGroup(groups) && !hasEffectiveActionMiniCourseSubscriberGroup(groups)) {
        return redirect('/oferta/kurs-efektywnego-dzialania');
    }
    return null;
}

export async function redirectIfNotEffectiveActionCourseSubscriber(): Promise<Response | null> {
    const [_, groups] = await getRawIdTokenWithGroups();
    if (!hasEffectiveActionCourseSubscriberGroup(groups)) {
        return redirect('/oferta/kurs-efektywnego-dzialania');
    }
    return null;
}

export async function redirectIfNotEffectiveActionMiniCourseSubscriber(): Promise<Response | null> {
    const [_, groups] = await getRawIdTokenWithGroups();
    if (!hasEffectiveActionMiniCourseSubscriberGroup(groups)) {
        return redirect('/newsletter/podstawy-efektywnego-dzialania');
    }
    return null;
}

export async function isAdmin(): Promise<boolean> {
    const [_, groups] = await getRawIdTokenWithGroups();
    return hasAdminGroup(groups);
}

export async function isEffectiveActionCourseSubscriber(): Promise<boolean> {
    const [_, groups] = await getRawIdTokenWithGroups();
    return hasEffectiveActionCourseSubscriberGroup(groups);
}

export async function isEffectiveActionMiniCourseSubscriber(): Promise<boolean> {
    const [_, groups] = await getRawIdTokenWithGroups();
    return hasEffectiveActionMiniCourseSubscriberGroup(groups);
}

export function hasAdminGroup(groups: string[]): boolean {
    return groups.includes(ADMIN_GROUP);
}

export function hasEffectiveActionCourseSubscriberGroup(groups: string[]): boolean {
    return groups.includes(EFFECTIVE_ACTION_COURSE_SUBSCRIBER);
}

export function hasEffectiveActionMiniCourseSubscriberGroup(groups: string[]): boolean {
    return groups.includes(EFFECTIVE_ACTION_MINI_COURSE_SUBSCRIBER);
}

export async function getCurrentlyLoggedInUser(): Promise<TokenPayload | null> {
    // otherwise it returns null on first call...
    await new Promise(resolve => setTimeout(resolve, 1));
    const session = await fetchAuthSession();
    return session ? (session.tokens?.idToken?.payload as unknown as TokenPayload) : null;
}

export function getGroupsFromAuthSession(session: any): string[] {
    return session.tokens.idToken.payload['cognito:groups'];
}