import {ContentType, HttpMethod, HttpService} from "./HttpService";
import {
    CompleteEpisodeRequest,
    CourseEpisode,
    CourseStatistics,
    CourseWeekAndEpisodes,
    DiscordIdResponse,
    UpdateCourseWatchTimeRequest,
    UpdateEpisodeNotes
} from "../model/user/learning-course/LearningCourse";
import {UserCourse} from "../model/admin/UserCourse";
import {AvailableCourses} from "../model/user/learning-course/AvailableCourses";

export class CourseService {

    public static async getCourseStatistics(course: AvailableCourses): Promise<CourseStatistics> {
        return HttpService.sendRequest<CourseStatistics>(
            HttpMethod.GET,
            `/api/get_course_statistics?courseType=${course}`,
        );
    }

    public static async updateCourseWatchTime(updateCourseWatchTimeRequest: UpdateCourseWatchTimeRequest): Promise<void> {
        try {
            return HttpService.sendRequest<void>(
                HttpMethod.POST,
                '/api/update_watch_time',
                JSON.stringify(updateCourseWatchTimeRequest),
                ContentType.JSON
            );
        } catch (error) {
            console.log('Error updating watch time:', error);
        }
    }

    public static async updateEpisodeNotes(updateEpisodeNotes: UpdateEpisodeNotes): Promise<void> {
        try {
            return HttpService.sendRequest<void>(
                HttpMethod.POST,
                '/api/update_episode_notes',
                JSON.stringify(updateEpisodeNotes),
                ContentType.JSON
            );
        } catch (error) {
            console.log('Error updating watch time:', error);
        }
    }

    public static async completeEpisode(completeEpisodeRequest: CompleteEpisodeRequest): Promise<void> {
        try {
            return HttpService.sendRequest<void>(
                HttpMethod.POST,
                '/api/complete_episode',
                JSON.stringify(completeEpisodeRequest),
                ContentType.JSON
            );
        } catch (error) {
            console.log('Error updating watch time:', error);
        }
    }

    public static async getCourseWeekAndEpisodes(weekNum: number, episodesNum: number, silent: boolean = false, course: AvailableCourses): Promise<CourseWeekAndEpisodes> {
        return HttpService.sendRequest<CourseWeekAndEpisodes>(
            HttpMethod.GET,
            `/api/get_week_and_episodes?weekNum=${weekNum}&episodesNum=${episodesNum}&silent=${silent}&courseType=${course}`,
        );
    }

    public static async getCourseEpisode(weekNum: number, episodeNum: number, course: AvailableCourses): Promise<CourseEpisode> {
        return HttpService.sendRequest<CourseEpisode>(
            HttpMethod.GET,
            `/api/get_episode?weekNum=${weekNum}&episodeNum=${episodeNum}&courseType=${course}`,
        );
    }

    // Unused for now
    public static async generateIndividualSessionUrl(): Promise<{ url: string }> {
        return HttpService.sendRequest<{ url: string }>(
            HttpMethod.POST,
            '/api/generate_individual_session_url',
            null,
            ContentType.JSON
        );
    }

    // Admin-only
    public static async getKedUsers(course: AvailableCourses): Promise<UserCourse[]> {
        return HttpService.sendRequest<UserCourse[]>(
            HttpMethod.GET,
            `/api/get_ked_users?courseType=${course}`,
        );
    }

    // Admin-only | Only for KED for now
    public static async unlockNextWeek(userId: string): Promise<void> {
        return HttpService.sendRequest<void>(
            HttpMethod.POST,
            '/api/unlock_next_week',
            JSON.stringify({userId}),
            ContentType.JSON
        );
    }

    // KED-only | Only for KED for now
    public static async configureDiscordId(discordId: string): Promise<DiscordIdResponse> {
        return HttpService.sendRequest<DiscordIdResponse>(
            HttpMethod.POST,
            '/api/configure_discord_id',
            JSON.stringify({discordId}),
            ContentType.JSON
        );
    }
}