// Chat.tsx
import React, {KeyboardEvent, useEffect, useRef} from "react";
import {TraceService} from "../../../../service/TraceService";
import {TraceType} from "../../../../model/user/general/Trace";
import {KED_WEBINAR_EXPERIMENT_AB_9} from "../../../../util/ExperimentUtil";
import {WebinarDetails} from "../../../../model/user/webinar/WebinarDetails";

export interface ChatMessage {
    text: string;
    isUser: boolean;
    timestamp: Date;
    userName?: string;
}

export interface ChatProps {
    chatContainerRef: React.RefObject<HTMLDivElement>;
    chatMessages: ChatMessage[];
    userInput: string;
    setUserInput: React.Dispatch<React.SetStateAction<string>>;
    handleKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
    handleSend: () => void;
    webinar: WebinarDetails;
}

export default function Chat({
                                 chatContainerRef,
                                 chatMessages,
                                 userInput,
                                 setUserInput,
                                 handleKeyDown,
                                 handleSend,
                                 webinar,
                             }: ChatProps) {
    // Create a ref for the dummy bottom element
    const bottomRef = useRef<HTMLDivElement>(null);

    // Scroll the dummy element into view within the chat container only.
    useEffect(() => {
        bottomRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "nearest",
        });
    }, [chatMessages]);

    return (
        <>
            <div
                className="bg-light-dark d-flex flex-column p-3 rounded-3"
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                }}
            >
                <div
                    ref={chatContainerRef}
                    className="flex-grow-1 overflow-auto mb-3 p-2"
                    // Adding overscrollBehavior ensures only the container scrolls
                    style={{
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        overscrollBehavior: "contain",
                    }}
                >
                    {chatMessages.map((msg, index) => {
                        const isOrganizer =
                            msg.userName && msg.userName.includes("Marysia z Produkacji");
                        const isProdukacja = msg.userName === "Produkacja";

                        return (
                            <div
                                key={index}
                                className={`mb-3 ${msg.isUser ? "text-end" : "text-start"}`}
                            >
                                <div
                                    className="d-inline-block p-2"
                                    style={{
                                        background: msg.isUser ? "#0d6efd" :
                                            isProdukacja ? "gold" : "#6c757d",
                                        color: isProdukacja ? "#000" : "#fff",
                                        borderRadius: "12px",
                                        maxWidth: "80%",
                                        wordWrap: "break-word",
                                        fontWeight: isProdukacja ? "bold" : "normal"
                                    }}
                                >
                                    {isOrganizer && (
                                        <div className="fw-bold text-danger">
                                            {msg.userName || "Organizator"}
                                        </div>
                                    )}
                                    {!isOrganizer && !isProdukacja && msg.userName && (
                                        <div className="fw-bold">{msg.userName}</div>
                                    )}
                                    <div>
                                        {msg.text && msg.text.includes("https://") ? (
                                            <a
                                                onClick={() =>
                                                    TraceService.addTraceWithExperiment(
                                                        TraceType.WebinarLinkClick,
                                                        KED_WEBINAR_EXPERIMENT_AB_9,
                                                        webinar.variation,
                                                        webinar.source
                                                    )
                                                }
                                                target="_blank"
                                                className="text-success text-decoration-underline"
                                                href={msg.text}
                                            >
                                                {msg.text}
                                            </a>
                                        ) : (
                                            msg.text
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    {/* Dummy element to scroll into view */}
                    <div ref={bottomRef}/>
                </div>

                <div className="d-flex">
                    <input
                        type="text"
                        className="form-control me-2"
                        placeholder="Napisz wiadomość..."
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                    <button className="btn btn-success" onClick={handleSend}>
                        Wyślij
                    </button>
                </div>
            </div>
        </>
    );
}