export enum TraceType {
    PageLoad = "PageLoad",
    ErrorPage = "ErrorPage",
    BtnClick = "BtnClick",
    InitTransaction = "InitTransaction",
    InitTransactionSalesPage = "InitTransactionSalesPage",
    InitPedTransactionSalesPage = "InitPedTransactionSalesPage",
    ScheduleSmsReminder = "ScheduleSmsReminder",
    PlayVideo = "PlayVideo",
    RequestSend = "RequestSend",
    Redirect = "Redirect",
    Error = "Error",
    SolvedQuiz = "SolvedQuiz",
    CookieConsent = "CookieConsent",
    Experiment = "Experiment",
    ScheduledSalesCall = "ScheduledSalesCall",
    CalendlyAction = "CalendlyAction",
    CalendlyEventTypeViewed = "CalendlyEventTypeViewed",
    CalendlyDateAndTimeSelected = "CalendlyDateAndTimeSelected",
    CalendlyEventScheduled = "CalendlyEventScheduled",
    QrCodeScan = "QrCodeScan",
    SalesPageLoad = "SalesPageLoad",
    PedSalesPageLoad = "PedSalesPageLoad",
    WebinarLinkClick = "WebinarLinkClick",
    RegistrationComplete = "RegistrationComplete"
}

export interface Trace {
    sessionId: string
    origin: string,
    searchParams: string,
    pathname: string,
    href: string,
    timestamp: string,
    source: string,
    traceType: TraceType,
    additionalInfo?: string
    userId?: string
    ip?: string
    traceTypeForExperiment?: TraceType
    webinarId?: string
    email?: string
}

export interface Traces {
    items: Trace[],
    nextPageKey?: string
}

export interface ExperimentMetrics {
    experimentId: string
    variation: string
    source: string
}
