import React, {useEffect, useState} from "react";
import ResponsivePhotoWithText from "../../../../ui/element/ResponsivePhotoWithText";
import ResponsiveElement from "../../../../ui/element/ResponsiveElement";
import AdvantageItem from "../../../../ui/item/AdvantageItem";
import SalesPageCTA from "./SalesPageCTA";
import DependentOnScreenSizeElement from "../../../../ui/element/DependentOnScreenSizeElement";
import SalesPageNumbersItem from "./SalesPageNumbersItem";
import SimpleTestimonial from "./SimpleTestimonial";
import {useSubmit} from "react-router-dom";
import {useSubmitFormAction} from "../../../../../hooks/useSubmitFormAction";
import FaqList from "../../../../ui/faq/FaqList";
import Heading from "../../../../common/header/Heading";
import AnimatedLink from "../../../../ui/motion/AnimatedLink";
import {TraceService} from "../../../../../service/TraceService";
import {TraceType} from "../../../../../model/user/general/Trace";
import {KED_WEBINAR_EXPERIMENT_AB_9} from "../../../../../util/ExperimentUtil";
import {WebinarDetails} from "../../../../../model/user/webinar/WebinarDetails";

interface WebinarKedPurchaseProps {
    fromWebinar: boolean
    webinarDetails: WebinarDetails | null
}

export default function WebinarKedPurchase({fromWebinar, webinarDetails}: WebinarKedPurchaseProps) {
    const [transactionUrl, setTransactionUrl] = useState<string | null>(null);

    const {isSubmitting} = useSubmitFormAction(
        "whatever",
        null,
        (body) => {
            window.open(body, "_blank");
            setTransactionUrl(body);
        },
        false,
        undefined,
        undefined,
        true
    );

    useEffect(() => {
        if (webinarDetails && webinarDetails.experimentId) {
            TraceService.addTraceWithExperiment(TraceType.SalesPageLoad, webinarDetails.experimentId, webinarDetails?.variation, webinarDetails?.source, webinarDetails?.id, webinarDetails?.email)
        } else {
            // OLD ONE - BEFORE NEW CHANGES. ALL NEW PEOPLE SHOULD HAVE EXPERIMENT ID SET. -> Changed to the current one.
            TraceService.addTraceWithExperiment(TraceType.SalesPageLoad, KED_WEBINAR_EXPERIMENT_AB_9, webinarDetails?.variation, webinarDetails?.source, webinarDetails?.id, webinarDetails?.email)
        }
    }, [webinarDetails?.source, webinarDetails?.variation, webinarDetails?.experimentId, webinarDetails?.id, webinarDetails?.email, webinarDetails]);

    const submit = useSubmit();

    function submitForm() {
        submit({}, {
            method: "POST"
        });
    }

    return (
        <>
            <div className="mw-md-6xl mx-auto text-center text-white">
                <DependentOnScreenSizeElement mobileElement={<>
                    <h2 className='mb-0 text-center mw-md-5xl mx-auto font-heading mb-1 fs-4 text-white'>Pomóż swojemu
                        dziecku zostać</h2>
                    <h2 className='mb-0 text-center mw-md-5xl mx-auto font-heading mb-1 fs-3 text-white'><span
                        className='text-success'>NAJLEPSZYM </span>
                        uczniem.</h2>
                    <p className='text-warning fw-medium mw-md-3xl mx-auto fs-11 mt-3'>Dzięki sprawdzonemu przez setki
                        nastolatków Kursowi Efektywnego Działania.</p>
                </>} desktopElement={<>
                    <h2 className='mb-0 text-center mw-md-5xl mx-auto font-heading mb-1 fs-5 text-white'>Pomóż <u>swojemu
                        dziecku</u> zostać</h2>
                    <h2 className='mb-0 text-center mw-md-5xl mx-auto font-heading mb-1 fs-4 text-white'><span
                        className='text-success'>NAJLEPSZYM </span>
                        uczniem.</h2>
                    <p className='text-warning fw-medium mw-md-4xl mx-auto fs-9 mt-3'>Dzięki sprawdzonemu przez setki
                        nastolatków Kursowi Efektywnego Działania.</p>
                </>}/>
                <div className='mt-12'/>

                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-8 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/ked-showcase-sales-page.jpg'
                    paddingPhoto='56.25%'
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Zapisz swoje dziecko już teraz <img
                                    style={{height: '32px'}}
                                    src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/ikony/quality.svg'/>
                                </h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>Obejmuje Cię 30-dniowa gwarancja satysfakcji.</p>
                                <p className='fw-medium fs-10'>Jeżeli Twoje dziecko po przerobieniu pierwszych dwóch
                                    tygodni Kursu uzna, że to nie dla niego, zwrócimy Ci 100% pieniędzy.</p>
                            </div>
                            <AnimatedLink
                                hashLink={true}
                                to='#kup-teraz'
                                style={{
                                    maxWidth: '350px'
                                }}
                                className='btn btn-lg btn-success fs-10 py-4 w-100 text-success-light shadow mt-3'>Wypróbuj
                                Kurs Efektywnego Działania bez żadnego ryzyka</AnimatedLink>

                        </>
                    }/>

                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-16 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/md-800-1080.png'
                    paddingPhoto='135%'
                    customColText='col-md-8'
                    customColImage='col-md-4'
                    imageBelowText={true}
                    swap={true}
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Wiadomość od Twórcy Kursu
                                </h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>Wierzę, że każdy ma w sobie nieograniczony potencjał,
                                    który tylko czeka na wykorzystanie. </p>
                                <p className='fw-medium fs-10'>Na Produkacji, pomagamy przede wszystkim nastolatkom w
                                    jego odkryciu.</p>
                                <p className='fw-medium fs-10'>Jestem bardzo dumny, że pomogliśmy już setkom uczniów i
                                    wciąż możemy pomagać młodzieży w Efektywnym Działaniu.</p>
                                <p className='fw-medium fs-10'>Naszą misją jest stworzenie platformy na miarę XXI wieku,
                                    gdzie uczniowie z całej Polski będą mogli stawać się najlepszą wersją siebie każdego
                                    dnia.</p>
                                <p className='fw-medium fs-10'>Zapraszam Cię na niesamowitą przygodę.</p>
                                <p className='fw-medium fs-11'><i>~Mikołaj Dyblik, założyciel Produkacji i twórca Kursu
                                    Efektywnego Działania</i></p>
                            </div>

                        </>
                    }/>
                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-16 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/ked-devices.png'
                    paddingPhoto='74.89%'
                    imageBelowText={true}
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Czym jest <u>Kurs Efektywnego Działania</u>? <img
                                    style={{height: '32px'}}
                                    src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/ikony/quality.svg'/>
                                </h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>Kurs Efektywnego Działania to <b>jedyny w Polsce</b>,
                                    stworzony <b>specjalnie dla nastolatków</b> program,
                                    który całkowicie zmieni ich podejście do edukacji i rozwoju. </p>
                                <p className='fw-medium fs-10'>Jest to 9-tygodniowy Kurs łączący teorię z dużą
                                    ilością praktyki, po którym KAŻDY uczeń
                                    nauczy się działać efektywniej.</p>
                                <p className='fw-medium fs-10'>Kurs jest dostępny na całe życie, z każdego miejsca na
                                    świecie i o każdej porze.</p>
                            </div>

                        </>
                    }/>

                <div className='mt-10'/>

                <ResponsivePhotoWithText
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/sp-happy.png'
                    paddingPhoto='62.5%'
                    swap={true}
                    imageBelowText={true}
                    textPart={<>
                        <ResponsiveElement mobileClasses='fs-5 text-start mt-8 mt-md-0'
                                           desktopClasses='fs-7 text-start' element={
                            <h3>Czego dowie się Twoje dziecko?
                            </h3>
                        }/>
                        <div className='text-start'>
                            <AdvantageItem
                                title='Jak uczyć się efektywnie i dostawać najlepsze oceny przy minimalnym nakładzie pracy. '/>
                            <AdvantageItem
                                title='Jak RAZ NA ZAWSZE wygrać z prokrastynacją i zacząć działać SYSTEMATYCZNIE.'/>
                            <AdvantageItem
                                title='Jak uniezależnić się od telefonu i komputera.'/>
                            <AdvantageItem
                                title='Jak wykształcić w sobie zwycięskie nastawienie i chętnie podejmować nowe wyzwania.'/>
                            <AdvantageItem
                                title='Jak lepiej poznać siebie, jasno określić swój cel i stworzyć konkretny PLAN DZIAŁANIA.'/>
                            <AdvantageItem
                                title='I wiele, wiele więcej...'/>
                        </div>
                    </>}/>


                <div className='mt-12'/>
                <SimpleTestimonial name='Magdalena'
                                   quote='Czuję, że Hubertowi współpraca z Panem pokazała i uświadomiła parę ważnych rzeczy. Bardzo dziękuję, za to, co do tej pory dał Pan Hubertowi, myślę, że na pewno będzie to dla niego wspierająca wiedza.'
                                   description='Mama Huberta, maturzysty'
                                   imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/mama-huberta-incognito.png'/>

                <div id='kup-teraz'>
                    <Heading badgeText='Pozostał ostatni krok' title='Uzyskaj dostęp do Kursu już teraz 📚'/>
                    <SalesPageCTA isSubmitting={isSubmitting} submit={submitForm} fromWebinar={fromWebinar}
                                  transactionUrl={transactionUrl} hideDiscount={true}/>
                </div>

                <h3 className='mt-16 fs-5 text-success'>30-dniowa Gwarancja Satysfakcji <img
                    style={{height: '68px'}}
                    alt='jakość'
                    src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/ikony/quality.svg'/>
                </h3>
                <DependentOnScreenSizeElement mobileElement={<img style={{height: '192px'}}
                                                                  src="https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/gwarancja.png"
                                                                  alt='gwarancja'/>}
                                              desktopElement={<img style={{height: '256px'}}
                                                                   src="https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/gwarancja.png"
                                                                   alt='gwarancja'/>}/>
                <p className='fw-medium mx-auto mw-md-2xl fs-9 mt-6'>Jeżeli Twoje dziecko przejdzie przez program i
                    zacznie wprowadzać w życie zmiany, <b>zobaczy rezultaty</b>. Kropka.</p>

                <p className='fw-medium mx-auto mw-md-2xl fs-10 mt-6'>Jednak jeżeli nie do końca w to wierzysz,
                    proponuję Ci <b>30-dniową gwarancję satysfakcji</b>, więc Twoje dziecko będzie mogło przejść
                    przez nasz program bez żadnego ryzyka.</p>

                <p className='fw-medium mx-auto mw-md-2xl fs-9 mt-6'>Zapisz swoje dziecko już teraz, a jeżeli
                    po obejrzeniu dwóch pierwszych tygodni kursu i
                    wdrożeniu w życie zmian nie będzie zadowolone
                    z rezultatów, <u> zwrócimy Ci 100% zainwestowanej kwoty</u>.</p>

                <div className='mt-16'/>

                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-8 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/community.png'
                    paddingPhoto='56.25%'
                    imageBelowText={true}
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Dożywotni dostęp do naszej
                                    prywatnej społeczności
                                </h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'><i>Jesteś sumą 5 osób, z którymi spędzasz najwięcej
                                    czasu.</i></p>
                                <p className='fw-medium fs-10'>Pomyśl, jak zmieni się życie Twojego dziecka, jeżeli
                                    będzie otaczać się nastawionymi na rozwój, ambitnymi i pomocnymi rówieśnikami.</p>
                                <p className='fw-medium fs-10'><b>Odpowiedź jest prosta</b>: Stanie się takie jak oni.
                                    Ambitne,
                                    nastawione na rozwój, chętnie wychodzące ze swojej strefy komfortu i skutecznie
                                    realizujące swoje cele.</p>
                            </div>

                        </>
                    }/>

                <div className='mt-16'/>

                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-8 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/zoom-meetings.png'
                    paddingPhoto='56.25%'
                    swap={true}
                    imageBelowText={true}
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Dożywotni dostęp do regularnych
                                    spotkań online z twórcą kursu
                                </h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>Dzięki cotygodniowym spotkaniom na żywo możemy
                                    pomóc każdej osobie w czasie rzeczywistym i upewnić się,
                                    że każdy odniesie sukces.</p>
                                <p className='fw-medium fs-10'>Godzina konsultacji z Mikołajem kosztuje <b>1000PLN+</b>,
                                    więc otrzymujecie niesamowity bonus - i to na całe życie.</p>
                            </div>

                        </>
                    }/>

                <hr className='text-white mt-8 mt-md-16'/>
                <div className='row'>
                    <h3 className='fs-5 mb-md-8'>Spójrzmy na liczby...
                    </h3>
                    <SalesPageNumbersItem metricTitle='Lata doświadczenia' metricValue={5}/>
                    <SalesPageNumbersItem metricTitle='Zadowoleni uczniowie' metricValue={'3.000+'}/>
                    <SalesPageNumbersItem metricTitle='Zadowoleni rodzice' metricValue={'98.3%'}/>

                </div>
                <hr className='text-white mb-0 mb-md-16'/>

                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-0 mt-md-8 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/best-self.png'
                    paddingPhoto='56.25%'
                    imageBelowText={true}
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Autorski dokument "BEST Self"
                                </h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>Ten dokument pomoże Twojemu dziecku stworzyć jasny plan
                                    działania do realizacji swoich celów.</p>
                                <p className='fw-medium fs-10'>Specjalnie stworzone pytania skłonią je do myślenia i po
                                    pewnym czasie, odpowiedzi przyjdą zupełnie naturalnie.</p>
                                <p className='fw-medium fs-10'>Dostępny w dwóch wersjach - podstawowa (26 pytań) i
                                    rozbudowana (+-100 pytań). </p>
                            </div>

                        </>
                    }/>
                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-8 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/plan-dominacji.png'
                    paddingPhoto='56.25%'
                    swap={true}
                    imageBelowText={true}
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Autorski dokument "Roczny Plan Dominacji"
                                </h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>Jest to specjalny planer do wygrania 2025 roku.</p>
                                <p className='fw-medium fs-10'>Jego przejrzystość i prostota sprawiają, że nawet
                                    najbardziej oporni uczniowie chętnie zaczynają z niego korzystać.</p>
                                <p className='fw-medium fs-10'>Koniec nauki na ostatnią chwilę, zapominania o
                                    sprawdzianach, kartkówkach czy urodzinach swoich rodziców.</p>
                            </div>

                        </>
                    }/>

                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-8 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/ai-nauka.png'
                    paddingPhoto='56.25%'
                    imageBelowText={true}
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Sztuczna Inteligencja dostosowana pod Efektywną Naukę</h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>Zintegrowaliśmy najnowsze rozwiązania AI z naszą
                                    platformą online.</p>
                                <p className='fw-medium fs-10'>Od teraz, zamiast bezmyślnie przepisywać odpowiedzi
                                    czy zadania domowe, uczniowie będą musieli samodzielnie dojść do rozwiązania.</p>
                                <p className='fw-medium fs-10'>AI będzie ich wspierało i zadawało pytania skłaniające do
                                    myślenia - aby pobudzać mózg do jak najlepszego przetwarzania informacji.</p>
                            </div>

                        </>
                    }/>

                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-8 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/techniki-oddechowe.png'
                    paddingPhoto='56.25%'
                    swap={true}
                    imageBelowText={true}
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Autorskie techniki oddechowe</h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>W ostatnich latach młodzież coraz gorzej radzi sobie ze
                                    stresem.</p>
                                <p className='fw-medium fs-10'>Niepokój przed sprawdzianami czy kartkówkami skutecznie
                                    obniża ich koncentrację i odbiera szansę na dobrą ocenę.</p>
                                <p className='fw-medium fs-10'>Dzięki specjalnie przygotowanym technikom oddechowym,
                                    stres zniknie w mgnieniu oka.</p>
                            </div>

                        </>
                    }/>

                <div className='mt-16'/>

                <SimpleTestimonial name='Dominik' quote='Najbardziej osobiście podoba mi sie Twoje realne podejście. To znaczy, że nie sprzedajesz gruszek na wierzbie i nie
    klepiesz po ramionku, tylko efektywnie motywujesz i pokazujesz właśnie te techniki i narzedzia, dzięki którym można
    naprawdę wiele zdziałać w życiu. Z rzeczy które mi sie przydały i które wiem, że działają to chyba nie muszę mówić,
    bo to jest jakieś 80% materiału i ciężko trochę powiedzieć co konkretnie.'
                                   imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/testimonials/dominik.png'
                                   smallerQuote={true}
                                   description='Student ekonomii'
                />

                <Heading badgeText='Pozostał ostatni krok' title='Uzyskaj dostęp do Kursu już teraz 📚'/>
                <SalesPageCTA isSubmitting={isSubmitting} submit={submitForm} fromWebinar={fromWebinar}
                              transactionUrl={transactionUrl} hideDiscount={true}/>

                <div className='fw-medium mw-md-3xl mx-auto text-start'>
                    <h3 className='mt-10 fs-8'>Umiejętności Efektywnego Działania uczone w każdej szkole!</h3>
                    <p>Jedynym sposobem na zrealizowanie naszej misji <i>"Umiejętności Efektywnego Działania uczone w
                        każdej szkole"</i>, jest najpierw pomaganie pojedynczym uczniom w poprawie ich życia.</p>

                    <p>Kiedy część uczniów będzie działała znacznie efektywniej od innych, słowo wyjdzie na jaw i system
                        szkolnictwa będzie musiał zostać uaktualniony.</p>

                    <p>Kurs Efektywnego Działania został stworzony, aby pomóc:</p>
                    <ul>
                        <li className='mt-2'>jeszcze jednemu nastolatkowi w pełnym odkryciu jego potencjału.</li>
                        <li className='mt-2'>jeszcze jednemu uczniowi w skutecznej realizacji swoich celów.</li>
                        <li className='mt-2'>jeszcze jednemu podopiecznemu w znacznie bardziej efektywnej nauce.</li>
                        <li className='mt-2'>jeszcze jednemu dziecku w podniesieniu swojej samooceny i wierze w
                            swoje możliwości.
                        </li>
                        <li className='mt-2'>jeszcze jednemu rodzicowi w niemartwieniu się już o edukację swojego
                            dziecka.
                        </li>
                    </ul>

                    <p>Ostatecznym celem jest stworzenie platformy, która pomoże każdemu uczniowi w Polsce w pełni
                        wykorzystać
                        swój potencjał, Działać Efektywniej i Osiągać Więcej.</p>
                </div>
                <SimpleTestimonial name='Julia' quote='Hej Mikołaj, ogólnie bardzo podobał mi się ten program, nauczyłam się o wiele więcej niż zakładałam; najbardziej
    przydatne są dla mnie rzeczy dotyczące nauki i realizacji celów.'
                                   imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/julia-incognito.png'
                                   smallerQuote={true}
                                   description='Uczennica II Klasy Liceum'
                />

                <div className='fw-medium mw-md-3xl mx-auto text-center mt-2'>

                    <h3 className='fs-4'>Jak wygląda Kurs?</h3>
                    <p>Jest to pierwszy w Polsce, specjalnie <b>stworzony pod nastolatków 9-tygodniowy program</b>,
                        który nie
                        skupia się na leczeniu poszczególnych problemów, tylko
                        na <b>zapobieganiu</b> i <b>przygotowaniu</b> dziecka nie tylko do wyzwań w szkole, ale i
                        również w przyszłości.</p>
                </div>
                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-10 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/w1.png'
                    paddingPhoto='50%'
                    swap={false}
                    imageBelowText={true}
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Zaczynamy od budowy świadomości</h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>Po tym tygodniu, Twoje dziecko znacznie lepiej siebie
                                    pozna.</p>
                                <p className='fw-medium fs-10'>Będzie miało możliwość wykonania dokumentu 'BEST Self',
                                    dzięki któremu uświadomi sobie, do czego dąży i jakie są jego cele.</p>
                                <p className='fw-medium fs-10'>Zda sobie również sprawę z roli swojego nastawienia i
                                    będzie miało wszystkie niezbędne narzędzia do dalszej pracy oraz mierzenia swoich
                                    postępów.</p>
                            </div>

                        </>
                    }/>
                <img
                    className='img-fluid mw-md-md mx-auto d-none d-md-block'
                    src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/arrow-long-right.png'
                    alt='strzałka'/>
                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-10 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/w2.png'
                    paddingPhoto='70%'
                    swap={true}
                    imageBelowText={true}
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Następnie, idziemy do podstaw Efektywnego Działania</h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>Tutaj Twoje dziecko nauczy się solidnych podstaw
                                    związanych z działaniem.</p>
                                <p className='fw-medium fs-10'>Przyjrzymy się takim tematom, jak planowanie swojego
                                    czasu, stawianie sobie właściwych priorytetów, skończenie z prokrastynacją oraz
                                    radzenie sobie z gorszym nastrojem.</p>
                                <p className='fw-medium fs-10'>Po tym tygodniu będzie w stanie samodzielnie działać
                                    systematycznie, niezależnie od motywacji, chęci czy humoru.</p>
                            </div>

                        </>
                    }/>
                <img
                    className='img-fluid mw-md-md mx-auto d-none d-md-block'
                    src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/arrow-long-left.png'
                    alt='strzałka'/>
                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-10 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/w3.png'
                    paddingPhoto='65%'
                    swap={false}
                    imageBelowText={true}
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Następnie, przechodzimy do podstaw Efektywnej Nauki</h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>Tutaj Twoje dziecko nauczy się solidnych podstaw
                                    związanych z efektywną nauką.</p>
                                <p className='fw-medium fs-10'>Skupimy się na aspektach, które składają się na efektywną
                                    naukę, między innymi
                                    Powtórki, Odzyskiwanie i Przeplatanie.</p>
                                <p className='fw-medium fs-10'>Nauczymy się również skutecznego planowania nauki oraz
                                    wykorzystamy wiedzę z poprzednich tygodni i zastosujemy ją w praktyce.</p>
                            </div>

                        </>
                    }/>

                <img
                    className='img-fluid mw-md-md mx-auto d-none d-md-block'
                    src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/arrow-long-right.png'
                    alt='strzałka'/>
                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-10 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/w4.png'
                    paddingPhoto='65%'
                    swap={true}
                    imageBelowText={true}
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Następnie, optymalizujemy środowisko</h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>Twoje dziecko zrozumie, dlaczego ciągłe siedzenie na
                                    telefonie lub komputerze wcale mu nie służy oraz nauczy się, jak ten czas
                                    skutecznie redukować.
                                </p>
                                <p className='fw-medium fs-10'>Skupimy się również bezpośrednio na miejscu pracy, aby
                                    wyeliminować wszystkie rozpraszacze i upewnić się, że uczeń będzie pracował
                                    w warunkach sprzyjającym rozwojowi i skupieniu.
                                </p>
                                <p className='fw-medium fs-10'>Co więcej, popracujemy nad koncentracją i przeprowadzimy
                                    mu test na chronotyp snu, aby dowiedzieć się, w jakich godzinach najlepiej mu się
                                    uczy.</p>
                            </div>

                        </>
                    }/>

                <img
                    className='img-fluid mw-md-md mx-auto d-none d-md-block'
                    src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/arrow-long-left.png'
                    alt='strzałka'/>
                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-10 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/w5.png'
                    paddingPhoto='65%'
                    swap={false}
                    imageBelowText={true}
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Następnie, zagłębiamy się w temat Efektywnej Nauki</h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>Omówimy, w jaki sposób uczyć się na lekcji, aby być w
                                    stanie pamiętać i przede wszystkim rozumieć cały przerabiany materiał.</p>
                                <p className='fw-medium fs-10'>Spojrzymy również na podchodzenie do sprawdzianów i
                                    egzaminów - aby upewnić się, że w te najważniejsze dni jesteśmy w najlepszej formie
                                    i nic nas nie zaskoczy.</p>
                                <p className='fw-medium fs-10'>Co więcej, skupimy się na tworzeniu efektywnych notatek,
                                    oraz właściwym robieniu przerw.</p>
                                <p className='fw-medium fs-10'>Po przerobieniu tego tygodnia, Twoje dziecko będzie miało
                                    w głowie jasny plan działania.</p>
                            </div>

                        </>
                    }/>

                <img
                    className='img-fluid mw-md-md mx-auto d-none d-md-block'
                    src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/arrow-long-right.png'
                    alt='strzałka'/>
                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-10 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/w6.png'
                    paddingPhoto='60%'
                    swap={true}
                    imageBelowText={true}
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Następnie, optymalizujemy różne aspekty naszego życia</h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>Szczegółowo omówimy rolę snu, rolę jedzenia, rolę sportu
                                    oraz rolę odpoczynku, aby uczeń jasno zdawał sobie sprawę, jak te obszary na niego
                                    wpływają i co mógłby potencjalnie zoptymalizować.
                                </p>
                                <p className='fw-medium fs-10'>Na końcu pokażemy, w jaki sposób radzić sobie ze stresem
                                    i jako bonus, Twoje dziecko otrzyma również prowadzoną medytację, dzięki której w 10
                                    minut całkowicie wyeliminuje stres i poczuje się znacznie lepiej.
                                </p>
                            </div>

                        </>
                    }/>

                <img
                    className='img-fluid mw-md-md mx-auto d-none d-md-block'
                    src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/arrow-long-left.png'
                    alt='strzałka'/>
                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-10 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/w7.png'
                    paddingPhoto='60%'
                    swap={false}
                    imageBelowText={true}
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Następnie, wchodzimy na wyższe poziomy Efektywnej Nauki</h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>Skupimy się na bardziej zaawansowanych aspektach, takich
                                    jak nauka poprzez dociekanie, skuteczne robienie map myśli oraz efektywna nauka do
                                    przedmiotów ścisłych.
                                </p>
                                <p className='fw-medium fs-10'>Połączymy to także z wykorzystaniem sztucznej
                                    inteligencji, aby uczeń jasno wiedział, jak wykorzystać technologię aktualnych
                                    czasów na swoją korzyść.
                                </p>
                                <p className='fw-medium fs-10'>Po tym tygodniu, Twoje dziecko będzie miało wszystko
                                    czego potrzebuje, aby przygotować się do egzaminów na najwyższe wyniki.</p>
                            </div>

                        </>
                    }/>

                <img
                    className='img-fluid mw-md-md mx-auto d-none d-md-block'
                    src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/arrow-long-right.png'
                    alt='strzałka'/>
                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-10 container mx-auto'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/w8.png'
                    paddingPhoto='55%'
                    swap={true}
                    imageBelowText={true}
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>Następnie, zwiększamy naszą wydajność raz na zawsze</h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>Tutaj zagłębimy się w stan flow, oraz nauczymy się, jak w
                                    niego wchodzić.</p>
                                <p className='fw-medium fs-10'>Pokażemy, jak można nadrobić ogromną ilość
                                    materiału w jeden dzień, a także zbudujemy system do wygrywania każdego dnia, aby
                                    uczeń mógł wyciągać wnioski ze swoich działań i stawać się coraz lepszą wersją
                                    siebie.
                                </p>
                                <p className='fw-medium fs-10'>Zakończymy realizacją swoich celów krok po
                                    kroku, aby uczeń skutecznie zrealizował dowolne swoje plany.</p>
                            </div>

                        </>
                    }/>

                <img
                    className='img-fluid mw-md-md mx-auto d-none d-md-block'
                    src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/arrow-long-left.png'
                    alt='strzałka'/>
                <ResponsivePhotoWithText
                    customWrapperStyles='row mt-10 container mx-auto mb-20 mb-md-10'
                    photoUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/w9.png'
                    paddingPhoto='64%'
                    swap={false}
                    imageBelowText={true}
                    textPart={
                        <>
                            <ResponsiveElement mobileClasses='fs-7 text-start mt-8 mt-md-0'
                                               desktopClasses='fs-7 text-start' element={
                                <h3>I zakończymy wejściem na poziom wyżej!</h3>
                            }/>
                            <div className='text-start mt-4'>
                                <p className='fw-medium fs-10'>Tutaj upewnimy się, że uczeń nie wróci już do negatywnych
                                    nawyków oraz nie zboczy ze zwycięskiej ścieżki.</p>
                                <p className='fw-medium fs-10'>Nie będę jednak zdradzał wszystkiego, żeby też była potem
                                    jakaś niespodzianka :)
                                </p>
                                <p className='fw-medium fs-10'>Jak widzisz, po przerobieniu całego Kursu i wdrożeniu w
                                    życie zmian,
                                    nie ma szans, żeby uczeń nie nauczył się efektywnego działania.
                                </p>
                            </div>

                        </>
                    }/>

                <SimpleTestimonial name='Adam'
                                   quote='Najbardziej przydały mi się zagadnienia związane z koncentracją, organizacją i cała efektywna nauka. Znacznie łatwiej jest mi się systematycznie uczyć, dostaję lepsze oceny i w końcu rozumiem co się dzieje w szkole. Dziękuję. '
                                   imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/adam-incognito.png'
                                   smallerQuote={true}
                                   description='Uczeń I Klasy Liceum'
                />

                <Heading badgeText='Pozostał ostatni krok' title='Uzyskaj dostęp do Kursu już teraz 📚'/>

                <SalesPageCTA isSubmitting={isSubmitting} submit={submitForm} fromWebinar={fromWebinar}
                              transactionUrl={transactionUrl} hideDiscount={true}/>

                <h3 className='mt-10 fs-6'>Najczęściej zadawane pytania</h3>

                <div className='text-start'>
                    <FaqList items={[
                        {
                            question: "Dla kogo jest ten Kurs?",
                            answer: 'Ten Kurs jest dla wszystkich uczniów w wieku od 12 lat do końca studiów, którzy chcą zacząć Działać Efektywniej i Osiągać Więcej.'
                        },
                        {
                            question: "Ile czasu należy poświęcić na Kurs?",
                            answer: 'Idealnie jest poświęcać około 3 godziny w tygodniu. 1.5h na obejrzenie materiałów i drugie 1.5h na wdrożenie w życie zmian oraz wykonanie ćwiczeń. Wolniejsze przerabianie też jest jak najbardziej możliwe, jednak wtedy ukończenie Kursu zajmie dłużej niż 9 tygodni.'
                        },
                        {
                            question: "Co jeżeli moje dziecko ma problemy z koncentracją?",
                            answer: 'W Kursie uczymy sprawdzonych sposobów na podniesienie koncentracji oraz skupienia, ponieważ bez tego, nie ma Efektywnego Działania. Po wdrożeniu w życie pokazywanych rozwiązań problem z koncentracją pójdzie w niepamięć.'
                        },
                        {
                            question: "Moje dziecko nie jest zainteresowane przerabianiem Kursu.",
                            answer: 'Nie ma problemu, najwidoczniej to nie jest jeszcze dla niego właściwy moment. Uczeń musi samodzielnie widzieć sens w nauce Efektywnego Działania, aby wyniósł 100% z programu. Na szczęście dostęp do Kursu jest na całe życie, więc będzie można wrócić do niego za tydzień, miesiąc czy nawet rok. A ten moment zawsze nadchodzi.\n Jednak jest to jedyna szansa na uzyskanie dostępu do Kursu za 1999PLN, później cena pójdzie w górę.'
                        },
                        {
                            question: "Czy ten Program zadziała u dzieci z ADHD?",
                            answer: 'Zdecydowanie. W Kursie specjalnie dostosowaliśmy zarówno długość, jak i treść lekcji, aby nawet osoby z ADHD dały radę obejrzeć całość bez rozpraszania się. Dodatkowo uczymy, w jaki sposób można pracować nad swoją koncentracją oraz skupieniem, dzięki czemu osoby z ADHD widzą rezultaty najszybciej!'
                        },
                        {
                            question: "Co jeżeli to nie zadziała u mojego dziecka?",
                            answer: 'Nie ma problemu, pokaż nam, że próbowało i przerobiło przynajmniej 2 pierwsze tygodnie Kursu, a osobiście znajdę przyczynę i zaproponuję mu plan działania. Jeżeli wciąż z jakiegoś powodu nie będzie zadowolone lub nie będzie widziało rezultatów, napisz nam wiadomość na maila kontakt@produkacja.pl w ciągu 30 dni od zakupu, a zwrócimy Ci 100% pieniędzy.'
                        },
                        {
                            question: "Czy jeżeli nie wykupię dostępu teraz, cena pójdzie w górę?",
                            answer: 'Tak, ta jednorazowa oferta za 1999PLN jest tylko dla uczestników webinaru. Po 24h podniesiemy cenę z powrotem do 6000PLN.'
                        }
                    ]}/>
                </div>


                <div className='mt-16'/>
                <SimpleTestimonial name='Bohdi Sanders' quote='Brak podjęcia decyzji to również podjęcie decyzji.'
                                   description='Znany autor, filozof, trener życia i ekspert w dziedzinie filozofii sztuk walki.'
                                   imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/sales-page/bohdi-sanders.png'/>
            </div>
        </>
    );
}