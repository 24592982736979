import React from "react";
import {motion} from "framer-motion";
import {offerVariants} from "../../../pages/user/offer/OfferLandingPage";
import ProductPageOption from "../ai/pricing/ProductPageOption";

interface ProductsSelectOptionProps {
    isEffectiveActionCourseSubscriber: boolean;
    isEffectiveActionMiniCourseSubscriber: boolean;
}

export default function ProductsSelectOption(props: ProductsSelectOptionProps) {
    const {isEffectiveActionCourseSubscriber, isEffectiveActionMiniCourseSubscriber} = props;

    const hideMiniCourse = isEffectiveActionCourseSubscriber && !isEffectiveActionMiniCourseSubscriber;
    const chatFirst = !isEffectiveActionCourseSubscriber && !isEffectiveActionMiniCourseSubscriber;
    const hasAccessOnlyToMiniCourse = !isEffectiveActionCourseSubscriber && isEffectiveActionMiniCourseSubscriber;

    const kedOption = (
        <motion.div
            custom={0}
            variants={offerVariants}
            initial="hidden"
            animate="visible"
            className="option-wrapper col-12 col-md-4 mt-5 px-md-12"
        >
            <ProductPageOption
                imageUrl={
                    isEffectiveActionCourseSubscriber
                        ? "https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/ked.png"
                        : "https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/ked-lock.png"
                }
                redirectTo={
                    isEffectiveActionCourseSubscriber
                        ? "kurs-efektywnego-dzialania"
                        : "/oferta/kurs-efektywnego-dzialania"
                }
                title="Kurs Efektywnego Działania"
                ctaCustomText={
                    isEffectiveActionCourseSubscriber ? "Przejdź do kursu" : "Uzyskaj dostęp"
                }
                subtitle="W 9 tygodni nauczysz się wszystkiego na temat Efektywnego Działania. Kompleksowy Kurs omawiający każde zagadnienie krok po kroku. Od solidnych podstaw po zaawansowane techniki."
            />
        </motion.div>
    );

    const pedOption = (
        <motion.div
            custom={1}
            variants={offerVariants}
            initial="hidden"
            animate="visible"
            className="option-wrapper col-12 col-md-4 mt-5 px-md-12"
        >
            <ProductPageOption
                imageUrl="https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/ped-preview.png"
                redirectTo="podstawy-efektywnego-dzialania"
                title="Podstawy Efektywnego Działania"
                ctaCustomText="Przejdź do kursu"
                subtitle="Krótki Kurs omawiający konkretnie podstawy Efektywnego Działania. W 1.5h dwukrotnie podniesiesz swoją produktywność!"
            />
        </motion.div>
    );

    const chatOption = (
        <motion.div
            custom={2}
            variants={offerVariants}
            initial="hidden"
            animate="visible"
            className="option-wrapper col-12 col-md-4 mt-5 px-md-12"
        >
            <ProductPageOption
                imageUrl="https://produkacja.s3.eu-central-1.amazonaws.com/web/oferta-wspolne/rozwiazania-ai.png"
                ctaCustomText="Przejdź do chatu"
                redirectTo="/produkt/chat"
                title="Edukacyjny Chat"
                subtitle="Zdobywaj wiedzę łatwo, szybko i wygodnie. Nasze modele AI wspierają Cię na każdym etapie nauki, zapewniając niezbędną wiedzę oraz feedback."
            />
        </motion.div>
    );

    function calculateOrder(): JSX.Element[] {
        const options = [];

        // When user doesn't have access to any course.
        if (chatFirst) {
            options.push(chatOption);
            options.push(kedOption);
            return options;
        }

        // When user has access to the main course but doesn't have access to the mini course.
        if (hideMiniCourse) {
            options.push(kedOption);
            options.push(chatOption);
            return options;
        }

        // When user has access only to the mini course.
        if (hasAccessOnlyToMiniCourse) {
            options.push(pedOption);
            options.push(chatOption);
            options.push(kedOption);
            return options;
        }

        // When user has access to both courses.
        options.push(kedOption);
        options.push(pedOption);
        options.push(chatOption);
        return options;
    }

    // Get the options array and check its length.
    const options = calculateOrder();
    const isTwoOptions = options.length === 2;
    const containerClass = `row ${isTwoOptions ? "mw-5xl" : "mw-7xl"} mx-auto`;

    // Adjust the grid column class for each option if only two are rendered.
    const adjustedOptions = options.map((option) =>
        React.cloneElement(option, {
            className: option.props.className.replace(
                "col-md-4",
                isTwoOptions ? "col-md-6" : "col-md-4"
            )
        })
    );

    return (
        <>
            <div className={containerClass}>
                {adjustedOptions.map((option, index) => (
                    <React.Fragment key={index}>{option}</React.Fragment>
                ))}
            </div>
        </>
    );
}