import {PurchaseRequest} from "../model/user/purchase/PurchaseRequest";
import {ContentType, HttpMethod, HttpService} from "./HttpService";
import {
    CreateIndividualKedOfferRequest,
    CreateIndividualKedOfferResponse,
    IndividualKedOffer
} from "../model/user/purchase/IndividualKedOffer";
import {
    CourseActivationCode,
    GrantIndirectCourseAccessRequest,
    GrantIndirectCourseAccessResponse
} from "../model/user/purchase/KedAccessAfterWebinar";
import {PurchaseOption} from "../model/user/purchase/PurchaseOption";
import {getSessionId} from "../util/TrackingUtil";

export class PurchaseService {

    public static initWebinarPurchase(webinarId: string, source: string | null): Promise<string> {
        return PurchaseService.initPurchase({
            purchaseOptionName: PurchaseOption.COURSE_KED_WEBINAR.toString(),
            name: "Webinar",
            email: "webinar@produkacja.pl",
            source: source ?? (localStorage.getItem('firstJoinedFrom') ?? 'webinar'),
            webinarId: webinarId,
            successRedirectPath: `/webinar/zakup/sukces/${webinarId}`,
            errorRedirectPath: `/webinar/zakup/blad/${webinarId}`,
            eventUrl: window.location.href,
            sessionId: getSessionId()
        });
    }

    public static initNewsletterPurchase(source: string | null): Promise<string> {
        return PurchaseService.initPurchase({
            purchaseOptionName: PurchaseOption.COURSE_KED_NEWSLETTER.toString(),
            name: "Newsletter",
            email: "newsletter@produkacja.pl",
            source: source ?? (localStorage.getItem('firstJoinedFrom') ?? 'newsletter'),
            successRedirectPath: `/newsletter/kurs-efektywnego-dzialania/sukces`,
            errorRedirectPath: `/newsletter/kurs-efektywnego-dzialania/blad`,
            eventUrl: window.location.href,
            sessionId: getSessionId()
        });
    }

    public static initRadsPurchase(source: string | null): Promise<string> {
        return PurchaseService.initPurchase({
            purchaseOptionName: PurchaseOption.COURSE_KED_RADS.toString(),
            name: "Rads",
            email: "rads@produkacja.pl",
            source: source ?? (localStorage.getItem('firstJoinedFrom') ?? 'rads'),
            successRedirectPath: `/rads/kurs-efektywnego-dzialania/sukces`,
            errorRedirectPath: `/rads/kurs-efektywnego-dzialania/blad`,
            eventUrl: window.location.href,
            sessionId: getSessionId()
        });
    }

    public static initWebinarSalesPagePurchase(source: string | null): Promise<string> {
        return PurchaseService.initPurchase({
            purchaseOptionName: PurchaseOption.COURSE_KED_WEBINAR_SALES_PAGE.toString(),
            name: "WebinarSP",
            email: "webinar-sp@produkacja.pl",
            source: source ?? (localStorage.getItem('firstJoinedFrom') ?? 'webinar-sp'),
            successRedirectPath: `/webinar/kurs-efektywnego-dzialania/sukces`,
            errorRedirectPath: `/webinar/kurs-efektywnego-dzialania/blad`,
            eventUrl: window.location.href,
            sessionId: getSessionId()
        });
    }

    public static initPedPurchase(source: string | null): Promise<string> {
        return PurchaseService.initPurchase({
            purchaseOptionName: PurchaseOption.COURSE_PED.toString(),
            name: "Ped",
            email: "ped@produkacja.pl",
            source: source ?? (localStorage.getItem('firstJoinedFrom') ?? 'ped'),
            successRedirectPath: `/newsletter/podstawy-efektywnego-dzialania/sukces`,
            errorRedirectPath: `/newsletter/podstawy-efektywnego-dzialania/blad`,
            eventUrl: window.location.href,
            sessionId: getSessionId()
        });
    }

    public static initKedFromPedSalesPagePurchase(source: string | null, email: string, name: string): Promise<string> {
        return PurchaseService.initPurchase({
            purchaseOptionName: PurchaseOption.COURSE_KED_FROM_PED_SALES_PAGE.toString(),
            name: name,
            email: email,
            source: source ?? (localStorage.getItem('firstJoinedFrom') ?? 'ped'),
            successRedirectPath: `/produkt`,
            errorRedirectPath: `/produkt/zakup/kurs-efektywnego-dzialania/blad`,
            eventUrl: window.location.href,
            sessionId: getSessionId()
        });
    }

    public static initPurchase(purchaseRequest: PurchaseRequest): Promise<string> {
        return HttpService.sendRequest<string>(
            HttpMethod.POST,
            `/api/purchase/init`,
            JSON.stringify(purchaseRequest),
            ContentType.JSON
        );
    }

    public static getIndividualKedOffer(id: string): Promise<IndividualKedOffer> {
        return HttpService.sendRequest<IndividualKedOffer>(
            HttpMethod.GET,
            `/api/individual-ked-offer/${id}`,
        );
    }

    public static createIndividualKedOffer(createOfferRequest: CreateIndividualKedOfferRequest): Promise<CreateIndividualKedOfferResponse> {
        return HttpService.sendRequest<CreateIndividualKedOfferResponse>(
            HttpMethod.POST,
            `/api/individual-ked-offer`,
            JSON.stringify(createOfferRequest),
            ContentType.JSON
        );
    }

    public static grantIndirectCourseAccess(grantCourseAccess: GrantIndirectCourseAccessRequest): Promise<GrantIndirectCourseAccessResponse> {
        return HttpService.sendRequest<GrantIndirectCourseAccessResponse>(
            HttpMethod.POST,
            `/api/activate-course-access`,
            JSON.stringify(grantCourseAccess),
            ContentType.JSON
        );
    }

    public static getCourseActivationCode(id: string): Promise<CourseActivationCode> {
        return HttpService.sendRequest<CourseActivationCode>(
            HttpMethod.GET,
            `/api/course-activation-code/${id}`,
        );
    }
}