import {kedDescription, pedDescription} from "../../../components/user/learning-course/dashboard/CourseDescriptions";
import {kedCourseStructure, LearningCourseWeek, pedCourseStructure} from "./LearningCourse";
import {AvailableCourses} from "./AvailableCourses";

export class CourseDetails {
    key: AvailableCourses;
    fullName: string
    welcomeMessage: string;
    length: string;
    structure: LearningCourseWeek[];
    description: JSX.Element;
    dashboardUrl: string;

    static KED = new CourseDetails(AvailableCourses.KED, 'Kurs Efektywnego Działania', 'Witamy na Kursie Efektywnego Działania!', '9 tygodni', kedCourseStructure,
        kedDescription, '/produkt/kurs-efektywnego-dzialania');
    static PED = new CourseDetails(AvailableCourses.PED, 'Podstawy Efektywnego Działania', 'Witamy na Kursie "Podstawy Efektywnego Działania"', '1 tydzień', pedCourseStructure,
        pedDescription, '/produkt/podstawy-efektywnego-dzialania');

    constructor(key: AvailableCourses, fullName: string, welcomeMessage: string, length: string, structure: LearningCourseWeek[], description: JSX.Element, dashboardUrl: string) {
        this.key = key;
        this.fullName = fullName
        this.welcomeMessage = welcomeMessage
        this.length = length;
        this.structure = structure;
        this.description = description
        this.dashboardUrl = dashboardUrl
    }

    toString() {
        return this.key
    }

    static getDetailsByCourseName(name: AvailableCourses): CourseDetails | null {
        const allProps = Object.keys(this) as Array<keyof typeof CourseDetails>;
        for (const prop of allProps) {
            const value = this[prop];
            if (value instanceof CourseDetails && value.key === name) {
                return value;
            }
        }
        return null;
    }
}