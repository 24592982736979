import Header from "../../ui/header/Header";
import {useLoaderData} from "react-router-dom";
import {
    hasEffectiveActionCourseSubscriberGroup,
    hasEffectiveActionMiniCourseSubscriberGroup
} from "../../../util/AuthUtil";

export default function ProductsHeader() {
    const [_, groups] = useLoaderData() as [string, string[]];

    return <Header
        loaderId='productRoot'
        topItems={[
            {
                title: 'Program',
                to: hasEffectiveActionMiniCourseSubscriberGroup(groups) && !hasEffectiveActionCourseSubscriberGroup(groups) ? '/produkt/podstawy-efektywnego-dzialania' : '/produkt/kurs-efektywnego-dzialania'
            },
            {
                title: 'Chat',
                to: '/produkt/chat'
            },
            {
                title: 'Społeczność',
                to: '/produkt/spolecznosc'
            }
        ]}
        logoCustomRedirect='/produkt'
        dropdownItems={[
            {
                title: 'Moje produkty',
                to: '/produkt'
            },
            {
                title: 'Lista transakcji',
                to: '/transakcje'
            }
        ]}
    />
}