import {ContentType, HttpMethod, HttpService} from "./HttpService";
import {
    AddChatMessage,
    SchedulePhoneNotificationRequest,
    SchedulePhoneNotificationResponse,
    UpdateWebinarStatistics,
    WebinarDetails
} from "../model/user/webinar/WebinarDetails";

export class WebinarService {

    public static getWebinarDetails(ksuid: string, jit?: boolean): Promise<WebinarDetails> {
        return HttpService.sendRequest<WebinarDetails>(
            HttpMethod.GET,
            `/api/webinar/${ksuid}${jit ? '?startJit=true' : ''}`,
        );
    }

    public static addChatMessage(requestBody: AddChatMessage): Promise<void> {
        return HttpService.sendRequest<void>(
            HttpMethod.POST,
            '/api/webinar/add_chat_message',
            JSON.stringify(requestBody),
            ContentType.JSON
        );
    }

    public static updateWebinarStatistics(requestBody: UpdateWebinarStatistics): Promise<void> {
        return HttpService.sendRequest<void>(
            HttpMethod.POST,
            '/api/webinar/update_webinar_statistics',
            JSON.stringify(requestBody),
            ContentType.JSON
        );
    }

    public static schedulePhoneNotification(requestBody: SchedulePhoneNotificationRequest): Promise<SchedulePhoneNotificationResponse> {
        return HttpService.sendRequest<SchedulePhoneNotificationResponse>(
            HttpMethod.POST,
            '/api/webinar/schedule_phone_notification',
            JSON.stringify(requestBody),
            ContentType.JSON
        );
    }
}
