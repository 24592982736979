import React, {KeyboardEvent, useCallback, useEffect, useRef, useState} from "react";
import {AnimatePresence, motion} from "framer-motion";
import {Link, useSubmit} from "react-router-dom";

import Heading from "../../../common/header/Heading";
import WebinarTestimonials from "./WebinarTestimonials";

import {AddChatMessage, WebinarDetails} from "../../../../model/user/webinar/WebinarDetails";
import {WebinarService} from "../../../../service/WebinarService";
import {formatDate, formatDateWithHourWord} from "../../../../util/DateUtil";
import {useSubmitFormAction} from "../../../../hooks/useSubmitFormAction";
import AnimatedButton from "../../../ui/motion/AnimatedButton";
import {TraceService} from "../../../../service/TraceService";
import {TraceType} from "../../../../model/user/general/Trace";
import WebinarPlayer from "../../../user/WebinarPlayer";
import Chat from "./Chat";
import VideoWithPlayButton from "../../../ui/video/VideoWithPlayButton";
import {DiagnosticService} from "../../../../service/DiagnosticService";
import {CHAT_MESSAGES} from "./ChatMessages";


interface WebinarProps {
    webinarDetails: WebinarDetails;
}

function getRandomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

// The total run-time of your webinar in seconds
export const WEBINAR_DURATION_IN_SECONDS = 60 * 60 + 47 * 60 + 16;

// 10 minutes in seconds
const TEN_MINUTES = 60 * 10;

// PITCH_TIME_IN_SECONDS
const PITCH_TIME_IN_SECONDS = 60 * 60 + 15 * 60 + 50;


function formatTimeLeft(seconds: number) {
    if (seconds < 0) return "0s";
    const s = Math.floor(seconds % 60);
    const m = Math.floor((seconds / 60) % 60);
    const h = Math.floor(seconds / 3600);
    if (h > 0) return `${h}h ${m}m ${s}s`;
    if (m > 0) return `${m}m ${s}s`;
    return `${s}s`;
}

/** Framer Motion fade variants */
const fadeVariants = {
    hidden: {opacity: 0},
    visible: {
        opacity: 1,
        transition: {duration: 0.5},
    },
    exit: {
        opacity: 0,
        transition: {duration: 0.3},
    },
};

/** A small repeating animation for time-left text */
const timeBounceVariants = {
    animate: {
        scale: [1, 1.08, 1],
        transition: {
            repeat: Infinity,
            duration: 1.5,
            ease: "easeInOut",
        },
    },
};

/** Dots animation for "Webinar zaraz się zacznie..." */
const dotsContainer = {
    hidden: {},
    visible: {
        transition: {
            staggerChildren: 0.25,
        },
    },
};

const dot = {
    hidden: {opacity: 0.2},
    visible: {
        opacity: [0.2, 1, 0.2],
        transition: {
            duration: 1,
            repeat: Infinity,
            repeatType: "loop" as const,
        },
    },
};

function AnimatedDots() {
    return (
        <motion.div
            variants={dotsContainer}
            initial="hidden"
            animate="visible"
            style={{display: "inline-flex", marginLeft: "8px"}}
        >
            <motion.span className="mx-1" variants={dot}>
                .
            </motion.span>
            <motion.span className="mx-1" variants={dot}>
                .
            </motion.span>
            <motion.span className="mx-1" variants={dot}>
                .
            </motion.span>
        </motion.div>
    );
}

export default function Webinar({webinarDetails}: WebinarProps) {
    // Basic scheduling
    const [currentTime, setCurrentTime] = useState(Date.now());
    const plannedTime = new Date(webinarDetails.plannedDate).getTime();
    const dateDiff = (currentTime - plannedTime) / 1000; // in seconds

    const hasNotStarted = dateDiff < 0;
    const hasEnded = dateDiff > WEBINAR_DURATION_IN_SECONDS;
    const isRunning = !hasNotStarted && !hasEnded;

    // For tracking watch time in seconds (updated by WebinarPlayer)
    const [watchTime, setWatchTime] = useState(webinarDetails.watchTime > 0 && webinarDetails.watchTime < WEBINAR_DURATION_IN_SECONDS ? webinarDetails.watchTime : 0);

    const [transactionUrl, setTransactionUrl] = useState<string | null>(null);

    // Chat + discount logic (unchanged)
    const submit = useSubmit();
    const {isSubmitting} = useSubmitFormAction(
        "whatever",
        null,
        (body) => {
            window.open(body, "_blank");
            setTransactionUrl(body);
        },
        false,
        undefined,
        undefined,
        true
    );

    function createTransaction() {
        if (webinarDetails && webinarDetails.experimentId) {
            TraceService.addTraceWithExperiment(
                TraceType.InitTransaction,
                webinarDetails.experimentId,
                webinarDetails.variation,
                webinarDetails.source,
                webinarDetails.id,
                webinarDetails.email
            );
        } else {
            DiagnosticService.addDiagnostic({
                fullEvent: webinarDetails,
                error: null,
                additionalInfo: "Experiment id unknown for init transaction.",
                functionName: "createTransaction()"
            })
        }
        submit(null, {
            method: "POST",
        });
    }

    // Update currentTime every second
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(Date.now());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    // This is your discountDeadline logic:
    const discountDeadline =
        plannedTime + WEBINAR_DURATION_IN_SECONDS * 1000 + TEN_MINUTES * 1000;
    const discountSecondsLeft = Math.floor((discountDeadline - Date.now()) / 1000);
    const discountExpired = discountSecondsLeft <= 0;

    // Chat messages
    const [chatMessages, setChatMessages] = useState<
        {
            text: string;
            isUser: boolean;
            timestamp: Date;
            userName?: string;
        }[]
    >([]);

    const [userInput, setUserInput] = useState("");
    const chatContainerRef = useRef<HTMLDivElement>(null);

    const [bubbleKeys, setBubbleKeys] = useState<number[]>([]);

    // 1) A ref for the timeout handle
    const bubbleTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    // 2) Whether we have scheduled the *first* bubble after user passes watch threshold
    const [firstBubbleScheduled, setFirstBubbleScheduled] = useState(false);

    const scheduleNextBubble = useCallback(() => {
        const delaySeconds = getRandomInt(3, 5) * 60;

        // Clear any old leftover, just in case:
        if (bubbleTimeoutRef.current) {
            clearTimeout(bubbleTimeoutRef.current);
            bubbleTimeoutRef.current = null;
        }

        // Schedule
        bubbleTimeoutRef.current = setTimeout(() => {
            setBubbleKeys(prev => [...prev, Date.now()]);
            bubbleTimeoutRef.current = null;
        }, delaySeconds * 1000);
    }, []);

    /**
     * Effect #1: Kick off the *first* bubble
     * once the user has watched enough (pitch + 120).
     */
    useEffect(() => {
        if (!isRunning) {
            // webinar ended => clean up
            if (bubbleTimeoutRef.current) {
                clearTimeout(bubbleTimeoutRef.current);
                bubbleTimeoutRef.current = null;
            }
            setFirstBubbleScheduled(false);
            return;
        }

        // If we haven't scheduled a bubble yet AND
        // user watchTime >= pitch + 120 => schedule
        if (!firstBubbleScheduled && watchTime >= PITCH_TIME_IN_SECONDS + 120) {
            setFirstBubbleScheduled(true);
            scheduleNextBubble();
        }
    }, [isRunning, watchTime, firstBubbleScheduled, scheduleNextBubble]);

    /**
     * Effect #2: Whenever a bubble actually *appears*,
     * schedule the *next* one (if still running).
     */
    useEffect(() => {
        if (bubbleKeys.length > 0 && bubbleTimeoutRef.current === null && isRunning) {
            scheduleNextBubble();
        }
    }, [bubbleKeys, isRunning, scheduleNextBubble]);

    // Scroll chat down on new messages
    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chatMessages]);

    // Load existing user messages
    useEffect(() => {
        if (!webinarDetails?.chatMessages) return;
        const loadedMessages = Object.entries(webinarDetails.chatMessages).map(
            ([isoDate, text]) => ({
                text,
                isUser: true,
                timestamp: new Date(isoDate),
                userName: webinarDetails?.userName || "Ty",
            })
        );

        setChatMessages((prev) => {
            const newEntries = loadedMessages.filter((msg) => {
                return !prev.some(
                    (p) =>
                        p.timestamp.getTime() === msg.timestamp.getTime() && p.text === msg.text
                );
            });
            return [...prev, ...newEntries].sort(
                (a, b) => a.timestamp.getTime() - b.timestamp.getTime()
            );
        });
    }, [webinarDetails?.chatMessages, webinarDetails?.userName]);

    // Schedule the fake messages
    useEffect(() => {
        if (!webinarDetails?.plannedDate) return;
        CHAT_MESSAGES.forEach((fakeMsg) => {
            const messageTime = plannedTime + fakeMsg.timestamp * 1000;
            const delay = messageTime - Date.now();
            if (delay <= 0) {
                // Already in the past => add immediately (skip duplicates)
                setChatMessages((prev) => {
                    const alreadyScheduled = prev.find(
                        (m) =>
                            m.timestamp.getTime() === messageTime && m.text === fakeMsg.message
                    );
                    if (alreadyScheduled) return prev;
                    return [
                        ...prev,
                        {
                            text: fakeMsg.message,
                            isUser: false,
                            timestamp: new Date(messageTime),
                            userName: fakeMsg.name,
                        },
                    ].sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
                });
            } else {
                // Future => schedule setTimeout
                setTimeout(() => {
                    setChatMessages((prev) => {
                        const alreadyScheduled = prev.find(
                            (m) =>
                                m.timestamp.getTime() === messageTime && m.text === fakeMsg.message
                        );
                        if (alreadyScheduled) return prev;
                        return [
                            ...prev,
                            {
                                text: fakeMsg.message,
                                isUser: false,
                                timestamp: new Date(messageTime),
                                userName: fakeMsg.name,
                            },
                        ].sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
                    });
                }, delay);
            }
        });
    }, [webinarDetails, plannedTime]);

    // Send message
    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleSend();
        }
    };

    const handleSend = async () => {
        const trimmedUserInput = userInput.trim();

        if (!trimmedUserInput) {
            return;
        }

        const correctWatchTime = watchTime === 0 ? webinarDetails.watchTime ?? 0 : watchTime

        const body = {
            id: webinarDetails.id,
            message: trimmedUserInput,
            // If watchTime is still 0, fallback to webinarDetails.watchTime
            watchTime: correctWatchTime
        } as AddChatMessage

        try {
            WebinarService.addChatMessage(body);
        } catch (error) {
            DiagnosticService.addDiagnostic({error: error, fullEvent: body, functionName: "webinar_add_chat_message"})
        }


        const now = new Date();
        setChatMessages((prev) => [
            ...prev,
            {
                text: userInput.trim(),
                isUser: true,
                timestamp: now,
                userName: webinarDetails?.userName || "Ty",
            },
        ]);
        setUserInput("");
    };

    // Time left to start
    const timeLeftToStart = plannedTime - currentTime;
    const secondsLeftToStart = Math.floor(timeLeftToStart / 1000);

    // Conditionally render discount block
    function renderDiscountBlock() {

        // If the webinar is still running but user hasn't watched >= PITCH_TIME
        if (isRunning && watchTime < PITCH_TIME_IN_SECONDS) {
            return null;
        }

        // Otherwise, show the discount CTA
        let countdownElement = null;
        if (!isRunning) {
            countdownElement = (
                <h2 className="fs-5 text-warning mb-6">
                    Promocja wygaśnie za:{" "}
                    <span className="fw-bold">{formatTimeLeft(discountSecondsLeft)}</span>
                </h2>
            );
        }

        return (
            <div id="discount-section" className="col-12 text-center mt-10 mt-md-0 pt-md-8 mw-md-5xl mx-auto">
                {countdownElement}

                <h2 className="mb-5 text-success">
                    🔥 Zyskaj <span className="fw-bold"><u>4000 PLN</u> rabatu</span> na Kurs Efektywnego Działania! 🔥
                </h2>

                <p className="text-white fw-medium fs-5">
                    <span className="text-decoration-line-through text-danger fw-bold me-2">
                    6000 zł
                    </span>
                    <span className="text-success fw-bold">1999 zł</span>
                </p>
                <p className="text-white fw-medium">
                    Jednorazowa płatność <span className="fw-bold text-success">1999 zł</span> lub{" "}
                    <span className="fw-bold text-success">3 raty 0% (666 zł/mc)</span>{" "}
                    bez żadnych dodatkowych kosztów (0% prowizji)!
                </p>

                <div
                    className="d-flex flex-column flex-md-row align-items-center justify-content-center mt-4"
                    style={{gap: "20px"}}
                >
                    <AnimatedButton
                        className="btn btn-success btn-lg fw-bold purchase-button"
                        disabled={isSubmitting}
                        onClick={createTransaction}
                        type="button"
                    >
                        Kupuję już teraz
                    </AnimatedButton>
                    <h3 className="text-danger fs-9 mb-0 mt-2 mt-md-0 d-none d-md-block">
                        ⏳ Uwaga: Cena obowiązuje tylko do końca {!hasEnded ? "webinaru" : "dostępnego czasu"}!
                    </h3>
                </div>
                <p className={`mb-1 fs-13 fw-medium text-white text-start ${hasEnded ? 'mx-md-8' : 'mx-md-16'} ${hasEnded ? 'mx-lg-20' : 'mx-lg-32'} mt-1`}>
                    <span>Dokonując płatności akceptujesz </span>
                    <Link target="_blank" className="text-success link-success" to="/polityka-prywatnosci">
                        politykę prywatności
                    </Link>
                    <span>, </span>
                    <Link target="_blank" className="text-success link-success" to="/regulamin">
                        regulamin
                    </Link>
                    <span> oraz </span>
                    <Link target="_blank" className="text-success link-success" to="/regulamin-ked">
                        regulamin KED
                    </Link>
                    <span>. Wszystkie instrukcje dotyczące dostępu do kursu zostaną wysłane na adres email podany przy płatości.</span>
                    {
                        transactionUrl && <>
                            <br/><br/>
                            <p className='fs-10 text-warning'>
                                Jeżeli okno płatności nie otworzyło się automatycznie, <a href={transactionUrl}
                                                                                          className='text-success text-decoration-underline'>kliknij
                                tutaj aby dokonać płatność</a>.
                            </p>
                        </>
                    }
                </p>


            </div>
        );
    }

    return (
        <>
            {/* Notification header - only visible when webinar is running */}
            {isRunning && (
                <motion.div
                    initial={{opacity: 0, y: -20}}
                    animate={{
                        opacity: 1, 
                        y: 0
                    }}
                    exit={{opacity: 0, y: -20}}
                    transition={{duration: 0.3}}
                    className="text-dark py-2 px-3 text-center fw-bold"
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        zIndex: 1050,
                        fontSize: "0.9rem",
                        boxShadow: "0 2px 5px rgba(0,0,0,0.2)"
                    }}
                >
                    <motion.div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: -1
                        }}
                        animate={{
                            backgroundColor: ["#f0cd00", "#f59e0c", "#f0cd00"]
                        }}
                        transition={{
                            duration: 3,
                            repeat: Infinity,
                            ease: "easeInOut"
                        }}
                    />
                    Webinar jest w trakcie. Prosimy o zamknięcie wszystkich innych kart i nie zamykanie tej strony.
                </motion.div>
            )}
            
            <Heading
                title="Jak pomóc swojemu dziecku zostać NAJLEPSZYM uczniem?"
                badgeText={webinarDetails.justInTime ? undefined : `Start: ${formatDate(webinarDetails.plannedDate)}`}
            />

            <div className="row mt-10">
                <AnimatePresence mode="wait">
                    {/* NOT STARTED */}
                    {hasNotStarted && (
                        <motion.div
                            key="notStarted"
                            className="text-center text-warning mt-2"
                            variants={fadeVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            style={{width: "100%"}}
                        >
                            <p style={{minHeight: "50px"}} className="mb-0 fw-medium fs-8">
                                Webinar rozpocznie się{" "}
                                {formatDateWithHourWord(webinarDetails.plannedDate)}.
                            </p>

                            <h2 style={{minHeight: "50px"}} className="mb-0 mt-10 fs-6">
                                Pozostało jeszcze:
                            </h2>

                            <motion.div
                                variants={timeBounceVariants}
                                animate="animate"
                                className="fs-6 text-success fw-medium"
                                style={{marginTop: "5px"}}
                            >
                                <p>
                                    {formatTimeLeft(secondsLeftToStart)}
                                    <AnimatedDots/>
                                </p>
                            </motion.div>

                            <VideoWithPlayButton vimeoVideoId='1059405730' id={'thank-you'}/>

                            <div className="mw-md-2xl mx-auto text-center text-white mt-16">
                                <WebinarTestimonials/>
                            </div>
                        </motion.div>
                    )}

                    {/* ENDED */}
                    {hasEnded && (
                        <motion.div
                            key="ended"
                            className="text-center text-warning mt-10"
                            variants={fadeVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            style={{width: "100%"}}
                        >
                            <h2 style={{minHeight: "50px"}}>Webinar został zakończony.</h2>
                            {renderDiscountBlock()}
                        </motion.div>
                    )}

                    {/* RUNNING */}
                    {isRunning && (
                        <motion.div
                            key="running"
                            className="d-flex flex-wrap"
                            variants={fadeVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            style={{width: "100%"}}
                        >
                            {/* VIDEO (col-md-8) */}
                            <div
                                className="col-12 col-md-8 mb-4 mb-md-0 webinar-player-col"
                                style={{
                                    position: "relative",
                                    height: 0,
                                }}
                            >
                                <WebinarPlayer
                                    videoId="hiad624dp6"
                                    webinarDetails={webinarDetails}
                                    liveSimulation={true}
                                    start={dateDiff}
                                    onFirstMountOnce={() => {
                                        if (webinarDetails && webinarDetails.experimentId) {
                                            TraceService.addTraceWithExperiment(TraceType.PlayVideo, webinarDetails.experimentId, webinarDetails.variation, webinarDetails.source, webinarDetails.id, webinarDetails.email)
                                        } else {
                                            DiagnosticService.addDiagnostic({
                                                fullEvent: webinarDetails,
                                                error: null,
                                                additionalInfo: "Experiment id unknown for PlayVideo.",
                                                functionName: "Webinar -> onFirstMountOnce()"
                                            })
                                        }
                                    }}
                                    onWatchTimeUpdate={(secondsWatched) => {
                                        // Called every second by secondchange
                                        // Update both server and local watchTime
                                        if (secondsWatched % 5 === 0) {
                                            try {
                                                WebinarService.updateWebinarStatistics({
                                                    id: webinarDetails.id,
                                                    watchTime: secondsWatched,
                                                });
                                            } catch (error) {
                                                DiagnosticService.addDiagnostic({
                                                    functionName: `onWatchTimeUpdate: ${secondsWatched}`,
                                                    error: error, fullEvent: {}
                                                })
                                            }
                                        }
                                        setWatchTime((prev) =>
                                            secondsWatched > prev ? secondsWatched : prev
                                        );
                                    }}
                                    showProgressBar={false}
                                    autoPlay={true}
                                    forceContinuousPlay={true}
                                />
                            </div>

                            {(watchTime >= PITCH_TIME_IN_SECONDS || hasEnded) && (
                                <div className="d-block d-md-none text-center align-content-center mt-0 mb-2 mx-auto">
                                    <AnimatedButton
                                        className="btn btn-warning btn-sm fw-bold"
                                        onClick={() => {
                                            document.getElementById("discount-section")?.scrollIntoView({
                                                behavior: "smooth",
                                                block: "start"
                                            });
                                        }}
                                        type="button"
                                    >
                                        Kliknij tutaj, aby uzyskać dostęp do Kursu
                                    </AnimatedButton>
                                </div>
                            )}

                            <div
                                className="d-block d-md-none col-12"
                                style={{
                                    position: "relative",
                                    minHeight: "450px",
                                    height: 0,
                                }}
                            >
                                <Chat
                                    webinar={webinarDetails}
                                    chatContainerRef={chatContainerRef}
                                    chatMessages={chatMessages}
                                    userInput={userInput}
                                    setUserInput={setUserInput}
                                    handleKeyDown={handleKeyDown}
                                    handleSend={handleSend}
                                />
                            </div>

                            <div
                                className="d-none d-md-block col-md-4"
                                style={{
                                    position: "relative",
                                    paddingBottom: "37.5%",
                                    height: 0,
                                }}
                            >
                                <Chat
                                    webinar={webinarDetails}
                                    chatContainerRef={chatContainerRef}
                                    chatMessages={chatMessages}
                                    userInput={userInput}
                                    setUserInput={setUserInput}
                                    handleKeyDown={handleKeyDown}
                                    handleSend={handleSend}
                                />
                            </div>

                            {/* Discount block, etc. */}
                            {renderDiscountBlock()}
                        </motion.div>
                    )}

                </AnimatePresence>
            </div>
            {/*{bubbleKeys.map((k) => (*/}
            {/*    <BubbleNotificationAboutTraining*/}
            {/*        key={k}  // Each is a new mount => picks new random city*/}
            {/*        message="Wykupił/a dostęp do Kursu Efektywnego Działania"*/}
            {/*        delay={0}       // Show immediately on mount*/}
            {/*        duration={8000} // Hide after 8s*/}
            {/*    />*/}
            {/*))}*/}
        </>
    );
}