import {
    getSourceAndVariationFromRequest,
    KED_WEBINAR_EXPERIMENT_AB_9,
    MW_FULL_FUNNEL_EXPERIMENT_2
} from "../../../util/ExperimentUtil";
import {NewsletterService} from "../../../service/NewsletterService";
import {NewsletterType} from "../../../components/user/newsletter/NewsletterForm";
import {TraceService} from "../../../service/TraceService";
import {TraceType} from "../../../model/user/general/Trace";
import {DiagnosticService} from "../../../service/DiagnosticService";
import {getSessionId} from "../../../util/TrackingUtil";

// Frontend SHA-256 hashing function
async function hashEmail(email: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(email.trim().toLowerCase());
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);
    return Array.from(new Uint8Array(hashBuffer))
        .map(byte => byte.toString(16).padStart(2, '0'))
        .join('');
}

export async function funnelMWParentOptInAction({request}: { request: Request }) {
    return action(request, MW_FULL_FUNNEL_EXPERIMENT_2, NewsletterType.MWOptInParent);
}

export async function funnelKedWebinarOptInAction({request}: { request: Request }) {
    return action(request, KED_WEBINAR_EXPERIMENT_AB_9, NewsletterType.WebinarKedOptInV1);
}

export async function action(request: Request, experimentId: string, newsletterType: string) {
    const {source, variation} = await getSourceAndVariationFromRequest(request, experimentId);
    const formData = await request.formData();

    const optInData = {
        name: formData.get('name') as string,
        email: formData.get('email') as string
    };

    try {
        // Hash the email using frontend-friendly SHA-256
        const hashedEmail = await hashEmail(optInData.email);
        // Grant consent AFTER form submission (GDPR compliant)
        // @ts-ignore
        gtag('consent', 'update', {
            'ad_storage': 'granted',
            'analytics_storage': 'granted'
        });

        // Send conversion event to Google Ads with Hashed Email
        // @ts-ignore
        gtag('event', 'conversion', {
            'send_to': 'AW-16562613681/yysSCIGb7pkaELHb1dk9',
            'user_data': {
                'email': hashedEmail
            }
        });
    } catch (error) {
        DiagnosticService.addDiagnostic({
            error: error,
            fullEvent: {},
            functionName: "MW-OptIn / Ked-Webinar",
            additionalInfo: "There was an error sending conversions to Google Ads."
        });
    }

    try {
        // Subscribe user to the newsletter
        const {webinarId} = await NewsletterService.subscribeNewsletter({
            name: optInData.name,
            type: newsletterType,
            email: optInData.email,
            source: source,
            variation: variation,
            eventUrl: window.location.href,
            webinarDate: formData.get('webinarDate') as string ?? undefined,
            experimentId: experimentId ?? undefined,
            sessionId: getSessionId() ?? undefined
        });

        // TODO: Fix the tracking (sent twice)
        // try {
        //     ReactPixel.track('SubmitApplication', {eventID: getSessionId() ?? uuidv4()});
        // } catch (error) {
        //     await DiagnosticService.addDiagnostic({
        //         fullEvent: request,
        //         error: error,
        //         functionName: "MW-OptIn / Ked-Webinar",
        //         additionalInfo: "Error while sending Facebook conversion"
        //     });
        // }

        // Log the trace
        TraceService.addTraceWithExperiment(TraceType.RequestSend, experimentId, variation, source, webinarId, optInData.email);

        if (webinarId) {
            return {
                status: 200,
                body: webinarId
            };
        } else {
            return {
                status: 400,
                body: "Wystąpił błąd. Spróbuj ponownie później."
            };
        }
    } catch (error) {
        try {
            await DiagnosticService.addDiagnostic({
                fullEvent: request,
                error: error,
                functionName: "MW-OptIn / Ked-Webinar",
                additionalInfo: "Error while adding to newsletter"
            });
        } catch (error) {
        }

        return {
            status: 400,
            body: "Wystąpił błąd. Spróbuj ponownie później."
        };
    }
}