import {CourseFeedbackRequest, FeedbackItemsModel, FeedbackRequest} from "../model/common/Feedback";
import {ContentType, HttpMethod, HttpService} from "./HttpService";
import {constructUrl} from "../util/Util";

export class FeedbackService {

    public static sendFeedback(requestBody: FeedbackRequest): Promise<void> {
        return HttpService.sendRequest<void>(
            HttpMethod.POST,
            '/api/feedback',
            JSON.stringify(requestBody),
            ContentType.JSON
        );
    }

    public static sendCourseFeedback(requestBody: CourseFeedbackRequest): Promise<void> {
        return HttpService.sendRequest<void>(
            HttpMethod.POST,
            '/api/feedback-course',
            JSON.stringify(requestBody),
            ContentType.JSON
        );
    }

    public static markFeedbackAsRead(feedbackId: string): Promise<void> {
        return HttpService.sendRequest<void>(
            HttpMethod.POST,
            `/api/feedback/read`,
            JSON.stringify({
                id: feedbackId
            }),
            ContentType.JSON
        );
    }

    public static getAllFeedback(pageKey: string | undefined, category: string | null): Promise<FeedbackItemsModel> {
        let url = constructUrl('/api/feedback', {nextPageKey: pageKey, category: category});
        return HttpService.sendRequest<FeedbackItemsModel>(
            HttpMethod.GET,
            url,
        );
    }
}
