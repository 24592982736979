import {AvailableCourses} from "../learning-course/AvailableCourses";

export class PurchaseOption {
    static readonly BASIC_CHAT_TOKENS = new PurchaseOption('BASIC_CHAT_TOKENS', 10.99, {tokens: 100}, "100 Tokenów");
    static readonly REGULAR_CHAT_TOKENS = new PurchaseOption('REGULAR_CHAT_TOKENS', 59.99, {tokens: 600}, "600 Tokenów");
    static readonly ADVANCED_CHAT_TOKENS = new PurchaseOption('ADVANCED_CHAT_TOKENS', 147.99, {tokens: 1500}, "1500 Tokenów");

    static readonly COURSE_KED_BASIC_ACCESS = new PurchaseOption('COURSE_KED_BASIC_ACCESS', 6000.00, {courseAccess: AvailableCourses.KED}, "Dostęp do Kursu Efektywnego Działania");
    static readonly COURSE_KED_WEBINAR = new PurchaseOption('COURSE_KED_WEBINAR', 1999.00, {courseAccess: AvailableCourses.KED}, "Dostęp do Kursu Efektywnego Działania");
    static readonly COURSE_KED_NEWSLETTER = new PurchaseOption('COURSE_KED_NEWSLETTER', 1999.00, {courseAccess: AvailableCourses.KED}, "Dostęp do Kursu Efektywnego Działania");
    static readonly COURSE_KED_RADS = new PurchaseOption('COURSE_KED_RADS', 1999.00, {courseAccess: AvailableCourses.KED}, "Dostęp do Kursu Efektywnego Działania");
    static readonly COURSE_KED_WEBINAR_SALES_PAGE = new PurchaseOption('COURSE_KED_WEBINAR_SALES_PAGE', 1999.00, {courseAccess: AvailableCourses.KED}, "Dostęp do Kursu Efektywnego Działania");
    static readonly COURSE_KED_FROM_PED_SALES_PAGE = new PurchaseOption('COURSE_KED_FROM_PED_SALES_PAGE', 1999.00, {courseAccess: AvailableCourses.KED}, "Dostęp do Kursu Efektywnego Działania");

    static readonly COURSE_PED = new PurchaseOption('COURSE_PED', 199.00, {courseAccess: AvailableCourses.PED}, "Dostęp do Podstaw Efektywnego Działania");

    static readonly EXTRAORDINARY_MONTHLY_SUPPORT = new PurchaseOption('EXTRAORDINARY_MONTHLY_SUPPORT', 3000.00, {extraordinarySupport: 1}, "MISTRZOWSKIE Wsparcie");

    private constructor(private readonly key: string, public readonly price: number, public readonly item: TransactionItem, public readonly description: string) {
    }

    toString() {
        return this.key
    }
}

export interface TransactionItem {
    tokens?: number
    courseAccess?: AvailableCourses
    coachingSessions?: number
    extraordinarySupport?: number
}