import Meta from "../../../components/common/Meta";
import React, {Suspense} from "react";
import MainDesign from "../../MainDesign";
import {Await, defer, useLoaderData} from "react-router-dom";
import {AdminService} from "../../../service/AdminService";
import {
    KED_WEBINAR_EXPERIMENT_1,
    KED_WEBINAR_EXPERIMENT_AB_1,
    KED_WEBINAR_EXPERIMENT_AB_2,
    KED_WEBINAR_EXPERIMENT_AB_3,
    KED_WEBINAR_EXPERIMENT_AB_4,
    KED_WEBINAR_EXPERIMENT_AB_5,
    KED_WEBINAR_EXPERIMENT_AB_6,
    KED_WEBINAR_EXPERIMENT_AB_7,
    KED_WEBINAR_EXPERIMENT_AB_8,
    KED_WEBINAR_EXPERIMENT_AB_9,
    MW_FULL_FUNNEL_EXPERIMENT_1,
    MW_FULL_FUNNEL_EXPERIMENT_2,
} from "../../../util/ExperimentUtil";
import {ExperimentMetrics} from "../../../model/admin/Metrics";
import LoadingPage from "../../common/LoadingPage";
import ErrorContent from "../../../components/common/error/ErrorContent";
import AdminKedExperimentResults from "../../../components/admin/ked/AdminKedExperimentResults";
import Heading from "../../../components/common/header/Heading";
import AdminKedNavbar from "../../../components/admin/ked/AdminKedNavbar";

type LoaderData = {
    experimentMetrics: Promise<ExperimentMetrics[]>[]
}
export default function AdminKedExperimentPage() {
    const {experimentMetrics} = useLoaderData() as LoaderData;

    return <>
        <Meta title='KED - Eksperymenty'/>
        <MainDesign containerStyles='container pt-20'>
            <Heading badgeText='wykresy' title='Wyniki eksperymentów KED'
                     customTitleStyles='font-heading mb-4 fs-5 text-white'/>
            <AdminKedNavbar/>
            <hr className='text-white'/>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={experimentMetrics} errorElement={<ErrorContent/>}>
                    {([fullFunnelAdsMetrics, fullFunnelAdsMetrics2, kedWebinarExperiment1, kedWebinarExperimentAB1, kedWebinarExperimentAB2, kedWebinarExperimentAB3, kedWebinarExperimentAB4, kedWebinarExperimentAB5, kedWebinarExperimentAB6, kedWebinarExperimentAB7, kedWebinarExperimentAB8, kedWebinarExperimentAB9]) => <>
                        <AdminKedExperimentResults fullFunnelAdsMetrics={fullFunnelAdsMetrics}
                                                   fullFunnelAdsMetrics2={fullFunnelAdsMetrics2}
                                                   kedWebinarExperiment1={kedWebinarExperiment1}
                                                   kedWebinarExperimentAB1={kedWebinarExperimentAB1}
                                                   kedWebinarExperimentAB2={kedWebinarExperimentAB2}
                                                   kedWebinarExperimentAB3={kedWebinarExperimentAB3}
                                                   kedWebinarExperimentAB4={kedWebinarExperimentAB4}
                                                   kedWebinarExperimentAB5={kedWebinarExperimentAB5}
                                                   kedWebinarExperimentAB6={kedWebinarExperimentAB6}
                                                   kedWebinarExperimentAB7={kedWebinarExperimentAB7}
                                                   kedWebinarExperimentAB8={kedWebinarExperimentAB8}
                                                   kedWebinarExperimentAB9={kedWebinarExperimentAB9}
                        />
                    </>}
                </Await>
            </Suspense>
        </MainDesign>
    </>
}

export async function loader() {

    return defer({
        experimentMetrics: Promise.all([
            AdminService.getExperimentResults(MW_FULL_FUNNEL_EXPERIMENT_1),
            AdminService.getExperimentResults(MW_FULL_FUNNEL_EXPERIMENT_2),
            AdminService.getExperimentResults(KED_WEBINAR_EXPERIMENT_1),
            AdminService.getExperimentResults(KED_WEBINAR_EXPERIMENT_AB_1),
            AdminService.getExperimentResults(KED_WEBINAR_EXPERIMENT_AB_2),
            AdminService.getExperimentResults(KED_WEBINAR_EXPERIMENT_AB_3),
            AdminService.getExperimentResults(KED_WEBINAR_EXPERIMENT_AB_4),
            AdminService.getExperimentResults(KED_WEBINAR_EXPERIMENT_AB_5),
            AdminService.getExperimentResults(KED_WEBINAR_EXPERIMENT_AB_6),
            AdminService.getExperimentResults(KED_WEBINAR_EXPERIMENT_AB_7),
            AdminService.getExperimentResults(KED_WEBINAR_EXPERIMENT_AB_8),
            AdminService.getExperimentResults(KED_WEBINAR_EXPERIMENT_AB_9),
        ])
    })
}