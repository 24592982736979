import {ContentType, HttpMethod, HttpService} from "./HttpService";
import {ExperimentMetrics, Trace, Traces, TraceType} from "../model/user/general/Trace";
import {DiagnosticService} from "./DiagnosticService";
import {constructUrl} from "../util/Util";
import {getExperimentVariation} from "../util/ExperimentUtil";
import {getSessionId} from "../util/TrackingUtil";

export class TraceService {

    // Variation is either A or B
    public static async addTraceWithExperiment(traceType: TraceType, experimentId: string, variation?: string | null, source?: string | null, webinarId?: string, email?: string): Promise<void> {
        const experimentMetrics = {
            experimentId: experimentId,
            variation: variation ?? getExperimentVariation(experimentId),
            source: source ?? (localStorage.getItem('firstJoinedFrom') ?? "unknown")
        } as ExperimentMetrics
        await this.addTrace(TraceType.Experiment, JSON.stringify(experimentMetrics), undefined, traceType, webinarId, email);
    }

    public static async addTrace(traceType: TraceType, additionalInfo?: string, newUrl?: string, traceTypeForExperiment?: TraceType, webinarId?: string, email?: string): Promise<void> {
        let searchParams = window.location.search;
        let pathname = window.location.pathname;
        let href = window.location.href

        if (newUrl) {
            const url = new URL(newUrl);
            searchParams = url.searchParams.toString();
            pathname = url.pathname;
            href = url.href;
        }

        const trace = {
            sessionId: getSessionId(),
            origin: window.location.origin,
            searchParams: searchParams,
            pathname: pathname,
            href: href,
            traceType: traceType,
            additionalInfo: additionalInfo,
            traceTypeForExperiment: traceTypeForExperiment,
            webinarId: webinarId,
            email: email
        } as Trace

        // do not store admin traces and password change
        if ((trace.pathname && trace.pathname.startsWith('/admin')) || (trace.pathname && trace.pathname.startsWith("/zmien-haslo") && trace.traceType === TraceType.RequestSend)) {
            return;
        }

        try {
            await HttpService.sendRequest<void>(
                HttpMethod.POST,
                '/api/trace',
                JSON.stringify(trace),
                ContentType.JSON
            );
        } catch (error) {
            await DiagnosticService.addDiagnostic({
                error: JSON.stringify(error),
                fullEvent: JSON.stringify(trace),
                functionName: 'addTrace',
            })
            console.log('Error adding trace:', error);
        }
    }

    public static async getTraces(nextPageKey: string | undefined, date: string): Promise<Traces> {
        const url = constructUrl('/api/trace', {nextPageKey: nextPageKey, date: date});

        return await HttpService.sendRequest<Traces>(
            HttpMethod.GET,
            url
        );
    }
}