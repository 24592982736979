import AnimatedButton from "../ui/motion/AnimatedButton";
import MainDesign from "../../pages/MainDesign";
import {Link, useSubmit} from "react-router-dom";
import React, {useRef, useState} from "react";
import {useSubmitFormAction} from "../../hooks/useSubmitFormAction";
import ReCAPTCHA from "react-google-recaptcha"
import AnimatedLink from "../ui/motion/AnimatedLink";

export default function ContactContent() {
    const {isSubmitting} = useSubmitFormAction();
    const submit = useSubmit();
    const captchaRef = useRef<ReCAPTCHA>(null)
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [content, setContent] = useState('');

    const isEmailValid = email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    function submitForm() {
        const captchaToken = captchaRef.current?.getValue();

        submit({
            name,
            email,
            content,
            captchaToken: captchaToken ?? null
        }, {
            method: "POST"
        });
    }

    const isInputValid = isEmailValid && content.length >= 30;
    return <MainDesign containerStyles='container py-20'>
        <div className='mb-16 row align-items-center g-16'>
            <div className='col-12 col-md-6'>
              <span className='badge mb-4 bg-success-dark text-success text-uppercase shadow'>
                Kontakt
              </span>
                <h1
                    className='font-heading mb-4 fs-5 text-white'
                    style={{letterSpacing: '-1px'}}
                >
                    Pozostańmy w kontakcie
                </h1>

                <p className='mb-0 fs-9 fw-medium text-secondary-light'>
                    Masz jakieś pytania? Napisz do nas wiadomość.
                </p>
            </div>
            <div className='col-12 col-md-6'>
                <div className='row justify-content-end g-4'>
                    <div className='col-12 col-md-auto'>
                        <AnimatedLink
                            className='btn btn-lg btn-success w-100 text-success-light shadow'
                            to='/o-nas'
                        >
                            O nas
                        </AnimatedLink>
                    </div>
                    <div className='col-12 col-md-auto'>
                        <AnimatedLink
                            className="btn btn-lg btn-dark-light w-100 text-success-light shadow"
                            to="/oferta"
                        >
                            Zobacz ofertę
                        </AnimatedLink>
                    </div>
                </div>
            </div>
        </div>
        <div className='row align-items-center g-16'>
            <div className='col-12 col-md-6'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='mw-md-xl'>
                            <div className='row g-8'>
                                <div className='col-12 col-md-6'>
                                    <svg
                                        className='mb-6 text-success'
                                        width={48}
                                        height={48}
                                        viewBox='0 0 48 48'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <circle cx={24} cy={24} r={24} fill='currentColor'/>
                                        <path
                                            d='M32.21 20.82L26 14.78C25.474 14.2799 24.7759 14.001 24.05 14.001C23.3241 14.001 22.626 14.2799 22.1 14.78L15.89 20.78C15.6141 21.0209 15.3922 21.3173 15.2388 21.6499C15.0854 21.9825 15.004 22.3438 15 22.71V31.29C15.0105 32.0176 15.309 32.7114 15.8301 33.2193C16.3512 33.7273 17.0524 34.008 17.78 34H30.22C30.9476 34.008 31.6488 33.7273 32.1699 33.2193C32.691 32.7114 32.9895 32.0176 33 31.29V22.71C32.9992 22.3585 32.929 22.0106 32.7935 21.6862C32.6579 21.3619 32.4596 21.0675 32.21 20.82ZM23.44 16.22C23.593 16.0802 23.7927 16.0026 24 16.0026C24.2073 16.0026 24.407 16.0802 24.56 16.22L30 21.5L24.53 26.78C24.377 26.9198 24.1773 26.9974 23.97 26.9974C23.7627 26.9974 23.563 26.9198 23.41 26.78L18 21.5L23.44 16.22ZM31 31.29C30.9871 31.4863 30.8987 31.6699 30.7532 31.8023C30.6078 31.9347 30.4166 32.0056 30.22 32H17.78C17.5834 32.0056 17.3922 31.9347 17.2468 31.8023C17.1013 31.6699 17.0129 31.4863 17 31.29V23.35L21.05 27.25L19.39 28.85C19.2037 29.0374 19.0992 29.2908 19.0992 29.555C19.0992 29.8192 19.2037 30.0726 19.39 30.26C19.4829 30.3575 19.5946 30.4352 19.7184 30.4885C19.8421 30.5418 19.9753 30.5695 20.11 30.57C20.3675 30.569 20.6146 30.4687 20.8 30.29L22.57 28.59C23.0096 28.8586 23.5148 29.0008 24.03 29.0008C24.5452 29.0008 25.0504 28.8586 25.49 28.59L27.26 30.29C27.4454 30.4687 27.6925 30.569 27.95 30.57C28.0847 30.5695 28.2179 30.5418 28.3416 30.4885C28.4654 30.4352 28.5771 30.3575 28.67 30.26C28.8563 30.0726 28.9608 29.8192 28.9608 29.555C28.9608 29.2908 28.8563 29.0374 28.67 28.85L27 27.25L31 23.35V31.29Z'
                                            fill='#F0FDF4'
                                        />
                                    </svg>
                                    <h2 className='mb-2 font-heading fs-7 text-white'>
                                        Email
                                    </h2>
                                    <p className='mb-0 fs-9 text-secondary-light fw-medium'>
                                        <a
                                            href="mailto:kontakt@produkacja.pl"
                                            className='text-secondary-light'
                                        >
                                            kontakt@produkacja.pl
                                        </a>
                                    </p>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <svg
                                        className='mb-6 text-success'
                                        width={48}
                                        height={48}
                                        viewBox='0 0 48 48'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <circle cx={24} cy={24} r={24} fill='currentColor'/>
                                        <path
                                            d='M33 14H15C14.7348 14 14.4804 14.1054 14.2929 14.2929C14.1054 14.4804 14 14.7348 14 15V33C14 33.2652 14.1054 33.5196 14.2929 33.7071C14.4804 33.8946 14.7348 34 15 34H33C33.2652 34 33.5196 33.8946 33.7071 33.7071C33.8946 33.5196 34 33.2652 34 33V15C34 14.7348 33.8946 14.4804 33.7071 14.2929C33.5196 14.1054 33.2652 14 33 14V14ZM20 32H16V28H20V32ZM20 26H16V22H20V26ZM20 20H16V16H20V20ZM26 32H22V28H26V32ZM26 26H22V22H26V26ZM26 20H22V16H26V20ZM32 32H28V28H32V32ZM32 26H28V22H32V26ZM32 20H28V16H32V20Z'
                                            fill='#F0FDF4'
                                        />
                                    </svg>
                                    <h2 className='mb-8 font-heading fs-7 text-white'>
                                        Media
                                    </h2>
                                    <div className='row'>
                                        <div className='col-auto'>
                                            <AnimatedLink className='text-success link-success d-inline-block'
                                                          to='https://www.facebook.com/profile.php?id=61556407554059'
                                                          customScale={1.3}>
                                                <svg
                                                    width={24}
                                                    height={24}
                                                    viewBox='0 0 24 24'
                                                    fill='none'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                >
                                                    <path
                                                        fillRule='evenodd'
                                                        clipRule='evenodd'
                                                        d='M13.6348 20.7272V12.766H16.3582L16.7668 9.66243H13.6348V7.68126C13.6348 6.78299 13.8881 6.17083 15.2029 6.17083L16.877 6.17015V3.39421C16.5875 3.35731 15.5937 3.27271 14.437 3.27271C12.0216 3.27271 10.368 4.71878 10.368 7.37389V9.66243H7.63635V12.766H10.368V20.7272H13.6348Z'
                                                        fill='currentColor'
                                                    />
                                                    <mask
                                                        id='mask0_1201_15606'
                                                        style={{maskType: 'alpha'}}
                                                        maskUnits='userSpaceOnUse'
                                                        x={7}
                                                        y={3}
                                                        width={10}
                                                        height={18}
                                                    >
                                                        <path
                                                            fillRule='evenodd'
                                                            clipRule='evenodd'
                                                            d='M13.6348 20.7272V12.766H16.3582L16.7668 9.66243H13.6348V7.68126C13.6348 6.78299 13.8881 6.17083 15.2029 6.17083L16.877 6.17015V3.39421C16.5875 3.35731 15.5937 3.27271 14.437 3.27271C12.0216 3.27271 10.368 4.71878 10.368 7.37389V9.66243H7.63635V12.766H10.368V20.7272H13.6348Z'
                                                            fill='white'
                                                        />
                                                    </mask>
                                                    <g mask='url(#mask0_1201_15606)'/>
                                                </svg>
                                            </AnimatedLink>
                                        </div>
                                        <div className='col-auto'>
                                            <AnimatedLink className='text-success link-success d-inline-block'
                                                          to='https://www.instagram.com/produkacja.pl/'
                                                          customScale={1.3}>
                                                <svg
                                                    width={24}
                                                    height={24}
                                                    viewBox='0 0 24 24'
                                                    fill='none'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                >
                                                    <path
                                                        fillRule='evenodd'
                                                        clipRule='evenodd'
                                                        d='M7.60057 2.18176H16.3991C19.3872 2.18176 21.8182 4.61276 21.8181 7.60069V16.3992C21.8181 19.3871 19.3872 21.8181 16.3991 21.8181H7.60057C4.61264 21.8181 2.18176 19.3872 2.18176 16.3992V7.60069C2.18176 4.61276 4.61264 2.18176 7.60057 2.18176ZM16.3992 20.0759C18.4266 20.0759 20.076 18.4266 20.076 16.3992H20.0759V7.60069C20.0759 5.57343 18.4265 3.924 16.3991 3.924H7.60057C5.57331 3.924 3.924 5.57343 3.924 7.60069V16.3992C3.924 18.4266 5.57331 20.076 7.60057 20.0759H16.3992ZM6.85709 12.0001C6.85709 9.16418 9.16413 6.85709 11.9999 6.85709C14.8358 6.85709 17.1428 9.16418 17.1428 12.0001C17.1428 14.8358 14.8358 17.1428 11.9999 17.1428C9.16413 17.1428 6.85709 14.8358 6.85709 12.0001ZM8.62792 11.9999C8.62792 13.8592 10.1407 15.3718 11.9999 15.3718C13.8592 15.3718 15.372 13.8592 15.372 11.9999C15.372 10.1405 13.8593 8.62784 11.9999 8.62784C10.1406 8.62784 8.62792 10.1405 8.62792 11.9999Z'
                                                        fill='currentColor'
                                                    />
                                                    <mask
                                                        id='mask0_1201_15608'
                                                        style={{maskType: 'alpha'}}
                                                        maskUnits='userSpaceOnUse'
                                                        x={2}
                                                        y={2}
                                                        width={20}
                                                        height={20}
                                                    >
                                                        <path
                                                            fillRule='evenodd'
                                                            clipRule='evenodd'
                                                            d='M7.60057 2.18176H16.3991C19.3872 2.18176 21.8182 4.61276 21.8181 7.60069V16.3992C21.8181 19.3871 19.3872 21.8181 16.3991 21.8181H7.60057C4.61264 21.8181 2.18176 19.3872 2.18176 16.3992V7.60069C2.18176 4.61276 4.61264 2.18176 7.60057 2.18176ZM16.3992 20.0759C18.4266 20.0759 20.076 18.4266 20.076 16.3992H20.0759V7.60069C20.0759 5.57343 18.4265 3.924 16.3991 3.924H7.60057C5.57331 3.924 3.924 5.57343 3.924 7.60069V16.3992C3.924 18.4266 5.57331 20.076 7.60057 20.0759H16.3992ZM6.85709 12.0001C6.85709 9.16418 9.16413 6.85709 11.9999 6.85709C14.8358 6.85709 17.1428 9.16418 17.1428 12.0001C17.1428 14.8358 14.8358 17.1428 11.9999 17.1428C9.16413 17.1428 6.85709 14.8358 6.85709 12.0001ZM8.62792 11.9999C8.62792 13.8592 10.1407 15.3718 11.9999 15.3718C13.8592 15.3718 15.372 13.8592 15.372 11.9999C15.372 10.1405 13.8593 8.62784 11.9999 8.62784C10.1406 8.62784 8.62792 10.1405 8.62792 11.9999Z'
                                                            fill='white'
                                                        />
                                                    </mask>
                                                    <g mask='url(#mask0_1201_15608)'/>
                                                </svg>
                                            </AnimatedLink>
                                        </div>
                                        <div className='col-auto'>
                                            <AnimatedLink className='text-success link-success d-inline-block'
                                                          customScale={1.3}
                                                          to='https://www.tiktok.com/@produkacja'>
                                                <img alt='tiktok logo' style={{width: "24px"}}
                                                     src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/kontakt/tiktok.png'/>
                                            </AnimatedLink>
                                        </div>
                                        <div className='col-auto'>
                                            <AnimatedLink className='text-success link-success d-inline-block'
                                                          customScale={1.3}
                                                          to='https://www.youtube.com/@produkacja'>
                                                <img alt='tiktok logo' style={{width: "26px"}}
                                                     src='https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/kontakt/youtube.png'/>
                                            </AnimatedLink>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <img className='img-fluid'
                                         src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kontakt/mikolaj-talking-square.png'
                                         alt='rozmowa'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12 col-md-6'>
                <form className='mw-md-xl ms-auto px-10 pb-10 pt-6 bg-light-dark rounded shadow'>
                    <p className='mb-0 fs-9 fw-medium text-white'>
                        Postaraj się zawrzeć w swojej wiadomości wszystkie szczegóły.
                    </p>

                    <p className='mb-2 fs-11 fw-medium text-secondary-light'>
                        Treść powinna zawierać
                        przynajmniej 30 znaków.
                    </p>

                    <div className='mb-6 form-group'>
                        <label
                            className='mb-1 fw-medium text-white'
                            htmlFor='contactInput9-2'
                        >
                            Imię
                        </label>
                        <input
                            className='form-control shadow'
                            id='contactInput110-1'
                            type='text'
                            aria-describedby='name'
                            placeholder='Podaj swoje imię'
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                        />
                    </div>
                    <div className='mb-6 form-group'>
                        <label
                            className='mb-1 fw-medium text-white'
                            htmlFor='contactInput9-2'
                        >
                            Email
                        </label>
                        <input
                            className='form-control shadow'
                            id='contactInput10-1'
                            type='email'
                            aria-describedby='emailHelp'
                            placeholder='Podaj swój adres email'
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                    </div>
                    <div className='mb-6 form-group'>
                        <label
                            className='mb-1 fw-medium text-white'
                            htmlFor='contactInput10-2'
                        >
                            Wiadomość
                        </label>
                        <textarea
                            className='form-control shadow'
                            id='contactInput10-2'
                            placeholder='Napisz wiadomość'
                            rows={10}
                            value={content}
                            onChange={(event) => setContent(event.target.value)}
                        />
                    </div>
                    <div className='mb-6 form-group'>
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY!}
                            ref={captchaRef}
                        />
                    </div>
                    <AnimatedButton
                        className='btn btn-lg btn-success w-100 text-success-light shadow'
                        type='button'
                        onClick={submitForm}
                        disabled={!isInputValid || isSubmitting}
                    >
                        {isSubmitting ? "Trwa wysyłanie..." : "Wyślij"}
                    </AnimatedButton>
                    <p className='mb-0 fs-13 fw-medium text-white text-left mt-3'>
                        <span>Podanie adresu email wiąże się z akceptacją </span>
                        <Link className='text-success link-success' to='/polityka-prywatnosci'>
                            polityki prywatności
                        </Link>
                    </p>
                </form>
            </div>
        </div>
    </MainDesign>
}