import React, {useEffect, useState} from "react";
import {useFetcher} from "react-router-dom";
import AnimatedButton from "../../../ui/motion/AnimatedButton";
import {useSubmitFormAction} from "../../../../hooks/useSubmitFormAction";
import {FeedbackService} from "../../../../service/FeedbackService";
import {AvailableCourses} from "../../../../model/user/learning-course/AvailableCourses";

interface MandatoryFeedbackCourseModalProps {
    isOpen: boolean;
}

interface FeedbackData {
    ratingCourse: string;
    experience: string;
    missing: string;
    magicWand: string;
    recommend: string;
}

const LOCAL_STORAGE_KEY = "MandatoryFeedbackCourseModalData";

export default function MandatoryFeedbackCourseModal({isOpen}: MandatoryFeedbackCourseModalProps) {
    const fetcher = useFetcher();
    const {isSubmitting} = useSubmitFormAction("whatever", fetcher, () => {
        // On successful submission, clear state and localStorage.
        setRatingCourse("");
        setExperience("");
        setMissing("");
        setMagicWand("");
        setRecommend("");
        localStorage.removeItem(LOCAL_STORAGE_KEY);
    }, false);

    // State for each answer.
    const [ratingCourse, setRatingCourse] = useState("");
    const [experience, setExperience] = useState("");
    const [missing, setMissing] = useState("");
    const [magicWand, setMagicWand] = useState("");
    const [recommend, setRecommend] = useState("");

    // Control the current question (0 to 4).
    const [currentQuestion, setCurrentQuestion] = useState(0);

    // State for error message on current question.
    const [error, setError] = useState("");

    // Load any saved answers from localStorage on mount.
    useEffect(() => {
        const storedData = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (storedData) {
            const parsed: FeedbackData = JSON.parse(storedData);
            setRatingCourse(parsed.ratingCourse || "");
            setExperience(parsed.experience || "");
            setMissing(parsed.missing || "");
            setMagicWand(parsed.magicWand || "");
            setRecommend(parsed.recommend || "");
        }
    }, []);

    // Save answers to localStorage whenever any answer changes.
    useEffect(() => {
        const data: FeedbackData = {ratingCourse, experience, missing, magicWand, recommend};
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
    }, [ratingCourse, experience, missing, magicWand, recommend]);

    // Validate current question’s answer.
    const validateCurrent = (): boolean => {
        switch (currentQuestion) {
            case 0:
                if (!ratingCourse) {
                    setError("Wybierz ocenę od 1 do 10.");
                    return false;
                }
                break;
            case 1:
                if (!experience || experience.trim().length < 20) {
                    setError("Twoja odpowiedź powinna składać się z przynajmniej 20 znaków.");
                    return false;
                }
                break;
            case 2:
                if (!missing || missing.trim().length < 3) {
                    setError("Twoja odpowiedź powinna składać się z przynajmniej 3 znaków.");
                    return false;
                }
                break;
            case 3:
                if (!magicWand || magicWand.trim().length < 20) {
                    setError("Twoja odpowiedź powinna składać się z przynajmniej 20 znaków.");
                    return false;
                }
                break;
            case 4:
                if (!recommend) {
                    setError("Wybierz ocenę od 1 do 10.");
                    return false;
                }
                break;
            default:
                break;
        }
        setError("");
        return true;
    };

    // Move to next question or submit on the last one.
    const handleNext = () => {
        if (!validateCurrent()) return;
        if (currentQuestion < 4) {
            setCurrentQuestion((prev) => prev + 1);
        } else {
            // On the last question, submit the form.
            submitForm();
        }
    };

    // Navigate back.
    const handleBack = () => {
        if (currentQuestion > 0) {
            setCurrentQuestion((prev) => prev - 1);
            setError("");
        }
    };

    // Submit the form using fetcher.
    const submitForm = () => {
        fetcher.submit(
            {
                ratingCourse,
                experience,
                missing,
                magicWand,
                recommend,
                course: AvailableCourses.KED,
            },
            {
                method: "POST",
                action: "/feedbackCourse",
            }
        );
    };

    // Render the current question.
    const renderQuestion = () => {
        switch (currentQuestion) {
            case 0:
                return (
                    <div className="col-12">
                        <label className="mb-0 text-white fw-bold fs-9">1. Jak oceniasz Kurs do tej pory?</label>
                        <p className='mt-0'>1 - bardzo słabo, tragicznie | 10 - świetnie, bez żadnych zarzutów</p>
                        <div>
                            {Array.from({length: 10}, (_, i) => {
                                const value = (i + 1).toString();
                                return (
                                    <div className="form-check form-check-inline" key={value}>
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="ratingCourse"
                                            id={`ratingCourse${value}`}
                                            value={value}
                                            checked={ratingCourse === value}
                                            onChange={(e) => setRatingCourse(e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor={`ratingCourse${value}`}>
                                            {value}
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div className="col-12">
                        <label className="mb-2 text-white fw-bold fs-9">
                            2. Napisz 1-2 zdania o swoich doświadczeniach z Kursem.
                        </label>
                        <textarea
                            className="input-lg form-control shadow"
                            rows={3}
                            value={experience}
                            onChange={(e) => setExperience(e.target.value)}
                            placeholder="Opisz swoje doświadczenia..."
                            style={experience ? {color: "black"} : {}}
                        ></textarea>
                    </div>
                );
            case 2:
                return (
                    <div className="col-12">
                        <label className="mb-2 text-white fw-bold fs-9">
                            3. Czy było coś, czego brakowało Ci w pierwszych tygodniach Kursu?
                        </label>
                        <textarea
                            className="input-lg form-control shadow"
                            rows={3}
                            value={missing}
                            onChange={(e) => setMissing(e.target.value)}
                            placeholder="Opisz, czego Ci brakowało..."
                            style={missing ? {color: "black"} : {}}
                        ></textarea>
                    </div>
                );
            case 3:
                return (
                    <div className="col-12">
                        <label className="mb-2 text-white fw-bold fs-9">
                            4. Co byś zmienił/a lub dodał/a w Kursie, gdybyś miał/a magiczną różdżkę i mógł/mogła
                            zmienić
                            wszystko?
                        </label>
                        <textarea
                            className="input-lg form-control shadow"
                            rows={3}
                            value={magicWand}
                            onChange={(e) => setMagicWand(e.target.value)}
                            placeholder="Podziel się swoimi pomysłami..."
                            style={magicWand ? {color: "black"} : {}}
                        ></textarea>
                    </div>
                );
            case 4:
                return (
                    <div className="col-12">
                        <label className="mb-2 text-white fw-bold fs-9">
                            5. Z jak dużym prawdopodobieństwem poleciłbyś/poleciłabyś Kurs swojemu/swojej
                            przyjacielowi/przyjaciółce?
                        </label>
                        <div>
                            {Array.from({length: 10}, (_, i) => {
                                const value = (i + 1).toString();
                                return (
                                    <div className="form-check form-check-inline" key={value}>
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="recommend"
                                            id={`recommend${value}`}
                                            value={value}
                                            checked={recommend === value}
                                            onChange={(e) => setRecommend(e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor={`recommend${value}`}>
                                            {value}
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <section
            className={`position-fixed bottom-0 start-0 bg-secondary-dark bg-opacity-50 h-100 w-100 overflow-auto ${isOpen ? "" : "d-none"}`}
            style={{zIndex: 50}}
        >
            <div className="d-flex flex-wrap align-items-center h-100 text-secondary-light">
                <div className="container py-20">
                    <form
                        className="p-10 mw-3xl mx-auto bg-light-dark overflow-hidden rounded shadow"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleNext();
                        }}
                    >
                        <h3>Odpowiedz na kilka pytań, aby kontynuować.</h3>
                        <p><span
                            className='fw-medium'>Widzimy, że odblokowałeś/aś już trzeci tydzień Kursu. Gratulacje! 🎉 </span><br/>Prosilibyśmy
                            Cię o
                            wypełnienie tego
                            krótkiego
                            formularza (składa się on w sumie z 5 pytań). Twoje odpowiedzi pomogą nam w stworzeniu
                            jeszcze lepszego Kursu.</p>
                        <div className="row g-8 align-items-center">{renderQuestion()}</div>
                        {error && <p className="text-danger mt-2">{error}</p>}
                        <div className="row mt-2">
                            <div className="col-6">
                                {currentQuestion > 0 && (
                                    <AnimatedButton
                                        type="button"
                                        className="btn btn-lg btn-dark-light w-100 text-success-light shadow"
                                        onClick={handleBack}
                                    >
                                        Wstecz
                                    </AnimatedButton>
                                )}
                            </div>
                            <div className="col-6">
                                <AnimatedButton
                                    type="button"
                                    className="btn btn-lg btn-success w-100 text-success-light shadow"
                                    onClick={handleNext}
                                    disabled={isSubmitting}
                                >
                                    {currentQuestion === 4 ? "Prześlij feedback" : "Dalej"}
                                </AnimatedButton>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}

export async function mandatoryFeedbackCourseAction({request}: { request: Request }) {
    const formData = await request.formData();
    const feedbackRequest = {
        ratingCourse: formData.get("ratingCourse") as string,
        experience: formData.get("experience") as string,
        missing: formData.get("missing") as string,
        magicWand: formData.get("magicWand") as string,
        recommend: formData.get("recommend") as string,
        course: formData.get("course") as string,
    };

    try {
        await FeedbackService.sendCourseFeedback(feedbackRequest);
        localStorage.removeItem(LOCAL_STORAGE_KEY);
        return {
            status: 200,
            body: "Feedback o kursie został wysłany pomyślnie. Dziękujemy.",
        };
    } catch (error) {
        console.log(error);
        return {
            status: 500,
            body: "Wystąpił błąd. Spróbuj ponownie później.",
        };
    }
}