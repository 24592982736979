import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import {DeferredData} from "@remix-run/router/dist/utils";
import {Await, defer, useLoaderData} from "react-router-dom";
import {isEffectiveActionCourseSubscriber, redirectIfNotLoggedIn} from "../../../util/AuthUtil";
import Community from "../../../components/user/products/Community";
import {CourseService} from "../../../service/CourseService";
import {loadCourseStatistics} from "../learning-course/LearningCourseDashboardPage";
import {CourseStatistics} from "../../../model/user/learning-course/LearningCourse";
import React, {Suspense} from "react";
import LoadingPage from "../../common/LoadingPage";
import ErrorContent from "../../../components/common/error/ErrorContent";
import {AvailableCourses} from "../../../model/user/learning-course/AvailableCourses";

interface LoaderData {
    isEffectiveActionCourseSubscriber: boolean
    statistics: Promise<CourseStatistics>
}

export default function CommunityPage() {
    const {isEffectiveActionCourseSubscriber, statistics} = useLoaderData() as LoaderData;
    return <>
        <Meta title='Produkty'/>
        <MainDesign containerStyles='container py-20'>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={statistics} errorElement={<ErrorContent/>}>
                    {(loadedStatistics: CourseStatistics) => <>
                        <Community isEffectiveActionCourseSubscriber={isEffectiveActionCourseSubscriber}
                                   courseStatistics={loadedStatistics}/>
                    </>}
                </Await>
            </Suspense>
        </MainDesign>
    </>
}

export async function loader(): Promise<DeferredData | Response> {
    const redirect = await redirectIfNotLoggedIn();
    if (redirect) {
        return redirect;
    }

    const isSubscriber = await isEffectiveActionCourseSubscriber()

    return defer({
        isEffectiveActionCourseSubscriber: isSubscriber,
        statistics: isSubscriber ? loadCourseStatistics(AvailableCourses.KED) : null
    });
}

export async function action({request}: { request: Request }) {
    const formData = await request.formData();
    const discordId = formData.get('discordId') as string

    try {
        const {message} = await CourseService.configureDiscordId(discordId);
        return {
            status: 200,
            body: message
        }
    } catch (error) {
        const apiError = error as Error
        return {
            status: 500,
            body: apiError.message
        }
    }
}