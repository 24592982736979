import AnimatedLink from "../../ui/motion/AnimatedLink";
import {useLocation} from "react-router-dom";

const selectedItemStyles = 'nav-link py-2 px-4 fs-12 fw-bold text-success link-success bg-success bg-opacity-25 shadow'
const otherItemStyles = 'nav-link py-2 px-4 fs-12 fw-bold text-secondary link-secondary-light'

export default function AdminNewsletterNavbar() {
    const location = useLocation();
    console.log(location.pathname);

    const sendNewsletterPath = '/admin/newsletter';
    const listNewslettersPath = '/admin/newsletter/lista';
    const addToNewsletterPath = '/admin/newsletter/dodaj';
    const showNewsletterCampaignsStatisticsPath = '/admin/newsletter/statystyki/kampanie';
    const showNewsletterFollowUpsStatisticsPath = '/admin/newsletter/statystyki/followups';

    return <>
        <div className='mw-4xl mx-auto'>
            <ul className='mb-8 nav nav-pills'>
                <li>
                    <AnimatedLink
                        className={location.pathname === sendNewsletterPath ? selectedItemStyles : otherItemStyles}
                        to={sendNewsletterPath}>
                        Wyślij Newsletter
                    </AnimatedLink>
                </li>
                <li>
                    <AnimatedLink
                        className={location.pathname === listNewslettersPath ? selectedItemStyles : otherItemStyles}
                        to={listNewslettersPath}>
                        Listy mailingowe
                    </AnimatedLink>
                </li>
                <li>
                    <AnimatedLink
                        className={location.pathname === addToNewsletterPath ? selectedItemStyles : otherItemStyles}
                        to={addToNewsletterPath}>
                        Manualne dodawanie
                    </AnimatedLink>
                </li>
                <li>
                    <AnimatedLink
                        className={location.pathname === showNewsletterCampaignsStatisticsPath ? selectedItemStyles : otherItemStyles}
                        to={showNewsletterCampaignsStatisticsPath}>
                        Statystyki - Kampanie
                    </AnimatedLink>
                </li>
                <li>
                    <AnimatedLink
                        className={location.pathname === showNewsletterFollowUpsStatisticsPath ? selectedItemStyles : otherItemStyles}
                        to={showNewsletterFollowUpsStatisticsPath}>
                        Statystyki - Auto FU
                    </AnimatedLink>
                </li>
            </ul>
        </div>
        <hr className='text-white'/>
    </>

}