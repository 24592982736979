import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'lazysizes';
import Clarity from "@microsoft/clarity";


const projectId = "os3zpotjsv"

Clarity.init(projectId);
Clarity.consent(false);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <App/>
);
