import {AvailableCourses} from "./AvailableCourses";

export const kedCourseStructure = [
    {
        title: "Wprowadzenie",
        episodes: [
            {
                title: "Witaj na kursie!",
                vimeoUrl: 'https://vimeo.com/1048286825',
                description: "Witaj na Kursie Efektywnego Działania!<br/><br/>" +
                    "Nagrałem ten krótki film, aby pogratulować Ci podjęcia doskonałej decyzji i osobiście " +
                    "przywitać Cię w programie. <br/>" +
                    "W tygodniu zerowym przejdziemy sobie przez sprawy organizacyjne i pokażę Ci, jak wynieść 100% z " +
                    "kursu. <br/><br/> " +
                    "Bardzo się cieszę, że tutaj jesteś i życzę Ci powodzenia w stawaniu się najlepszą wersją siebie!" +
                    "<br/> <i>-Mikołaj, Twój główny instruktor</i>"
            },
            {
                title: "Jak wygląda kurs?",
                description: "Kurs składa się z 9 tygodni. <br/> Zaczynamy od budowania świadomości, przez podstawy efektywnego działania i efektywną naukę, aż po wejście na wyższy poziom i stanie się najlepszą wersją siebie.<br/><br/>" +
                    "Pełny opis poszczególnych tygodni znajduje się <a class='text-success text-decoration-underline' href='/produkt/kurs-efektywnego-dzialania'>tutaj</a>. <br/><br/>" +
                    "Każdy kolejny tydzień zostanie odblokowany automatycznie po 7 dniach od rozpoczęcia oglądania pierwszego filmu z poprzedniego tygodnia. <br/><br/>" +
                    "Kluczowe jest, aby wykonywać ćwiczenia oraz wprowadzać w życie zmiany. W przeciwnym razie stracisz tylko swój czas.<br/><br/>" +
                    "Informacje o pozostałym czasie do odblokowania kolejnego tygodnia znajdziesz na stronie głównej Kursu, pod kolejnym tygodniem: (Przykład) <img class='img-fluid' src='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/auto-unlock-next-week.png' alt='odblokowanie kolejnego tygodnia'/><br/><br/>" +
                    "Jeżeli nie widzisz tej informacji, musisz najpierw rozpocząć oglądanie najnowszego tygodnia Kursu." +
                    "<br/><br/><i>\"Nic się nie zmieni, o ile Ty się nie zmienisz.\"",
            },
            {
                title: "Gdzie szukać pomocy?",
                description: "<ol>" +
                    "<li>Co tydzień odbywają się konsultacje na zoomie. Na ten moment, jest to sobota 17:00. Na spotkaniu odpowiadamy na pytania uczniów i pomagamy im w zrozumieniu i wdrożeniu w życie rzeczy z kursu. Więcej informacji znajdziesz po kliknięciu w link w sekcji \"Materiały\". </li>" +
                    "<br/>" +
                    "<li>Dołącz na naszego Discorda, gdzie poznasz nowe, ambitne osoby oraz uzyskasz szybką pomoc. Więcej informacji w sekcji \"Materiały\".</li>" +
                    "</ol> ",
                resources: [
                    {
                        title: "Cotygodniowe spotkania",
                        link: "/produkt/spolecznosc/spotkania"
                    },
                    {
                        title: "Discord",
                        link: "/produkt/spolecznosc"
                    }
                ]
            },
            {
                title: "Jak wynieść 100% z kursu?",
                description: "Aby wynieść z Kursu jak najwięcej, warto stosować się następujących zasad:<br/> " +
                    "<ol>" +
                    "<li class='mb-1 mt-3'>Regularnie pojawiaj się na spotkaniach na zoomie.</li>" +
                    "<li class='mb-1'>Dołącz na naszego Discorda, nawiązuj nowe znajomości i uzyskuj szybką pomoc.</li>" +
                    "<li class='mb-1'>Zawsze zachowaj otwarty umysł.</li>" +
                    "<li class='mb-1'>Po każdej lekcji zapisz swoimi słowami, czego się z niej nauczyłeś i jakie zmiany zamierzasz wprowadzić w swoim życiu.</li>" +
                    "<li class='mb-1'>Nie spiesz się. Lepiej poświęcić więcej czasu na praktykę niż tylko oglądać filmy. Nie ma czegoś takiego jak za dużo praktyki :)</li>" +
                    "<li class='mb-1'>Wykonuj ćwiczenia, o których jest mowa w filmach, nawet jeżeli wydaje Ci się, że nie jest Ci to potrzebne. Zobaczysz, że podziękujesz sobie.</li>" +
                    "<li class='mb-1'>Zaplanuj czas, w którym poświęcisz swoją uwagę tylko i wyłącznie na Kurs. Średnio będziesz potrzebować ~3h tygodniowo, aby obejrzeć wszystkie filmy i wprowadzić w życie zmiany. </li>" +
                    "</ol><br/>" +
                    "<b class='fs-9'>Powodzenia!</b>",
                resources: [
                    {
                        title: "Cotygodniowe spotkania",
                        link: "/produkt/spolecznosc/spotkania"
                    },
                    {
                        title: "Discord",
                        link: "/produkt/spolecznosc"
                    }
                ]
            },
            {
                title: "Dodatkowe narzędzia",
                description: "Dostęp do wszystkich materiałów, o których była mowa przy dołączaniu do kursu, otrzymasz we właściwym tygodniu. <br/> " +
                    "<ul>" +
                    "<li class='mb-1 mt-3'>Dokument \"BEST Self\" - Tydzień 1</li>" +
                    "<li class='mb-1'>Dokument \"Roczny Plan Dominacji\" - Tydzień 2</li>" +
                    "<li class='mb-1'>Autorskie Techniki Oddechowe - Tydzień 2</li>" +
                    "</ul>" +
                    "Temat Sztucznej Inteligencji będzie szczegółowo omawiany w Tygodniu 7, jednak dostęp do Chatu masz już teraz. Więcej w sekcji \"Materiały\". <br/><br/>",
                resources: [
                    {
                        title: "Chat Produkacji",
                        link: "/produkt/chat"
                    },
                ]
            }
        ]
    },
    {
        title: "Budowa świadomości",
        episodes: [
            {
                title: 'Przedstawienie tygodnia',
                vimeoUrl: 'https://vimeo.com/1048286859',
                description: "W tej lekcji dowiesz się, jak wygląda pierwszy tydzień oraz czego się w nim spodziewać."
            },
            {
                title: 'Po co mi świadomość?',
                vimeoUrl: 'https://vimeo.com/1048286897',
                description: "W tej lekcji poznasz podstawy pracy ze świadomością. Zrozumiesz, czym jest, dlaczego jest istotna oraz jak wykorzystywać ją do swoich celów.<br/>" +
                    "Na świadomości będziemy bazować w całym kursie, dlatego tak kluczowe jest jej zrozumienie. <br/><br/>" +
                    "Omówimy następujące zagadanienia:" +
                    "<ul>" +
                    "<li>Dlaczego świadomość jest istotna.</li>" +
                    "<li>Przykład niskiej świadomości.</li>" +
                    "<li>Jak działają najlepsi.</li>" +
                    "</ul>",
            },
            {
                title: 'Idealna wersja siebie',
                vimeoUrl: 'https://vimeo.com/1048286949',
                description: "W tej lekcji przejdziemy przez ćwiczenie polegające na stworzeniu idealnej wersji siebie. Zaczniemy od analizy sytuacji obecnej, a następnie stworzymy personę, którą chcesz się stać i do której dążysz. Na podstawie tego ćwiczenia, będziesz mógł/mogła jasno określić dalsze działania konieczne do stawania się najlepszą wersją siebie",
                resources: [
                    {
                        link: 'https://docs.google.com/document/d/1muaPqx4UlmK9taEOqH4Qxw8pWY-Eo317eyNV_bo-sL8/edit?usp=sharing',
                        title: 'Idealna wersja siebie (Wersja podstawowa)'
                    },
                    {
                        link: 'https://docs.google.com/document/d/1H_ogNwmD6PN27LEvW-4_itdbP339IJJ2iQI8afWsooQ/edit?usp=sharing',
                        title: 'Idealna wersja siebie (Wersja rozszerzona)'
                    }
                ]
            },
            {
                title: 'Rola naszego nastawienia',
                vimeoUrl: 'https://vimeo.com/1048286994',
                description: "W tej lekcji zrozumiesz dokładnie rolę nastawienia i dowiesz się, w jaki sposób Twój mindset kształtuje Twoje życie. <br/><br/>" +
                    "Omówimy następujące zagadanienia:" +
                    "<ul>" +
                    "<li>Dlaczego nasze nastawienie jest istotne.</li>" +
                    "<li>Różnice pomiędzy \"Fixed mindset\" i \"Growth mindset\".</li>" +
                    "<li>Jak pracować z nastawieniem.</li>" +
                    "</ul>",
            },
            {
                title: 'Marginalne zyski',
                vimeoUrl: "https://vimeo.com/1048880467",
                description: "W tej lekcji skupimy się na marginalnych zyskach - małych zmianach, które nałożone na siebie, dają w długoterminowej perspektywie ogromne rezultaty. <br/><br/>" +
                    "Omówimy następujące zagadanienia:" +
                    "<ul>" +
                    "<li>Przykład marginalnych zysków na podstawie historii Brytyjskich kolarzy.</li>" +
                    "<li>Czym są marginalne zyski.</li>" +
                    "<li>Dlaczego marginalne zyski są istotne.</li>" +
                    "<li>W jaki sposób mierzyć marginalne zyski.</li>" +
                    "</ul>",
                resources: [
                    {
                        title: "Historia Brytyjskich kolarzy (dla zainteresowanych)",
                        link: "https://jamesclear.com/marginal-gains"
                    }
                ]
            },
            {
                title: 'Cykl Kolba',
                description: "W tej lekcji zajmiemy się dokładnym zrozumieniem Cyklu Kolba.<br/><br/>" +
                    "Omówimy następujące zagadanienia:" +
                    "<ul>" +
                    "<li>Czym jest Cykl Kolba i dlaczego jest on istotny.</li>" +
                    "<li>Jak tworzyć Cykl Kolba.</li>" +
                    "<li>Przykład Cyklu Kolba.</li>" +
                    "</ul>" +
                    "Po przerobieniu tej lekcji, <b>Twoim zadaniem będzie stworzenie przynajmniej dwóch Cyklów Kolba</b>. ",
                vimeoUrl: "https://vimeo.com/1048880606",
                resources: [
                    {
                        title: 'Cykl Kolba - Cheat Sheet',
                        link: 'https://docs.google.com/document/d/1bsmkEtrmOgDHhBmo5MYiRo0duydQIP9btpFDDtLJ468/edit?usp=sharing'
                    },
                    {
                        title: 'Przykład cyklu Kolba z lekcji',
                        link: 'https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/materialy/Przyk%C5%82ad+Cyklu+Kolba+z+filmu.pdf'
                    },
                    {
                        title: 'Przykłady innych cyklów Kolba (blog)',
                        link: 'https://produkacja.pl/blog/2i5Lz6X464cb7STIj8W75WMKfA7'
                    }
                ]
            },
            {
                title: 'Podsumowanie tygodnia',
                description: "Przed przejściem do kolejnego tygodnia, upewnij się, że: <br/><br/>" +
                    "<ul>" +
                    "<li>Dokument \"BEST Self\" jest przynajmniej w ukończony przynajmniej dla obecnej wersji siebie.</li>" +
                    "<li>Znasz różnice między <i>Fixed</i> i <i>Growth</i> mindset.</li>" +
                    "<li>Zdajesz sobie sprawę, w jaki sposób działać każdego dnia, aby codziennie przybliżać się do najlepszej wersji siebie.</li>" +
                    "<li>Mierzysz marginalne zyski w kontekście swojego celu. </li>" +
                    "<li>Dwa Cykle Kolba zostały w pełni ukończone.</li>" +
                    "</ul>" +
                    "<br/>Jeżeli masz jakieś pytania, śmiało napisz na Discordzie lub weź udział w cotygodniowych spotkaniach na Zoomie. <br/><br/>" +
                    "Jeżeli chcesz się z nami podzielić czymś na temat Kursu, kliknij w przycisk \"Prześlij Feedback, zgłoś błąd\" i napisz nam, co uważasz. Dziękujemy :) <br/><br/>" +
                    "P.S. Kolejny tydzień odblokuje się automatycznie po 7 dniach od rozpoczęcia oglądania pierwszego filmu z tego tygodnia. Jeżeli nie jest on jeszcze dla Ciebie dostępny, nie przejmuj się. <br/><br/>" +
                    "Pamiętaj, że <b>praktyka > teoria</b>. Poświęć więcej czasu na przećwiczenie zagadnień, które omówiliśmy sobie w tym tygodniu i podziękuj sobie później! <br/><br/>" +
                    "Informacje o czasie odblokowania kolejnego tygodnia znajdziesz na stronie głównej kursu: Przegląd Kursu -> Tydzień 2.",
            },

        ]
    },
    {
        title: "Podstawy Efektywnego Działania",
        episodes: [
            {
                title: "Przedstawienie tygodnia",
                vimeoUrl: "https://vimeo.com/1051131451",
                description: "W tej lekcji dowiesz się, jak wygląda drugi tydzień oraz czego się w nim spodziewać."
            },
            {
                title: "Pokonaj prokrastynację raz na zawsze!",
                vimeoUrl: "https://vimeo.com/1050476619",
                description: "W tej lekcji zagłębimy się w temat prokrastynacji.<br/><br/>" +
                    "Omówimy sobie czym jest prokrastynacja, a następnie przejdziemy przez przykład z życia, aby dokładniej zobrazować Ci problem i przedstawić rozwiązanie. <br/><br/>" +
                    "Jeżeli borykasz się z odkładaniem rzeczy na później, zastanów się, z czego to wynika i co można zrobić, aby to zmienić. Wykonanie Cyklu Kolba może być bardzo dobrym pomysłem.",
                resources: [
                    {
                        title: "Notatki z filmu",
                        link: "https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/materialy/tablice/W2E2+-+Pokonaj+prokrastynacje%CC%A8%C2%A0raz+na+zawsze.png"
                    }
                ]
            },
            {
                title: "Techniki anty-prokrastynacyjne",
                vimeoUrl: "https://vimeo.com/1050476698",
                description: "W tej lekcji omówimy 4 techniki, które skutecznie pomogą Ci w walce z prokrastynacją."
            },
            {
                title: "Zarządzaj czasem jak mistrz",
                vimeoUrl: "https://vimeo.com/1050476746",
                description: "W tym filmie dokładnie omówimy sobie nasz autorski dokument \"Roczny Plan Dominacji\", który od tej pory będzie Twoim dobrym przyjacielem podczas planowania.",
                resources: [
                    {
                        title: "Roczny Plan Dominacji 2025",
                        link: "https://docs.google.com/spreadsheets/d/1K-VIUtH_LFzAvB9JgzjzyOSJfsQDNZIAjuu5fj2XFTE/edit?usp=sharing"
                    }
                ]
            },
            {
                title: "Określanie priorytetów",
                vimeoUrl: "https://vimeo.com/1050476795",
                description: "W tej lekcji zagłębimy się w temat określania priorytetów.<br/><br/>" +
                    "Omówimy sobie dokładnie Matrycę Eisenhowera, na podstawie której określimy zadania w kategoriach WAŻNE / PILNE oraz pokażemy, na co zwrócić uwagę podczas planowania.",
                resources: [
                    {
                        title: "Notatki z filmu",
                        link: "https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/materialy/tablice/W2E5+-+Matryca+Eisenhowera.png"
                    }
                ]
            },
            {
                title: "Planowanie nauki",
                vimeoUrl: "https://vimeo.com/1050476846",
                description: "W tej lekcji omówimy właściwe planowanie nauki do sprawdzianów, a także pokażemy przykład skutecznego planowania.",
                resources: [
                    {
                        title: "Notatki z filmu",
                        link: "https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/materialy/tablice/W2E6+-+Planowanie+nauki.png"
                    }
                ]
            },
            {
                title: "Świadomość czasu",
                vimeoUrl: 'https://vimeo.com/1051131518',
                description: "W tej lekcji omówimy arkusz 'Świadomość czasu' oraz 'Mapa mocy' - na czym polegają i jak zastosować je w praktyce. Po wykonaniu tych ćwiczeń, odzyskasz kontrolę nad swoim czasem.",
                resources: [
                    {
                        title: "Arkusz 'Świadomość czasu'",
                        link: 'https://docs.google.com/spreadsheets/d/1GnxxyYwwpZ1-56YhCC1Ek7zLoTMs2hw99yjonX6cpsU/edit?usp=sharing'
                    },
                    {
                        title: "Arkusz 'Mapa mocy'",
                        link: "https://docs.google.com/spreadsheets/d/1OVvW0Ntq7kbMgzR9Ci7HoaYJiM4bFaEvsFmNOG4rFcg/edit?usp=sharing"
                    }
                ]
            },
            {
                title: "Radzenie sobie z gorszym nastrojem",
                description: "Raz na jakiś czas może się zdarzyć, że będziesz w gorszym humorze, mniej Ci się będzie chciało i słabo się będziesz czuć. Czasami takie dni się zdarzają.<br/><br/>" +
                    "Jednak jeżeli masz sporo do zrobienia, warto znać sposoby na działanie nawet w gorszej formie. Poniżej znajdziesz parę porad: <br/>" +
                    "<ul>" +
                    "<li class='mb-1 mt-1'>Pamiętaj, że to jest tylko chwilowe i bądź dla siebie empatyczny/a.</li>" +
                    "<li class='mb-1'>Zajmij się rzeczami łatwiejszymi / pilniejszymi, które i tak w końcu trzeba kiedyś zrobić. Dzięki temu, nie będziesz musieć poświęcać na nie czasu w inne dni, kiedy Twoja wydajność będzie na znacznie wyższym poziomie.</li>" +
                    "<li class='mb-1'>Zacznij robić coś tylko przez 5 minut - a następnie ustal, co dalej. Dzięki temu łatwiej się będzie zebrać do działania - przypomnij sobie czwartą technikę z filmu o technikach anty-prokrastynacyjnych.</li>" +
                    "<li class='mb-1'>Poświęć więcej czasu na (aktywny) odpoczynek i porządnie się wyśpij.</li>" +
                    "<li class='mb-1'>Jeżeli zdarza Ci się regularnie mieć gorszy nastrój, wykonaj Cykl Kolba, aby zdać sobie sprawę, skąd on wynika i co może być przyczyną. Możesz również prowadzić dziennik i w nim analizować swoje odczucia/emocje/myśli.<br/> Więcej o prowadzeniu dziennika w przyszłych tygodniach Kursu.</li>" +
                    "</ul>"
            },
            {
                title: "BONUS: Techniki oddechowe na redukcję stresu",
                description: "W sekcji z zasobami znajdziesz dwa nagrania technik oddechowych. <br/><br/>" +
                    "<b>Pierwsze nagranie: Oddech Pudełkowy</b><br/>" +
                    "Wdech 4s<br/>" +
                    "Zatrzymanie 4s<br/>" +
                    "Wydech 4s<br/><br/>" +
                    "Reguluje oddech oraz tętno, redukuje stres, wspiera koncentrację. <br/><br/><hr/><br/>" +
                    "<b>Drugie nagranie: Oddech Naprzemienny</b><br/>" +
                    "Zamknięcie prawej dziurki prawym kciukiem, wdech lewą.<br/>" +
                    "Zatkanie lewej palcem serdecznym wydech prawą.<br/><br/>" +
                    "Redukuje stres, uspokaja układ nerwowy, równoważy działanie półkul, poprawia koncentrację.",
                resources: [
                    {
                        title: "Oddech pudełkowy",
                        link: "https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/materialy/techniki-oddechowe/Oddech+pude%C5%82kowy.WAV"
                    },
                    {
                        title: "Oddech naprzemienny",
                        link: "https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/materialy/techniki-oddechowe/Oddech+naprzemienny.WAV"
                    }
                ]
            },
            {
                title: "Podsumowanie tygodnia",
                description: "Przed przejściem do kolejnego tygodnia, upewnij się, że: <br/><br/>" +
                    "<ul>" +
                    "<li>Dokument \"Plan Dominacji\" został przez Ciebie uzupełniony do Twoich potrzeb. </li>" +
                    "<li>Znasz techniki anty-prokrastynacyjne i stosujesz je na co dzień. </li>" +
                    "<li>Znasz różnice między zadaniami PILNYMI a WAŻNYMI oraz zdajesz sobie sprawę, że należy skupiać się na rzeczach WAŻNYCH. </li>" +
                    "<li>Wiesz, w jaki sposób planować sobie naukę do sprawdzianów oraz na co zwracać uwagę.</li>" +
                    "<li>Jesteś w trakcie lub już wypełniłeś/aś arkusz \"Świadomość czasu\". Kolejnym krokiem jest przerzenie tamtych aktywności do arkusza \"Mapa mocy\".</li>" +
                    "<li>Potrafisz radzić sobie z gorszym nastrojem.</li>" +
                    "</ul>" +
                    "<br/>Jeżeli masz jakieś pytania, śmiało napisz na Discordzie lub weź udział w cotygodniowych spotkaniach na Zoomie. <br/><br/>" +
                    "Jeżeli chcesz się z nami podzielić czymś na temat Kursu, kliknij w przycisk \"Prześlij Feedback, zgłoś błąd\" i napisz nam, co uważasz. Dziękujemy :) <br/><br/>" +
                    "P.S. Kolejny tydzień odblokuje się automatycznie po 7 dniach od rozpoczęcia oglądania pierwszego filmu z tego tygodnia. Jeżeli nie jest on jeszcze dla Ciebie dostępny, nie przejmuj się. <br/><br/>" +
                    "Pamiętaj, że <b>praktyka > teoria</b>. Poświęć więcej czasu na przećwiczenie zagadnień, które omówiliśmy sobie w tym tygodniu i podziękuj sobie później!<br/><br/>" +
                    "Informacje o czasie odblokowania kolejnego tygodnia znajdziesz na stronie głównej kursu: Przegląd Kursu -> Tydzień 3.",
            }
        ]
    },
    {
        title: "Wstęp do Efektywnej Nauki",
        episodes: [
            {
                title: "Przedstawienie tygodnia",
                vimeoUrl: "https://vimeo.com/1053283973",
                description: "W tej lekcji dowiesz się, jak wygląda trzeci tydzień Kursu oraz czego się w nim spodziewać."
            },
            {
                title: "Postawy Efektywnej Nauki (POP)",
                vimeoUrl: 'https://vimeo.com/1052917379',
                description: "W tym filmie przedstawimy pokrótce, czym jest POP:<br/>" +
                    "<ul>" +
                    "<li><b>P</b>owtórki - Rozstaw czasowy między powtórkami.</li>" +
                    "<li><b>O</b>dzyskiwanie - Wydobywanie tego, czego się nauczyliśmy z pamięci.</li>" +
                    "<li><b>P</b>rzeplatanie - Korzystanie z różnych technik podczas każdej sesji nauki.</li>" +
                    "</ul>" +
                    "" +
                    "Trzy kolejne lekcje będą poświęcone powtórkom, odzyskiwaniu oraz przeplataniu."
            },
            {
                title: "Powtórki",
                vimeoUrl: 'https://vimeo.com/1052917397',
                description: "W tym filmie przyjrzymy się tematowi powtórek i omówimy sobie:<br/>" +
                    "<ul>" +
                    "<li>Czym jest <i>krzywa zapominania</i> i jak z nią walczyć.</li>" +
                    "<li>Jak często należy robić powtórki.</li>" +
                    "<li>Na co zwracać uwagę podczas powtórek.</li>" +
                    "</ul>",
                resources: [
                    {
                        title: "Krzywa zapominania",
                        link: "https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/materialy/tablice/W3E3+-+Krzywa+zapominania.png"
                    }
                ]
            },
            {
                title: "Odzyskiwanie",
                description: "Odzyskiwanie polega na wydobywaniu informacji z pamięci w celu rozwiązania konkretnego problemu. (np. przypomnienie sobie, jak zastosować konkretny wzór na sprawdzianie)<br/><br/>" +
                    "Nauczenie się na sprawdzian to jedno, a odzyskiwanie informacji to drugie. <br/><br/>" +
                    "Co z tego, że spędzisz 10h przygotowując się na sprawdzian, jak później nie będziesz sobie w stanie niczego przypomnieć? 🤯<br/><br/>" +
                    "Dlatego tak ważne jest ćwiczenie odzyskiwania. Na ten moment musisz zwrócić uwagę na dwie rzeczy: <br/>" +
                    "<ol>" +
                    "<li class='mt-2'><b>Nie patrz na notatki przed i podczas robienia powtórek</b>. Zawsze daj sobie szansę na odzyskanie informacji z pamięci, nawet jak nie jesteś do końca pewny/a, czy te informacje są poprawne. " +
                    "Nawet jak wydaje Ci się, że nic nie pamiętasz, poświęć przynajmniej 5 minut na <b>próbę</b> przypomnienia sobie, czego się nauczyłeś/aś. Jeżeli wciąż niczego sobie nie przypomnisz, nic nie szkodzi. Najważniejsze jest ćwiczenie - a z czasem, jak odpowiednio 'wytrenujemy' nasz mózg, znacznie łatwiej będzie nam sobie to, czego się nauczyliśmy. <br/>" +
                    "Można myśleć o naszej pamięci jak o mięśniach - ćwicząć ją, staje się silniejsza.</li>" +
                    "<li class='mt-4'><b>Śpij przynajmniej 8-8.5h</b>, tym bardziej w noc przed sprawdzianem/egzaminem. Jest to niezbędne, aby mieć właściwą ilość fazu snu REM, w której następuje łączenie ze sobą informacji - co sprawia, że znacznie łatwiej jest nam je później odzyskać.<br/>" +
                    "Więcej o roli snu w Tygodniu 4.</li>" +
                    "</ol>" +
                    "Dodatkowo, sama umiejętność kodowania informacji pozytywnie wpłynie na zdolność odzyskiwania ich z pamięci. W kolejnej lekcji będziemy mówić o różnych technikach, które mogą Ci w tym pomóc - i będziemy do tego wracać w 7 Tygodniu Kursu, gdzie zagłębimy się w bardziej zaawansowane aspekty Efektywnej Nauki.",
            },
            {
                title: "Przeplatanie",
                vimeoUrl: "https://vimeo.com/1052917496",
                description: "W tej lekcji zajmiemy się tematem <i>przeplatania</i>.<br/><br/>" +
                    "Omówimy następujące zagadanienia:" +
                    "<ul>" +
                    "<li>Na czym polega <i>przeplatanie</i> różnych technik. </li>" +
                    "<li>Jakie techniki można stosować.</li>" +
                    "</ul>" +
                    "Przyjrzymy się również dokładnie takim technikom, jak: " +
                    "<ul>" +
                    "<li>Nauka wyimaginowanej osoby / technika Feynmana</li>" +
                    "<li>Tworzenie własnych pytań testowych</li>" +
                    "<li>Odpowiadanie na pytania testowe</li>" +
                    "<li>Zrzut pamięci, ang. <i>Brain dump</i></li>" +
                    "<li>Mapa myśli (więcej o mapach w 7 tygodniu Kursu)</li>" +
                    "<li>Fiszki (więcej i fiszkach w 5 tygodniu Kursu)</li>" +
                    "<li>Dyskusja / Nauka w grupie</li>" +
                    "<li>Dodawanie / Modyfikowanie zmiennych</li>" +
                    "</ul>",
            },
            {
                title: "Cykl Kolba w praktyce",
                vimeoUrl: "https://vimeo.com/1052917611",
                description: "W tym filmie przyjrzymy się wykorzystaniu Cyklu Kolba w praktyce, w kontekście Efektywnej Nauki. <br/><br/>" +
                    "Omówimy sobie dokładnie dwa przykłady - jeden z techniką Feynmana oraz jeden z tworzeniem pytań.",
                resources: [
                    {
                        title: "Cykl Kolba (Cheat-Sheet)",
                        link: "https://docs.google.com/document/d/1bsmkEtrmOgDHhBmo5MYiRo0duydQIP9btpFDDtLJ468/edit?usp=sharing"
                    },
                    {
                        title: "Przykład - Technika Feynmana",
                        link: "https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/materialy/cykl-kolba/Cykl+Kolba+-+Technika+Feynmana.pdf"
                    },
                    {
                        title: "Przykład - Tworzenie pytań",
                        link: "https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/materialy/cykl-kolba/Cykl+Kolba+-+Tworzenie+pytan%CC%81.pdf"
                    }
                ]
            },
            {
                title: "Planowanie SMART",
                vimeoUrl: "https://vimeo.com/1052917686",
                description: "W tym filmie przyjrzymy się tematowi planowania \"SMART\". <br/><br/>" +
                    "Omówimy następujące zagadanienia:" +
                    "<ul>" +
                    "<li>Na czym polega planowanie \"SMART\".</li>" +
                    "<li>Przykłady celów \"SMART\" i celów nie-\"SMART\".</li>" +
                    "<li>Jak skutecznie stawiać cele \"SMART\".</li>",
                resources: [
                    {
                        title: "Cele \"SMART\" - Ściąga",
                        link: "https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/materialy/cele-smart/Cele+SMART.pdf"
                    },
                    {
                        title: "Pytania pomocnicze do tworzenia celów \"SMART\"",
                        link: "https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/materialy/cele-smart/Pytania+podczas+stawiania+celo%CC%81w+SMART.pdf"
                    }
                ]
            },
            {
                title: "30-dniowy Plan Działania",
                vimeoUrl: "https://vimeo.com/1052917743",
                description: "W tym omówimy sobie dokładnie, w jaki sposób możemy wykorzystać Plan Dominacji do stworzenia 30-dniowego planu działania, " +
                    "dzięki któremu upewnimy się, że wdrożymy w życie wszystko, o czym była mowa w tym tygodniu Kursu. <br/><br/>" +
                    "Jeżeli potrzebujesz sobie odświeżyć temat Planu Dominacji, był on szczegółowo omawiamy w poprzednim tygodniu Kursu, w filmie \"Zarządzaj czasem jak mistrz\"."
            },
            {
                title: "Podsumowanie tygodnia",
                description: "Przed przejściem do kolejnego tygodnia, upewnij się, że: <br/><br/>" +
                    "<ul>" +
                    "<li>Wiesz, co składa się na podstawy Efektywnej Nauki (POP).</li>" +
                    "<li>Zdajesz sobie sprawę z istoty powtórek i wiesz, jak często oraz w jaki sposób należy je wykonywać.</li>" +
                    "<li>Potrafisz przeplatać różne rodzaje technik Efektywnej Nauki.</li>" +
                    "<li>Potrafisz wykorzystywać Cykl Kolba do analizowania technik Efektywnej Nauki.</li>" +
                    "<li>Wiesz, na czym polegają cele/plany SMART.</li>" +
                    "<li>Stworzyłeś/aś 30-dniowy działania plan w swoim Planie Dominacji.</li>" +
                    "</ul>" +
                    "<br/>Jeżeli masz jakieś pytania, śmiało napisz na Discordzie lub weź udział w cotygodniowych spotkaniach na Zoomie. <br/><br/>" +
                    "Jeżeli chcesz się z nami podzielić czymś na temat Kursu, kliknij w przycisk \"Prześlij Feedback, zgłoś błąd\" i napisz nam, co uważasz. Dziękujemy :) <br/><br/>" +
                    "P.S. Kolejny tydzień odblokuje się automatycznie po 7 dniach od rozpoczęcia oglądania pierwszego filmu z tego tygodnia. Jeżeli nie jest on jeszcze dla Ciebie dostępny, nie przejmuj się. <br/><br/>" +
                    "Pamiętaj, że <b>praktyka > teoria</b>. Poświęć więcej czasu na przećwiczenie zagadnień, które omówiliśmy sobie w tym tygodniu i podziękuj sobie później! <br/><br/>" +
                    "Informacje o czasie odblokowania kolejnego tygodnia znajdziesz na stronie głównej kursu: Przegląd Kursu -> Tydzień 4.",
            }
        ]
    },
    {
        title: "Środowisko",
        episodes: [
            {
                title: "Przedstawienie tygodnia",
                vimeoUrl: "https://vimeo.com/1069106943",
                description: "W tej lekcji dowiesz się, jak wygląda czwarty tydzień Kursu oraz czego się w nim spodziewać."
            },
            {
                title: "Chronotypy snu",
                vimeoUrl: "https://vimeo.com/1067621057",
                description: "W tym filmie przyjrzymy się tematowi chronotypów snu i omówimy sobie:<br/>" +
                    "<ul>" +
                    "<li>Czym są chronotypy snu.</li>" +
                    "<li>Wyznacznie Twojego chronotypu.</li>" +
                    "<li>Charakteryzowanie każdego z chronotypów.</li>" +
                    "</ul>" +
                    "W sekcji z materiałami znajdziesz link do testu na chronotyp snu oraz do bardziej szczegółowego opisu każdego z nich.",
                resources: [
                    {
                        title: "Test na chronotyp",
                        link: "https://produkacja.pl/chronotypy"
                    },
                    {
                        title: "Chronotyp DELFIN",
                        link: "https://docs.google.com/document/d/1mn_wWiWHqDOwST20XQyaHf7vYk8Xw4LfM1KMdHrTkKM/edit?usp=sharing",
                    },
                    {
                        title: "Chronotyp LEW",
                        link: "https://docs.google.com/document/d/1KO_UpB_KPn3IlIoMaAsNTCqgxUMdMRM4AVk0KBEZvAk/edit?usp=sharing"
                    },
                    {
                        title: "Chronotyp NIEDŹWIEDŹ",
                        link: "https://docs.google.com/document/d/1qSwgBKCW6TzPKLZLa0udIL05b8vFErQNYHGUX4sBB2M/edit?usp=sharing"
                    },
                    {
                        title: "Chronotyp WILK",
                        link: "https://docs.google.com/document/d/1_7l1ElBPG9o4yAz21ImC943YO-9kJ-DOW1XgdiPztmQ/edit?usp=sharing"
                    },
                ]
            },
            {
                title: "Sen - A po co to komu?",
                vimeoUrl: "https://vimeo.com/1067621138",
                description: "W tym filmie przyjrzymy się tematowi snu i omówimy sobie:<br/>" +
                    "<ul>" +
                    "<li>Po co nam sen.</li>" +
                    "<li>Badania na temat snu.</li>" +
                    "<li>Wyjaśnienie faz snu - i dlaczego zarywanie nocki przed sprawdzianem to głupota.</li>" +
                    "<li>Błędne koło związane ze snem.</li>" +
                    "</ul>",
                resources: [
                    {
                        title: "Fazy snu na przestrzeni nocy (materiały z filmu)",
                        link: "https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/materialy/W4/W4E3+-+Fazy+snu+na+przestrzeni+nocy.png"
                    },
                    {
                        title: "Przykład błędnego koła (materiały z filmu)",
                        link: "https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/materialy/W4/W4E3+-+B%C5%82e%CC%A8dne+ko%C5%82o.png"
                    }
                ]
            },
            {
                title: "Zostaw ten telefon",
                vimeoUrl: "https://vimeo.com/1067621214",
                description: "W tym filmie przyjrzymy się tematowi telefonu i omówimy sobie:<br/>" +
                    "<ul>" +
                    "<li>Dlaczego spędzanie dużej ilości czasu na telefonie Ci nie sprzyja.</li>" +
                    "<li>Jak zoptymalizować telefon, aby spędzać na nim mniej czasu.</li>" +
                    "</ul>",
                resources: [
                    {
                        title: "Sposoby na optymalizację telefonu",
                        link: "https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/materialy/W4/W4E4+-+Sposoby+na+optymalizacje%CC%A8+telefonu.pdf"
                    }
                ]
            },
            {
                title: "Optymalizacja komputera",
                vimeoUrl: "https://vimeo.com/1067621295",
                description: "W tym filmie omówimy sobie, w jaki sposób zoptymalizować nasz komputer do maksymalnej produktywności.<br/><br/>" +
                    "Przedstawimy ogólne zasady, zaproponujemy plan działania oraz zainstalujemy niezbędne rozszerzenia do przeglądarki.",
                resources: [
                    {
                        title: "Lista rozszerzeń",
                        link: "https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/materialy/W4/W4E5+-+Lista+rozszerzen.pdf"
                    },
                    {
                        title: "HTML New Tab - kod",
                        link: "https://pastebin.com/FQifj62c"
                    }
                ]
            },
            {
                title: "Twoje miejsce pracy",
                vimeoUrl: "https://vimeo.com/1067621337",
                description: "W tym filmie omówimy sobie, w jaki sposób stworzyć produktywne miejsce pracy oraz na co zwrócić uwagę.<br/><br/>" +
                    "Naszym celem jest upewnienie się, że pracujesz w środowisku sprzyjającym skupieniu się oraz rozwojowi.",
                resources: [
                    {
                        title: "Na co zwrócić uwagę (Cheat-sheet)",
                        link: "https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/materialy/W4/W4E6+-+Na+co+zwro%CC%81cic%CC%81+uwage%CC%A8.pdf"
                    }
                ]
            },
            {
                title: "Z kim się przystajesz takim się stajesz",
                vimeoUrl: "https://vimeo.com/1067621407",
                description: "W tym filmie przyjrzymy się wpływowi swojego otoczenia i omówimy sobie:<br/>" +
                    "<ul>" +
                    "<li>Jak inni na Ciebie wpływają.</li>" +
                    "<li>Świadome podejście do swoich znajomych.</li>" +
                    "</ul>" +
                    "<i>\"Jesteś sumą 5 osób, z którymi spędzasz najwięcej czasu.\"</i>",
            },
            {
                title: "Koncentracja mistrza",
                description: "Umiejętność dłuższego koncentrowania się na danym zadaniu naturalnie przyjdzie z czasem, po wdrażaniu w życie proponowanych zmian. Tutaj podsumujemy sobie ogólne zasady, które już zostały lub zostaną omówione bardziej szczegółowo w Kursie.<br/><br/>" +
                    "<ol>" +
                    "<li><b>Dokładne rozplanowanie sobie, co będziemy robić (SMART)</b>. Przed zabraniem się do działania, chcesz wiedzieć, co dokładnie jest do zrobienia, tak aby od razu móc przejść do rzeczy. Idealnie, planowanie powinno odbywać się poprzedniego dnia wieczorem. </li><br/>" +
                    "<li><b>Zoptymalizowane miejsce pracy</b>. Środowisko, w którym się uczysz/pracujesz powinno być wolne od rozpraszaczy i sprzyjać rozwojowi oraz skupieniu.</li><br/>" +
                    "<li><b>Regularne wykonywanie Cyklów Kolba / pisanie w dzienniku</b>. Temat dziennika będziemy poruszać w dalszej części Kursu, jednak z Cyklem Kolba jesteś już zaznajomiony/a. Dobrze jest go wykonywać dość systematycznie i analizować swoje doświadczenia związane z utrzymywaniem koncentracji. To pozwoli Ci łatwiej znaleźć miejsca do poprawy i zaobserwować pozytywne zmiany. </li><br/>" +
                    "<li><b>Medytacja / ćwiczenia oddechowe</b>. Regularne praktyki medytacji lub ćwiczeń oddechowych pomogą w utrzymaniu skupienia przez dłuższy czas. Nawet 5 minut każdego dnia może pomóc w znaczącej poprawie koncentracji oraz w zyskaniu większej klarowności umysłu. </li><br/>" +
                    "<li><b>Właściwa ilość i jakość snu</b>. Temat snu już kilkukrotnie poruszaliśmy i wciąż będziemy do niego wracać w kolejnych tygodniach, ponieważ jest on BARDZO istotny w kontekście Efektywnego Działania. Bez właściwej ilości snu nie ma opcji, aby być w stanie pracować w skupieniu przez dłuższy czas. Zacznij priorytetyzować sen i zobaczysz, że większość Twoich problemów naturalnie się rozwiąże.  </li><br/>" +
                    "<li><b>Przerwy, limity</b>. Ucząc się przez 4h bez żadnych przerw, nawet najlepsi będą mieli trudność z utrzymaniem skupienia na najwyższym poziomie. Zaplanuj sobie przerwy od nauki, w czasie od 25 minut do 3 godzin, w zależności od tego, jak się czujesz. U większości uczniów górną granicą będzie 1.5h-2h. Eksperymentuj i patrz, co najlepiej sprawdza się w Twoim przypadku. Pamiętaj również, że lepiej jest rozłożyć pracę/naukę w czasie, niż starać się wszystko zrobić jednego dnia. O ile jednorazowo cały dzień poświecony na naukę może być naprawdę pomocny (więcej w Tygodniu 8), o tyle regularne 'przepracowywanie' się nie prowadzi do niczego dobrego.</li>" +
                    "</ol>"
            },
            {
                title: "Podsumowanie tygodnia",
                description: "Przed przejściem do kolejnego tygodnia, upewnij się, że: <br/><br/>" +
                    "<ul>" +
                    "<li>Znasz swój chronotyp snu i eksperymentujesz z różnymi porami różnych aktywności (przede wszystkim nauki/pracy).</li>" +
                    "<li>Zdajesz sobie sprawę, dlaczego sen jest istotny, priorytetyzujesz go i wyszedłeś/wyszłaś z błędnego koła (o ile w nim tkwiłeś/aś).</li>" +
                    "<li>Zoptymalizowałeś/aś swój telefon i zaczynasz spędzać na nim mniej czasu.</li>" +
                    "<li>Zoptymalizowałeś/aś swój komputer do maksymalnej produktywności. (Rozszerzenia zostały zainstalowane, powiadomienia wyłączone, nieużywane aplikacje zamknięte...)</li>" +
                    "<li>Stworzyłeś/aś produktywne miejsce pracy, wolne od jakichkolwiek rozpraszaczy, dzięki czemu jesteś w stanie dłużej utrzymać skupienie oraz szybciej zrobić to, co potrzebujesz.</li>" +
                    "<li>Wiesz, w jaki sposób pracować nad koncentracją. </li>" +
                    "</ul>" +
                    "<br/>Jeżeli masz jakieś pytania, śmiało napisz na Discordzie lub weź udział w cotygodniowych spotkaniach na Zoomie. <br/><br/>" +
                    "Jeżeli chcesz się z nami podzielić czymś na temat Kursu, kliknij w przycisk \"Prześlij Feedback, zgłoś błąd\" i napisz nam, co uważasz. Dziękujemy :) <br/><br/>" +
                    "P.S. Kolejny tydzień odblokuje się automatycznie po 7 dniach od rozpoczęcia oglądania pierwszego filmu z tego tygodnia. Jeżeli nie jest on jeszcze dla Ciebie dostępny, nie przejmuj się. <br/><br/>" +
                    "Pamiętaj, że <b>praktyka > teoria</b>. Poświęć więcej czasu na przećwiczenie zagadnień, które omówiliśmy sobie w tym tygodniu i podziękuj sobie później! <br/><br/>" +
                    "Informacje o czasie odblokowania kolejnego tygodnia znajdziesz na stronie głównej kursu: Przegląd Kursu -> Tydzień 5.",
            },
        ]
    },
    {
        title: "Wystrzał",
        episodes: [
            {
                title: "Przedstawienie tygodnia",
                vimeoUrl: "https://vimeo.com/1069111411",
                description: "W tej lekcji dowiesz się, jak wygląda piąty tydzień Kursu oraz czego się w nim spodziewać."
            },
            {
                title: "O czym należy pamiętać?",
                description: "Wszystko, czego uczyliśmy się w trzecim tygodniu na temat Efektywnej Nauki nas nie opuści. <br/><br/>" +
                    "To znaczy, dalej robimy powtórki, dalej planujemy sobie naukę, dalej ćwiczymy odzyskiwanie i dalej staramy się stosować różne techniki przyswajania wiedzy.<br/><br/>" +
                    "I trzymamy się tego, dopóki nie będziemy proponować alternatyw :) <br/><br/>" +
                    "Powodzenia!"
            },
            {
                title: "Nauka na lekcji",
                vimeoUrl: "https://vimeo.com/1068558207",
                resources: [
                    {
                        title: "3-stopniowy proces (Cheat-sheet)",
                        link: "https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/materialy/W5/W5E3+-+Wynoszenie+z+lekcji+100%25+(Cheat-sheet).pdf"
                    }
                ],
                description: "W tym filmie przyjrzymy się tematowi nauki na lekcji i omówimy sobie:<br/>" +
                    "<ul>" +
                    "<li>Na co zwrócić uwagę podczas nauki w szkole.</li>" +
                    "<li>3-stopniowy proces wynoszenia z lekcji 100%.</li>" +
                    "</ul>",
            },
            {
                title: "Techniki egzaminacyjne",
                vimeoUrl: "https://vimeo.com/1068558255",
                resources: [
                    {
                        title: "Pokonaj stres przed testem - 7 Porad (Cheat-sheet)",
                        link: "https://docs.google.com/document/d/1xacXncyKn3alT26SvB8Fah6PYh0pZ6n0TeH-xnrnW5Q/edit?usp=sharing"
                    },
                    {
                        title: "Równe tempo i sprawdzanie odpowiedzi (Notatki z filmu)",
                        link: "https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/materialy/W5/W5E4+-+Technika+2.png"
                    }
                ],
                description: "W tym filmie przyjrzymy się technikom egzaminacyjnym i omówimy sobie:<br/>" +
                    "<ul>" +
                    "<li>5-stopniowy proces pisania sprawdzianów/egzaminów na topowe wyniki.</li>" +
                    "<li>W jaki sposób pokonać stres przed testem. (Cheat-sheet w sekcji z materiałami)</li>" +
                    "</ul>",
            },
            {
                title: "Efektywne notatki",
                vimeoUrl: "https://vimeo.com/1068558290",
                description: "W tym filmie przyjrzymy się tematowi efektywnych notatek i omówimy sobie, na co zwracać uwagę podczas notowania.<br/><br/>" +
                    "Podsumujemy sobie to, czego już do tej pory się nauczyliśmy wraz z prostymi przykładami.<br/><br/>" +
                    "Temat notatek będziemy bardziej szczegółowo rozwijać w Tygodniu 7, gdzie zagłębimy się w mapy myśli.<br/><br/>" +
                    "Na ten moment, staraj się pisać MNIEJ tekstu, a dodawać więcej wizualnych elementów, takich jak diagramy, rysunki czy mapy myśli. <br/><br/>"
            },
            {
                title: "Fiszki",
                vimeoUrl: "https://vimeo.com/1069111444",
                description: "W tym filmie przyjrzymy się tematowi fiszek i omówimy sobie:<br/>" +
                    "<ul>" +
                    "<li>Czym są fiszki i dlaczego są one skuteczne.</li>" +
                    "<li>Kiedy fiszki sprawdzą się najlepiej.</li>" +
                    "<li>Jak skutecznie tworzyć fiszki.</li>" +
                    "</ul>",
            },
            {
                title: "Przerwy",
                description: "Wiele osób zastanawia się, czy istnieje 'idealna' długość nauki oraz przerw.<br/><br/>" +
                    "Odpowiedź brzmi <b>nie</b>. Nie ma jednej właściwej odpowiedzi. <br/><br/>" +
                    "Istnieją jednak ogólne zasady, których warto się stosować, aby nauka była maksymalnie produktywna. <br/><br/>" +
                    "1. Robienie przerw między 25min a 3h. U większości uczniów górną granicą będzie 1.5h-2h. Istotne jest to, aby eksperymentować i patrzeć, co najlepiej działa w Twoim przypadku. Możesz wykorzystać do tego Cykl Kolba i przeanalizować, jak czujesz się robiąc przerwę po 30min, a jak po 1.5h? Czy jest jakaś różnica w przypadku nauki do innych przedmiotów? Wyciągaj wnioski i powtarzaj to, co działa w Twoim przypadku.<br/><br/>" +
                    "2. Podczas przerw, postaw na aktywny odpoczynek. Lepiej sprawdzi się wyjście na spacer, pomedytowanie, poczytanie książki czy pójście pobiegać, niż bezmyślne scrollowanie i gapienie się w telefon. Nasz mózg powinien mieć szansę na odpoczynek i regenerację - aby mieć siłę na kolejny blok pracy/nauki. <br/><br/>" +
                    "3. Pamiętaj o limitach. Wielu osobom trudno będzie pracować dłużej niż 3-4h na maksymalnej produktywności. Znacznie lepiej jest rozłożyć naukę w czasie, niż starać się zrobić wszystko w ostatnie 2 dni przed sprawdzianem/egzaminem.",
            },
            {
                title: "Rutyna przed nauką",
                description: "Aby nasz mózg automatycznie wchodził w stan koncentracji, świetnie sprawdzi się wykonywanie zawsze tej samej czynności przed każdą sesją nauki. <br/><br/>" +
                    "Może być to na przykład słuchanie zawsze tej samej piosenki czy robienie sobie zawsze tej samej herbaty. Możliwości jest wiele, jednak dobrze, żeby nie trwało to więcej niż 5 minut. <br/><br/>" +
                    "Dzięki temu, Twój mózg zacznie kojarzyć tę konkretną aktywność z koniecznością skupienia się. Wykształcenie takiej reakcji zajmuje kilka tygodni, jednak po tym czasie zobaczysz, że jeszcze łatwiej będzie Ci wejść w \"stan nauki\".<br/><br/>" +
                    "Ten schemat można również stosować w przypadku sprawdzianów / egzaminów. Czyli za każdym razem przed testem, wykonujemy tę samą czynność. <br/><br/>" +
                    "Eksperymentuj i patrz, jak to działa w Twoim przypadku!"
            },
            {
                title: "Kolejność nauki",
                vimeoUrl: "https://vimeo.com/1069111483",
                description: "W tym filmie przyjrzymy się tematowi fiszek i omówimy sobie:<br/>" +
                    "<ul>" +
                    "<li>Czym kolejność nauki ma znaczenie.</li>" +
                    "<li>W jakiej kolejności powinniśmy się uczyć.</li>" +
                    "</ul>",
            },
            {
                title: "Łączenie wszystkiego w całość",
                description: "W tym filmie wykorzystamy Plan Dominacji, aby podsumować zagadnienia z Tygodnia 5 i wdrożyć w życie zmiany.",
                vimeoUrl: "https://vimeo.com/1069111503"
            },
            {
                title: "Podsumowanie tygodnia",
                description: "Przed przejściem do kolejnego tygodnia, upewnij się, że: <br/><br/>" +
                    "<ul>" +
                    "<li>Wiesz, w jaki sposób uczyć się na lekcji i stosujesz to w swoim przypadku.</li>" +
                    "<li>Znasz techniki egzaminacyjne i masz stworzoną swoją <i>\"Checklistę\"</i> przed testami.</li>" +
                    "<li>Wiesz, na co zwracać uwagę podczas robienia notatek i starasz się robić je w nieliniowy sposób.</li>" +
                    "<li>Potrafisz robić efektywne fiszki i wiesz, na co zwracać uwagę.</li>" +
                    "<li>Eksperymentujesz z czasem przerw i nauki, aby zobaczyć, co najlepiej sprawdza się w Twoim przypadku.</li>" +
                    "<li>Wdrożyłeś/aś w życie swoją rutynę przed nauką - i powtarzasz ją za każdym razem przed rozpoczęciem kolejnej sesji. </li>" +
                    "<li>Wiesz, w jakiej kolejności najlepiej się uczyć. </li>" +
                    "<li>Dostosowałeś/aś swój Plan Dominacji do wprowadzenia w życie zmian z tego tygodnia. </li>" +
                    "</ul>" +
                    "<br/>Jeżeli masz jakieś pytania, śmiało napisz na Discordzie lub weź udział w cotygodniowych spotkaniach na Zoomie. <br/><br/>" +
                    "Jeżeli chcesz się z nami podzielić czymś na temat Kursu, kliknij w przycisk \"Prześlij Feedback, zgłoś błąd\" i napisz nam, co uważasz. Dziękujemy :) <br/><br/>" +
                    "P.S. Kolejny tydzień odblokuje się automatycznie po 7 dniach od rozpoczęcia oglądania pierwszego filmu z tego tygodnia. Jeżeli nie jest on jeszcze dla Ciebie dostępny, nie przejmuj się. <br/><br/>" +
                    "Pamiętaj, że <b>praktyka > teoria</b>. Poświęć więcej czasu na przećwiczenie zagadnień, które omówiliśmy sobie w tym tygodniu i podziękuj sobie później! <br/><br/>" +
                    "Informacje o czasie odblokowania kolejnego tygodnia znajdziesz na stronie głównej kursu: Przegląd Kursu -> Tydzień 6.",
            },
        ]
    },
    {
        title: "Optymalizacja",
        episodes: [
            {
                title: "Przedstawienie tygodnia"
            },
            {
                title: "Rola snu"
            },
            {
                title: "Rola jedzenia"
            },
            {
                title: "Rola sportu"
            },
            {
                title: "Rola odpoczynku"
            },
            {
                title: "Radzenie sobie ze stresem"
            },
            {
                title: "BONUS: Medytacja prowadzona"
            },
            {
                title: "Podsumowanie tygodnia"
            },
        ]
    },
    {
        title: "Top 1% uczniów",
        episodes: [
            {
                title: "Przedstawienie tygodnia"
            },
            {
                title: "Kiedy najlepiej zapamiętujemy?"
            },
            {
                title: "Nauka poprzez dociekanie"
            },
            {
                title: "Mapy myśli - część I"
            },
            {
                title: "Mapy myśli - część II"
            },
            {
                title: "Efektywna nauka do przedmiotów ścisłych "
            },
            {
                title: "Wykorzystanie AI do efektywnej nauki"
            },
            {
                title: "Podsumowanie tygodnia"
            },
        ]
    },
    {
        title: "Boost",
        episodes: [
            {
                title: "Przedstawienie tygodnia"
            },
            {
                title: "Stan flow"
            },
            {
                title: "Miesiąc w dzień"
            },
            {
                title: "Wygrywaj każdego dnia"
            },
            {
                title: "Skuteczna realizacja celów krok po kroku"
            },
            {
                title: "Brain dump"
            },
            {
                title: "Podsumowanie tygodnia"
            },
        ]
    },
    {
        title: "Następny poziom",
        episodes: [
            {
                title: "Przedstawienie tygodnia"
            },
            {
                title: "Bądź sobą"
            },
            {
                title: "Przestać tryhardować"
            },
            {
                title: "Twój obraz siebie"
            },
            {
                title: "Rola przekonań"
            },
            {
                title: "Zmiana stanu"
            },
            {
                title: "Stwórz swoją przyszłość"
            },
            {
                title: "Podsumowanie tygodnia"
            },
            {
                title: "Co dalej?"
            },
        ]
    },
] as LearningCourseWeek[]

export const pedCourseStructure = [
    {
        title: "Podstawy Efektywnego Działania",
        episodes: [
            {
                title: "Wprowadzenie",
                vimeoUrl: "https://vimeo.com/1064599541",
                description: "W tym filmie dowiesz się jak wygląda Kurs Podstaw Efektywnego Działania i czego się w nim spodziewać."
            },
            {
                title: 'Najlepsza wersja siebie',
                vimeoUrl: 'https://vimeo.com/1064731798',
                description: "W tej lekcji przejdziemy przez ćwiczenie polegające na stworzeniu idealnej wersji siebie. Zaczniemy od analizy sytuacji obecnej, a następnie stworzymy personę, którą chcesz się stać i do której dążysz. Na podstawie tego ćwiczenia, będziesz mógł/mogła jasno określić dalsze działania konieczne do stawania się najlepszą wersją siebie.",
                resources: [
                    {
                        link: 'https://docs.google.com/document/d/1muaPqx4UlmK9taEOqH4Qxw8pWY-Eo317eyNV_bo-sL8/edit?usp=sharing',
                        title: 'Najlepsza wersja siebie (Wersja podstawowa)'
                    },
                    {
                        link: 'https://docs.google.com/document/d/1H_ogNwmD6PN27LEvW-4_itdbP339IJJ2iQI8afWsooQ/edit?usp=sharing',
                        title: 'Najlepsza wersja siebie (Wersja rozszerzona)'
                    }
                ]
            },
            {
                // Plan Dominacji w kontekście nauki [Praca na dokumencie]
                title: "Skuteczne planowanie nauki",
                vimeoUrl: "https://vimeo.com/1064599697",
                description: "W tym filmie dokładnie omówimy sobie nasz autorski dokument \"Roczny Plan Dominacji\", który od tej pory będzie Twoim dobrym przyjacielem podczas planowania.",
                resources: [
                    {
                        title: "Roczny Plan Dominacji 2025",
                        link: "https://docs.google.com/spreadsheets/d/1K-VIUtH_LFzAvB9JgzjzyOSJfsQDNZIAjuu5fj2XFTE/edit?usp=sharing"
                    }
                ]
            },
            {
                // Pokonywanie prokrastynacji na zasadzie nawyków [Praca na tablicy]
                title: "Systematyczne działanie",
                vimeoUrl: "https://vimeo.com/1064599956",
                description: "W tym filmie omówimy sobie, w jaki sposób działać systematycznie i raz na zawsze pokonać prokrastynację.",
                resources: [
                    {
                        title: "Notatki z filmu",
                        link: "https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/materialy/ped/W0E4+-+systematyczne+dzialanie.png"
                    }
                ]
            },
            {
                // Pokazanie, na co zwrócić uwagę w kontekście biurka itp. [Praca na prezentacji]
                title: "Optymalizacja środowiska",
                vimeoUrl: "https://vimeo.com/1064600191",
                description: "W tym filmie omówimy sobie, w jaki sposób zoptymalizować swoje środowisko do maksymalnej produktywności. Usuniemy wszystkie rozpraszacze i upewnimy się, że sprzyja ono rozwojowi.",
            },
            {
                // Omówienie POP wraz z konkretnymi przykładami [Praca na prezentacji / tablicy]
                title: "Efektywna nauka",
                vimeoUrl: "https://vimeo.com/1064600305",
                description: "W tym filmie zbudujemy solidne podstawy do Efektywnej Nauki. Omówimy sobie następujące zagadnienia:" +
                    "<ul>" +
                    "<li>Czym jest POP</li>" +
                    "<li>Jak przeciwdziałać krzywej zapominania</li>" +
                    "<li>Na co zwrócić uwagę przed sprawdzianami/egzaminami</li>" +
                    "<li>Jakie techniki nauki możemy stosować</li>",
                resources: [
                    {
                        title: "Krzywa zapominania (notatki z filmu)",
                        link: "https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/materialy/ped/W0E6+-+krzywa+zapominania.png"
                    },
                    {
                        title: "Fazy snu (notatki z filmu)",
                        link: "https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/materialy/ped/W0E6+-+fazy+snu.png"
                    }
                ]
            },
            {
                // Pokazanie prezentacji -> Czyli 1. Dokument BEST Self zrobiony, 2. Optymalizacja środowiska 3. Plan Dominacji - i wykorzystanie tego, czego się nauczyliśmy.
                title: "Jasny plan działania",
                vimeoUrl: "https://vimeo.com/1064600556",
                description: "W tym filmie stworzymy jasny plan działania, abyś miał/a pewność, że potrafisz wykorzystać całą wiedzę z tego Kursu w praktyce!"
            },
            {
                // Sprzedaż głównego Kursu + Pokazanie go i omówienie, czego się nauczyć można.
                title: "Co dalej?",
                description: "<h5>Gratulacje 🏆</h5>" +
                    "Udało Ci się ukończyć cały Kurs Podstaw Efektywnego Działania.  <br/><br/>" +
                    "Jesteśmy z Ciebie bardzo dumni. To naprawdę bardzo dobrze o Tobie świadczy. <br/><br/>" +
                    "Spokojnie wprowadzaj w życie zmiany, a pozytywne rezultaty zobaczysz już po kilku dniach! <br/><br/>" +
                    "Był to pierwszy poziom Efektywnego Działania (podstawy). Jeżeli ten Kurs Ci się spodobał i przydał, jesteśmy przekonani, że pokochasz pełny Kurs Efektywnego Działania i naprawdę dużo się z niego nauczysz.<br/><br/>" +
                    "<b>Czym jest pełny Kurs Efektywnego Działania?</b><br/>" +
                    "Jest to 9-tygodniowy program wchodzący w szczegóły Efektywnego Działania oraz zagłębiający się w bardziej zaawansowane ćwiczenia i techniki. <br/><br/>" +
                    "W ramach Kursu dostajesz dostęp do ~15h filmów online, ćwiczeń oraz cheat-sheetów, a także cotygodniowe spotkania bezpośrednio z Twórcą Kursu oraz dostęp do prywatnej społeczności nastawionych na rozwój nastolatków.<br/><br/>" +
                    "Chcesz kontynuować przygodę z Efektywnym Działaniem?<br/>" +
                    "<a class='text-decoration-underline text-success' href='/produkt/zakup/kurs-efektywnego-dzialania'>Kliknij tutaj, aby uzyskać dostęp do Kursu.</a>"
            },
        ]
    }
] as LearningCourseWeek[]

export interface LearningCourseWeek {
    title: string
    episodes: LearningCourseEpisode[]
}

export interface LearningCourseEpisode {
    title: string
    description: string
    vimeoUrl: string
    resources?: Resource[]
}

export interface Resource {
    title: string
    link: string
}

export interface LastWatchedEpisode {
    w: number,
    e: number,
}

export interface CourseStatistics {
    totalWatchTimeInSeconds: number
    boughtAt: string // Date in ISO
    lastWatched: LastWatchedEpisode
    episodesCompleted: number
    name: string
    email: string
    maxIndividualSessionsNum: number,
    individualSessionsScheduledNum: number
    individualSessionUrl: string,
    weeksUnlocked: number
    unlockNextWeek: UnlockNextWeekSchedule
    discordId?: string,
    completedMandatoryFeedbackForm?: boolean
}

interface UnlockNextWeekSchedule {
    week: number,
    unlockingAt: string
}

export interface CourseWeek {
    week: number
    isUnlocked: boolean
    episodesCompleted: number
}

export interface CourseEpisode {
    week: number
    episode: number
    isUnlocked: boolean
    watchTimeInSeconds: number
    currentWatchTime: number
    completed: boolean
    notes?: string
}

export interface CourseWeekAndEpisodes {
    week: CourseWeek
    episodes: CourseEpisode[]
    weeksUnlocked: number
}

export interface UpdateCourseWatchTimeRequest {
    week: number
    episode: number
    currentWatchTime: number
    increaseWatchTime: number
    course: AvailableCourses
}

export interface UpdateEpisodeNotes {
    week: number
    episode: number
    notes: string
    course: AvailableCourses
}

export interface CompleteEpisodeRequest {
    week: number
    episode: number
    completed: boolean
    course: AvailableCourses
}

export interface DiscordIdResponse {
    message: string
}


