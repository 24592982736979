import React, {useRef} from "react";
import {AnimatePresence, motion} from "framer-motion";
import {useBubbleNotification} from "../../../hooks/useBubbleNotification";

interface BubbleNotificationProps {
    message: string;
    delay?: number;
    duration?: number;

    useNowInsteadOfRandomMinutes?: boolean;
}

const names = [
    "Mirosław", "Agnieszka", "Monika", "Marta", "Adam", "Andrzej", "Julia", "Anna",
    "Justyna", "Piotr", "Tomasz", "Bogumiła", "Patrycja", "Monika", "Małgorzata",
    "Magdalena", "Elżbieta", "Katarzyna", "Edyta", "Rafał", "Anita", "Artur"
];
const times = [2, 3, 4, 5];

const cities = [
    {name: "Wrocław", form: "Wrocławia"},
    {name: "Warszawa", form: "Warszawy"},
    {name: "Kraków", form: "Krakowa"},
    {name: "Poznań", form: "Poznania"},
    {name: "Gdańsk", form: "Gdańska"},
    {name: "Łódź", form: "Łodzi"},
    {name: "Białystok", form: "Białegostoku"},
    {name: "Opole", form: "Opola"},
    {name: "Katowice", form: "Katowic"},
    {name: "Rawicz", form: "Rawicza"},
    {name: "Kołobrzeg", form: "Kołobrzegu"},
    {name: "Zakopane", form: "Zakopanego"},
    {name: "Wałcz", form: "Wałcza"},
    {name: "Sopot", form: "Sopotu"},
    {name: "Częstochowa", form: "Częstochowy"},
];

function getRandomElement<T>(array: T[]): T {
    return array[Math.floor(Math.random() * array.length)];
}

export function BubbleNotificationAboutTraining({
                                                    message,
                                                    delay,
                                                    duration,
                                                    useNowInsteadOfRandomMinutes
                                                }: BubbleNotificationProps) {
    const {showBubble, setShowBubble} = useBubbleNotification({delay, duration});

    const messageRef = useRef<string>("");
    const randomNameAndCityRef = useRef<string>("");
    const randomTimeRef = useRef<string>("");
    const randomCityRef = useRef<string>("");

    if (!messageRef.current) {
        messageRef.current = message;
    }

    if (!randomNameAndCityRef.current) {
        const randomName = getRandomElement(names);
        const randomCity = getRandomElement(cities);
        randomNameAndCityRef.current = `${randomName} z ${randomCity.form} | ${randomCity.name}`;
        randomCityRef.current = randomCity.name;
    }

    // Instead of "3 min temu", show "Teraz"
    if (!randomTimeRef.current) {
        if (useNowInsteadOfRandomMinutes) {
            randomTimeRef.current = "Właśnie teraz";
        } else {
            const randomTime = getRandomElement(times);
            randomTimeRef.current = `${randomTime} min temu`;
        }
    }

    return (
        <AnimatePresence>
            {showBubble && (
                <motion.div
                    className="position-fixed bottom-0 start-0 mb-3 ms-3 p-3 bg-dark text-white rounded-4 shadow d-flex flex-column"
                    style={{zIndex: 1000, maxWidth: "500px", cursor: "pointer"}}
                    initial={{opacity: 0, y: 50}}
                    animate={{opacity: 1, y: 0}}
                    exit={{opacity: 0, y: 50}}
                    transition={{duration: 0.5}}
                    onClick={() => setShowBubble(false)}
                >
                    <div className="d-flex align-items-center">
                        <img
                            src={`https://maps.googleapis.com/maps/api/staticmap?center=${encodeURIComponent(
                                randomCityRef.current || "Warszawa"
                            )}&zoom=9&size=75x75&maptype=roadmap&key=AIzaSyCpC3kLqQqxMfg7czZSHd6ZBxG1N4rGMrE`}
                            alt={`Mapa ${randomCityRef.current || "Warszawa"}`}
                            className="me-2 rounded"
                        />
                        <div>
                            <span className="fw-bold text-white">{randomNameAndCityRef.current}</span>
                            <div className="text-secondary-light fw-medium" style={{fontSize: "0.9em"}}>
                                {messageRef.current}
                            </div>
                        </div>
                    </div>
                    <div
                        className="d-flex justify-content-between mt-2 text-secondary-light"
                        style={{fontSize: "0.9rem"}}
                    >
                        <span>{randomTimeRef.current}</span>
                        <span>✅ Zweryfikowano przez Produkacja</span>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}