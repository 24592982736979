import {redirect, useLoaderData} from "react-router-dom";
import PurchaseOptions from "../PurchaseOptions";
import {PurchaseOption} from "../../../../../model/user/purchase/PurchaseOption";
import React from "react";
import {PurchaseService} from "../../../../../service/PurchaseService";
import {TraceService} from "../../../../../service/TraceService";
import {TraceType} from "../../../../../model/user/general/Trace";
import {ApiError} from "../../../../../service/HttpService";
import {getSessionId} from "../../../../../util/TrackingUtil";
import {encodeUrlIfExists} from "../../../../../util/Util";


type PricingData = {
    isLoggedIn: boolean;
};

export default function ChatPricing() {
    const {isLoggedIn} = useLoaderData() as PricingData;

    return <>
        <div className='mb-16 text-center'>
            <div className='mb-6 text-center mw-md-4xl mx-auto'>
                <span className='badge mb-4 bg-success-dark text-success text-uppercase shadow'>
                Cennik
            </span>
                <h2
                    className='font-heading mb-4 fs-5 text-white'
                    style={{letterSpacing: '-1px'}}
                >
                    Kupno tokenów
                </h2>
                <p className='fs-10 fw-medium text-secondary-light'>
                    <span className='fw-bold'>Token </span>
                    jest wirtualną walutą, którą wykorzystuje się przy każdym zapytaniu do AI.
                    W zależności od wybranego modelu, cena zapytania wahać się będzie od 1 do 5 tokenów. Cena każdego
                    wywołania sztucznej inteligencji będzie jasno podana.
                </p>
            </div>
        </div>
        <div className='row g-8'>
            <PurchaseOptions key={PurchaseOption.BASIC_CHAT_TOKENS.toString()}
                             isLoggedIn={isLoggedIn}
                             tokensNum={100}
                             title='Ciekawski odkrywca' price={10.99}
                             description='Idealny, aby w pełni poznać możliwości narzędzia.'
                             chatPurchaseOption={PurchaseOption.BASIC_CHAT_TOKENS}
            />
            <PurchaseOptions key={PurchaseOption.REGULAR_CHAT_TOKENS.toString()}
                             isLoggedIn={isLoggedIn}
                             tokensNum={600}
                             title='Zainteresowany podróżnik' price={59.99}
                             description='Świetnie sprawdzi się jako codzienna pomoc w nauce oraz pomoże wyrobić nawyk regularnej praktyki.'
                             chatPurchaseOption={PurchaseOption.REGULAR_CHAT_TOKENS}
            />
            <PurchaseOptions key={PurchaseOption.ADVANCED_CHAT_TOKENS.toString()}
                             isLoggedIn={isLoggedIn}
                             tokensNum={1500}
                             title='Zaangażowany strateg' price={147.99}
                             description='Najlepsze rozwiązanie dla najbardziej ambitnych uczniów.'
                             chatPurchaseOption={PurchaseOption.ADVANCED_CHAT_TOKENS}
            />

        </div>
    </>
}

export async function action({request}: { request: Request }) {
    const formData = await request.formData();
    const purchaseData = {
        purchaseOption: formData.get('purchaseOption') as unknown as PurchaseOption,
        name: formData.get('name') as string,
        email: formData.get('email') as string,
    };

    try {
        const redirectUrl = await PurchaseService.initPurchase({
            purchaseOptionName: purchaseData.purchaseOption.toString(),
            name: purchaseData.name,
            email: purchaseData.email,
            source: localStorage.getItem('firstJoinedFrom') ?? '',
            errorRedirectPath: '/produkt/chat',
            successRedirectPath: '/produkt/chat',
            sessionId: getSessionId()
        });
        TraceService.addTrace(TraceType.Redirect, `Redirect url: ${encodeUrlIfExists(redirectUrl)}, purchaseOptionName: ${purchaseData.purchaseOption.toString()}`, undefined, undefined, undefined, purchaseData.email);
        return redirect(redirectUrl);
    } catch (error) {
        const apiError = error as ApiError;
        return {
            status: 500,
            body: apiError.message
        }
    }
}