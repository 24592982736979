import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import {Await, defer, useLoaderData, useSearchParams} from "react-router-dom";
import {
    CourseEpisode,
    CourseStatistics,
    CourseWeekAndEpisodes
} from "../../../model/user/learning-course/LearningCourse";
import React, {Suspense} from "react";
import {CourseService} from "../../../service/CourseService";
import LoadingPage from "../../common/LoadingPage";
import ErrorContent from "../../../components/common/error/ErrorContent";
import {
    redirectIfNotEffectiveActionCourseSubscriber,
    redirectIfNotEffectiveActionMiniCourseSubscriber
} from "../../../util/AuthUtil";
import LearningCourseDashboard from "../../../components/user/learning-course/dashboard/LearningCourseDashboard";
import {CourseDetails} from "../../../model/user/learning-course/CourseDetails";
import {AvailableCourses} from "../../../model/user/learning-course/AvailableCourses";

type LoaderData = {
    // only on the dashboard page
    statistics: Promise<CourseStatistics & { weekAndEpisodes: CourseWeekAndEpisodes[] }>;

    // only on the episode page
    episodeDashboard: {
        courseWeekAndEpisodes: Promise<CourseWeekAndEpisodes>;
        episode: Promise<CourseEpisode>;
        course: Promise<CourseStatistics>;
    }
};

export interface EpisodeDashboard {
    courseWeekAndEpisodes: CourseWeekAndEpisodes;
    episode: CourseEpisode;
    course: CourseStatistics;
}

interface LearningCourseDashboardPageProps {
    course: CourseDetails
}

export default function LearningCourseDashboardPage({course}: LearningCourseDashboardPageProps) {
    const {statistics, episodeDashboard} = useLoaderData() as LoaderData;

    const [searchParams] = useSearchParams();
    const week = searchParams.get('w');
    const episode = searchParams.get('e');

    const watchesCourseEpisode = !!week && !!episode;

    return <>
        <Meta title={course.fullName}/>
        <MainDesign containerStyles={`container ${watchesCourseEpisode ? 'py-10' : 'py-20'}`}>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={watchesCourseEpisode ? episodeDashboard : statistics}
                       errorElement={<ErrorContent/>}>
                    {(loaded: CourseStatistics & { weekAndEpisodes: CourseWeekAndEpisodes[] } | EpisodeDashboard) => {
                        return <LearningCourseDashboard watchesCourseEpisode={watchesCourseEpisode} loaded={loaded}
                                                        week={week} episode={episode} course={course}/>
                    }
                    }
                </Await>
            </Suspense>

        </MainDesign>
    </>
}

export async function loadCourseStatistics(course: AvailableCourses): Promise<CourseStatistics> {
    return await CourseService.getCourseStatistics(course);
}

async function loadCourseWeekAndEpisodes(weekNum: number, silent: boolean = false, course: AvailableCourses): Promise<CourseWeekAndEpisodes> {
    const courseDetails = CourseDetails.getDetailsByCourseName(course)!!;
    const episodesNum = courseDetails.structure[Number(weekNum)].episodes.length;
    return await CourseService.getCourseWeekAndEpisodes(weekNum, episodesNum, silent, course);
}

async function loadCourseEpisode(weekNum: number, episodeNum: number, course: AvailableCourses): Promise<CourseEpisode> {
    return await CourseService.getCourseEpisode(weekNum, episodeNum, course);
}

async function loadEpisodeDashboard(weekNum: number, episodeNum: number, course: AvailableCourses): Promise<EpisodeDashboard> {
    return {
        // TODO: Remove await in the future if too long to load -> add spinner + suspense
        courseWeekAndEpisodes: await loadCourseWeekAndEpisodes(weekNum, false, course),
        episode: await loadCourseEpisode(weekNum, episodeNum, course),
        course: await loadCourseStatistics(course)
    }
}

export async function kedLoader({request}: { request: Request }) {
    const response = await redirectIfNotEffectiveActionCourseSubscriber();
    if (response) {
        return response;
    }
    return loader(request, AvailableCourses.KED)
}

export async function pedLoader({request}: { request: Request }) {
    const response = await redirectIfNotEffectiveActionMiniCourseSubscriber();
    if (response) {
        return response;
    }
    return loader(request, AvailableCourses.PED)
}

export async function loader(request: Request, course: AvailableCourses) {
    const courseDetails = CourseDetails.getDetailsByCourseName(course)!!;

    const url = new URL(request.url);
    const week = url.searchParams.get('w');
    const episode = url.searchParams.get('e');
    if (!!week && !!episode) {
        return defer({
            episodeDashboard: loadEpisodeDashboard(Number(week), Number(episode), course)
        });
    } else {
        let functions = [];
        for (let i = 0; i < courseDetails.structure.length; i++) {
            if (courseDetails.structure[i].episodes.length > 0) {
                functions.push(() => loadCourseWeekAndEpisodes(i, true, course));
            }
        }
        return defer({
            statistics: Promise.all([loadCourseStatistics(course), ...functions.map(f => f())])
        });
    }
}