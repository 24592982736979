import {NewsletterFollowUp} from "../../../../model/user/general/Newsletter";
import {useNavigate, useSearchParams} from "react-router-dom";
import React, {useState} from "react";
import {NewsletterType} from "../../../user/newsletter/NewsletterForm";
import CustomSelect from "../../../ui/form/CustomSelect";
import AdminNewsletterFollowUpsStatisticsItem from "./AdminNewsletterFollowUpsStatisticsItem";

interface AdminNewsletterFollowUpsStatisticsProps {
    followUps: NewsletterFollowUp[]
}

export default function AdminNewsletterFollowUpsStatistics({followUps}: AdminNewsletterFollowUpsStatisticsProps) {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const options = Object.keys(NewsletterType)
    const [selectedMailingList, setSelectedMailingList] = useState<NewsletterType>(searchParams.get('mailingList') as NewsletterType || NewsletterType.WebinarKedFollowUpV1);

    function onMailingListChange(mailingList: NewsletterType) {
        setSelectedMailingList(mailingList);
        navigate(`?mailingList=${mailingList}`, {replace: true});
        setSearchParams({mailingList: mailingList});
    }

    return <>
        <div className='mw-md-md mx-auto my-10'>
            <p className='text-white fw-bold mb-1'>Wybierz listę mailingową</p>
            <CustomSelect name='mailingList' value={selectedMailingList}
                          onChange={(event) => {
                              onMailingListChange(event.target.value as NewsletterType)
                          }}
                          customStyles={'select-md'}>
                {
                    options.map(model => (
                        <option value={model} key={model}>{model}</option>
                    ))
                }
            </CustomSelect>

        </div>
        <div className='mw-md-4xl mx-auto my-10'>
            <div className='mb-20 row g-8'>
                {
                    followUps.map(followUp => (
                        <AdminNewsletterFollowUpsStatisticsItem followUp={followUp}/>
                    ))
                }
            </div>
        </div>
    </>
}