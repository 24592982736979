import CustomSelect from "../../ui/form/CustomSelect";
import {NewsletterType} from "../../user/newsletter/NewsletterForm";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import React, {useState} from "react";
import {useSubmit} from "react-router-dom";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";

export default function AdminAddToNewsletter() {
    const {isSubmitting} = useSubmitFormAction(`/admin/newsletter/dodaj`, null, () => {
        setAddToNewsletterName('');
        setAddToNewsletterEmail('');
    });

    const submit = useSubmit();

    const [addToNewsletterName, setAddToNewsletterName] = useState('');
    const [addToNewsletterEmail, setAddToNewsletterEmail] = useState('');
    const [addToNewsletterSource, setAddToNewsletterSource] = useState('');
    const [addToNewsletterType, setAddToNewsletterType] = useState<NewsletterType>(NewsletterType.Newsletter);

    const isAddToNewsletterEmailValid = !!addToNewsletterEmail.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    async function sendForm() {
        submit({
            name: addToNewsletterName,
            source: addToNewsletterSource,
            email: addToNewsletterEmail,
            type: addToNewsletterType
        }, {
            method: "POST"
        });
    }

    return <>
        <div className='row'>
            <div className={`col-12 col-md-2 form-group pt-3`}>
                <input
                    className='form-control p-4 shadow bg-dark'
                    id='admin-newsletter-name-1'
                    type='text'
                    aria-describedby='emailHelp'
                    placeholder='Wprowadź imię'
                    name='name'
                    value={addToNewsletterName}
                    onChange={(event) => setAddToNewsletterName(event.target.value)}
                    style={addToNewsletterName ? {color: 'white'} : {}}
                />
            </div>
            <div className={`col-12 col-md-3 form-group pt-3`}>
                <input
                    className='form-control p-4 shadow bg-dark'
                    id='admin-newsletter-name-1'
                    type='text'
                    aria-describedby='emailHelp'
                    placeholder='Wprowadź maila'
                    name='name'
                    value={addToNewsletterEmail}
                    onChange={(event) => setAddToNewsletterEmail(event.target.value)}
                    style={addToNewsletterEmail ? {color: 'white'} : {}}
                />
            </div>
            <div className={`col-12 col-md-2 form-group pt-3`}>
                <input
                    className='form-control p-4 shadow bg-dark'
                    id='admin-newsletter-name-1'
                    type='text'
                    aria-describedby='emailHelp'
                    placeholder='Wprowadź źródło'
                    name='name'
                    value={addToNewsletterSource}
                    onChange={(event) => setAddToNewsletterSource(event.target.value)}
                    style={addToNewsletterSource ? {color: 'white'} : {}}
                />
            </div>
            <div className={`col-12 col-md-2 form-group pt-3`}>
                <CustomSelect name='newsletterType' value={addToNewsletterType}
                              onChange={(event) => setAddToNewsletterType(event.target.value as NewsletterType)}
                              customStyles={'select-md p-4'}>
                    {
                        Object.keys(NewsletterType).map(type => (
                            <option value={type} key={type}>{type}</option>
                        ))
                    }
                </CustomSelect>
            </div>
            <div className={`col-12 col-md-3 form-group pt-2`}>
                <AnimatedButton className='btn btn-lg btn-success w-100 text-success-light shadow'
                                disabled={isSubmitting || !isAddToNewsletterEmailValid}
                                onClick={sendForm}>Potwierdź</AnimatedButton>
            </div>
        </div>
    </>
}