import Header from "../ui/header/Header";

export default function AdminHeader() {
    return <Header
        loaderId='adminRoot'
        mainPage={{
            title: 'Dashboard',
            to: '/admin'
        }} topItems={[
        {
            title: 'AI',
            to: 'ai'
        },
        {
            title: 'Blog',
            to: 'blog'
        },
        {
            title: 'Feedback',
            to: 'feedback'
        },
        {
            title: 'Newsletter',
            to: 'newsletter'
        },
        {
            title: 'Diagnostyka',
            to: 'diagnostyka'
        },
        {
            title: 'Ścieżki',
            to: 'traces'
        },
        {
            title: 'KED',
            to: 'ked'
        }
    ]}
        dropdownItems={[
            {
                title: 'Moje produkty',
                to: '/produkt'
            },
            {
                title: 'Lista transakcji',
                to: '/transakcje'
            }
        ]}/>
}