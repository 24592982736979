// ABTestingComponent.tsx
import React, {ReactNode, useEffect, useState} from "react";
import {TraceService} from "../../service/TraceService";
import {TraceType} from "../../model/user/general/Trace";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";

interface ABTestingComponentProps {
    ComponentA: ReactNode;
    ComponentB: ReactNode;
    ComponentC?: ReactNode;
    ComponentD?: ReactNode;
    experimentId: string;
    noCookies?: boolean
}

function getRandomVariation(experimentId: string, searchParams: URLSearchParams, ComponentC?: ReactNode, ComponentD?: ReactNode): string {
    const variations = ["A", "B"];
    if (ComponentC) variations.push("C");
    if (ComponentD) variations.push("D");

    const storedVariation = searchParams.get(`ab-variation-${experimentId}`);
    if (storedVariation && variations.includes(storedVariation)) {
        return storedVariation;
    }

    const randomIndex = Math.floor(Math.random() * variations.length);
    return variations[randomIndex];
}

export default function ABTestingComponent({
                                               ComponentA,
                                               ComponentB,
                                               ComponentC,
                                               ComponentD,
                                               experimentId,
                                               noCookies
                                           }: ABTestingComponentProps) {
    const [variation, setVariation] = useState<string | null>(null);
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        let storedVariation = localStorage.getItem(`ab-variation-${experimentId}`);

        let firstView = false

        if (noCookies) {
            const urlParams = new URLSearchParams(location.search);
            storedVariation = urlParams.get(`ab-variation-${experimentId}`);

            if (!storedVariation) {
                storedVariation = getRandomVariation(experimentId, searchParams, ComponentC, ComponentD);
                urlParams.set(`ab-variation-${experimentId}`, storedVariation);
                navigate({search: urlParams.toString()}, {replace: true});
                firstView = true
            }
        } else {
            if (!storedVariation) {
                storedVariation = getRandomVariation(experimentId, searchParams, ComponentC, ComponentD);
                localStorage.setItem(`ab-variation-${experimentId}`, storedVariation);
                firstView = true
            }
        }

        if (firstView) {
            TraceService.addTraceWithExperiment(TraceType.PageLoad, experimentId, storedVariation, searchParams.get('source'));
        }

        setVariation(storedVariation);
    }, [experimentId, variation, noCookies, location, navigate]);

    if (variation === null) {
        return null; // Or a loading spinner
    }

    return (
        <>
            {
                variation === "A" && ComponentA
            }
            {
                variation === "B" && ComponentB
            }
            {
                variation === "C" && ComponentC
            }
            {
                variation === "D" && ComponentD
            }
        </>
    );
};