import Heading from "../../../components/common/header/Heading";
import MainDesign from "../../MainDesign";
import Meta from "../../../components/common/Meta";
import React, {Suspense} from "react";
import Categories, {Category} from "../../../components/ui/category/Categories";
import {Await, defer, useLoaderData} from "react-router-dom";
import {FeedbackService} from "../../../service/FeedbackService";
import {FeedbackItemsModel} from "../../../model/common/Feedback";
import LoadingPage from "../../common/LoadingPage";
import ErrorContent from "../../../components/common/error/ErrorContent";
import FeedbackItems from "../../../components/admin/feedback/FeedbackItems";
import {useCategory} from "../../../hooks/useCategory";

type LoaderData = {
    feedbacks: Promise<FeedbackItemsModel>
}

const categories = [
    {
        displayName: 'Nieprzeczytane',
        value: "unread"
    },
    {
        displayName: 'Wszystkie kategorie',
        value: "all"
    },
    {
        displayName: 'Chat',
        value: "chat"
    },
    {
        displayName: 'Konwersacje',
        value: "conversations"
    },
    {
        displayName: 'Kontakt',
        value: "contact"
    },
    {
        displayName: 'Rekomendacje',
        value: "recommendation"
    },
    {
        displayName: "Newsletter",
        value: "newsletter"
    },
    {
        displayName: "Zadania",
        value: "exercise"
    },
    {
        displayName: 'KED',
        value: "ked_episode"
    },
    {
        displayName: 'PED',
        value: "ped_episode"
    },
    {
        displayName: 'KED (obowiązkowy)',
        value: "ked_mandatory"
    },
] as Category[]

export default function AdminFeedbackPage() {

    const {feedbacks} = useLoaderData() as LoaderData;
    const {selectedCategory, onCategoryChange} = useCategory(categories, 'unread');

    return <>
        <Meta title='Feedback'/>
        <MainDesign containerStyles='container py-20'>
            <Heading title='Wszystkie wiadomości' badgeText='Feedback'
                     subtitle='Zobacz, co piszą do nas ludzie, jak podoba im się korzystanie z produktów i jakie mają sugestie.'/>
            <div className='mw-4xl mx-auto'>
                <Categories
                    categories={categories}
                    selectedCategory={selectedCategory} onCategoryChange={onCategoryChange}/>
                <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                    <Await resolve={feedbacks} errorElement={<ErrorContent/>}>
                        {(loadedFeedbacks) => <>
                            <FeedbackItems feedbacks={loadedFeedbacks} category={selectedCategory.value}/>
                        </>}
                    </Await>
                </Suspense>
            </div>

        </MainDesign>
    </>
}

async function loadFeedback(category: string): Promise<FeedbackItemsModel> {
    return await FeedbackService.getAllFeedback(undefined, category)
}

export async function loader({request}: { request: Request }) {
    const url = new URL(request.url);
    const category = url.searchParams.get('category') ?? 'unread';

    return defer({
        feedbacks: loadFeedback(category)
    })
}