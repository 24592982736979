import {motion} from "framer-motion";
import {formatDate} from "../../../util/DateUtil";
import React from "react";
import AnimatedButton from "../../ui/motion/AnimatedButton";
import {UserCourse} from "../../../model/admin/UserCourse";
import {
    calculateNumOfDaysFromStart,
    calculatePercentageOfDoneEpisodes,
    formatSecondsToHoursAndMinutes
} from "../../../util/KedUtil";
import {AvailableCourses} from "../../../model/user/learning-course/AvailableCourses";
import {CourseDetails} from "../../../model/user/learning-course/CourseDetails";

interface DiagnosticItemProps {
    userCourse: UserCourse;
    isSubmitting: boolean;
    unlockNextWeek: (userId: string) => void;
    courseType: AvailableCourses
}

export default function AdminKedUserItem(props: DiagnosticItemProps) {
    const {userCourse, isSubmitting, unlockNextWeek, courseType} = props;

    const courseDetails = CourseDetails.getDetailsByCourseName(courseType);

    return <>
        <motion.div className='col-12' key={userCourse.userId}
                    initial={{opacity: 0, scale: 0.5}}
                    animate={{opacity: 1, scale: 1}}>
            <div className='px-10 py-8 bg-light-dark rounded shadow'>
                <div className='row justify-content-between g-4'>
                    <div className='col d-flex flex-column justify-content-center'>
                        <h3 className='mb-1 font-heading fs-9 text-white'>
                            {userCourse.name + " - " + userCourse.email}
                        </h3>
                        <h6 className='mb-1 text-white fs-12'>
                            Dołączył/a: {formatDate(userCourse.boughtAt)} ({calculateNumOfDaysFromStart(userCourse.boughtAt)} dni
                            temu)
                        </h6>
                        <p className='mb-0 fw-medium text-secondary-light'
                           style={{fontSize: '16px'}}>
                            Kliknij aby zobaczyć szczegóły...
                        </p>
                        <div>

                                <span
                                    className='badge bg-success text-white text-uppercase shadow mt-1'
                                    style={{marginRight: "22px", marginLeft: "-10px"}}>
                                      Odblokowano: {userCourse.weeksUnlocked}/{courseDetails!!.structure.length === 1 ? 1 : courseDetails!!.structure.length - 1}
                                </span>
                            <span
                                className='badge bg-success text-white text-uppercase shadow mt-1'
                                style={{marginRight: "22px", marginLeft: "-10px"}}>
                                      Czas oglądania: {formatSecondsToHoursAndMinutes(userCourse.totalWatchTimeInSeconds)}
                                </span>
                            <span
                                className='badge bg-success text-white text-uppercase shadow mt-1'
                                style={{marginRight: "22px", marginLeft: "-10px"}}>
                                      Przerobiono: {calculatePercentageOfDoneEpisodes(userCourse.episodesCompleted, courseType)}%
                                </span>

                        </div>
                    </div>
                    <div className='col-12 col-md-auto mt-6'>
                        <AnimatedButton
                            className='btn btn-lg btn-success w-100 text-success-light shadow'
                            onClick={() => unlockNextWeek(userCourse.userId)}
                            disabled={isSubmitting || userCourse.weeksUnlocked >= CourseDetails.getDetailsByCourseName(courseType)!!.structure.length - 1}
                        >
                            Odblokuj kolejny tydzień
                        </AnimatedButton>
                    </div>
                </div>
            </div>
        </motion.div>
    </>
}