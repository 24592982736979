import React from "react";

interface ResponsivePhotoWithTextProps {
    photoUrl: string;
    textPart: JSX.Element;
    paddingPhoto: string;
    customWrapperStyles?: string;
    swap?: boolean;
    customComponentInsteadOfPhoto?: JSX.Element;
    customColImage?: string;
    customColText?: string;
    imageBelowText?: boolean;
}

export default function ResponsivePhotoWithText(props: ResponsivePhotoWithTextProps) {
    const {
        photoUrl,
        textPart,
        paddingPhoto,
        customWrapperStyles,
        swap,
        customComponentInsteadOfPhoto,
        customColText,
        customColImage,
        imageBelowText,
    } = props;

    /*
      Logic for column ordering:
      - If `imageBelowText` is true, on mobile (`col-12`), the image should be below the text.
      - On desktop (`md and up`):
        - If `swap` is true, the image should be on the right (`order-md-2`).
        - If `swap` is false, the image should be on the left (`order-md-1`).
    */

    const imageColumnClasses = `${customColImage ?? 'col-md-6'} col-12 text-center 
    ${imageBelowText ? "order-2 order-md-" + (swap ? "2" : "1") : swap ? "order-md-2" : "order-md-1 order-1"}`.trim();

    const textColumnClasses = `${customColText ?? 'col-md-6'} col-12 
    ${imageBelowText ? "order-1 order-md-" + (swap ? "1" : "2") : swap ? "order-md-1" : "order-md-2 order-2"}`.trim();

    return (
        <div className={customWrapperStyles ?? "row mt-8 mw-md-7xl mx-auto"}>
            {/* Image column */}
            <div className={imageColumnClasses}>
                <div style={{position: "relative", width: "100%", paddingTop: paddingPhoto}}>
                    {customComponentInsteadOfPhoto ? (
                        customComponentInsteadOfPhoto
                    ) : (
                        <img
                            data-src={photoUrl}
                            className="img-fluid lazyload"
                            alt="zdjęcie obok tekstu"
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                            }}
                        />
                    )}
                </div>
            </div>

            {/* Text column */}
            <div className={textColumnClasses}>
                <div className="container">
                    {textPart}
                </div>
            </div>
        </div>
    );
}
