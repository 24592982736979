import {PurchaseService} from "../../../../service/PurchaseService";
import {TraceService} from "../../../../service/TraceService";
import {TraceType} from "../../../../model/user/general/Trace";
import {PurchaseOption} from "../../../../model/user/purchase/PurchaseOption";
import {ApiError} from "../../../../service/HttpService";
import {KED_WEBINAR_EXPERIMENT_AB_9} from "../../../../util/ExperimentUtil";
import {DiagnosticService} from "../../../../service/DiagnosticService";
import {encodeUrlIfExists} from "../../../../util/Util";
import {getCurrentlyLoggedInUser} from "../../../../util/AuthUtil";

export async function purchaseWebinarAction({request, params}: { request: Request, params: any }) {
    const {ksuid} = params;

    try {
        // Extract the URL from the request
        const url = new URL(request.url);
        const source = url.searchParams.get('source');

        // Check if the current path contains '/zakup'
        if (url.pathname.includes('/zakup')) {
            try {
                TraceService.addTraceWithExperiment(TraceType.InitTransactionSalesPage, KED_WEBINAR_EXPERIMENT_AB_9, undefined, ksuid);
            } catch (error) {
                DiagnosticService.addDiagnostic({
                    fullEvent: {},
                    error: error,
                    functionName: "purchaseWebinarAction",
                    additionalInfo: "error while extracting path from url"
                })
            }
        }

        const redirectUrl = await PurchaseService.initWebinarPurchase(ksuid, source);
        TraceService.addTrace(TraceType.Redirect, `Redirect url: ${encodeUrlIfExists(redirectUrl)}, purchaseOptionName: ${PurchaseOption.COURSE_KED_WEBINAR.toString()}`, undefined, undefined, ksuid);
        return {
            status: 200,
            body: redirectUrl
        };
    } catch (error) {
        const apiError = error as ApiError;
        return {
            status: 500,
            body: apiError.message
        };
    }
}

export async function purchaseKedFromNewsletterAction({request}: { request: Request }) {
    try {
        // Extract the URL from the request
        const url = new URL(request.url);
        const source = url.searchParams.get('source');

        const redirectUrl = await PurchaseService.initNewsletterPurchase(source);
        TraceService.addTrace(TraceType.Redirect, `Redirect url: ${encodeUrlIfExists(redirectUrl)}, purchaseOptionName: ${PurchaseOption.COURSE_KED_NEWSLETTER.toString()}`);
        return {
            status: 200,
            body: redirectUrl
        };
    } catch (error) {
        const apiError = error as ApiError;
        return {
            status: 500,
            body: apiError.message
        };
    }
}

export async function purchaseKedFromRadsAction({request}: { request: Request }) {
    try {
        // Extract the URL from the request
        const url = new URL(request.url);
        const source = url.searchParams.get('source');

        const redirectUrl = await PurchaseService.initRadsPurchase(source);
        TraceService.addTrace(TraceType.Redirect, `Redirect url: ${encodeUrlIfExists(redirectUrl)}, purchaseOptionName: ${PurchaseOption.COURSE_KED_RADS.toString()}`);
        return {
            status: 200,
            body: redirectUrl
        };
    } catch (error) {
        const apiError = error as ApiError;
        return {
            status: 500,
            body: apiError.message
        };
    }
}

export async function purchaseKedFromWebinarSalesPageAction({request}: { request: Request }) {
    try {
        // Extract the URL from the request
        const url = new URL(request.url);
        const source = url.searchParams.get('source');

        const redirectUrl = await PurchaseService.initWebinarSalesPagePurchase(source);
        TraceService.addTrace(TraceType.Redirect, `Redirect url: ${encodeUrlIfExists(redirectUrl)}, purchaseOptionName: ${PurchaseOption.COURSE_KED_WEBINAR_SALES_PAGE.toString()}`);
        return {
            status: 200,
            body: redirectUrl
        };
    } catch (error) {
        const apiError = error as ApiError;
        return {
            status: 500,
            body: apiError.message
        };
    }
}

export async function purchasePedFromSalesPageAction({request}: { request: Request }) {
    try {
        // Extract the URL from the request
        const url = new URL(request.url);
        const source = url.searchParams.get('source');

        TraceService.addTraceWithExperiment(TraceType.InitPedTransactionSalesPage, KED_WEBINAR_EXPERIMENT_AB_9, undefined, source);
        const redirectUrl = await PurchaseService.initPedPurchase(source);
        TraceService.addTrace(TraceType.Redirect, `Redirect url: ${encodeUrlIfExists(redirectUrl)}, purchaseOptionName: ${PurchaseOption.COURSE_PED.toString()}`);
        return {
            status: 200,
            body: redirectUrl
        };
    } catch (error) {
        const apiError = error as ApiError;
        return {
            status: 500,
            body: apiError.message
        };
    }
}

export async function purchaseKedFromPedCourseSalesPage({request}: { request: Request }) {
    try {
        // Extract the URL from the request
        const url = new URL(request.url);
        const source = url.searchParams.get('source');

        const user = await getCurrentlyLoggedInUser();

        if (!user) {
            return {
                status: 401,
                body: "Zaloguj się aby móc kupić Kurs."
            }
        }

        const redirectUrl = await PurchaseService.initKedFromPedSalesPagePurchase(source, user.email, user.name);
        TraceService.addTrace(TraceType.Redirect, `Redirect url: ${encodeUrlIfExists(redirectUrl)}, purchaseOptionName: ${PurchaseOption.COURSE_KED_FROM_PED_SALES_PAGE.toString()}`);
        return {
            status: 200,
            body: redirectUrl
        };
    } catch (error) {
        const apiError = error as ApiError;
        return {
            status: 500,
            body: apiError.message
        };
    }
}