import AnimatedButton from "../../ui/motion/AnimatedButton";
import React, {useState} from "react";
import CopyToClipIcon from "../../ui/element/CopyToClipIcon";
import {useToast} from "../../ui/toast/ToastContext";
import Heading from "../../common/header/Heading";
import AnimatedLink from "../../ui/motion/AnimatedLink";
import CommunityNavbar from "./CommunityNavbar";
import {useSubmit} from "react-router-dom";
import {CourseStatistics} from "../../../model/user/learning-course/LearningCourse";
import {useSubmitFormAction} from "../../../hooks/useSubmitFormAction";

interface CommunityProps {
    isEffectiveActionCourseSubscriber: boolean
    courseStatistics: CourseStatistics | null // It's null for people who don't have access to KED
}

const DISCORD_LINK = "https://discord.gg/Fkesj2ue5Y";

export default function Community({isEffectiveActionCourseSubscriber, courseStatistics}: CommunityProps) {
    const {isSubmitting} = useSubmitFormAction('whatever', null, undefined, false)
    const {showMessage} = useToast();
    const submit = useSubmit();

    const [discordId, setDiscordId] = useState<string>(courseStatistics?.discordId ?? '');

    async function copyToClip() {
        await navigator.clipboard.writeText(DISCORD_LINK);
        showMessage("Pomyślnie skopiowano.");
    }

    function configureDiscordId() {
        submit({
            discordId: discordId
        }, {
            method: "POST"
        });
    }

    return <>
        <Heading title='Dołącz na Discorda!' badgeText='Społeczność'/>
        {
            isEffectiveActionCourseSubscriber && <CommunityNavbar/>
        }
        <div
            className={`row justify-content-center g-2 mt-6 mw-md-xs mx-auto`}>

            <AnimatedLink
                openLinkInNewTab={true}
                className="btn btn-success btn-lg"
                to={DISCORD_LINK}
            >
                Dołączam
            </AnimatedLink>

            <p className="mb-0 mt-6 fs-10 text-center fw-medium text-secondary-light">Lub skorzystaj z linku...</p>
            <input
                type="text"
                className="form-control form-control-lg flex-grow-1 bg-dark"
                value={DISCORD_LINK}
                readOnly
                style={{
                    padding: '1rem 0.75rem',
                    color: "white",
                    textAlign: "center"
                }}
            />
            <AnimatedButton
                className="btn btn-outline-light btn-sm flex-grow-0"
                type="button"
                onClick={copyToClip}
                style={{whiteSpace: 'nowrap'}} // Ensure text stays in one line
            >
                <CopyToClipIcon/>
                <span>Skopiuj zaproszenie</span>
            </AnimatedButton>
        </div>

        {
            isEffectiveActionCourseSubscriber &&
            <p className="mb-0 mt-6 fs-11 text-center fw-medium text-warning mw-md-md mx-auto">Tylko 11% wszystkich
                Kursantów dołącza na Discorda 🤯. Zwiększmy tę liczbę!
            </p>
        }

        <p className="mb-0 mt-6 fs-11 text-center fw-medium text-white mw-md-md mx-auto">Na Discordzie uzyskasz szybką
            pomoc, a także nawiążesz nowe, pozytywne znajomości. 😎
        </p>
        {
            isEffectiveActionCourseSubscriber && <>
                <h2 className='text-center mt-10'>Uzyskaj dostęp do specjalnych kanałów</h2>
                <div
                    className={`row justify-content-center g-2 mt-0 mw-md-md mx-auto`}>
                    <p className='text-center fw-medium text-white'>Wprowadź poniżej swoje ID na Discordzie, aby uzyskać
                        dostęp
                        do
                        kanałów z Kursem Efektywnego Działania.</p>
                    <p className='text-center fw-medium text-white'>Jak znaleźć swoje ID? <a target={"_blank"}
                                                                                             className='text-success text-decoration-underline fw-bold'
                                                                                             href='https://helpmi.pl/jak-sprawdzic-id-uzytkownika-na-discordzie/'>Przeczytaj
                        tutaj.</a></p>
                </div>

                <div
                    className={`row justify-content-center g-2 mt-2 mw-md-xs mx-auto`}>
                    <label className='text-white'>
                        Wprowadź swoje ID Discorda:
                    </label>
                    <input
                        type="text"
                        className="form-control form-control-lg flex-grow-1 bg-dark"
                        value={discordId}
                        readOnly={!!courseStatistics?.discordId}
                        style={{
                            padding: '1rem 0.75rem',
                            color: "white",
                            textAlign: "center"
                        }}
                        onChange={(event) => setDiscordId(event.target.value)}
                    />
                    <AnimatedButton
                        className="btn btn-warning btn-sm flex-grow-0"
                        type="button"
                        onClick={configureDiscordId}
                        disabled={isSubmitting || !!courseStatistics?.discordId || !(new RegExp("^\\d{17,19}$").test(discordId))}
                        style={{whiteSpace: 'nowrap'}} // Ensure text stays in one line
                    >
                        {!!courseStatistics?.discordId ? "Konfiguracja zakończona" : "Zakończ konfigurację"}
                    </AnimatedButton>
                    <p className='text-secondary-light fw-medium fs-11 mt-2 mb-2'>Prawidłowe ID składa się z 17-19 cyfr.</p>
                    <p className='text-secondary-light fw-medium fs-12 mt-0'><span className='text-danger fw-bold'>Uwaga: ID nie da się zmienić manualnie.</span><br/>
                        Jeżeli potrzebujesz je zmienić lub masz problem z konfiguracją, skontaktuj się z nami:
                        kontakt@produkacja.pl </p>
                </div>
            </>
        }
    </>
}