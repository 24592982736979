// Messages from the past live webinar + Info about past purchases.
export const CHAT_MESSAGES = [
    {
        "message": "hej",
        "name": "Magda",
        "timestamp": 5.05
    },
    {
        "message": "Witam",
        "name": "Krzysztof",
        "timestamp": 5.47
    },
    {
        "message": "Czesc",
        "name": "Paweł",
        "timestamp": 6.02
    },
    {
        "message": "witam",
        "name": "Anna",
        "timestamp": 7.31
    },
    {
        "message": "witam",
        "name": "Tomasz",
        "timestamp": 7.58
    },
    {
        "message": "Witam serdecznie.",
        "name": "Ewa",
        "timestamp": 7.89
    },
    {
        "message": "Czesc",
        "name": "Janusz",
        "timestamp": 8.27
    },
    {
        "message": "Udało się",
        "name": "Kasia",
        "timestamp": 9.02
    },
    {
        "message": "👍",
        "name": "Marek",
        "timestamp": 9.33
    },
    {
        "message": "Dzień dobry",
        "name": "Agnieszka",
        "timestamp": 11.01
    },
    {
        "message": "Dzień dobry",
        "name": "Piotr",
        "timestamp": 11.14
    },
    {
        "message": "Tak",
        "name": "Magdalena",
        "timestamp": 11.46
    },
    {
        "message": "hej,",
        "name": "Ola",
        "timestamp": 12.02
    },
    {
        "message": "yep",
        "name": "Damian",
        "timestamp": 13.38
    },
    {
        "message": "słychać",
        "name": "Klara",
        "timestamp": 14.12
    },
    {
        "message": "Dzień dobry",
        "name": "Michał",
        "timestamp": 14.89
    },
    {
        "message": "tak",
        "name": "Justyna",
        "timestamp": 14.99
    },
    {
        "message": "Su6",
        "name": "Paula",
        "timestamp": 15.02
    },
    {
        "message": "Witam",
        "name": "Magda",
        "timestamp": 15.09
    },
    {
        "message": "tak",
        "name": "Wojtek",
        "timestamp": 15.27
    },
    {
        "message": "czesc",
        "name": "Olga",
        "timestamp": 15.33
    },
    {
        "message": "czeß©",
        "name": "Łukasz",
        "timestamp": 15.44
    },
    {
        "message": "Tak",
        "name": "Krzysztof",
        "timestamp": 16.01
    },
    {
        "message": "Słychać",
        "name": "Zofia",
        "timestamp": 16.12
    },
    {
        "message": "witam",
        "name": "Robert",
        "timestamp": 16.24
    },
    {
        "message": "słychać",
        "name": "Karolina",
        "timestamp": 16.37
    },
    {
        "message": "tak",
        "name": "Patryk",
        "timestamp": 16.55
    },
    {
        "message": "Tak",
        "name": "Ewelina",
        "timestamp": 16.67
    },
    {
        "message": "slychać",
        "name": "Tomek",
        "timestamp": 16.72
    },
    {
        "message": "tak",
        "name": "Alicja",
        "timestamp": 17.02
    },
    {
        "message": "Dzień dobry. Słychać i widać",
        "name": "Katarzyna",
        "timestamp": 17.14
    },
    {
        "message": "Slychac",
        "name": "Filip",
        "timestamp": 17.22
    },
    {
        "message": "witam",
        "name": "Bartłomiej",
        "timestamp": 17.29
    },
    {
        "message": "Hej!",
        "name": "Kacper",
        "timestamp": 17.4
    },
    {
        "message": "słychać i widać",
        "name": "Marek",
        "timestamp": 18.02
    },
    {
        "message": "Owszem",
        "name": "Zuzanna",
        "timestamp": 18.14
    },
    {
        "message": "słychac",
        "name": "Monika",
        "timestamp": 18.23
    },
    {
        "message": "Tak, słychać",
        "name": "Grzegorz",
        "timestamp": 18.36
    },
    {
        "message": "ta słychać",
        "name": "Natalia",
        "timestamp": 18.44
    },
    {
        "message": "Słyszymy",
        "name": "Beata",
        "timestamp": 18.51
    },
    {
        "message": "Tak",
        "name": "Adam",
        "timestamp": 18.62
    },
    {
        "message": "tak",
        "name": "Jacek",
        "timestamp": 18.77
    },
    {
        "message": "Witam. Słychać",
        "name": "Iwona",
        "timestamp": 19.01
    },
    {
        "message": "Tak slyszymy",
        "name": "Marcin",
        "timestamp": 19.22
    },
    {
        "message": "Cześć",
        "name": "Patrycja",
        "timestamp": 19.38
    },
    {
        "message": "dzień dobry",
        "name": "Mateusz",
        "timestamp": 19.47
    },
    {
        "message": "Tak",
        "name": "Ewa",
        "timestamp": 19.51
    },
    {
        "message": "Tak",
        "name": "Kasia",
        "timestamp": 19.6
    },
    {
        "message": "Tak słychać",
        "name": "Artur",
        "timestamp": 20.02
    },
    {
        "message": "Słyszymy",
        "name": "Michał",
        "timestamp": 20.09
    },
    {
        "message": "Dzień dobry",
        "name": "Zofia",
        "timestamp": 20.33
    },
    {
        "message": "Tak",
        "name": "Weronika",
        "timestamp": 20.47
    },
    {
        "message": "Słychać",
        "name": "Tomasz",
        "timestamp": 20.59
    },
    {
        "message": "Tak słychać",
        "name": "Klara",
        "timestamp": 20.69
    },
    {
        "message": "Pozdrawiam z Olsztyna",
        "name": "Dariusz",
        "timestamp": 20.83
    },
    {
        "message": "witam",
        "name": "Beata",
        "timestamp": 20.92
    },
    {
        "message": "Tak, słychać",
        "name": "Maria",
        "timestamp": 20.99
    },
    {
        "message": "Dzień dobry.",
        "name": "Jan",
        "timestamp": 21.11
    },
    {
        "message": "Czesc",
        "name": "Paweł",
        "timestamp": 22.02
    },
    {
        "message": "słychać",
        "name": "Mariusz",
        "timestamp": 22.19
    },
    {
        "message": "tak",
        "name": "Oliwia",
        "timestamp": 22.28
    },
    {
        "message": "Działa",
        "name": "Dorota",
        "timestamp": 22.33
    },
    {
        "message": "Tak",
        "name": "Krzysztof",
        "timestamp": 23.02
    },
    {
        "message": "tak",
        "name": "Hanna",
        "timestamp": 23.15
    },
    {
        "message": "Witam",
        "name": "Patryk",
        "timestamp": 23.27
    },
    {
        "message": "Dzień dobry",
        "name": "Ola",
        "timestamp": 23.44
    },
    {
        "message": "Tak słychać",
        "name": "Martyna",
        "timestamp": 24.02
    },
    {
        "message": "Wszystko ok :)",
        "name": "Damian",
        "timestamp": 24.19
    },
    {
        "message": "hej",
        "name": "Magdalena",
        "timestamp": 25.01
    },
    {
        "message": "Dzień dobry 🙂",
        "name": "Wojtek",
        "timestamp": 25.11
    },
    {
        "message": "dzień dobry",
        "name": "Kinga",
        "timestamp": 25.47
    },
    {
        "message": "Cześć. Słychać",
        "name": "Karolina",
        "timestamp": 25.62
    },
    {
        "message": "Tak",
        "name": "Piotr",
        "timestamp": 26.03
    },
    {
        "message": "WITAM",
        "name": "Jan",
        "timestamp": 26.11
    },
    {
        "message": "Cześć",
        "name": "Mateusz",
        "timestamp": 26.19
    },
    {
        "message": "tak",
        "name": "Izabela",
        "timestamp": 26.23
    },
    {
        "message": "Słychać",
        "name": "Norbert",
        "timestamp": 27.02
    },
    {
        "message": "Tak",
        "name": "Zuzanna",
        "timestamp": 27.18
    },
    {
        "message": "Dzień dobry.Słychać",
        "name": "Agata",
        "timestamp": 28.01
    },
    {
        "message": "Tak",
        "name": "Patrycja",
        "timestamp": 28.14
    },
    {
        "message": "Tak ok",
        "name": "Marcin",
        "timestamp": 28.26
    },
    {
        "message": "hej",
        "name": "Bartłomiej",
        "timestamp": 28.47
    },
    {
        "message": "witam.Tak",
        "name": "Karol",
        "timestamp": 28.59
    },
    {
        "message": "Ok",
        "name": "Kasia",
        "timestamp": 28.62
    },
    {
        "message": "tak",
        "name": "Monika",
        "timestamp": 29.01
    },
    {
        "message": "Jest ok",
        "name": "Paula",
        "timestamp": 29.19
    },
    {
        "message": "slychac",
        "name": "Filip",
        "timestamp": 31.02
    },
    {
        "message": "Slychac i widac, hej",
        "name": "Weronika",
        "timestamp": 32.11
    },
    {
        "message": "słychać",
        "name": "Tomasz",
        "timestamp": 35.02
    },
    {
        "message": "Widać i słychać..",
        "name": "Justyna",
        "timestamp": 38.02
    },
    {
        "message": ":) Super!",
        "name": "Karo",
        "timestamp": 40.02
    },
    {
        "message": "Witam",
        "name": "Damian",
        "timestamp": 48.02
    },
    {
        "message": "hej! jest o.K.",
        "name": "Karolina",
        "timestamp": 48.13
    },
    {
        "message": "Hej, nie mogę się doczekać!",
        "name": "Adam",
        "timestamp": 48.13
    },
    {
        "message": "😊",
        "name": "Marek",
        "timestamp": 54.02
    },
    {
        "message": "Witam",
        "name": "Kinga",
        "timestamp": 55.09
    },
    {
        "message": "WITAM",
        "name": "Alicja",
        "timestamp": 60.02
    },
    {
        "message": "Witam",
        "name": "Wojciech",
        "timestamp": 68.02
    },
    {
        "message": "Dzień dobry",
        "name": "Zofia",
        "timestamp": 70.12
    },
    {
        "message": "Cześć",
        "name": "Kamila",
        "timestamp": 71.02
    },
    {
        "message": "Witajcie",
        "name": "Piotr",
        "timestamp": 75.01
    },
    {
        "message": "witam wszystkich",
        "name": "Ola",
        "timestamp": 78.02
    },
    {
        "message": "Witam",
        "name": "Bartek",
        "timestamp": 81.02
    },
    {
        "message": "Do kiedy potrwa webinar?",
        "name": "Mariusz",
        "timestamp": 82.83
    },
    {
        "message": "Dzień dobry :)",
        "name": "Patrycja",
        "timestamp": 84.02
    },
    {
        "message": "Dzień dobry",
        "name": "Łukasz",
        "timestamp": 87.01
    },
    {
        "message": "Suepr!",
        "name": "Agnieszka",
        "timestamp": 88.02
    },
    {
        "message": "Ile potrwa webinar?",
        "name": "Jakub",
        "timestamp": 90.63
    },
    {
        "message": "czy dziecko też może uczestniczyć w tym webinarze?",
        "name": "Tomek",
        "timestamp": 94.02
    },
    {
        "message": "Dzień dobry",
        "name": "Ewelina",
        "timestamp": 97.01
    },
    {
        "message": "hej",
        "name": "Jakub",
        "timestamp": 98.02
    },
    {
        "message": "Mam pytanie techniczne - ile potrwa szkolenie?",
        "name": "Paulina",
        "timestamp": 100.78
    },
    {
        "message": "Dzień dobry🤗",
        "name": "Magda",
        "timestamp": 102.02
    },
    {
        "message": "czesc :)",
        "name": "Daniel",
        "timestamp": 103.12
    },
    {
        "message": "Będzie coś o prokratynacji?",
        "name": "Ewelina",
        "timestamp": 104.12
    },
    {
        "message": "Wooow",
        "name": "Natalia",
        "timestamp": 106.02
    },
    {
        "message": "Dzień dobry :)",
        "name": "Klaudia",
        "timestamp": 112.02
    },
    {
        "message": "Witam",
        "name": "Olek",
        "timestamp": 114.02
    },
    {
        "message": "Super webinar",
        "name": "Tomek",
        "timestamp": 116.17
    },
    {
        "message": "Cześć wszystkim!",
        "name": "Karolina",
        "timestamp": 116.29
    },
    {
        "message": "Jestem tu pierwszy raz",
        "name": "Paweł",
        "timestamp": 116.37
    },
    {
        "message": "Działamy",
        "name": "Ola",
        "timestamp": 116.79
    },
    {
        "message": "Czy można zadawąć pytania?",
        "name": "Zofia",
        "timestamp": 118.02
    },
    {
        "message": "Wszystko jasne",
        "name": "Paula",
        "timestamp": 120.19
    },
    {
        "message": "Będzie dostęp do slajdów?",
        "name": "Weronika",
        "timestamp": 121.47
    },
    {
        "message": "Nie mogę się doczekać Q&A",
        "name": "Wojtek",
        "timestamp": 121.51
    },
    {
        "message": "Czy można się cofnąć do początku?",
        "name": "Filip",
        "timestamp": 122.02
    },
    {
        "message": "Wow dużo osób tu",
        "name": "Alicja",
        "timestamp": 122.11
    },
    {
        "message": "Pozdrowienia z Krakowa",
        "name": "Łukasz",
        "timestamp": 124.37
    },
    {
        "message": "Extra webinar",
        "name": "Natalia",
        "timestamp": 125.43
    },
    {
        "message": "Czy będzie nagranie?",
        "name": "Jacek",
        "timestamp": 126.49
    },
    {
        "message": "Czekam na slajdy",
        "name": "Maria",
        "timestamp": 127.57
    },
    {
        "message": "duzo wiedzy sie szykuje",
        "name": "Jakub",
        "timestamp": 130.63
    },
    {
        "message": "Można chwilę poczekać?",
        "name": "Ewelina",
        "timestamp": 131.71
    },
    {
        "message": "Witam wszystkich, spóźniłem się",
        "name": "Przemek",
        "timestamp": 133.76
    },
    {
        "message": "Panie Mikolaju, dziekuje jeszcze raz za pomoc! Teraz Ignacy dostaje same 5 w szkole i doskonale radzi sobie z materialem.",
        "name": "Paulina",
        "timestamp": 136.78
    },
    {
        "message": "Do ktorej potrwa webinar?",
        "name": "Marcin",
        "timestamp": 140.83
    },
    {
        "message": "Również byłam na ostatnim webinarze i było świetnie! Nie mogęsię doczekać co teraz pan nam pokaze:)",
        "name": "Dorota",
        "timestamp": 143.86
    },
    {
        "message": "Będzie Q&A na końcu?",
        "name": "Robert",
        "timestamp": 150.92
    },
    {
        "message": "test",
        "name": "Magdalena",
        "timestamp": 153.98
    },
    {
        "message": "Czyli dla studentów też?",
        "name": "T.",
        "timestamp": 155.98
    },
    {
        "message": "Tak",
        "name": "Tadek",
        "timestamp": 160.8
    },
    {
        "message": "Lecimy",
        "name": "Karol",
        "timestamp": 170.8
    },
    {
        "message": ":)",
        "name": "Kasia",
        "timestamp": 188.8
    },
    {
        "message": "wylaczam chat",
        "name": "Maria",
        "timestamp": 195.8
    },
    {
        "message": "spoznilam sie. czy byla mowa ile potrwa webinar?",
        "name": "Marta",
        "timestamp": 210.1
    },
    {
        "message": "1.5h",
        "name": "T.",
        "timestamp": 215.8
    },
    {
        "message": "poltorej godzinyt",
        "name": "Agnieszka",
        "timestamp": 215.9
    },
    {
        "message": "podobno okolo 1.5h",
        "name": "Artur",
        "timestamp": 217.0
    },
    {
        "message": "dziekuje",
        "name": "Marta",
        "timestamp": 225.0
    },
    {
        "message": "czy bedzie cos o efektywnej nauce?",
        "name": "Monika",
        "timestamp": 235.0
    },
    {
        "message": "Ostatnio bylo",
        "name": "Ola",
        "timestamp": 243.0
    },
    {
        "message": "Super!",
        "name": "Kacper",
        "timestamp": 250.0
    },
    {
        "message": "Moje dzieci chodza na korepetycje",
        "name": "Patrycja",
        "timestamp": 259.0
    },
    {
        "message": "U mnie tak samo. ",
        "name": "Ola",
        "timestamp": 265.0
    },
    {
        "message": "Bez korepetycji to ni rusz",
        "name": "Agnieszka",
        "timestamp": 266.0
    },
    {
        "message": "ciekawi mnie ten proces",
        "name": "Paweł",
        "timestamp": 274.11
    },
    {
        "message": "prowadzi pan indywidualne konsultacje?",
        "name": "Anna",
        "timestamp": 275.11
    },
    {
        "message": "czy bedzie powtorka?",
        "name": "Katarzyna",
        "timestamp": 278.11
    },
    {
        "message": "super ze bedzie prezentacja!",
        "name": "Anita",
        "timestamp": 290.11
    },
    {
        "message": ":(",
        "name": "Katarzyna",
        "timestamp": 300.11
    },
    {
        "message": "ok",
        "name": "Miłosz",
        "timestamp": 302.11
    },
    {
        "message": "NIe dam rady zostac do konca....",
        "name": "Katarzyna",
        "timestamp": 306.11
    },
    {
        "message": "Zamykam wszystko i lecimy",
        "name": "Bog",
        "timestamp": 315.51
    },
    {
        "message": "Wylaczam chat i skupiam sie",
        "name": "Borys",
        "timestamp": 319.11
    },
    {
        "message": "Szkoda",
        "name": "Ara23",
        "timestamp": 320.11
    },
    {
        "message": "Polecam sluchac. Ostatnio zrobilam 2 strony notatek i nie mogę sie doczekac co bdzie teraz",
        "name": "Anka",
        "timestamp": 325.11
    },
    {
        "message": "Skupiam sie",
        "name": "Paulina",
        "timestamp": 340.0
    },
    {
        "message": "wow",
        "name": "Klaudia",
        "timestamp": 352.0
    },
    {
        "message": "da się tak??",
        "name": "Marek",
        "timestamp": 356.0
    },
    {
        "message": "A mi się nie chce kolacji sobie zrobić :) ",
        "name": "Bartek",
        "timestamp": 363.2
    },
    {
        "message": "opłacało się to Panu?",
        "name": "Monika",
        "timestamp": 368.2
    },
    {
        "message": "poleci Pan jakiś kurs?",
        "name": "Anna",
        "timestamp": 375.1
    },
    {
        "message": "jak nauczyles sie tego wszysktiego?",
        "name": "Karo",
        "timestamp": 377.1
    },
    {
        "message": "poleci Pan jakiś kurs?",
        "name": "Anna",
        "timestamp": 390.1
    },
    {
        "message": "pozdrawiam z Warszawy",
        "name": "Mariusz",
        "timestamp": 399.1
    },
    {
        "message": "co studiowałes?",
        "name": "Leszek",
        "timestamp": 409.1
    },
    {
        "message": "U nas duze problemy z motywacja.",
        "name": "Ludmiła",
        "timestamp": 420.1
    },
    {
        "message": "super 👍",
        "name": "Karolina",
        "timestamp": 430.1
    },
    {
        "message": "nie moge sie doczekac tego o korepetycjach. wydaje prawie 2000zl miesiecznie, a wokol kazdy mowi ze to normalne ??",
        "name": "Natalia",
        "timestamp": 438.1
    },
    {
        "message": "2000zl?",
        "name": "Anna",
        "timestamp": 445.1
    },
    {
        "message": "u nas ponad 1500zl a wydaje sie wciaz ze za malo ...",
        "name": "Patrycja",
        "timestamp": 450.1
    },
    {
        "message": "u nas podobnie.",
        "name": "Karol",
        "timestamp": 455.1
    },
    {
        "message": "Mamy tak samo z dziecmi...",
        "name": "Ludmiła",
        "timestamp": 460.1
    },
    {
        "message": "i jak pan to zmeinil?",
        "name": "moni",
        "timestamp": 465.1
    },
    {
        "message": "co to za kurs??",
        "name": "Anna",
        "timestamp": 480.1
    },
    {
        "message": "prowadzi pan jeszcze zajecia z uczniami?",
        "name": "Katarzyna",
        "timestamp": 491.1
    },
    {
        "message": "dzięki Pana pomocy syn dostał się na studia do Sztokholmu!!! Dziękuję Panu bardzo!!!",
        "name": "K.",
        "timestamp": 500.1
    },
    {
        "message": "syn pracowal z mikolajem kilka miesiecy temu i udalo mu sie dostac do wymarzonego liceum:))",
        "name": "Bogumiła",
        "timestamp": 512.1
    },
    {
        "message": "czy będzie mowa o efektywnej nauce?",
        "name": "Józef",
        "timestamp": 520.1
    },
    {
        "message": "U nas podobnie. Kupiłam synowi Kurs i myślałam że to na marne, a przerobił wszystko w tajemnicy🙂",
        "name": "Renata",
        "timestamp": 525.1
    },
    {
        "message": "również czekam na pomoc w nauce",
        "name": "Teresa",
        "timestamp": 528.0
    },
    {
        "message": "tak poprosimy wykład o tym jak pomoc dziecku uczyc sie lepiej",
        "name": "Małgorzata",
        "timestamp": 533.1
    },
    {
        "message": "+1",
        "name": "Andrzej",
        "timestamp": 538.1
    },
    {
        "message": "mam nadzieję ze bedzie",
        "name": "Arek",
        "timestamp": 542.1
    },
    {
        "message": "skupcie sie zamiast ciagle pisac... jak bedzie to bedzie jak nie to nie",
        "name": "Anna",
        "timestamp": 545.1
    },
    {
        "message": "Nie martwcie się. Będzie mowa o efektywnej nauce :)",
        "name": "Marysia z Produkacji",
        "timestamp": 550.1
    },
    {
        "message": "Super!",
        "name": "Małgorzata",
        "timestamp": 554.55
    },
    {
        "message": "swietnie",
        "name": "Teresa",
        "timestamp": 555.15
    },
    {
        "message": "🫶",
        "name": "Arek",
        "timestamp": 558.15
    },
    {
        "message": "nie moge sie doczekac",
        "name": "Józef",
        "timestamp": 560.15
    },
    {
        "message": "skupiamy sie juz!!",
        "name": "Anna",
        "timestamp": 565.02
    },
    {
        "message": "nieźle",
        "name": "Karol",
        "timestamp": 576.02
    },
    {
        "message": "pomaga pan jeszcze mlodziezy?",
        "name": "Nora",
        "timestamp": 585.02
    },
    {
        "message": "ile potrwa szkolenei?",
        "name": "Charlotte",
        "timestamp": 595.02
    },
    {
        "message": "zapowiada sie super",
        "name": "Jan",
        "timestamp": 610.02
    },
    {
        "message": "wow. wyjasni pan jak to mozliwe?",
        "name": "Kinga",
        "timestamp": 630.02
    },
    {
        "message": "u nas podobne rezultaty po wspolpracy. dziekujemy!",
        "name": "Weronika",
        "timestamp": 643.1
    },
    {
        "message": "można jeszcze wspołpracować z panem indywidualnie???",
        "name": "Ola",
        "timestamp": 655.1
    },
    {
        "message": "dziękujemy",
        "name": "Małgorzata",
        "timestamp": 680.1
    },
    {
        "message": "początek ciekawy to będzie tylko lepiej",
        "name": "Martyna",
        "timestamp": 702.1
    },
    {
        "message": "tak, tak",
        "name": "Zuzanna",
        "timestamp": 717.05
    },
    {
        "message": "coś musi się zmienić",
        "name": "Tomasz",
        "timestamp": 717.19
    },
    {
        "message": "tak",
        "name": "Ola",
        "timestamp": 717.46
    },
    {
        "message": "może tak",
        "name": "Krzysztof",
        "timestamp": 717.82
    },
    {
        "message": "lecimy!",
        "name": "Karolina",
        "timestamp": 717.03
    },
    {
        "message": "let's go",
        "name": "Piotr",
        "timestamp": 718.27
    },
    {
        "message": "zaczynamy",
        "name": "Magda",
        "timestamp": 718.63
    },
    {
        "message": "zapowiada się super",
        "name": "Paweł",
        "timestamp": 719.02
    },
    {
        "message": "Tak, jestem gotowa",
        "name": "Anna",
        "timestamp": 720.18
    },
    {
        "message": "No to zaczynamy",
        "name": "Mateusz",
        "timestamp": 720.41
    },
    {
        "message": "Też tak myślę",
        "name": "Zofia",
        "timestamp": 720.56
    },
    {
        "message": "Jedziemy dalej",
        "name": "Marek",
        "timestamp": 720.97
    },
    {
        "message": "coś musi się zmienić, prawda?",
        "name": "Iwona",
        "timestamp": 721.21
    },
    {
        "message": "Tak, tak, idźmy do przodu",
        "name": "Kasia",
        "timestamp": 721.54
    },
    {
        "message": "Zapowiada się mega",
        "name": "Robert",
        "timestamp": 721.82
    },
    {
        "message": "tak",
        "name": "Monika",
        "timestamp": 722.03
    },
    {
        "message": "może..",
        "name": "Damian",
        "timestamp": 722.19
    },
    {
        "message": "Pora startować!",
        "name": "Weronika",
        "timestamp": 722.45
    },
    {
        "message": "Lecimy z tematem",
        "name": "Łukasz",
        "timestamp": 722.62
    },
    {
        "message": "tak, tak",
        "name": "Beata",
        "timestamp": 723.04
    },
    {
        "message": "Zmieniamy się na lepsze",
        "name": "Emil",
        "timestamp": 723.19
    },
    {
        "message": "coś musi się zmienić, aby ruszyć",
        "name": "Klaudia",
        "timestamp": 723.33
    },
    {
        "message": "tak, wchodzę w to",
        "name": "Michał",
        "timestamp": 723.57
    },
    {
        "message": "Zobaczymy, co dalej",
        "name": "Natalia",
        "timestamp": 723.89
    },
    {
        "message": "Zapowiada się super!",
        "name": "Ewa",
        "timestamp": 724.09
    },
    {
        "message": "No, lecimy!",
        "name": "Tomek",
        "timestamp": 724.27
    },
    {
        "message": "tak, tak, poproszę więcej",
        "name": "Ula",
        "timestamp": 724.46
    },
    {
        "message": "OK, zaczynamy",
        "name": "Paweł",
        "timestamp": 725.01
    },
    {
        "message": "Może tak spróbujemy",
        "name": "Justyna",
        "timestamp": 725.14
    },
    {
        "message": "super klimat",
        "name": "Adam",
        "timestamp": 725.49
    },
    {
        "message": "Tak, tak, zdecydowanie!",
        "name": "Alicja",
        "timestamp": 725.73
    },
    {
        "message": "Jedziemy!",
        "name": "Krzysztof",
        "timestamp": 726.04
    },
    {
        "message": "tak :)",
        "name": "Zuzanna",
        "timestamp": 726.38
    },
    {
        "message": "Zapowiada się intensywnie",
        "name": "Bartek",
        "timestamp": 727.02
    },
    {
        "message": "tak, jedźmy dalej",
        "name": "Agnieszka",
        "timestamp": 727.42
    },
    {
        "message": "tak",
        "name": "Kacper",
        "timestamp": 728.15
    },
    {
        "message": "tak",
        "name": "Magdalena",
        "timestamp": 728.66
    },
    {
        "message": "tak, tak",
        "name": "Dariusz",
        "timestamp": 729.02
    },
    {
        "message": "gotowa!",
        "name": "Natalia",
        "timestamp": 729.56
    },
    {
        "message": "Zaczynamy na dobre",
        "name": "Wojtek",
        "timestamp": 730.11
    },
    {
        "message": "Zapowiada się super, lecimy",
        "name": "Marta",
        "timestamp": 730.24
    },
    {
        "message": "ZACZYNAMY!!",
        "name": "Filip",
        "timestamp": 730.47
    },
    {
        "message": "coś musi się zmienić teraz",
        "name": "Dorota",
        "timestamp": 731.09
    },
    {
        "message": "Lecimy, let's go!",
        "name": "Daniel",
        "timestamp": 732.02
    },
    {
        "message": "tak, czas na zmiany",
        "name": "Patrycja",
        "timestamp": 732.22
    },
    {
        "message": "zaczynamy",
        "name": "Jakub",
        "timestamp": 732.44
    },
    {
        "message": "Zapowiada się super, jedziemy",
        "name": "Oliwia",
        "timestamp": 733.01
    },
    {
        "message": "tak, tak, nie ma co czekać",
        "name": "Ewelina",
        "timestamp": 733.28
    },
    {
        "message": "tak",
        "name": "Norbert",
        "timestamp": 733.54
    },
    {
        "message": "lets go",
        "name": "Hanna",
        "timestamp": 733.72
    },
    {
        "message": "zaczynamy!",
        "name": "Przemek",
        "timestamp": 735.88
    },
    {
        "message": "super!!",
        "name": "Karol",
        "timestamp": 745.88
    },

    // Efektywne działanie
    {
        "message": "dobre zplanowanie dnia",
        "name": "Magda",
        "timestamp": 759.05
    },
    {
        "message": "Skuteczna realizacja celów",
        "name": "Adam",
        "timestamp": 760.22
    },
    {
        "message": "Zaplanowane",
        "name": "Kinga",
        "timestamp": 760.47
    },
    {
        "message": "Osiąganie sukcesów",
        "name": "Damian",
        "timestamp": 760.72
    },
    {
        "message": "przynosi rezutaty",
        "name": "Zuzanna",
        "timestamp": 760.93
    },
    {
        "message": "Przynosi owoce",
        "name": "Tomek",
        "timestamp": 761.14
    },
    {
        "message": "Takie które przynosi rezultat",
        "name": "Weronika",
        "timestamp": 761.29
    },
    {
        "message": "osiągnięcie celów, które założyłes",
        "name": "Piotr",
        "timestamp": 761.53
    },
    {
        "message": "działanie które przynosi efekt :]",
        "name": "Karolina",
        "timestamp": 761.76
    },
    {
        "message": "niemarnowanie czasu",
        "name": "Paula",
        "timestamp": 761.92
    },
    {
        "message": "Praca która przynosi efekty",
        "name": "Adam",
        "timestamp": 762.18
    },
    {
        "message": "Umiejętność robienia tego, co należy",
        "name": "Monika",
        "timestamp": 762.42
    },
    {
        "message": "praca dająca rezultaty",
        "name": "Justyna",
        "timestamp": 762.64
    },
    {
        "message": "Dojście do wybranego celu",
        "name": "Ewa",
        "timestamp": 763.01
    },
    {
        "message": "Efektywna nauka",
        "name": "Marek",
        "timestamp": 763.26
    },
    {
        "message": "Dzialanue z sensem",
        "name": "Natalia",
        "timestamp": 763.49
    },
    {
        "message": "kiedy widzimy rezultaty",
        "name": "Bartek",
        "timestamp": 763.71
    },
    {
        "message": "skuteczne. i sensowne. i bez dystraktorow",
        "name": "Dorota",
        "timestamp": 763.95
    },
    {
        "message": "widać rezultaty",
        "name": "Patrycja",
        "timestamp": 764.16
    },
    {
        "message": "cos co przynosi rezultat",
        "name": "Konrad",
        "timestamp": 764.32
    },
    {
        "message": "rezultat",
        "name": "Iwona",
        "timestamp": 764.47
    },
    {
        "message": "To działanie skuteczne,",
        "name": "Mateusz",
        "timestamp": 764.64
    },
    {
        "message": "działanie, które przynosi korzyści",
        "name": "Blanka",
        "timestamp": 764.82
    },
    {
        "message": "Sumienność",
        "name": "Michał",
        "timestamp": 765.01
    },
    {
        "message": "systematyczność",
        "name": "Ula",
        "timestamp": 765.29
    },
    {
        "message": "działanie przynoszące efekty",
        "name": "Hanna",
        "timestamp": 765.46
    },
    {
        "message": "robienie tego co trzeba",
        "name": "Krzysztof",
        "timestamp": 765.62
    },
    {
        "message": "max korzysci w najkrótszym czasie",
        "name": "Beata",
        "timestamp": 765.89
    },
    {
        "message": "Brakiem marnowania czasu",
        "name": "Olek",
        "timestamp": 766.07
    },
    {
        "message": "Dużo osiągnąć w małym czasie",
        "name": "Zofia",
        "timestamp": 766.38
    },
    {
        "message": "Gdy osiągamy cel",
        "name": "Robert",
        "timestamp": 766.53
    },
    {
        "message": "To działanie prowadzące do realizacji swoich celów",
        "name": "Magdalena",
        "timestamp": 766.76
    },
    {
        "message": "to takie które przyniesie zmiany",
        "name": "Norbert",
        "timestamp": 767.02
    },
    {
        "message": "Działanie które przynosi oczekiwane rezultaty",
        "name": "Alicja",
        "timestamp": 767.28
    },
    {
        "message": "Daje efekt",
        "name": "Łukasz",
        "timestamp": 767.47
    },
    {
        "message": "Działanie któe przynosi skutek",
        "name": "Ewelina",
        "timestamp": 767.62
    },
    {
        "message": "Systematyczność",
        "name": "Jan",
        "timestamp": 767.79
    },
    {
        "message": "planowanie",
        "name": "Ola",
        "timestamp": 767.94
    },
    {
        "message": "osiągnięcie dobrych wyników",
        "name": "Wojtek",
        "timestamp": 768.11
    },
    {
        "message": "działanie które daje efekty",
        "name": "Marta",
        "timestamp": 768.25
    },
    {
        "message": "Dobre efekty w określonym czasie",
        "name": "Filip",
        "timestamp": 768.56
    },
    {
        "message": "widoczny skutek",
        "name": "Basia",
        "timestamp": 768.79
    },
    {
        "message": "Zdolność do wykonania założonych celów w zaplanowanym czasie",
        "name": "Przemek",
        "timestamp": 769.02
    },
    {
        "message": "nieodkładanie na później",
        "name": "Karolina",
        "timestamp": 769.18
    },
    {
        "message": "Skutecznisc",
        "name": "Wioleta",
        "timestamp": 769.36
    },
    {
        "message": "działanie które ma skutek..",
        "name": "Kacper",
        "timestamp": 769.58
    },
    {
        "message": "Sprawna realizacja celów",
        "name": "Janusz",
        "timestamp": 769.74
    },
    {
        "message": "Daje oczekiwane rezultaty",
        "name": "Justyna",
        "timestamp": 770.01
    },
    {
        "message": "W skrócie: zangażowanie w to co robię.",
        "name": "Mariusz",
        "timestamp": 770.22
    },
    {
        "message": "satysfakcja",
        "name": "Kasia",
        "timestamp": 770.43
    },
    {
        "message": "Efektywna nauka",
        "name": "Alan",
        "timestamp": 771.09
    },
    {
        "message": "Założony rezultat",
        "name": "Hania",
        "timestamp": 772.32
    },
    {
        "message": "Umiejętność uczenia się",
        "name": "Tomek",
        "timestamp": 773.57
    },
    {
        "message": "Coś co przynosi efekty",
        "name": "Dorota",
        "timestamp": 774.88
    },
    {
        "message": "zakładane cele",
        "name": "Adrianna",
        "timestamp": 776.77
    },
    {
        "message": "efektywna nauka",
        "name": "Kasia",
        "timestamp": 780.0
    },
    {
        "message": "ma to sens",
        "name": "Anna",
        "timestamp": 801.0
    },
    {
        "message": "zgadzam sie",
        "name": "Mariusz",
        "timestamp": 810.0
    },
    {
        "message": "a jak się ich nauczyć??",
        "name": "Krzysztof",
        "timestamp": 825.0
    },
    {
        "message": "teraz wszystko jasne",
        "name": "Karol",
        "timestamp": 849.0
    },
    {
        "message": "dziękuję za takie wytłumaczenie dokładne",
        "name": "Monika",
        "timestamp": 865.0
    },
    {
        "message": "do ktorej potrwa webinar??",
        "name": "Katarina",
        "timestamp": 870.0
    },
    {
        "message": "w koncu :)",
        "name": "Kasia",
        "timestamp": 880.0
    },
    {
        "message": "lecimy",
        "name": "Marta",
        "timestamp": 881.0
    },
    {
        "message": "nie mogę się doczekać!",
        "name": "W.",
        "timestamp": 883.0
    },
    {
        "message": "te korepetycje to katorga. my wydajemy po prawie 2000zł miesięcznie a efekty mierne",
        "name": "Małgorzata",
        "timestamp": 885.1
    },
    {
        "message": "u nas podobnie",
        "name": "Anna",
        "timestamp": 890.1
    },
    {
        "message": "nauczyciele nic nie uczą to niestety trzeba",
        "name": "Maria",
        "timestamp": 892.1
    },
    {
        "message": "u nas tak samo...",
        "name": "Andrzej",
        "timestamp": 893.1
    },
    {
        "message": "my po wspolpracy z mikolajem zrezygnowalismy z korepetycji i zdecydowanie wyszlo to na dobre",
        "name": "Magdalena",
        "timestamp": 894.59
    },
    {
        "message": "jakiej wspolpracy??",
        "name": "Martyna",
        "timestamp": 897.59
    },
    {
        "message": "u nas z korepetycjami podobnie. tylko kase wydajemy a rezultatow nie ma :(",
        "name": "Anita",
        "timestamp": 902.59
    },
    {
        "message": "Mikołaj prowadził kiedyś kursy, ale nie wiem czy jeszcze to robi.",
        "name": "Magdalena",
        "timestamp": 905
    },

    // korepetycje
    {
        "message": "tak",
        "name": "Marek",
        "timestamp": 907.05
    },
    {
        "message": "Tak",
        "name": "Klaudia",
        "timestamp": 907.28
    },
    {
        "message": "na każdej wywiadówce to słyszę",
        "name": "Karolina",
        "timestamp": 907.53
    },
    {
        "message": "tak",
        "name": "Wojtek",
        "timestamp": 907.82
    },
    {
        "message": "tak",
        "name": "Kasia",
        "timestamp": 908.05
    },
    {
        "message": "nie",
        "name": "Tomasz",
        "timestamp": 908.34
    },
    {
        "message": "takk",
        "name": "Monika",
        "timestamp": 908.64
    },
    {
        "message": "słyszałam",
        "name": "Zuzanna",
        "timestamp": 908.94
    },
    {
        "message": "Nie",
        "name": "Janusz",
        "timestamp": 909.22
    },
    {
        "message": "tak",
        "name": "Ewelina",
        "timestamp": 909.52
    },
    {
        "message": "nie",
        "name": "Piotr",
        "timestamp": 909.84
    },
    {
        "message": "tak",
        "name": "Alicja",
        "timestamp": 910.14
    },
    {
        "message": "tak",
        "name": "Oskar",
        "timestamp": 910.44
    },
    {
        "message": "nie",
        "name": "Beata",
        "timestamp": 910.74
    },
    {
        "message": "bardzo często",
        "name": "Weronika",
        "timestamp": 911.04
    },
    {
        "message": "takk",
        "name": "Damian",
        "timestamp": 911.34
    },
    {
        "message": "tak",
        "name": "Ola",
        "timestamp": 911.64
    },
    {
        "message": "bez korepetycji uczeń niczego nie osiągnie...",
        "name": "Tomek",
        "timestamp": 911.94
    },
    {
        "message": "tak",
        "name": "Dominik",
        "timestamp": 912.24
    },
    {
        "message": "nie",
        "name": "Emilia",
        "timestamp": 912.54
    },
    {
        "message": "tak",
        "name": "Agnieszka",
        "timestamp": 912.84
    },
    {
        "message": "Tak",
        "name": "Bartłomiej",
        "timestamp": 913.14
    },
    {
        "message": "tak",
        "name": "Gabriela",
        "timestamp": 913.44
    },
    {
        "message": "słyszałem",
        "name": "Jan",
        "timestamp": 913.74
    },
    {
        "message": "nie",
        "name": "Mariusz",
        "timestamp": 914.04
    },
    {
        "message": "tak",
        "name": "Ola",
        "timestamp": 914.34
    },
    {
        "message": "Tak",
        "name": "Patrycja",
        "timestamp": 914.64
    },
    {
        "message": "na każdej wywiadówce to słyszę",
        "name": "Paweł",
        "timestamp": 914.94
    },
    {
        "message": "takk",
        "name": "Ewa",
        "timestamp": 915.24
    },
    {
        "message": "bardzo często",
        "name": "Monika",
        "timestamp": 915.54
    },
    {
        "message": "tak",
        "name": "Tobiasz",
        "timestamp": 915.84
    },
    {
        "message": "Nie",
        "name": "Lena",
        "timestamp": 916.14
    },
    {
        "message": "tak, w klasie maturalnej bez korepetycji nie ma rusz",
        "name": "Krzysztof",
        "timestamp": 916.44
    },
    {
        "message": "tak",
        "name": "Mariola",
        "timestamp": 916.74
    },
    {
        "message": "tak",
        "name": "Filip",
        "timestamp": 917.04
    },
    {
        "message": "takk",
        "name": "Aneta",
        "timestamp": 917.34
    },
    {
        "message": "nie",
        "name": "Ewa",
        "timestamp": 917.64
    },
    {
        "message": "tak",
        "name": "Kornelia",
        "timestamp": 917.94
    },
    {
        "message": "Tak",
        "name": "Hubert",
        "timestamp": 918.24
    },
    {
        "message": "tak",
        "name": "Nadia",
        "timestamp": 918.54
    },
    {
        "message": "nie",
        "name": "Tadeusz",
        "timestamp": 918.84
    },
    {
        "message": "Tak",
        "name": "Laura",
        "timestamp": 920.05
    },
    {
        "message": "takk",
        "name": "Wiktor",
        "timestamp": 921.44
    },
    {
        "message": "Nie",
        "name": "Malwina",
        "timestamp": 922.83
    },
    {
        "message": "tak",
        "name": "Cezary",
        "timestamp": 923.78
    },
    {
        "message": "tak",
        "name": "Eliza",
        "timestamp": 924.66
    },
    {
        "message": "\"Korepetycje to edukacja przyszlosci\"",
        "name": "Ela",
        "timestamp": 926.66
    },
    {
        "message": "Bardzo czesto to slyszymy :)",
        "name": "Elżbieta",
        "timestamp": 928.1
    },
    {
        "message": "a da sie bez korepetycji dostac sie na studia??",
        "name": "marek",
        "timestamp": 930.1
    },
    {
        "message": "ciekawe jak bez korepetycji mozna sie uczyc w tych czasach ",
        "name": "Karo",
        "timestamp": 933
    },
    {
        "message": "tak",
        "name": "Anna",
        "timestamp": 936
    },
    {
        "message": "do któej potrwa to szkolenie bo sie spoznilam??",
        "name": "Gosia",
        "timestamp": 940
    },
    {
        "message": "jeszcze godzine",
        "name": "Monika",
        "timestamp": 945
    },
    {
        "message": "1.5h",
        "name": "Monika",
        "timestamp": 946
    },
    {
        "message": "dziekuje",
        "name": "Gosia",
        "timestamp": 950
    },
    {
        "message": "dziekuje",
        "name": "Gosia",
        "timestamp": 950
    },
    {
        "message": ":o",
        "name": "Marta",
        "timestamp": 960
    },
    {
        "message": "Tego sięnie spodziewałem",
        "name": "Miłosz",
        "timestamp": 971
    },
    {
        "message": "Naprawdę tak jest?",
        "name": "Agnieszka",
        "timestamp": 980
    },
    {
        "message": "Potwierdazm z autopsji",
        "name": "Kasia",
        "timestamp": 991
    },
    {
        "message": "Wszystko jasne",
        "name": "Ania",
        "timestamp": 1000
    },
    {
        "message": "wooow",
        "name": "Kornelia",
        "timestamp": 1021
    },
    {
        "message": "czy będzie coś o prokrastynacji??",
        "name": "Kornelia",
        "timestamp": 1040
    },
    {
        "message": "makes sense",
        "name": "Jakub",
        "timestamp": 1060
    },
    {
        "message": "🙂",
        "name": "Antoni",
        "timestamp": 1065
    },
    {
        "message": "dopiero 20min szkolenia a już mam wrażenie, że dowiedziałam się mnóśtwo nowych rzeczy",
        "name": "Małgorzata",
        "timestamp": 1079
    },
    {
        "message": "tak",
        "name": "Ewa",
        "timestamp": 1085.2
    },
    {
        "message": "potwierdzam",
        "name": "Artur",
        "timestamp": 1092.2
    },
    {
        "message": "tak",
        "name": "Eliza",
        "timestamp": 1100.2
    },
    {
        "message": "czy prowadzi pan jeszcze zajęcia??",
        "name": "Pola",
        "timestamp": 1120.2
    },
    {
        "message": "A jakie zajęcia pan poleca?",
        "name": "Katarzyna",
        "timestamp": 1130.2
    },
    {
        "message": "ma to sens",
        "name": "anna",
        "timestamp": 1139.2
    },
    {
        "message": "👍",
        "name": "Piotr",
        "timestamp": 1145
    },
    {
        "message": "takkk",
        "name": "Monika",
        "timestamp": 1170
    },
    {
        "message": ".",
        "name": "Adam",
        "timestamp": 1179
    },
    {
        "message": "Nie pomyślałam o tym wcześniej",
        "name": "Anna",
        "timestamp": 1190
    },
    {
        "message": "ma to sens..",
        "name": "Kornelia",
        "timestamp": 1199
    },
    {
        "message": "to widze, ze popelnialismy blad",
        "name": "Ada",
        "timestamp": 1208.13
    },
    {
        "message": "my rowniez..",
        "name": "Mariusz",
        "timestamp": 1213
    },
    {
        "message": "my tez",
        "name": "Aga",
        "timestamp": 1217
    },
    {
        "message": "a jakie sa alternatywy?",
        "name": "Monia",
        "timestamp": 1230
    },
    {
        "message": "witam wszystkich, przepraszam za spoznienie",
        "name": "Paweł",
        "timestamp": 1249.4
    },
    {
        "message": "Cześć",
        "name": "Arek",
        "timestamp": 1255.4
    },
    {
        "message": "czesc",
        "name": "Anna",
        "timestamp": 1260.4
    },
    {
        "message": "Pozdrawiam z Warszawy!",
        "name": "Paweł",
        "timestamp": 1270
    },
    {
        "message": "ma to sens mikolaj, szkoda ze dopiero teraz sie o tym dowiaduje",
        "name": "Monika",
        "timestamp": 1274
    },
    {
        "message": "super ze teraz kazdy promuje korepetycje haha",
        "name": "Adrian",
        "timestamp": 1280
    },
    {
        "message": "a rynek korepetycji z roku na rok rosnie? :) ",
        "name": "Bartosz",
        "timestamp": 1284
    },
    {
        "message": "Dopiero teraz widzę, że cały czas popełnialiśmy błąd. Dlaczego dopiero teraz się o tym dowiaduję? Jestem beznadziejną matką...",
        "name": "Agnieszka",
        "timestamp": 1290
    },

    // czy to ma sens - korepetycje
    {
        "message": "tak",
        "name": "Marek",
        "timestamp": 1295.03
    },
    {
        "message": "tak",
        "name": "Karolina",
        "timestamp": 1295.17
    },
    {
        "message": "super",
        "name": "Paweł",
        "timestamp": 1295.29
    },
    {
        "message": "tak",
        "name": "Agnieszka",
        "timestamp": 1295.42
    },
    {
        "message": "tak",
        "name": "Damian",
        "timestamp": 1295.56
    },
    {
        "message": "lecimy",
        "name": "Natalia",
        "timestamp": 1295.73
    },
    {
        "message": "tak",
        "name": "Krzysztof",
        "timestamp": 1295.89
    },
    {
        "message": "takk",
        "name": "Ola",
        "timestamp": 1296.05
    },
    {
        "message": "tak",
        "name": "Zuzanna",
        "timestamp": 1296.16
    },
    {
        "message": "tak",
        "name": "Bartek",
        "timestamp": 1296.31
    },
    {
        "message": "dużo sensu",
        "name": "Magda",
        "timestamp": 1296.44
    },
    {
        "message": "tak",
        "name": "Patryk",
        "timestamp": 1296.57
    },
    {
        "message": "tak",
        "name": "Kinga",
        "timestamp": 1296.71
    },
    {
        "message": "super",
        "name": "Emilia",
        "timestamp": 1296.88
    },
    {
        "message": "tak",
        "name": "Jan",
        "timestamp": 1297.02
    },
    {
        "message": "takk",
        "name": "Oliwia",
        "timestamp": 1297.18
    },
    {
        "message": "tak",
        "name": "Tomek",
        "timestamp": 1297.29
    },
    {
        "message": "tak",
        "name": "Weronika",
        "timestamp": 1297.46
    },
    {
        "message": "dużo sensu",
        "name": "Piotr",
        "timestamp": 1297.61
    },
    {
        "message": "tak",
        "name": "Beata",
        "timestamp": 1297.74
    },
    {
        "message": "lecimy",
        "name": "Łukasz",
        "timestamp": 1297.89
    },
    {
        "message": "super",
        "name": "Ewa",
        "timestamp": 1298.05
    },
    {
        "message": "tak",
        "name": "Kacper",
        "timestamp": 1298.22
    },
    {
        "message": "takk",
        "name": "Marta",
        "timestamp": 1298.35
    },
    {
        "message": "tak",
        "name": "Marcin",
        "timestamp": 1298.51
    },
    {
        "message": "tak",
        "name": "Blanka",
        "timestamp": 1298.66
    },
    {
        "message": "dużo sensu",
        "name": "Robert",
        "timestamp": 1298.84
    },
    {
        "message": "tak",
        "name": "Klaudia",
        "timestamp": 1299.01
    },
    {
        "message": "tak",
        "name": "Wojtek",
        "timestamp": 1299.16
    },
    {
        "message": "super",
        "name": "Iwona",
        "timestamp": 1299.33
    },
    {
        "message": "tak",
        "name": "Patrycja",
        "timestamp": 1299.49
    },
    {
        "message": "takk",
        "name": "Maciej",
        "timestamp": 1299.62
    },
    {
        "message": "tak",
        "name": "Weronika",
        "timestamp": 1299.78
    },
    {
        "message": "tak",
        "name": "Adam",
        "timestamp": 1299.93
    },
    {
        "message": "lecimy",
        "name": "Klaudia",
        "timestamp": 1300.06
    },
    {
        "message": "tak",
        "name": "Magdalena",
        "timestamp": 1300.25
    },
    {
        "message": "tak",
        "name": "Przemek",
        "timestamp": 1300.41
    },
    {
        "message": "super",
        "name": "Hanna",
        "timestamp": 1300.58
    },
    {
        "message": "tak",
        "name": "Paweł",
        "timestamp": 1300.75
    },
    {
        "message": "tak",
        "name": "Alicja",
        "timestamp": 1300.9
    },
    {
        "message": "dużo sensu",
        "name": "Damian",
        "timestamp": 1301.05
    },
    {
        "message": "takk",
        "name": "Ola",
        "timestamp": 1301.19
    },
    {
        "message": "tak",
        "name": "Konrad",
        "timestamp": 1301.36
    },
    {
        "message": "tak",
        "name": "Sandra",
        "timestamp": 1301.51
    },
    {
        "message": "super",
        "name": "Zofia",
        "timestamp": 1301.68
    },
    {
        "message": "tak",
        "name": "Norbert",
        "timestamp": 1302.04
    },
    {
        "message": "lecimy",
        "name": "Joanna",
        "timestamp": 1302.31
    },
    {
        "message": "dużo sensu",
        "name": "Hubert",
        "timestamp": 1302.58
    },
    {
        "message": "tak",
        "name": "Ewelina",
        "timestamp": 1303.07
    },
    {
        "message": "tak",
        "name": "Mateusz",
        "timestamp": 1303.89
    },
    {
        "message": "Tak!",
        "name": "Adam",
        "timestamp": 1304.89
    },
    {
        "message": "ma",
        "name": "Monika",
        "timestamp": 1305.7
    },
    {
        "message": "W końcu wszystko jasne",
        "name": "Anna",
        "timestamp": 1308.7
    },
    {
        "message": "czas zrezygnować z korepetycji",
        "name": "Marta",
        "timestamp": 1315.7
    },
    {
        "message": "tak",
        "name": "Marta",
        "timestamp": 1320.7
    },
    {
        "message": "My też popełnialiśmy ten błąd",
        "name": "Monika",
        "timestamp": 1330.7
    },
    {
        "message": "my też",
        "name": "Anita",
        "timestamp": 1334.2
    },
    {
        "message": "u nas tak samo",
        "name": "Bogumiła",
        "timestamp": 1338.2
    },
    {
        "message": "czekam na rozwiązania",
        "name": "Cecylia",
        "timestamp": 1345.2
    },
    {
        "message": "będziemy mieli dostęp do slajdów?",
        "name": "Kornelia",
        "timestamp": 1355.2
    },
    {
        "message": "mikolaj mowil ze na koncu bedzie",
        "name": "Anna",
        "timestamp": 1364.2
    },
    {
        "message": "tak",
        "name": "Artur",
        "timestamp": 1365.2
    },
    {
        "message": "prowadzi pan jeszcze kursy?",
        "name": "Magdalena",
        "timestamp": 1375.0
    },
    {
        "message": "czemu nikt nie mowi o takich tematach?",
        "name": "Patrycja",
        "timestamp": 1384.0
    },
    {
        "message": "mamy troche zacofany system edukacji :)",
        "name": "Mariusz",
        "timestamp": 1400.0
    },
    {
        "message": "lecimy ",
        "name": "Mon.",
        "timestamp": 1415.0
    },
    {
        "message": "super",
        "name": "Kasia",
        "timestamp": 1420.0
    },
    {
        "message": "bedzie o tym jak zmotywowac dziecko do nauki??",
        "name": "Patrycja",
        "timestamp": 1430.0
    },
    {
        "message": "bedzie o tym jak zmotywowac dziecko do nauki??",
        "name": "Patrycja",
        "timestamp": 1430.0
    },
    {
        "message": "czekam na praktyczne rady :)",
        "name": "Anna",
        "timestamp": 1440.3
    },
    {
        "message": ":(",
        "name": "Marta",
        "timestamp": 1465.3
    },
    {
        "message": "szkoda ze w szkoalch tego nie ucza",
        "name": "Teresa",
        "timestamp": 1470.3
    },
    {
        "message": "to da sie tak??",
        "name": "Monika",
        "timestamp": 1485.3
    },
    {
        "message": "to jakis mega mozg",
        "name": "Bartek",
        "timestamp": 1490.3
    },
    {
        "message": "u syna w klasie 90% na korepetycje chodzi",
        "name": "Karolina",
        "timestamp": 1500.3
    },
    {
        "message": "jak wiktor to zrobil?",
        "name": "Katarzyna",
        "timestamp": 1510.0
    },
    {
        "message": "wow",
        "name": "Eliza",
        "timestamp": 1513.0
    },
    {
        "message": ";oo",
        "name": "Pola",
        "timestamp": 1515.0
    },
    {
        "message": "niezle",
        "name": "M.",
        "timestamp": 1520.0
    },
    {
        "message": "👏👏👏",
        "name": "Bartłomiej",
        "timestamp": 1525.0
    },
    {
        "message": "Jak to wdrozyc u swoich dzieci??",
        "name": "Marcin",
        "timestamp": 1535.0
    },
    {
        "message": "witam wszystkich",
        "name": "Nora",
        "timestamp": 1560.0
    },
    {
        "message": "dopeiro chwile temu dolaczylam. duzo mnie ominelo??",
        "name": "Nora",
        "timestamp": 1580.0
    },
    {
        "message": "tak",
        "name": "Anna",
        "timestamp": 1585.0
    },
    {
        "message": "duzo wartosciowej wiedzy",
        "name": "Karolina",
        "timestamp": 1590.0
    },
    {
        "message": "brawo wiktori",
        "name": "Anna",
        "timestamp": 1600.0
    },
    {
        "message": "chciałabym żeby mój syn napisał maturę na 30%...",
        "name": "Sylwia",
        "timestamp": 1610.0
    },
    {
        "message": "prowadzi pan jeszcze wspolprace z uczniami???",
        "name": "Sylwia",
        "timestamp": 1630.0
    },
    {
        "message": "super",
        "name": "Kornelia",
        "timestamp": 1634.5
    },
    {
        "message": "woooow",
        "name": "Małgorzata",
        "timestamp": 1640.3
    },
    {
        "message": "można ofertę prosić?",
        "name": "Katarzyna",
        "timestamp": 1650.3
    },
    {
        "message": "jak można z panem wspolpracowac?",
        "name": "Elżbieta",
        "timestamp": 1655.3
    },
    {
        "message": "u nas corka po wspolpracy z mikolajem dostala sie na medycyne w warszawie. moge polecic",
        "name": "Ewa",
        "timestamp": 1660.3
    },
    {
        "message": "👏",
        "name": "Katarzyna",
        "timestamp": 1666.12
    },
    {
        "message": "my chcielismy nawiazac wspolprace ale poand 3 miesiace oczekiwania na termin... chyba za duzo chetnych jest",
        "name": "Maria",
        "timestamp": 1675.12
    },
    {
        "message": "pozdrawiam z krakowa",
        "name": "Karol",
        "timestamp": 1678.12
    },
    {
        "message": "a jak z korepetycji zrezygnowac??",
        "name": "Anita",
        "timestamp": 1680.10
    },
    {
        "message": "u nas syn nie ma pojecai co chce robic pozniej",
        "name": "Artur",
        "timestamp": 1690.10
    },
    {
        "message": "rezygnuję z korepetycji",
        "name": "Agnieszka",
        "timestamp": 1700.10
    },
    {
        "message": "jakie to zasady?",
        "name": "Anna",
        "timestamp": 1713.10
    },
    {
        "message": "a jak to zrobić?",
        "name": "Monika",
        "timestamp": 1725.10
    },
    {
        "message": "to jak pomoc mu?",
        "name": "Maria",
        "timestamp": 1740.10
    },
    {
        "message": "my mamy problem z motywacja",
        "name": "Mariola",
        "timestamp": 1750.10
    },
    {
        "message": "my tez",
        "name": "Marcin",
        "timestamp": 1758.10
    },
    {
        "message": "jaki dokument?",
        "name": "Antoni",
        "timestamp": 1770.10
    },
    {
        "message": "poproszę o szczegóły",
        "name": "Magdalena",
        "timestamp": 1782.10
    },
    {
        "message": "dostaniemy to ćwiczenie? ",
        "name": "Magdalena",
        "timestamp": 1790.10
    },
    {
        "message": "jakie to ćwiczenie?",
        "name": "Mariusz",
        "timestamp": 1798.10
    },
    {
        "message": "można dostęp do tego dokumenutu?",
        "name": "Marek",
        "timestamp": 1807.10
    },
    {
        "message": "skąd te pytania wziąć?",
        "name": "Marcin",
        "timestamp": 1820.10
    },
    {
        "message": "gdzie moge wykupic dokument?",
        "name": "Miłosz",
        "timestamp": 1828.10
    },
    {
        "message": "u nas corka nie ma zadnego planu dzialania",
        "name": "Anna",
        "timestamp": 1835.10
    },
    {
        "message": "gdzie znalezc ten dokument??",
        "name": "Ania",
        "timestamp": 1840.10
    },
    {
        "message": "poprosze o pytania",
        "name": "Mariola",
        "timestamp": 1850
    },
    {
        "message": "halo skad to wziac??",
        "name": "Magda",
        "timestamp": 1860
    },
    {
        "message": "jakie pytania??",
        "name": "Miłosz",
        "timestamp": 1862
    },
    {
        "message": "poprosimy o dokument",
        "name": "Ewa",
        "timestamp": 1870
    },
    {
        "message": "mozemy dostac dostep do best self?",
        "name": "Elżbieta",
        "timestamp": 1875
    },
    {
        "message": ":(",
        "name": "Anna",
        "timestamp": 1884
    },
    {
        "message": "...",
        "name": "Monika",
        "timestamp": 1886
    },
    {
        "message": "super",
        "name": "Maria",
        "timestamp": 1895
    },
    {
        "message": "ok",
        "name": "Miłosz",
        "timestamp": 1898
    },
    {
        "message": "dziękuję!",
        "name": "Anna",
        "timestamp": 1900
    },
    {
        "message": "A kiedy prezentację dostanieny?",
        "name": "Agnieszka",
        "timestamp": 1903
    },
    {
        "message": "super :)",
        "name": "Ola",
        "timestamp": 1908
    },
    {
        "message": "dziękujemy",
        "name": "Aga",
        "timestamp": 1908.3
    },
    {
        "message": "super",
        "name": "Arkadiusz",
        "timestamp": 1909
    },
    {
        "message": "dziekujemy bardzo",
        "name": "Artur",
        "timestamp": 1910.3
    },
    {
        "message": "💖",
        "name": "Artur",
        "timestamp": 1912.3
    },
    {
        "message": "nie moge sie doczekac. corka napewno skorzysta ",
        "name": "Anita",
        "timestamp": 1917.3
    },
    {
        "message": "dzieki 👍",
        "name": "Miłosz",
        "timestamp": 1925.3
    },
    {
        "message": "u nas wlasnie problem z motywacja",
        "name": "Adam",
        "timestamp": 1940.3
    },
    {
        "message": "motywacja do dzialania to istna gehenna",
        "name": "Sylwia",
        "timestamp": 1950.3
    },
    {
        "message": "to jak motywować skutecznei??",
        "name": "Magdalena",
        "timestamp": 1960.3
    },
    {
        "message": "my zabieralismy telefon ale to nie dzialalo",
        "name": "Lena",
        "timestamp": 1970
    },
    {
        "message": "a jak wyksztalcic nawyk?",
        "name": "Artur",
        "timestamp": 1978
    },
    {
        "message": "jak pomoc dziecku wyksztalcic nawyk?",
        "name": "Agnieszka",
        "timestamp": 1990
    },
    {
        "message": "ja nie myje zebow",
        "name": "Antek",
        "timestamp": 2000
    },
    {
        "message": "😅",
        "name": "Mariusz",
        "timestamp": 2005
    },
    {
        "message": "haha",
        "name": "Ewa",
        "timestamp": 2006.3
    },
    {
        "message": "ma to sens",
        "name": "Monika",
        "timestamp": 2010.3
    },
    {
        "message": "prawda",
        "name": "Agnieszka",
        "timestamp": 2018
    },
    {
        "message": "a jak ten nawyk wyksztalcic?",
        "name": "Sylwia",
        "timestamp": 2030
    },
    {
        "message": "moj probowal uczyc sie 2h dziennie i po tyghodniu odpuscil",
        "name": "Katarzyna",
        "timestamp": 2037
    },
    {
        "message": "haha ma to sens:)",
        "name": "Katarzyna",
        "timestamp": 2050
    },
    {
        "message": "Czyli ile czasu?",
        "name": "Aleksandra",
        "timestamp": 2055
    },
    {
        "message": "ma to sens",
        "name": "Mariusz",
        "timestamp": 2065
    },
    {
        "message": "czyli lepiej uczyc sie po 1h dziennie niz 2x w tygodniu po 3h?",
        "name": "Wioletta",
        "timestamp": 2071
    },
    {
        "message": "wszystko jasne, dziekuje",
        "name": "Andrzej",
        "timestamp": 2084.2
    },
    {
        "message": "okej, czyli lepiej po kolei",
        "name": "Monika",
        "timestamp": 2090.2
    },
    {
        "message": "dziekuje",
        "name": "Agnieszka",
        "timestamp": 2095.2
    },
    {
        "message": "wiadomo",
        "name": "Magda",
        "timestamp": 2100.2
    },
    {
        "message": "ok",
        "name": "Kasia",
        "timestamp": 2108.2
    },

    // Czy ma to sens do 2 pkt - sprawdzony system działania
    {
        "message": "tak",
        "name": "Marek",
        "timestamp": 2110.05
    },
    {
        "message": "takkkk",
        "name": "Natalia",
        "timestamp": 2110.18
    },
    {
        "message": "dużo sensu",
        "name": "Kacper",
        "timestamp": 2110.35
    },
    {
        "message": "Tak",
        "name": "Magda",
        "timestamp": 2110.49
    },
    {
        "message": "tak",
        "name": "Łukasz",
        "timestamp": 2111.02
    },
    {
        "message": "tak",
        "name": "Agnieszka",
        "timestamp": 2111.15
    },
    {
        "message": "dużo sensu",
        "name": "Damian",
        "timestamp": 2111.30
    },
    {
        "message": "takkkk",
        "name": "Ewelina",
        "timestamp": 2111.49
    },
    {
        "message": "tak",
        "name": "Mateusz",
        "timestamp": 2112.01
    },
    {
        "message": "Tak",
        "name": "Karolina",
        "timestamp": 2112.14
    },
    {
        "message": "lecimy",
        "name": "Piotr",
        "timestamp": 2112.27
    },
    {
        "message": "tak",
        "name": "Dominik",
        "timestamp": 2112.41
    },
    {
        "message": "super",
        "name": "Olga",
        "timestamp": 2113.01
    },
    {
        "message": "tak",
        "name": "Bartek",
        "timestamp": 2113.12
    },
    {
        "message": "tak",
        "name": "Weronika",
        "timestamp": 2113.25
    },
    {
        "message": "dużo sensu",
        "name": "Janusz",
        "timestamp": 2113.39
    },
    {
        "message": "tak",
        "name": "Beata",
        "timestamp": 2114.02
    },
    {
        "message": "takkkk",
        "name": "Adrian",
        "timestamp": 2114.16
    },
    {
        "message": "super",
        "name": "Agata",
        "timestamp": 2114.29
    },
    {
        "message": "tak",
        "name": "Zofia",
        "timestamp": 2114.45
    },
    {
        "message": "Tak",
        "name": "Marcin",
        "timestamp": 2115.05
    },
    {
        "message": "tak",
        "name": "Kinga",
        "timestamp": 2115.18
    },
    {
        "message": "lecimy",
        "name": "Andrzej",
        "timestamp": 2115.34
    },
    {
        "message": "takkkk",
        "name": "Justyna",
        "timestamp": 2115.48
    },
    {
        "message": "tak",
        "name": "Ola",
        "timestamp": 2116.06
    },
    {
        "message": "dużo sensu",
        "name": "Darek",
        "timestamp": 2116.22
    },
    {
        "message": "tak",
        "name": "Wioletta",
        "timestamp": 2116.35
    },
    {
        "message": "tak",
        "name": "Paweł",
        "timestamp": 2116.49
    },
    {
        "message": "Tak",
        "name": "Michał",
        "timestamp": 2117.01
    },
    {
        "message": "tak",
        "name": "Klaudia",
        "timestamp": 2117.18
    },
    {
        "message": "super",
        "name": "Filip",
        "timestamp": 2117.31
    },
    {
        "message": "takkkk",
        "name": "Hanna",
        "timestamp": 2117.46
    },
    {
        "message": "dużo sensu",
        "name": "Krzysztof",
        "timestamp": 2118.04
    },
    {
        "message": "tak",
        "name": "Zuzanna",
        "timestamp": 2118.19
    },
    {
        "message": "tak",
        "name": "Alan",
        "timestamp": 2118.34
    },
    {
        "message": "lecimy",
        "name": "Natalia",
        "timestamp": 2118.51
    },
    {
        "message": "tak",
        "name": "Artur",
        "timestamp": 2119.01
    },
    {
        "message": "Tak",
        "name": "Mariola",
        "timestamp": 2119.16
    },
    {
        "message": "dużo sensu",
        "name": "Tomek",
        "timestamp": 2119.32
    },
    {
        "message": "tak",
        "name": "Sylwia",
        "timestamp": 2119.46
    },
    {
        "message": "takkkk",
        "name": "Kornelia",
        "timestamp": 2119.61
    },
    {
        "message": "super",
        "name": "Tobiasz",
        "timestamp": 2119.72
    },
    {
        "message": "tak",
        "name": "Emilia",
        "timestamp": 2119.85
    },
    {
        "message": "tak",
        "name": "Daniel",
        "timestamp": 2119.99
    },

    {
        "message": "Tak",
        "name": "Magdalena",
        "timestamp": 2120.12
    },
    {
        "message": "tak",
        "name": "Oskar",
        "timestamp": 2121.25
    },
    {
        "message": "dużo sensu",
        "name": "Iwona",
        "timestamp": 2122.37
    },
    {
        "message": "super",
        "name": "Adam",
        "timestamp": 2123.48
    },
    {
        "message": "takkkk",
        "name": "Patrycja",
        "timestamp": 2124.56
    },
    {
        "message": "tak",
        "name": "Kamil",
        "timestamp": 2125.62
    },
    {
        "message": "idziemy dalej",
        "name": "Elżbieta",
        "timestamp": 2129.8
    },
    {
        "message": "poproszę temat efektywnej nauki",
        "name": "Ewa",
        "timestamp": 2135.8
    },
    {
        "message": "u nas samoocena to najwiekszy problem",
        "name": "Katarzyna",
        "timestamp": 2145
    },
    {
        "message": ":OO",
        "name": "Kasia",
        "timestamp": 2155
    },
    {
        "message": "Naprawdę aż tyle??",
        "name": "Anna",
        "timestamp": 2160
    },
    {
        "message": "co?",
        "name": "Magdalena",
        "timestamp": 2165
    },
    {
        "message": "tak dużo???",
        "name": "Andrzej",
        "timestamp": 2170
    },
    {
        "message": "co możęmy zrobić aby było lepiej?",
        "name": "Marcin",
        "timestamp": 2177
    },
    {
        "message": "tego sie nie spodziewalam",
        "name": "Sylwia",
        "timestamp": 2183
    },
    {
        "message": "jestesmy w tym razem",
        "name": "Ewa",
        "timestamp": 2190.3
    },
    {
        "message": "wspieramy!",
        "name": "Mariusz",
        "timestamp": 2198
    },
    {
        "message": "dajemy z siebie wszystko",
        "name": "Marta",
        "timestamp": 2210
    },
    {
        "message": "Mam wspaniałego syna 😘",
        "name": "Mariola",
        "timestamp": 2225
    },
    {
        "message": "wspieramy!!",
        "name": "Kasia",
        "timestamp": 2226
    },
    {
        "message": "dziekuje za uswiadomienie ☺️",
        "name": "Łukasz",
        "timestamp": 2230.4
    },
    {
        "message": "tak to bardzo pomaga",
        "name": "Eliza",
        "timestamp": 2234.4
    },
    {
        "message": "ma to dużo sensu, dziękuję",
        "name": "Bogumiła",
        "timestamp": 2240.4
    },
    {
        "message": "tak",
        "name": "Józef",
        "timestamp": 2249
    },
    {
        "message": "wspieramy 👍",
        "name": "Karol",
        "timestamp": 2255
    },
    {
        "message": "po webinarze kazdy powinien pojsc i przytulic swoje dziecko!",
        "name": "Anna",
        "timestamp": 2260
    },
    {
        "message": "Tak, takie chwile sa bardzo wazne",
        "name": "Katarzyna",
        "timestamp": 2265
    },
    {
        "message": "racja",
        "name": "Krzysztof",
        "timestamp": 2278
    },
    {
        "message": "my tne blad popelnialismy i byly zle skutki. uwazajcie na te porownania.",
        "name": "Ewa",
        "timestamp": 2290
    },
    {
        "message": "tak, jest bardzo duza roznica.",
        "name": "Grzegorz",
        "timestamp": 2300
    },
    {
        "message": "znam ta ksiazke - mindset!! polecam lekture",
        "name": "Magdalena",
        "timestamp": 2315
    },
    {
        "message": "to naprawde duzo zmienia, mowie z autopsji. mikolaj wie co mowi",
        "name": "Mariola",
        "timestamp": 2328
    },
    {
        "message": "tak",
        "name": "Katarzyna",
        "timestamp": 2340
    },
    {
        "message": "Zdecydowanie",
        "name": "Pola",
        "timestamp": 2345
    },
    {
        "message": "dziekuje panie mikolaju!!",
        "name": "Anita",
        "timestamp": 2349
    },
    {
        "message": "dziękuję :)",
        "name": "Krzysztof",
        "timestamp": 2349.5
    },
    {
        "message": "mój najbardziej wyczekiwany temat!",
        "name": "Marta",
        "timestamp": 2360.5
    },
    {
        "message": "Skad pan to wszystko wie??",
        "name": "Monika",
        "timestamp": 2370.5
    },
    {
        "message": "W szkole nikt nie uczy jak sie uczyc :)",
        "name": "Artur",
        "timestamp": 2379.5
    },
    {
        "message": "My wyslalismy corke na kurs efektywnej nauki, a rezultaty wciaz mierne... mam nadzieje, ze dowiem sie tutaj jak jej skutecznie pomoc",
        "name": "Roman",
        "timestamp": 2390.5
    },
    {
        "message": "w szkole kaza przepisywac wszystko z tablicy i tyle z efektywnej nauki",
        "name": "Anita",
        "timestamp": 2400
    },
    {
        "message": "skad mozna nauczyc sie efektywnej nauki? poleca pan cos?",
        "name": "Miłosz",
        "timestamp": 2408
    },
    {
        "message": "szkoda ze szkoly nie ucza takich tematow",
        "name": "Magda",
        "timestamp": 2430
    },
    {
        "message": "u nas zawsze to samo - poucze sie przed sprawdzianem, jeszcze mam duzo czasu ..",
        "name": "ania",
        "timestamp": 2445
    },
    {
        "message": "tak duzo??",
        "name": "Kasia",
        "timestamp": 2450
    },
    {
        "message": "a jak powtorki robic?",
        "name": "Magdalena",
        "timestamp": 2460
    },
    {
        "message": "co ile robic powtorki?",
        "name": "Anita",
        "timestamp": 2470
    },
    {
        "message": "ok",
        "name": "Andrzej",
        "timestamp": 2500
    },
    {
        "message": "dziekuje, ma to sens",
        "name": "Anita",
        "timestamp": 2510
    },
    {
        "message": "teraz wszystko jasne",
        "name": "Anna",
        "timestamp": 2520
    },
    {
        "message": "Jak pomóc dziecku sie tego nauczyć?",
        "name": "Patrycja",
        "timestamp": 2534
    },
    {
        "message": "Czyli każda sesja inną techniką?",
        "name": "Wiktoria",
        "timestamp": 2560
    },
    {
        "message": "dziekuje",
        "name": "Bogumiła",
        "timestamp": 2560.2
    },
    {
        "message": "gdzie mozna znalezc te techniki?",
        "name": "Adam",
        "timestamp": 2570.2
    },
    {
        "message": "syn zawsze mowi, ze wszystko umial ale nie mogl sobie tego przypomniec na sprawdzianie.",
        "name": "Paweł",
        "timestamp": 2600
    },
    {
        "message": "wow ile wartosciowych informacji",
        "name": "Maria",
        "timestamp": 2610
    },
    {
        "message": "szkoda ze w szkole tego nikt nie uczy",
        "name": "Miłosz",
        "timestamp": 2630
    },
    {
        "message": "Moja sie uczy tylko czytajac podrecznik i dostaje slabe oceny. Jak mogę jej pomóć?",
        "name": "Agnieszka",
        "timestamp": 2638
    },
    {
        "message": "Córka śpi po 6h i uważa, że tyle jej wystarcza.",
        "name": "Mariola",
        "timestamp": 2650
    },
    {
        "message": "U nas syna przed północą nie położymy",
        "name": "Magda",
        "timestamp": 2651
    },
    {
        "message": "Z tym snem to od zawsze mamy problemy.",
        "name": "Karol",
        "timestamp": 2660
    },
    {
        "message": "Poproszę o szczegóły",
        "name": "Elżbieta",
        "timestamp": 2665
    },
    {
        "message": "okej",
        "name": "Anna",
        "timestamp": 2675.3
    },
    {
        "message": "nigdy nie rozumialam o co chodzi z fazami snu, moze w koncu ktos mi to wytlumaczy",
        "name": "Kasia",
        "timestamp": 2695.3
    },
    {
        "message": "a ile powinny spac nastolatki?",
        "name": "Paweł",
        "timestamp": 2720.3
    },
    {
        "message": "Mikołaj mówił chyba 8h",
        "name": "Karol",
        "timestamp": 2735.3
    },
    {
        "message": "Tak, między 8 a 10.",
        "name": "Magdalena",
        "timestamp": 2740.3
    },
    {
        "message": "Ma to sens",
        "name": "Oleksandra",
        "timestamp": 2770.5
    },
    {
        "message": "a kiedy jest rem?",
        "name": "Olek",
        "timestamp": 2780.5
    },
    {
        "message": "okejjjj",
        "name": "Arek",
        "timestamp": 2800
    },
    {
        "message": "w końcu rozumiem o co chodzi",
        "name": "Marta",
        "timestamp": 2810
    },
    {
        "message": "Fajne wyjasnienie, dzoiekuje",
        "name": "Aleksandra",
        "timestamp": 2830
    },
    {
        "message": "a kiedy jest rem?",
        "name": "Monika",
        "timestamp": 2840
    },
    {
        "message": "Teraz wszystko jasne",
        "name": "Mariola",
        "timestamp": 2880
    },
    {
        "message": "W końcu wiem dlaczego córce sprawdziany nie idą...",
        "name": "Miłosz",
        "timestamp": 2890.4
    },
    {
        "message": "wszystko jasne, dziękuję!",
        "name": "Magdalena",
        "timestamp": 2902
    },
    {
        "message": "dziękuję panie mikolaju!",
        "name": "Malwina",
        "timestamp": 2902.12
    },
    {
        "message": "tak!",
        "name": "Anna",
        "timestamp": 2915.12
    },
    {
        "message": "Balans bardzo ważny",
        "name": "Żaneta",
        "timestamp": 2920.12
    },
    {
        "message": "wiadomo",
        "name": "Ola",
        "timestamp": 2927
    },
    {
        "message": "tak",
        "name": "Anita",
        "timestamp": 2938
    },
    {
        "message": "Ma to sens.",
        "name": "Monika",
        "timestamp": 2945
    },
    {
        "message": "będziemy :)",
        "name": "Katarzyna",
        "timestamp": 2960
    },
    {
        "message": "za ile będzie q&a?",
        "name": "Marta",
        "timestamp": 2965
    },
    {
        "message": "super!",
        "name": "Malwina",
        "timestamp": 2968
    },

    // jak czujecie się z tym, czego się nauczyliście?
    {
        "message": "dziękuję bardzo",
        "name": "Marek",
        "timestamp": 2974.05
    },
    {
        "message": "mega dużo wiedzy",
        "name": "Ola",
        "timestamp": 2975.19
    },
    {
        "message": "Naprawdę się nauczyłem",
        "name": "Krzysztof",
        "timestamp": 2975.32
    },
    {
        "message": "fajnie",
        "name": "Karolina",
        "timestamp": 2975.48
    },
    {
        "message": "jak to teraz wdrożyć?",
        "name": "Damian",
        "timestamp": 2975.66
    },
    {
        "message": "dziękuję Mikołaj",
        "name": "Natalia",
        "timestamp": 2975.81
    },
    {
        "message": "dziękuję bardzo",
        "name": "Agnieszka",
        "timestamp": 2976.05
    },
    {
        "message": "Dużo wiedzy",
        "name": "Piotr",
        "timestamp": 2976.26
    },
    {
        "message": "super",
        "name": "Zuzanna",
        "timestamp": 2976.39
    },
    {
        "message": "dużo się nauczyłem",
        "name": "Bartek",
        "timestamp": 2976.61
    },
    {
        "message": "Wszystko fajnie ale jak to teraz wdrożyć?",
        "name": "Magda",
        "timestamp": 2976.79
    },
    {
        "message": "dziękuję Panie Mikołaju!",
        "name": "Wojtek",
        "timestamp": 2977.05
    },
    {
        "message": "fajnie",
        "name": "Kasia",
        "timestamp": 2977.25
    },
    {
        "message": "Dużo wartościowej wiedzy",
        "name": "Łukasz",
        "timestamp": 2977.42
    },
    {
        "message": "naprawdę dużo się nauczyłam",
        "name": "Beata",
        "timestamp": 2977.59
    },
    {
        "message": "Dziękuję bardzo!",
        "name": "Michał",
        "timestamp": 2977.79
    },
    {
        "message": "Czuję się przytłoczona ilością informacji...",
        "name": "Justyna",
        "timestamp": 2978.03
    },
    {
        "message": "Fajnie",
        "name": "Robert",
        "timestamp": 2978.32
    },
    {
        "message": "Potężna dawka wiedzy. wow",
        "name": "Monika",
        "timestamp": 2978.49
    },
    {
        "message": "skąd pan to wszystko wie? można wykupić z panem jeszcze współpracę???",
        "name": "Patryk",
        "timestamp": 2978.64
    },
    {
        "message": "dziękujemy panie mikolaju",
        "name": "Kamila",
        "timestamp": 2978.83
    },
    {
        "message": "a jak to teraz wdrożyć w praktyce?",
        "name": "Darek",
        "timestamp": 2979.06
    },
    {
        "message": "dziękuję bardzo",
        "name": "Klaudia",
        "timestamp": 2979.33
    },
    {
        "message": "dziękuję",
        "name": "Tomek",
        "timestamp": 2979.52
    },
    {
        "message": "dzięki! dużo wiedzy.",
        "name": "Ewelina",
        "timestamp": 2979.77
    },
    {
        "message": "mega dużo się nauczyłem",
        "name": "Piotrek",
        "timestamp": 2979.92
    },
    {
        "message": "dziękuję Mikołaj",
        "name": "Basia",
        "timestamp": 2980.15
    },
    {
        "message": "fajnie",
        "name": "Weronika",
        "timestamp": 2980.55
    },
    {
        "message": "Dużo się nauczyłam. Dziękuję",
        "name": "Patrycja",
        "timestamp": 2980.7
    },
    {
        "message": "dziękuję bardzo",
        "name": "Artur",
        "timestamp": 2981.02
    },
    {
        "message": "fajnie",
        "name": "Ada",
        "timestamp": 2981.25
    },
    {
        "message": "poproszę o poradę praktyczną - jak to wprowadzić w życie??",
        "name": "Paulina",
        "timestamp": 2982.07
    },
    {
        "message": "dziękuję mikolaj",
        "name": "Marcin",
        "timestamp": 2982.3
    },
    {
        "message": "super",
        "name": "Jagoda",
        "timestamp": 2982.49
    },
    {
        "message": "dziękuję bardzo",
        "name": "Szymon",
        "timestamp": 2982.67
    },
    {
        "message": "mega!",
        "name": "Ola",
        "timestamp": 2982.86
    },
    {
        "message": "szkoda ze w szkolach tego nie uczą :(",
        "name": "Hubert",
        "timestamp": 2983.93
    },
    {
        "message": "dziękuję bardzo",
        "name": "Ewa",
        "timestamp": 2984.18
    },
    {
        "message": "dziękuję",
        "name": "Rafał",
        "timestamp": 2984.43
    },
    {
        "message": "dziękuję Mikołaj",
        "name": "Eliza",
        "timestamp": 2984.64
    },
    {
        "message": "Mega! Jestem zmotywowana do działania.",
        "name": "Zosia",
        "timestamp": 2984.85
    },
    {
        "message": "Lecimy z tematem !! ",
        "name": "Damian",
        "timestamp": 2985.04
    },
    {
        "message": "Duuuuużo zmian nas czeka",
        "name": "Aneta",
        "timestamp": 2985.22
    },
    {
        "message": "Dziękuję bardzo",
        "name": "Bartek",
        "timestamp": 2985.44
    },
    {
        "message": "dużo się nauczyłem",
        "name": "Martyna",
        "timestamp": 2985.67
    },
    {
        "message": "fajnie",
        "name": "Krystian",
        "timestamp": 2986.02
    },
    {
        "message": "poproszę o poradę praktyczną.",
        "name": "Olga",
        "timestamp": 2986.35
    },
    {
        "message": "dziękuję Mikołaj",
        "name": "Daria",
        "timestamp": 2986.71
    },
    {
        "message": "dziękuję bardzo",
        "name": "Sebastian",
        "timestamp": 2989.92
    },
    {
        "message": "Można było ugruntować sobie wiedzę. Dziękuję",
        "name": "Agnieszka",
        "timestamp": 2990
    },
    {
        "message": "Trochę już wiedziałem, trochę nowości. dzięki",
        "name": "Tomasz",
        "timestamp": 2991
    },
    {
        "message": "super 👍",
        "name": "Małgosia",
        "timestamp": 2992
    },
    {
        "message": "dziękuję Mikołaj",
        "name": "Basia",
        "timestamp": 2994
    },
    {
        "message": "czy będzie powtórka?",
        "name": "Katarzyna",
        "timestamp": 2998.4
    },
    {
        "message": "super!",
        "name": "Marta",
        "timestamp": 3000
    },
    {
        "message": "dziękuję",
        "name": "Andrzej",
        "timestamp": 3007
    },
    {
        "message": "haha super porównanie",
        "name": "Agnieszka",
        "timestamp": 3013
    },
    {
        "message": "skąd pan się teog wszystkiego nauczył?",
        "name": "Marcin",
        "timestamp": 3020
    },
    {
        "message": "Czy prowadzi Pan jeszcze zajęcia 1-1?",
        "name": "Mariusz",
        "timestamp": 3028
    },
    {
        "message": "Kiedy dostaniemy slajdy?",
        "name": "Karolina",
        "timestamp": 3035
    },
    {
        "message": "poproszę ofertę współpracy z panem",
        "name": "Anna",
        "timestamp": 3044
    },
    {
        "message": "chyba tak",
        "name": "M.",
        "timestamp": 3048
    },
    {
        "message": "Dziękuję za tyle wiedzy Mikołaj. Głowa mi pęka 🤯",
        "name": "Martyna",
        "timestamp": 3050
    },
    {
        "message": "Może być ciężko",
        "name": "Anna",
        "timestamp": 3054
    },
    {
        "message": "super :)",
        "name": "Kasia",
        "timestamp": 3055
    },
    {
        "message": "ciężko powiedzieć",
        "name": "Natalia",
        "timestamp": 3059
    },
    {
        "message": "nie wiem czy sobie poradzę sama z tym",
        "name": "Anita",
        "timestamp": 3062
    },
    {
        "message": "Brzmi to super, ale przydałaby mi się w tym czyjaś pomoc.",
        "name": "Anita",
        "timestamp": 3062.5
    },
    {
        "message": "Mam nadzieję, że damy radę z córką.",
        "name": "Marlena",
        "timestamp": 3064
    },
    {
        "message": "Uda się",
        "name": "Ewa",
        "timestamp": 3068
    },
    {
        "message": "zdecydowanie",
        "name": "Karol",
        "timestamp": 3071
    },
    {
        "message": "udałoby sie",
        "name": "Michał",
        "timestamp": 3075
    },
    {
        "message": "na pewno dałoby radę",
        "name": "Magdalena",
        "timestamp": 3079
    },
    {
        "message": "Myślę, że tak. Tylko jak ich się nauczyć?",
        "name": "Hubert",
        "timestamp": 3082
    },
    {
        "message": "tak!",
        "name": "Joanna",
        "timestamp": 3084
    },

    // jak czujecie się z tą wiedzą i czy te umiejętności pomogłyby dziecku w efektywnym działaniu i realizacji celów
    {
        "message": "tak",
        "name": "Marek",
        "timestamp": 3088.05
    },
    {
        "message": "myślę, że tak",
        "name": "Karolina",
        "timestamp": 3088.22
    },
    {
        "message": "Zdecydowanie",
        "name": "Piotr",
        "timestamp": 3088.39
    },
    {
        "message": "super pomysł",
        "name": "Justyna",
        "timestamp": 3088.57
    },
    {
        "message": "Na pewno dałoby radę",
        "name": "Ola",
        "timestamp": 3088.74
    },
    {
        "message": "tak, tak!",
        "name": "Damian",
        "timestamp": 3088.92
    },
    {
        "message": "zdecydowanie tak",
        "name": "Beata",
        "timestamp": 3089.16
    },
    {
        "message": "myślę, że tak",
        "name": "Tomek",
        "timestamp": 3089.32
    },
    {
        "message": "super",
        "name": "Ewelina",
        "timestamp": 3089.49
    },
    {
        "message": "dokładnie!",
        "name": "Łukasz",
        "timestamp": 3089.66
    },
    {
        "message": "Tak, zdecydowanie",
        "name": "Agnieszka",
        "timestamp": 3089.81
    },
    {
        "message": "na pewno dałoby radę",
        "name": "Patryk",
        "timestamp": 3090.05
    },
    {
        "message": "tak, nie widzę przeszkód",
        "name": "Magda",
        "timestamp": 3090.22
    },
    {
        "message": "zdecydowanie tak",
        "name": "Krzysztof",
        "timestamp": 3090.39
    },
    {
        "message": "Myślę, że tak",
        "name": "Zuzanna",
        "timestamp": 3090.57
    },
    {
        "message": "Tak",
        "name": "Michał",
        "timestamp": 3090.74
    },
    {
        "message": "super",
        "name": "Klaudia",
        "timestamp": 3090.89
    },
    {
        "message": "zdecydowanie",
        "name": "Bartek",
        "timestamp": 3091.05
    },
    {
        "message": "tak, tak!",
        "name": "Weronika",
        "timestamp": 3091.22
    },
    {
        "message": "na pewno dałoby radę",
        "name": "Damian",
        "timestamp": 3091.41
    },
    {
        "message": "tak, jestem za",
        "name": "Ewa",
        "timestamp": 3091.56
    },
    {
        "message": "Zdecydowanie tak",
        "name": "Olek",
        "timestamp": 3091.72
    },
    {
        "message": "super pomysł",
        "name": "Basia",
        "timestamp": 3091.89
    },
    {
        "message": "myślę, że tak",
        "name": "Tomek",
        "timestamp": 3092.06
    },
    {
        "message": "tak, tak!",
        "name": "Marcin",
        "timestamp": 3092.23
    },
    {
        "message": "zdecydowanie",
        "name": "Jagoda",
        "timestamp": 3092.39
    },
    {
        "message": "tak, jak najbardziej",
        "name": "Robert",
        "timestamp": 3092.76
    },
    {
        "message": "super",
        "name": "Aneta",
        "timestamp": 3092.92
    },
    {
        "message": "chyba tak",
        "name": "Wojtek",
        "timestamp": 3093.15
    },
    {
        "message": "dałoby radę!",
        "name": "Artur",
        "timestamp": 3095.15
    },
    {
        "message": "takkk",
        "name": "Anna",
        "timestamp": 3096.15
    },
    {
        "message": "super. a co jak dziecko jest oporne?",
        "name": "Małgorzata",
        "timestamp": 3100.00
    },
    {
        "message": "na pewno dałoby radę",
        "name": "Kasia",
        "timestamp": 3104.58
    },
    {
        "message": "czy może pan nam w tym pomóc?",
        "name": "Artur",
        "timestamp": 3110.00
    },
    {
        "message": "super",
        "name": "Marlena",
        "timestamp": 3115.00
    },
    {
        "message": "u nas problem z pewnością siebie :(",
        "name": "Monika",
        "timestamp": 3125.00
    },
    {
        "message": "racja",
        "name": "Anna",
        "timestamp": 3130.00
    },
    {
        "message": ":)",
        "name": "Małgosia",
        "timestamp": 3136.00
    },
    {
        "message": "oj tak",
        "name": "Natalia",
        "timestamp": 3145.00
    },
    {
        "message": "Dziękuję za tyle wiedzy za darmo.",
        "name": "Bartłomiej",
        "timestamp": 3146.00
    },
    {
        "message": "dziękuję mikołaj",
        "name": "Natasza",
        "timestamp": 3150.00
    },
    {
        "message": "tak",
        "name": "Karola",
        "timestamp": 3151.00
    },

    // czy czujecie się trochę przytłoczeni?
    {
        "message": "tak",
        "name": "Kinga",
        "timestamp": 3151.05
    },
    {
        "message": "dość mocno przytłoczony",
        "name": "Damian",
        "timestamp": 3151.22
    },
    {
        "message": "dużo wartościowej wiedzy",
        "name": "Magda",
        "timestamp": 3151.39
    },
    {
        "message": "dziękuję Mikołaj",
        "name": "Łukasz",
        "timestamp": 3151.53
    },
    {
        "message": "tak tak",
        "name": "Ola",
        "timestamp": 3151.69
    },
    {
        "message": "czy może nam Pan w tym pomóc?",
        "name": "Wojtek",
        "timestamp": 3151.86
    },
    {
        "message": "tak",
        "name": "Beata",
        "timestamp": 3152.07
    },
    {
        "message": "mocno przytłoczony tym wszystkim",
        "name": "Marcin",
        "timestamp": 3152.26
    },
    {
        "message": "dużo wartościowej wiedzy, aż za dużo",
        "name": "Karolina",
        "timestamp": 3152.41
    },
    {
        "message": "dziękuję Mikołaj!",
        "name": "Piotr",
        "timestamp": 3152.58
    },
    {
        "message": "Tak",
        "name": "Patrycja",
        "timestamp": 3152.74
    },
    {
        "message": "Tak",
        "name": "Robert",
        "timestamp": 3152.93
    },
    {
        "message": "Tak",
        "name": "Agnieszka",
        "timestamp": 3153.11
    },
    {
        "message": "tak",
        "name": "Tomek",
        "timestamp": 3153.29
    },
    {
        "message": "dużo wartościowej wiedzy - ale nie wiem, od czego zacząć",
        "name": "Kasia",
        "timestamp": 3153.46
    },
    {
        "message": "dziękuję Mikołaj, ale potrzebuję wsparcia",
        "name": "Zuzanna",
        "timestamp": 3153.63
    },
    {
        "message": "Tak",
        "name": "Artur",
        "timestamp": 3153.81
    },
    {
        "message": "mocno przytłoczona...",
        "name": "Dorota",
        "timestamp": 3153.97
    },
    {
        "message": "dziękuję",
        "name": "Damian",
        "timestamp": 3154.15
    },
    {
        "message": "czy może nam Pan w tym pomóc?",
        "name": "Monika",
        "timestamp": 3154.32
    },
    {
        "message": "dziękuję Mikołaj",
        "name": "Bartek",
        "timestamp": 3154.49
    },
    {
        "message": "tak, chcę spróbować",
        "name": "Ewelina",
        "timestamp": 3154.66
    },
    {
        "message": "mocno przytłoczona, ale daje radę",
        "name": "Janusz",
        "timestamp": 3154.82
    },
    {
        "message": "skarbnica wiedzy. dziękuję",
        "name": "Natalia",
        "timestamp": 3155.01
    },
    {
        "message": "tak",
        "name": "Krzysztof",
        "timestamp": 3155.18
    },
    {
        "message": "tak, ale mega ciekawie pan opowiadał",
        "name": "Magdalena",
        "timestamp": 3155.35
    },
    {
        "message": "czy może nam Pan w tym pomóc potem?",
        "name": "Filip",
        "timestamp": 3155.51
    },
    {
        "message": "dziękuję Mikołaj",
        "name": "Hanna",
        "timestamp": 3155.69
    },
    {
        "message": "Mocno przytłoczona",
        "name": "Wioleta",
        "timestamp": 3155.85
    },
    {
        "message": "tak",
        "name": "Daniel",
        "timestamp": 3156.02
    },
    {
        "message": "Dużo wartościowej wiedzy, super. Ale jak to wdrożyć?",
        "name": "Oliwia",
        "timestamp": 3156.19
    },
    {
        "message": "Dziękuję Mikołaj!",
        "name": "Tomasz",
        "timestamp": 3156.36
    },
    {
        "message": "Czy może nam Pan w tym pomóc?",
        "name": "Anna",
        "timestamp": 3156.53
    },
    {
        "message": "tak",
        "name": "Konrad",
        "timestamp": 3156.69
    },
    {
        "message": "przytłoczona.",
        "name": "Kasia",
        "timestamp": 3157.86
    },
    {
        "message": "To wszystko brzmi naprawdę super, jednak obawiam się, że syn i tak mnie nie posłucha, ponieważ jestem jego mamą",
        "name": "Monika",
        "timestamp": 3158.86
    },
    {
        "message": "tak",
        "name": "Patrycja",
        "timestamp": 3160
    },
    {
        "message": "mózg mi paruje.",
        "name": "Patrycja",
        "timestamp": 3164
    },
    {
        "message": "u nas już synowie działają tak jak pan sugeruje, wiec za duzo sie nie nauczylam nowego, ale dziekuje i tak.",
        "name": "Elżbieta",
        "timestamp": 3166
    },
    {
        "message": "aż za mocno przytłoczona...",
        "name": "Marta",
        "timestamp": 3167
    },
    {
        "message": "czy pracuje pan jeszcze z uczniami?",
        "name": "Andrzej",
        "timestamp": 3169
    },
    {
        "message": "Dziekuje za solidna dawke wiedzy!",
        "name": "Katarzyna",
        "timestamp": 3172.5
    },
    {
        "message": "wiadomo, ale dziekujemy i tak",
        "name": "Anna",
        "timestamp": 3180
    },
    {
        "message": "no wlasnie, jak pomoc dziecku??",
        "name": "Anita",
        "timestamp": 3187
    },
    {
        "message": "znamy z autopsji",
        "name": "Anita",
        "timestamp": 3197
    },
    {
        "message": "Można z Panem jeszcze na konsultacje sie umówić?",
        "name": "Karo.",
        "timestamp": 3200
    },
    {
        "message": "tak",
        "name": "M.",
        "timestamp": 3208
    },
    {
        "message": "oj tak",
        "name": "Małgorzata",
        "timestamp": 3210
    },
    {
        "message": "u nas bardzo częste",
        "name": "Anna",
        "timestamp": 3215
    },
    {
        "message": "znane :)",
        "name": "K.",
        "timestamp": 3221
    },
    {
        "message": "Bardzo często się to powtarza ",
        "name": "Marcin",
        "timestamp": 3230
    },
    {
        "message": "To jak to lepiej zrobić?",
        "name": "Marta",
        "timestamp": 3244
    },
    {
        "message": "Czyli czyja pomoc?",
        "name": "Monika",
        "timestamp": 3250
    },
    {
        "message": "A kto mógłby mi w tym pomóc?",
        "name": "Wiktoria",
        "timestamp": 3254
    },
    {
        "message": "Poproszę",
        "name": "Weronika",
        "timestamp": 3263
    },
    {
        "message": "Tak",
        "name": "Marta",
        "timestamp": 3266
    },
    // Oferta
    {
        "message": "Tak!",
        "name": "Marek",
        "timestamp": 3266.05
    },
    {
        "message": "tak",
        "name": "Agnieszka",
        "timestamp": 3266.12
    },
    {
        "message": "tak",
        "name": "Piotr",
        "timestamp": 3266.29
    },
    {
        "message": "3 razy tak",
        "name": "Klaudia",
        "timestamp": 3266.38
    },
    {
        "message": "Tak",
        "name": "Maciej",
        "timestamp": 3266.56
    },
    {
        "message": "Tak!",
        "name": "Ewelina",
        "timestamp": 3266.72
    },
    {
        "message": "tak",
        "name": "Olga",
        "timestamp": 3266.89
    },
    {
        "message": "tak!",
        "name": "Damian",
        "timestamp": 3267.05
    },
    {
        "message": "Tak!",
        "name": "Magda",
        "timestamp": 3267.14
    },
    {
        "message": "nie trzeba, dziękuję",
        "name": "Bartek",
        "timestamp": 3267.29
    },
    {
        "message": "tak",
        "name": "Krzysztof",
        "timestamp": 3267.41
    },
    {
        "message": "tak",
        "name": "Weronika",
        "timestamp": 3267.58
    },
    {
        "message": "Tak!",
        "name": "Tomek",
        "timestamp": 3267.75
    },
    {
        "message": "tak",
        "name": "Mariola",
        "timestamp": 3267.92
    },
    {
        "message": "Tak",
        "name": "Kasia",
        "timestamp": 3268.07
    },
    {
        "message": "tak",
        "name": "Adam",
        "timestamp": 3268.24
    },
    {
        "message": "tak",
        "name": "Paweł",
        "timestamp": 3268.39
    },
    {
        "message": "tak",
        "name": "Natalia",
        "timestamp": 3268.57
    },
    {
        "message": "Tak, poproszę!",
        "name": "Dominik",
        "timestamp": 3268.69
    },
    {
        "message": "Jestem zainteresowana",
        "name": "Justyna",
        "timestamp": 3268.82
    },
    {
        "message": "Tak",
        "name": "Oskar",
        "timestamp": 3268.99
    },
    {
        "message": "Poproszę ofertę",
        "name": "Beata",
        "timestamp": 3269.14
    },
    {
        "message": "Tak",
        "name": "Sebastian",
        "timestamp": 3269.33
    },
    {
        "message": "tak",
        "name": "Aneta",
        "timestamp": 3269.48
    },
    {
        "message": "Chętnie",
        "name": "Grzegorz",
        "timestamp": 3269.64
    },
    {
        "message": "Tak!",
        "name": "Zuzanna",
        "timestamp": 3269.79
    },
    {
        "message": "Poproszę!",
        "name": "Marcin",
        "timestamp": 3270.02
    },
    {
        "message": "Jestem zainteresowana",
        "name": "Ola",
        "timestamp": 3270.16
    },
    {
        "message": "Tak, poproszę ofertę",
        "name": "Norbert",
        "timestamp": 3270.33
    },
    {
        "message": "tak",
        "name": "Daria",
        "timestamp": 3270.47
    },
    {
        "message": "Tak",
        "name": "Filip",
        "timestamp": 3270.65
    },
    {
        "message": "Tak",
        "name": "Gabriela",
        "timestamp": 3270.77
    },
    {
        "message": "tak",
        "name": "Adrian",
        "timestamp": 3270.94
    },
    {
        "message": "Poproszę :)",
        "name": "Marta",
        "timestamp": 3271.09
    },
    {
        "message": "tak",
        "name": "Tadeusz",
        "timestamp": 3271.26
    },
    {
        "message": "tak",
        "name": "Alicja",
        "timestamp": 3271.42
    },
    {
        "message": "Tak!",
        "name": "Krzysztof",
        "timestamp": 3271.59
    },
    {
        "message": "tak",
        "name": "Hubert",
        "timestamp": 3271.73
    },
    {
        "message": "Poproszę ofertę",
        "name": "Kinga",
        "timestamp": 3271.89
    },
    {
        "message": "Tak",
        "name": "Łukasz",
        "timestamp": 3272.06
    },
    {
        "message": "Tak",
        "name": "Emilia",
        "timestamp": 3272.2
    },
    {
        "message": "tak",
        "name": "Patryk",
        "timestamp": 3272.36
    },
    {
        "message": "Jestem zainteresowana",
        "name": "Monika",
        "timestamp": 3272.54
    },
    {
        "message": "Poproszę",
        "name": "Daniel",
        "timestamp": 3272.69
    },
    {
        "message": "Tak, poproszę ofertę",
        "name": "Barbara",
        "timestamp": 3272.87
    },
    {
        "message": "Tak",
        "name": "Mateusz",
        "timestamp": 3273.06
    },
    {
        "message": "tak",
        "name": "Paulina",
        "timestamp": 3273.19
    },
    {
        "message": "Chętnie",
        "name": "Mikołaj",
        "timestamp": 3273.37
    },
    {
        "message": "Tak!",
        "name": "Hanna",
        "timestamp": 3273.58
    },
    {
        "message": "tak",
        "name": "Natalia",
        "timestamp": 3273.69
    },
    {
        "message": "Poproszę ofertę",
        "name": "Marcel",
        "timestamp": 3273.86
    },
    {
        "message": "tak",
        "name": "Joanna",
        "timestamp": 3274.02
    },
    {
        "message": "tak",
        "name": "Dawid",
        "timestamp": 3274.19
    },
    {
        "message": "Chętnie skorzystam",
        "name": "Eliza",
        "timestamp": 3274.33
    },
    {
        "message": "Tak!",
        "name": "Rafał",
        "timestamp": 3274.49
    },
    {
        "message": "Poproszę ofertę",
        "name": "Ewa",
        "timestamp": 3274.67
    },
    {
        "message": "Tak, poproszę",
        "name": "Bartłomiej",
        "timestamp": 3274.84
    },
    {
        "message": "Jestem zainteresowany",
        "name": "Arek",
        "timestamp": 3274.99
    },
    {
        "message": "tak",
        "name": "Angelika",
        "timestamp": 3275.17
    },
    {
        "message": "Tak",
        "name": "Wiktoria",
        "timestamp": 3275.34
    },
    {
        "message": "tak",
        "name": "Janusz",
        "timestamp": 3275.48
    },
    {
        "message": "tak",
        "name": "Milena",
        "timestamp": 3275.66
    },
    {
        "message": "Tak",
        "name": "Konrad",
        "timestamp": 3275.83
    },
    {
        "message": "tak!",
        "name": "Katarzyna",
        "timestamp": 3276.01
    },
    {
        "message": "Jestem zainteresowany",
        "name": "Tobiasz",
        "timestamp": 3276.18
    },
    {
        "message": "Poproszę ofertę",
        "name": "Magdalena",
        "timestamp": 3276.33
    },
    {
        "message": "Tak!",
        "name": "Norbert",
        "timestamp": 3276.52
    },
    {
        "message": "Chętnie",
        "name": "Izabela",
        "timestamp": 3276.69
    },
    {
        "message": "Jestem zainteresowana",
        "name": "Maria",
        "timestamp": 3276.84
    },
    {
        "message": "tak",
        "name": "Przemek",
        "timestamp": 3277.02
    },
    {
        "message": "tak",
        "name": "Sandra",
        "timestamp": 3277.18
    },
    {
        "message": "tak",
        "name": "Jarek",
        "timestamp": 3277.36
    },
    {
        "message": "Tak",
        "name": "Kamil",
        "timestamp": 3277.49
    },
    {
        "message": "Tak!",
        "name": "Ania",
        "timestamp": 3277.64
    },
    {
        "message": "tak 👍",
        "name": "Olivier",
        "timestamp": 3277.82
    },
    {
        "message": "tak",
        "name": "Daria",
        "timestamp": 3277.94
    },
    {
        "message": "Poproszę ofertę",
        "name": "Sebastian",
        "timestamp": 3278.11
    },
    {
        "message": "Tak!",
        "name": "Martyna",
        "timestamp": 3278.27
    },
    {
        "message": "Chętnie",
        "name": "Michał",
        "timestamp": 3278.46
    },
    {
        "message": "Tak",
        "name": "Wojciech",
        "timestamp": 3278.64
    },
    {
        "message": "tak",
        "name": "Karolina",
        "timestamp": 3278.79
    },
    {
        "message": "tak",
        "name": "Hubert",
        "timestamp": 3278.97
    },
    {
        "message": "Tak!",
        "name": "Hanna",
        "timestamp": 3279.12
    },
    {
        "message": "Tak",
        "name": "Anita",
        "timestamp": 3279.28
    },
    {
        "message": "Poproszę",
        "name": "Patrycja",
        "timestamp": 3279.46
    },
    {
        "message": "Tak",
        "name": "Henryk",
        "timestamp": 3279.64
    },
    {
        "message": "Jestem zainteresowany",
        "name": "Kazimierz",
        "timestamp": 3279.81
    },
    {
        "message": "Tak, poproszę!",
        "name": "Ela",
        "timestamp": 3279.95
    },
    {
        "message": "Chętnie",
        "name": "Szymon",
        "timestamp": 3280.14
    },
    {
        "message": "Tak!",
        "name": "Łucja",
        "timestamp": 3280.33
    },
    {
        "message": "Poproszę ofertę",
        "name": "Aleksander",
        "timestamp": 3280.52
    },
    {
        "message": "tak!",
        "name": "Iwona",
        "timestamp": 3280.7
    },
    {
        "message": "tak",
        "name": "Bartosz",
        "timestamp": 3280.88
    },
    {
        "message": "tak",
        "name": "Urszula",
        "timestamp": 3281.09
    },
    {
        "message": "Tak!",
        "name": "Alicja",
        "timestamp": 3281.24
    },
    {
        "message": "tak",
        "name": "Tadeusz",
        "timestamp": 3281.41
    },
    {
        "message": "Poproszę ofertę",
        "name": "Lena",
        "timestamp": 3281.56
    },
    {
        "message": "Chętnie",
        "name": "Przemysław",
        "timestamp": 3281.72
    },
    {
        "message": "tak",
        "name": "Marek",
        "timestamp": 3281.91
    },
    {
        "message": "Tak!",
        "name": "Dorota",
        "timestamp": 3282.05
    },
    {
        "message": "Chętnie skorzystam",
        "name": "Ewelina",
        "timestamp": 3282.2
    },
    {
        "message": "Tak",
        "name": "Danuta",
        "timestamp": 3282.37
    },
    {
        "message": "Tak",
        "name": "Nikodem",
        "timestamp": 3282.54
    },
    {
        "message": "Poproszę ofertę",
        "name": "Zofia",
        "timestamp": 3282.7
    },
    {
        "message": "Tak!",
        "name": "Artur",
        "timestamp": 3282.88
    },
    {
        "message": "zainteresowany",
        "name": "Arkadiusz",
        "timestamp": 3283.05
    },
    {
        "message": "Chętnie",
        "name": "Gabriela",
        "timestamp": 3283.22
    },
    {
        "message": "tak, poproszę ofertę",
        "name": "Zbigniew",
        "timestamp": 3283.39
    },
    {
        "message": "Tak",
        "name": "Halina",
        "timestamp": 3283.58
    },
    {
        "message": "Tak",
        "name": "Wioletta",
        "timestamp": 3283.76
    },
    {
        "message": "Chętnie skorzystam",
        "name": "Paula",
        "timestamp": 3283.92
    },
    {
        "message": "Poproszę ofertę!",
        "name": "Dawid",
        "timestamp": 3284.08
    },
    {
        "message": "Jestem zainteresowany",
        "name": "Robert",
        "timestamp": 3284.26
    },
    {
        "message": "Tak, tak",
        "name": "Agata",
        "timestamp": 3284.42
    },
    {
        "message": "Chętnie",
        "name": "Radek",
        "timestamp": 3284.59
    },
    {
        "message": "Poproszę ofertę",
        "name": "Edyta",
        "timestamp": 3284.77
    },
    {
        "message": "tak, poproszę",
        "name": "Jan",
        "timestamp": 3284.93
    },
    {
        "message": "Jestem zainteresowana",
        "name": "Renata",
        "timestamp": 3285.12
    },
    {
        "message": "Tak!",
        "name": "Ola",
        "timestamp": 3285.31
    },
    {
        "message": "Chętnie skorzystam",
        "name": "Sylwester",
        "timestamp": 3285.47
    },
    {
        "message": "Poproszę ofertę",
        "name": "Emilia",
        "timestamp": 3285.66
    },
    {
        "message": "tak",
        "name": "Marlena",
        "timestamp": 3285.84
    },
    {
        "message": "Jestem zainteresowany",
        "name": "Dariusz",
        "timestamp": 3286.02
    },
    {
        "message": "Chętnie",
        "name": "Jadwiga",
        "timestamp": 3286.21
    },
    {
        "message": "Tak!",
        "name": "Adrianna",
        "timestamp": 3286.39
    },
    {
        "message": "Jestem zainteresowana",
        "name": "Teresa",
        "timestamp": 3286.56
    },
    {
        "message": "tak, tak",
        "name": "Krzysztof",
        "timestamp": 3286.72
    },
    {
        "message": "Poproszę ofertę",
        "name": "Ilona",
        "timestamp": 3286.88
    },
    {
        "message": "Chętnie skorzystam",
        "name": "Olek",
        "timestamp": 3287.04
    },
    {
        "message": "Jestem zainteresowany",
        "name": "Albert",
        "timestamp": 3287.22
    },
    {
        "message": "tak!",
        "name": "Beata",
        "timestamp": 3287.36
    },
    {
        "message": "Poproszę ofertę",
        "name": "Sebastian",
        "timestamp": 3287.51
    },
    {
        "message": "Chętnie",
        "name": "Maria",
        "timestamp": 3287.67
    },
    {
        "message": "Jestem zainteresowana",
        "name": "Hanna",
        "timestamp": 3287.82
    },
    {
        "message": "Tak, tak",
        "name": "Patryk",
        "timestamp": 3287.96
    },
    {
        "message": "Chętnie skorzystam",
        "name": "Judyta",
        "timestamp": 3288.15
    },
    {
        "message": "Poproszę ofertę!",
        "name": "Kacper",
        "timestamp": 3288.33
    },
    {
        "message": "Jestem zainteresowany",
        "name": "Tomasz",
        "timestamp": 3288.47
    },
    {
        "message": "Tak!",
        "name": "Elżbieta",
        "timestamp": 3288.64
    },
    {
        "message": "Chętnie",
        "name": "Aleksandra",
        "timestamp": 3288.8
    },
    {
        "message": "Poproszę ofertę",
        "name": "Maciej",
        "timestamp": 3288.95
    },
    {
        "message": "Jestem zainteresowany",
        "name": "Janek",
        "timestamp": 3289.11
    },
    {
        "message": "Tak!",
        "name": "Ewelina",
        "timestamp": 3289.28
    },
    {
        "message": "tak, tak",
        "name": "Igor",
        "timestamp": 3289.46
    },
    {
        "message": "Poproszę ofertę",
        "name": "Lidia",
        "timestamp": 3289.66
    },
    {
        "message": "Chętnie skorzystam",
        "name": "Oliwia",
        "timestamp": 3289.79
    },
    {
        "message": "Jestem zainteresowana",
        "name": "Julia",
        "timestamp": 3289.95
    },
    {
        "message": "Tak",
        "name": "Wiktor",
        "timestamp": 3290.12
    },
    {
        "message": "tak, tak!",
        "name": "Celina",
        "timestamp": 3290.27
    },
    {
        "message": "Jestem zainteresowany",
        "name": "Piotr",
        "timestamp": 3290.43
    },
    {
        "message": "Poproszę ofertę",
        "name": "Anita",
        "timestamp": 3290.6
    },
    {
        "message": "Chętnie",
        "name": "Krzysztof",
        "timestamp": 3290.78
    },
    {
        "message": "Jestem zainteresowana",
        "name": "Magda",
        "timestamp": 3290.94
    },
    {
        "message": "Tak!",
        "name": "Mariusz",
        "timestamp": 3291.12
    },
    {
        "message": "Poproszę ofertę",
        "name": "Ania",
        "timestamp": 3291.3
    },
    {
        "message": "Jestem zainteresowany",
        "name": "Mateusz",
        "timestamp": 3291.44
    },
    {
        "message": "Chętnie",
        "name": "Beata",
        "timestamp": 3291.58
    },
    {
        "message": "tak",
        "name": "Waldek",
        "timestamp": 3291.76
    },
    {
        "message": "Zainteresowana",
        "name": "Gabriela",
        "timestamp": 3291.93
    },
    {
        "message": "Tak!",
        "name": "Iga",
        "timestamp": 3292
    },
    {
        "message": "Poproszę ofertę",
        "name": "Sebastian",
        "timestamp": 3293
    },
    {
        "message": "Jestem zainteresowany",
        "name": "Hanna",
        "timestamp": 3294
    },
    {
        "message": "Tak, tak!",
        "name": "Rafał",
        "timestamp": 3295
    },
    {
        "message": "Chętnie",
        "name": "Edyta",
        "timestamp": 3296
    },
    {
        "message": "Poproszę",
        "name": "Patrycja",
        "timestamp": 3297
    },
    {
        "message": "tak!",
        "name": "Tomasz",
        "timestamp": 3298
    },
    {
        "message": "Poproszę ofertę!",
        "name": "Alicja",
        "timestamp": 3299
    },
    {
        "message": "Jaka cena?",
        "name": "Anita",
        "timestamp": 3399.1
    },
    {
        "message": "tak!",
        "name": "Piotr",
        "timestamp": 3301
    },
    {
        "message": "prosze o szczegoły",
        "name": "Adrian",
        "timestamp": 3302
    },
    {
        "message": "super!",
        "name": "Agnieszka",
        "timestamp": 3304
    },
    {
        "message": "Poproszę o więcej informacji.",
        "name": "Monika",
        "timestamp": 3306
    },
    {
        "message": "Nie mogę się doczekać.",
        "name": "Monika",
        "timestamp": 3310
    },
    {
        "message": "PO takim webinarze biorę w ciemno",
        "name": "Anna",
        "timestamp": 3314
    },
    {
        "message": "Super!",
        "name": "Artur",
        "timestamp": 3320
    },
    {
        "message": "Czy to są zajęcia z panem indyiwudalne?",
        "name": "Bogumiła",
        "timestamp": 3323
    },
    {
        "message": "Syn skończył pana kurs parę dni temu i to była najlepsza decyzja w życiu. Dziękuję",
        "name": "Patrycja",
        "timestamp": 3327
    },
    {
        "message": "Poproszę więcej informacji Panie MIkołaju!",
        "name": "Monika",
        "timestamp": 3330.4
    },
    {
        "message": "Ja nie jestem zainteresowany. Dziękuję.",
        "name": "Adam",
        "timestamp": 3334
    },
    {
        "message": "Proszę o podanie ceny",
        "name": "Michał",
        "timestamp": 3335.4
    },
    {
        "message": "Chętnie weźmiemy udział",
        "name": "Anita",
        "timestamp": 3338
    },
    {
        "message": "biorę od razu. jak można zapisać dziecko?",
        "name": "Małgorzata",
        "timestamp": 3340
    },
    {

        "message": "czy ma pan kurs rowniez dla doroslych??",
        "name": "Andrzej",
        "timestamp": 3347
    },
    {
        "message": "jestem zainteresowana",
        "name": "Anna",
        "timestamp": 3355
    },
    {
        "message": "ja też",
        "name": "Katarzyna",
        "timestamp": 3360
    },
    {
        "message": "też. czekam na więcej informacji",
        "name": "Jakub",
        "timestamp": 3364
    },
    {
        "message": "Córka ukończyła Kurs miesiąc temu i w końcu nie muszę nad nią stać i planować jej nauki. Jej oceny również się podniosły i w końcu zaczęła spać tyle ile trzeba. Polecam!!",
        "name": "Marta",
        "timestamp": 3365
    },
    {
        "message": "wow. super",
        "name": "Katarzyna",
        "timestamp": 3373
    },
    {
        "message": "dziękuję za polecenie. czekam na więcej szczegołow.",
        "name": "Monika",
        "timestamp": 3376
    },
    {
        "message": "jestem zainteresowany",
        "name": "Paweł",
        "timestamp": 3380
    },
    {
        "message": "jak wykupić dostęp??",
        "name": "Patryk",
        "timestamp": 3385
    },
    {
        "message": "Już kupiłam kurs 3 tygodnie temu. Syn bardzo zadowolony i ja również widzę realne postępy. Dziękuję za tyle wartościowej wiedzy i do widzenia.",
        "name": "Magda",
        "timestamp": 3390
    },
    {
        "message": "Czy dostaniemy dostęp do slajdów?",
        "name": "Antoni",
        "timestamp": 3399
    },
    {
        "message": "na końcu ma byc",
        "name": "Maria",
        "timestamp": 3405
    },
    {
        "message": "supre",
        "name": "Adam",
        "timestamp": 3409
    },
    {
        "message": "dziękuję",
        "name": "Mariusz",
        "timestamp": 3415
    },
    {
        "message": "gdzie się kupuje kurs?",
        "name": "Weronika",
        "timestamp": 3420.5
    },
    {
        "message": "też czekam na więcej informacji o zakupie",
        "name": "Żaneta",
        "timestamp": 3430
    },
    {
        "message": "Córka ma za tydzień urodziny. Będzie świetny prezent :)",
        "name": "Pola",
        "timestamp": 3437
    },
    {
        "message": "Czy w kursie jest coś o uniezależnieniu się od telefonu?",
        "name": "Arek",
        "timestamp": 3445
    },
    {
        "message": "Można wrócić do poprzedniego slajdu?",
        "name": "Marek",
        "timestamp": 3448
    },
    {
        "message": "Poproszę o ofertę.",
        "name": "Monika",
        "timestamp": 3450
    },
    {
        "message": "Gdzie się kupuje ten kurs?",
        "name": "M.",
        "timestamp": 3455
    },
    {
        "message": "Na mnie już czas. Dziękuję wam",
        "name": "Marcin",
        "timestamp": 3459
    },
    {
        "message": "Brzmi świetnie:)",
        "name": "Grazyna",
        "timestamp": 3465
    },
    {
        "message": "Czy to jest możliwe?",
        "name": "Gracjan",
        "timestamp": 3470
    },
    {
        "message": "Super!",
        "name": "Rafał",
        "timestamp": 3476
    },
    {
        "message": "Ten telefon to u nas dramat",
        "name": "Arkadiusz",
        "timestamp": 3485
    },
    {
        "message": "Poproszę o ofertę.",
        "name": "Ania",
        "timestamp": 3495
    },
    {
        "message": "Czy jest ktoś kto wykupił kurs? Warto?",
        "name": "Elżbieta",
        "timestamp": 3505
    },
    {
        "message": "nie wiem",
        "name": "M.",
        "timestamp": 3510
    },
    {
        "message": "Kupiłam 2 miesiące temu. Warto",
        "name": "Marta",
        "timestamp": 3515
    },
    {
        "message": "Warto. Zdecydowanie.",
        "name": "Kacper",
        "timestamp": 3517
    },
    {
        "message": "Córka bardzo zadowolona. Już po 2 tygodniach widać rezultaty. Myślę, że warto.",
        "name": "Martyna",
        "timestamp": 3523
    },
    {
        "message": "My po miesiącu współpracy zrezygnowaliśmy z korepetycji i kurs się szybko zwrócił. Zdecydowanie polecam",
        "name": "Monika",
        "timestamp": 3530
    },
    {
        "message": "U nas syn sceptyczny był i dopiero po 3 tygodniach zaczął przerabiać Kurs, ale po tym czasie zmienił się nie do poznania i nauka zaczeła mu w knocu sprawiac przyjemnosc. Bylo warto. ",
        "name": "Cecylia",
        "timestamp": 3536
    },
    {
        "message": "Dziękuję. W takim razie jestem zainteresowana.",
        "name": "Elżbieta",
        "timestamp": 3545
    },
    {
        "message": "Tutaj podobnie. Kurs przerobiony, syn świetnie napisał maturę i dostał się na medycynę we wrocławiu. Czekam na sekcję Q&A.",
        "name": "Marek",
        "timestamp": 3550
    },
    {
        "message": "super. ",
        "name": "Janusz",
        "timestamp": 3564
    },
    {
        "message": "gdize można uzsykać dostęp??",
        "name": "Bogna",
        "timestamp": 3580
    },
    {
        "message": "a kiedy ten dokument best self dostaniemy?",
        "name": "Bartek",
        "timestamp": 3593
    },
    {
        "message": "na końcu",
        "name": "Marek",
        "timestamp": 3600
    },
    {
        "message": "dziękuję",
        "name": "Bartek",
        "timestamp": 3610
    },
    {
        "message": "o ale fajnie, proszę pokazać",
        "name": "Anita",
        "timestamp": 3625
    },
    {
        "message": "wygląda to naprawdę fajnie. Jaka cena i na ile jest dostęp?",
        "name": "Anna",
        "timestamp": 3640
    },
    {
        "message": "wow. czy ma pan tez program dla doroslych?",
        "name": "Magda",
        "timestamp": 3654
    },
    {
        "message": "chyba mikolaj mowil ze na cale zycie jest",
        "name": "Małgosia",
        "timestamp": 3662
    },
    {
        "message": "Jestem zainteresowana.",
        "name": "Anita",
        "timestamp": 3679
    },
    {
        "message": "Jak otrzymać dostęp?",
        "name": "Wojciech",
        "timestamp": 3689
    },
    {
        "message": "Zapowiada się świetnie. Dziś jest dzień, w którym coś się zmieni 🥳",
        "name": "Malwina",
        "timestamp": 3700
    },
    {
        "message": "Super!",
        "name": "Ola",
        "timestamp": 3701
    },
    {
        "message": "Super webinar to i kurs pewnie bedzie super",
        "name": "Grażyna",
        "timestamp": 3710
    },
    {
        "message": "Wygląda to bardzo profesjonalnie. Jestem zainteresowany",
        "name": "Bartłomiej",
        "timestamp": 3714
    },
    {
        "message": "Ale fajnie",
        "name": "Anita",
        "timestamp": 3725
    },
    {
        "message": "Wszystko wygląda fajnie. Jak uzyskać dostęp?",
        "name": "Agata",
        "timestamp": 3734
    },
    {
        "message": "A jak wyglądają inne tygodnie?",
        "name": "Anna",
        "timestamp": 3740
    },
    {
        "message": "Kurs trwa 9 tygodni?",
        "name": "Monika",
        "timestamp": 3748
    },
    {
        "message": "tak",
        "name": "Adam",
        "timestamp": 3754
    },
    {
        "message": "Podobno tak",
        "name": "Malwina",
        "timestamp": 3755
    },
    {
        "message": "Nie mogę się doczekać.",
        "name": "Marcin",
        "timestamp": 3765
    },
    {
        "message": "Córka najpierw była niechętna, ale po 2 tygodniach uznała, że jednak warto spróbować i widzę bardzo pozytywne rezultaty. W końcu nie siedzi ciągle w ekranach i uczy się systematycznie. Dziękuję.",
        "name": "Małgorzata",
        "timestamp": 3772
    },
    {
        "message": "Czy jako rodzic mogę korzystać z kursu?",
        "name": "Natalia",
        "timestamp": 3785
    },
    {
        "message": "Czekam na więcej informacji.",
        "name": "Marta",
        "timestamp": 3798
    },
    {
        "message": "Czekamy na podanie szczegółów",
        "name": "Piotr",
        "timestamp": 3805
    },
    {
        "message": "super",
        "name": "Marek",
        "timestamp": 3815
    },
    {
        "message": "zainteresowany.",
        "name": "Marcin",
        "timestamp": 3830
    },
    {
        "message": "Super.",
        "name": "Marcin",
        "timestamp": 3840
    },
    {
        "message": "Wygląda to super. ",
        "name": "Marlena",
        "timestamp": 3850
    },
    {
        "message": "Gdzie kupić kurs?",
        "name": "Marian",
        "timestamp": 3872
    },
    {
        "message": "brzmi obiecująco",
        "name": "Maria",
        "timestamp": 3900
    },
    {
        "message": "jejku to brzmi jakby miało rozwiązać wszystkie moje problemy.",
        "name": "Katarzyna",
        "timestamp": 3914
    },
    {
        "message": "chronotypy snu?",
        "name": "Kornelia",
        "timestamp": 3940
    },
    {
        "message": "A kiedy sie powinno uczyc?",
        "name": "Magda",
        "timestamp": 3953
    },
    {
        "message": "moze pan wiecej szczegolow podac?",
        "name": "Antek",
        "timestamp": 3962
    },
    {
        "message": "Kiedy nastolatkowie powinni sie uczyc?",
        "name": "Bartłomiej",
        "timestamp": 3979
    },
    {
        "message": "wszystko jasne",
        "name": "Maciej",
        "timestamp": 3998
    },
    {
        "message": "Ale ma pan dużą wiedzę. Wow!",
        "name": "Agnieszka",
        "timestamp": 4010
    },
    {
        "message": "Brzmi jak marzenie :)",
        "name": "Mariola",
        "timestamp": 4025
    },
    {
        "message": "A co jak w szkole nauczycieel nie tłumaczą nic?",
        "name": "Magdalena",
        "timestamp": 4039
    },
    {
        "message": "jestem zainteresowany.",
        "name": "Mariusz",
        "timestamp": 4060
    },
    {
        "message": "ile czasu zajmuje przerobienie kursu?",
        "name": "Anna",
        "timestamp": 4080
    },
    {
        "message": "Poproszę o ofertę",
        "name": "Magda",
        "timestamp": 4095
    },
    {
        "message": "Ma pan kurs dla dorosłych?",
        "name": "Natalia",
        "timestamp": 4110
    },
    {
        "message": "Brzmi to super.",
        "name": "Kornelia",
        "timestamp": 4114
    },
    {
        "message": "👍",
        "name": "Anita",
        "timestamp": 4135
    },
    {
        "message": "syn tylko spisuje zadania. mam nadzieje, ze wytlumaczy pan jak to robic skutecznie i sensownie",
        "name": "Wioletta",
        "timestamp": 4150
    },
    {
        "message": "Działa to u studentów?",
        "name": "Mariola",
        "timestamp": 4155
    },
    {
        "message": "ok już mam odpowiedź :)",
        "name": "Mariola",
        "timestamp": 4175
    },
    {
        "message": "super",
        "name": "Weronika",
        "timestamp": 4180
    },
    {
        "message": "zachęcenie syna do nauki... niemożliwe",
        "name": "Anita",
        "timestamp": 4194
    },
    {
        "message": "czy moge ten kurs dla siebie wykupic?",
        "name": "Marta",
        "timestamp": 4210
    },
    {
        "message": ":)",
        "name": "Arkadiusz",
        "timestamp": 4229
    },
    {
        "message": "czy można informacje gdzie kupić kurs?",
        "name": "Mariusz",
        "timestamp": 4240
    },
    {
        "message": "syn ma ogromne braki. jestem zainteresowana.",
        "name": "Lena",
        "timestamp": 4255
    },
    {
        "message": "córka chce isc na studia do stanów. czy kurs jej pomoze?",
        "name": "Marek",
        "timestamp": 4265
    },
    {
        "message": "super",
        "name": "M.",
        "timestamp": 4275
    },
    {
        "message": "Wygląda to naprawdę dobrze.",
        "name": "Weronika",
        "timestamp": 4289
    },
    {
        "message": "Ile ma pan doświadczenia?",
        "name": "Elżbieta",
        "timestamp": 4295
    },
    {
        "message": "gdzie ten kurs się kupuje?",
        "name": "Antoni",
        "timestamp": 4304
    },
    {
        "message": "ponad 5 lat, mowil napoczatku",
        "name": "Małgorzata",
        "timestamp": 4310
    },
    {
        "message": ".",
        "name": "Magda",
        "timestamp": 4322
    },
    {
        "message": "Super że jest to wsparcie.",
        "name": "Anna",
        "timestamp": 4335
    },
    {
        "message": "bardzo mi się podoba",
        "name": "Bogumiła",
        "timestamp": 4344
    },
    {
        "message": "brzmi to jak edukacja przyszlosci",
        "name": "Bartosz",
        "timestamp": 4355
    },
    {
        "message": "super!",
        "name": "Antoni",
        "timestamp": 4364
    },
    {
        "message": "jestem chętny",
        "name": "Adam",
        "timestamp": 4375
    },
    {
        "message": "woooow. to chyba najbardziej kompleksowy kurs jaki widzialam",
        "name": "Mariola",
        "timestamp": 4385
    },
    {
        "message": "prezentuje sie to swietnie. gdzie moge zapisac corke?",
        "name": "Natalia",
        "timestamp": 4396
    },
    {
        "message": "prawda",
        "name": "Ola",
        "timestamp": 4405
    },
    {
        "message": "👍",
        "name": "Piotr",
        "timestamp": 4415
    },
    {
        "message": "my wydajemy 2000zł miesięcznie prawie",
        "name": "Ada",
        "timestamp": 4425
    },
    {
        "message": "u nas 1400zł co miesiąc i wydaje się mało. Rezultay słabe.",
        "name": "Karol",
        "timestamp": 4430
    },
    {
        "message": "chce w koncu skonczyc z korpeetycjami. jestem zainteresowana.",
        "name": "Monika",
        "timestamp": 4439
    },
    {
        "message": "biorę.",
        "name": "Marta",
        "timestamp": 4448
    },
    {
        "message": "Jestem chętna",
        "name": "Agnieszka",
        "timestamp": 4460
    },
    {
        "message": "Wow",
        "name": "Artur",
        "timestamp": 4475
    },
    {
        "message": "Ile?",
        "name": "Adam",
        "timestamp": 4476
    },
    {
        "message": "Biorę od razu.",
        "name": "Anita",
        "timestamp": 4480
    },
    {
        "message": "Po 3 miesiącach się zwraca. Jestem zainteresowana.",
        "name": "Ada",
        "timestamp": 4484
    },
    {
        "message": "Czy będzie jakaś promocja?",
        "name": "Anna",
        "timestamp": 4489
    },
    {
        "message": "nie mam 6000zł. czy jest jakaś zniżka dostępna?",
        "name": "Magdalena",
        "timestamp": 4494
    },
    {
        "message": "za drogo. za 2000 bym kupił ale 6000 to trochę za duzo na moje mozliwosci, chcoiaz bym chcial...",
        "name": "Artur",
        "timestamp": 4500
    },
    {
        "message": "myślałam że do 3000zł się uda zamknąć. chyba musze zrezygnowac",
        "name": "Anita",
        "timestamp": 4510
    },
    {
        "message": "trochę dużo",
        "name": "Weronika",
        "timestamp": 4515
    },
    {
        "message": "sporo ale jestem zainteresowany",
        "name": "Wojciech",
        "timestamp": 4522
    },
    {
        "message": "max 2000zl jestem w stanie zapłacić.",
        "name": "Marta",
        "timestamp": 4528
    },
    {
        "message": "Czy jest jakaś gwarancja?",
        "name": "Mariusz",
        "timestamp": 4538
    },
    {
        "message": "proszę chociaż połowę mniej",
        "name": "Anna",
        "timestamp": 4542
    },
    {
        "message": "jejku ale pan nas w napieciu trzyma",
        "name": "Eliza",
        "timestamp": 4548
    },
    {
        "message": "biorę",
        "name": "Ewa",
        "timestamp": 4558
    },
    {
        "message": "jestem zainteresowana",
        "name": "Marta",
        "timestamp": 4560
    },
    {
        "message": "jak wykupić dostęp?",
        "name": "Marcin",
        "timestamp": 4562
    },
    {
        "message": "a co jak to u mojego dziecka nie zadziała? czy jest jakaś gwarancja?",
        "name": "Wojciech",
        "timestamp": 4563
    },
    {
        "message": "Uff! Biorę od razu.",
        "name": "Anita",
        "timestamp": 4563.4
    },
    {
        "message": "O chciałem do 2000zł przeznaczyć. Super :) Jak mogę uzyskać dostęp?",
        "name": "Artur",
        "timestamp": 4567
    },
    {
        "message": "Czy można tę płatność na raty rozłożyć?",
        "name": "Monika",
        "timestamp": 4571
    },
    {
        "message": "jestem zainteresowany. Jak uzyskać dostęp?",
        "name": "Zbigniew",
        "timestamp": 4575
    },
    {
        "message": "Wojciech wykupił/a dostęp do Kursu.",
        "name": "Produkacja",
        "timestamp": 4578
    },
    {
        "message": "Pod tym webinarem jest przycisk do zakupu",
        "name": "Anna",
        "timestamp": 4582
    },
    {
        "message": "Przekonał mnie pan. Kupuję.",
        "name": "Marta",
        "timestamp": 4585
    },
    {
        "message": "Zbyszek wykupił/a dostęp do Kursu.",
        "name": "Produkacja",
        "timestamp": 4586
    },
    {
        "message": "Ewa wykupił/a dostęp do Kursu.",
        "name": "Produkacja",
        "timestamp": 4590
    },
    {
        "message": "Dostęp już wykupiony:)",
        "name": "Ewa",
        "timestamp": 4601
    },
    {
        "message": "Anita wykupił/a dostęp do Kursu.",
        "name": "Produkacja",
        "timestamp": 4602
    },
    {
        "message": "Marta wykupił/a dostęp do Kursu.",
        "name": "Produkacja",
        "timestamp": 4609
    },
    {
        "message": "Do kiedy jest dostępna promocja?",
        "name": "Eliza",
        "timestamp": 4610
    },
    {
        "message": "Czy raty sa dostepne?",
        "name": "Hubert",
        "timestamp": 4615
    },
    {
        "message": "Adam wykupił/a dostęp do Kursu.",
        "name": "Produkacja",
        "timestamp": 4620
    },
    {
        "message": "jestem zainteresowana ale mam pytanie - kiedy bedzie sekcja q&a?",
        "name": "Malwina",
        "timestamp": 4621
    },
    {
        "message": "Krzysztof wykupił/a dostęp do Kursu.",
        "name": "Produkacja",
        "timestamp": 4629
    },
    {
        "message": "Już wykupiłem dostęp. Wszystko zadziałało bez problemu. Super pierwsze wrażenie. Dziękuję Panie mikołaju",
        "name": "Zbyszek",
        "timestamp": 4630
    },
    {
        "message": "Czy zadziała to u dzieci z ADHD?",
        "name": "Anna",
        "timestamp": 4635
    },
    {
        "message": "Eliza wykupił/a dostęp do Kursu.",
        "name": "Produkacja",
        "timestamp": 4643
    },
    {
        "message": "Na jakim kursie programowania pan byl?",
        "name": "Krzysztof",
        "timestamp": 4644
    },
    {
        "message": "Kurs zakupiony. Oby było warto.",
        "name": "Adam",
        "timestamp": 4650
    },
    {
        "message": "do kiedy mam czas na decyzję?",
        "name": "Sylwia",
        "timestamp": 4660
    },
    {
        "message": "jestem zainteresowana ratami.",
        "name": "Agnieszka",
        "timestamp": 4668
    },
    {
        "message": "Kasia wykupił/a dostęp do Kursu.",
        "name": "Produkacja",
        "timestamp": 4674
    },
    {
        "message": "czy może pan nam wysłać już prezentację?",
        "name": "Ola",
        "timestamp": 4675
    },
    {
        "message": "Kupuję na raty. dziękuję",
        "name": "Anna",
        "timestamp": 4685
    },
    {
        "message": "ok",
        "name": "Magda",
        "timestamp": 4692
    },
    {
        "message": "proszę o zachowanie jednego miesjca. mam problem z bankiem",
        "name": "Hanna",
        "timestamp": 4697
    },
    {
        "message": "Kiedy dostaniemy dostęp do slajdów?",
        "name": "Katarzyna",
        "timestamp": 4704
    },
    {
        "message": "Już aktywowałam dostęp do kursu. Dziękuję. Córka będzie miała miły prezent.",
        "name": "Eliza",
        "timestamp": 4715
    },
    {
        "message": "Coś czuję, żę więcej z tego kursu ja wyniosę niż córka :) ",
        "name": "Tomasz",
        "timestamp": 4721
    },
    {
        "message": "Jak z kursem radzą sobie dzieci z ADHD?",
        "name": "Marta",
        "timestamp": 4724
    },
    {
        "message": "Jakie są alternatywy dla korepetycji? ",
        "name": "Monika",
        "timestamp": 4727
    },
    {
        "message": "Czy dla 8 klasisty zadziała?",
        "name": "Antoni",
        "timestamp": 4728
    },
    {
        "message": "Możesz jeszcze raz wyjaśnić, jak wykształcić nawyk nauki?",
        "name": "Marta",
        "timestamp": 4732
    },
    {
        "message": "Jak wygląda proces dołączania do Kursu?",
        "name": "Marcin",
        "timestamp": 4740
    },
    {
        "message": "Jak kupić kurs na raty?",
        "name": "Lidia",
        "timestamp": 4742
    },
    {
        "message": "W. wykupił/a dostęp do Kursu.",
        "name": "Produkacja",
        "timestamp": 4745
    },
    {
        "message": "Co jak synowi się nic nie chce? Jak mogę mu pomóć?",
        "name": "Łukasz",
        "timestamp": 4750
    },
    {
        "message": "Jak zachęcić dziecko do przerabiania kursu?",
        "name": "Magdalena",
        "timestamp": 4754
    },
    {
        "message": "Ile trwa przerobienie kursu?",
        "name": "Filip",
        "timestamp": 4760
    },
    {
        "message": "Jak pomóc dziecku kiedy nie chce mnie słuchać?",
        "name": "Michał",
        "timestamp": 4767
    },
    {
        "message": "Wykupiłam dostęp. Dziękuję. 🙏",
        "name": "W.",
        "timestamp": 4780
    },
    {
        "message": "Jak z kursem radzą sobie dzieci z adhd?",
        "name": "Pola",
        "timestamp": 4789
    },
    {
        "message": "Jak z korepetycji zrezygnować bezproblemowo?",
        "name": "Małgorzata",
        "timestamp": 4798
    },
    {
        "message": "Ile czasu zajmuje przerobienie Kursu?",
        "name": "Lidia",
        "timestamp": 4810
    },
    {
        "message": "Czekam na prezentację i slajdy.",
        "name": "Ania",
        "timestamp": 4825
    },
    {
        "message": "Czy zakup na raty jest możliwy?",
        "name": "Marian",
        "timestamp": 4844
    },
    {
        "message": "Czy z kursem poradzi sobie 12-latek?",
        "name": "Anita",
        "timestamp": 4861
    },
    {
        "message": "Ma to sens",
        "name": "Maria",
        "timestamp": 4880
    },
    {
        "message": "Julia wykupił/a dostęp do Kursu.",
        "name": "Produkacja",
        "timestamp": 4882
    },
    {
        "message": "no tak",
        "name": "Hubert",
        "timestamp": 4900
    },
    {
        "message": "Jak wygląda proces dołączania do Kursu?",
        "name": "Marcin",
        "timestamp": 4910
    },
    {
        "message": "Czy można kupić teraz i zacząć kurs za 2 tygodnie?",
        "name": "Piotr",
        "timestamp": 4921
    },
    {
        "message": "Dziękuję bardzo za taką szczegółową odpowiedź :)",
        "name": "Monika",
        "timestamp": 4940
    },
    {
        "message": "Ile trwają filmy w kursie?",
        "name": "Celina",
        "timestamp": 4940.4
    },
    {
        "message": "Dostęp wykupiony. Dziekuję Panu za dzisiaj. Musze uciekac. ",
        "name": "Julia",
        "timestamp": 4952.4
    },
    {
        "message": "Czy z kursu moga korzystac tez rodzice?",
        "name": "Arkadiusz",
        "timestamp": 4960
    },
    {
        "message": "tez musze juz wychodzic. dziekuje",
        "name": "Ania",
        "timestamp": 4964
    },
    {
        "message": "moje dziecko nie ma motywacji. jak moge mu pomoc? czy oplaca sie kupic kurs?",
        "name": "Daria",
        "timestamp": 4972
    },
    {
        "message": "czy dostep do kursu jest tez dla rodzica?",
        "name": "Adam",
        "timestamp": 4972.5
    },
    {
        "message": "Czy mógłbyś pokazać, jak wygląda Kurs i jeszcze raz omówić, co jest w nim zawarte? \n",
        "name": "Teresa",
        "timestamp": 4977.5
    },
    {
        "message": "Rafał wykupił/a dostęp do Kursu.",
        "name": "Produkacja",
        "timestamp": 4980
    },
    {
        "message": "Jak motywowac dziecko do dzialania?",
        "name": "Monika",
        "timestamp": 4989.5
    },
    {
        "message": "Czy można kupić teraz i zacząć później?",
        "name": "Magda",
        "timestamp": 5000
    },
    {
        "message": "jak z kursem radza sobie dzieci z adhd? ",
        "name": "Joanna",
        "timestamp": 5010
    },
    {
        "message": "juz mi mozg wybucha. ",
        "name": "Andrzej",
        "timestamp": 5024
    },
    {
        "message": "Julia wykupił/a dostęp do Kursu.",
        "name": "Produkacja",
        "timestamp": 5028
    },
    {
        "message": "do kiedy potrwa webinar?",
        "name": "Kuba",
        "timestamp": 5039
    },
    {
        "message": "kurs wykupiony, czekam na sladjy i uciekam",
        "name": "Rafał",
        "timestamp": 5045
    },
    {
        "message": "czemu tego w szkole nie ucza?",
        "name": "Roman",
        "timestamp": 5055
    },
    {
        "message": "wow",
        "name": "Pola",
        "timestamp": 5067
    },
    {
        "message": "przekonał mnie pan. kupuję.",
        "name": "Adrianna",
        "timestamp": 5080
    },
    {
        "message": "Ale super to brzmi. Mam nadzieję, że córka bę∂zie chciała skorzystać.",
        "name": "Anna",
        "timestamp": 5090
    },
    {
        "message": "Dziękuję. Czy można prosićo ",
        "name": "Bogdan",
        "timestamp": 5101
    },
    {
        "message": "ile zostało jeszcze miejsc?",
        "name": "Ola",
        "timestamp": 5115
    },
    {
        "message": "U syna wyszło że jest lwem i po tym jak zaczal wstawac godzine szybciej czuje sie znacznie lepiej i w szkole lepiej mu sie uczy.",
        "name": "Łukasz",
        "timestamp": 5129
    },
    {
        "message": "jak corka nie skorzysta to przynajmniej ja bede miala:)",
        "name": "Lena",
        "timestamp": 5140
    },
    {
        "message": "Czy można płatność na raty rozłożyć?",
        "name": "Janusz",
        "timestamp": 5150
    },
    {
        "message": "Ciekawie to brzmi",
        "name": "Magdalena",
        "timestamp": 5170
    },
    {
        "message": "Adrianna wykupił/a dostęp do Kursu.",
        "name": "Produkacja",
        "timestamp": 5175
    },
    {
        "message": "Świetne założenia.",
        "name": "Michał",
        "timestamp": 5194
    },
    {
        "message": "Tak to AI to koszmar",
        "name": "Mateusz",
        "timestamp": 5210
    },
    {
        "message": "syn tylko przepisuje zadania",
        "name": "Elżbieta",
        "timestamp": 5220
    },
    {
        "message": "super że to bę∂zie omawiane. kurs na czasie",
        "name": "Anna",
        "timestamp": 5233
    },
    {
        "message": "Do kiedy trzeba podjać decyzje?",
        "name": "Adrian",
        "timestamp": 5250
    },
    {
        "message": "Wszystko super tylko jak zachęcic dziecko do regularnego uczenia sie?",
        "name": "Maria",
        "timestamp": 5274
    },
    {
        "message": "Czy można zacząć później?",
        "name": "Monika",
        "timestamp": 5290
    },
    {
        "message": "Da się raty 12 miesięcy??",
        "name": "Daniel",
        "timestamp": 5305
    },
    {
        "message": "Czy będzie pan odpowiadał na poprzednie pytania też?",
        "name": "Marlena",
        "timestamp": 5320
    },
    {
        "message": "Jak ciągle piszecie to nic dzinwego że nie jest w stanie odpowiedzieć na wszyskto.",
        "name": "Marek",
        "timestamp": 5331
    },
    {
        "message": "ma to sens",
        "name": "Michał",
        "timestamp": 5360
    },
    {
        "message": "nie piszcie nic przez chwile to moze wroci do poprzednich pytan",
        "name": "Andrzej",
        "timestamp": 5380
    },
    {
        "message": "Marek wykupił/a dostęp do Kursu.",
        "name": "Produkacja",
        "timestamp": 5382
    },
    {
        "message": "Czy dostaniemy pytania do najlepszej wersji siebie?",
        "name": "Kasia",
        "timestamp": 5400
    },
    {
        "message": "Czy płatność na raty jest możliwa?",
        "name": "Mariusz",
        "timestamp": 5410
    },
    {
        "message": "A jak zachęcić dziecko do przerabiania kursu?",
        "name": "Magda",
        "timestamp": 5430
    },
    {
        "message": "Poproszę o informacje o płatności na raty.",
        "name": "Natalia",
        "timestamp": 5459
    },
    {
        "message": "Jak z kursem radzą sobie dzieci z adhd??",
        "name": "Bogumiła",
        "timestamp": 5480
    },
    {
        "message": "Dziękuję za wszystko. Muszę lecieć.",
        "name": "Antek",
        "timestamp": 5500
    },
    {
        "message": "Ile zostało jeszcze miejsc??",
        "name": "Marta",
        "timestamp": 5518
    },
    {
        "message": "A ile jeszcze zostało czasu?",
        "name": "Beata",
        "timestamp": 5545
    },
    {
        "message": "Proszę o informacją o ratach",
        "name": "Grzesiek",
        "timestamp": 5549
    },
    {
        "message": "Czy muszę kupować dostęp do kursu teraz?",
        "name": "Andrzej",
        "timestamp": 5570
    },
    {
        "message": "Marcin wykupił/a dostęp do Kursu.",
        "name": "Produkacja",
        "timestamp": 5573
    },
    {
        "message": "Czy można wrócić do tematu o nawyku nauki?",
        "name": "Ania",
        "timestamp": 5585
    },
    {
        "message": "Jak wyrobić nawyk nauki?",
        "name": "Patrycja",
        "timestamp": 5600
    },
    {
        "message": "Tak poprosimy o temacie nawyku nauki.",
        "name": "Monika",
        "timestamp": 5605
    },
    {
        "message": "Dziękuje panu bardzo za odpowiedź. W takim razie kupuję na raty. DzieŻuje",
        "name": "Andrzej",
        "timestamp": 5620
    },
    {
        "message": "A do kiedy jest znizka?",
        "name": "Marek",
        "timestamp": 5645
    },
    {
        "message": "Czy można prosić informację o tym jak z kursem radza sobie dzieci z ADHD?",
        "name": "Edyta",
        "timestamp": 5650
    },
    {
        "message": "jak pomoc dziecku ktore nie ma motywacji?",
        "name": "Ewa",
        "timestamp": 5665
    },
    {
        "message": "Jak Wasze metody sprawdzają się w przypadku dzieci z adhd",
        "name": "Joanna",
        "timestamp": 5680
    },
    {
        "message": "wow. dziekuje za taka solidna dawke wiedzy.",
        "name": "Arkadiusz",
        "timestamp": 5700
    },
    {
        "message": "czy tego tez uczycie w kursie?",
        "name": "Adrian",
        "timestamp": 5702
    },
    {
        "message": "Czy mogę zapłacić teraz i zacząć później? Córka nie będzie miała czasu na przerabianie kursu w najbliższych dniach?",
        "name": "Ewa",
        "timestamp": 5718
    },
    {
        "message": "syn jest niechętny",
        "name": "Oliwia",
        "timestamp": 5725
    },
    {
        "message": "Syn póki co niezainteresowany. Jednak jestem zdecydowana na kurs. Wierzę, że jest tak jak pan mówi i wróci do niego jak uzna, że to ma sens.",
        "name": "Elżbieta",
        "timestamp": 5740
    },
    {
        "message": "u nas tak było i po miesiacu sie otworzyl a potem juz z gorki poszlo. Super jest to, ze kurs jest na cale zycie.",
        "name": "Marcin",
        "timestamp": 5760
    },
    {
        "message": "Co jeżeli syn nie ma motywacji i pewnie nie będzie mu się chciało oglądać tego kursu? Jak wtedy mogę mu pomóc?",
        "name": "Natalia",
        "timestamp": 5775
    },
    {
        "message": "agnieszka wykupił/a dostęp do Kursu.",
        "name": "Produkacja",
        "timestamp": 5775.45
    },
    {
        "message": "Dziękuję, spróbujmy.",
        "name": "Joanna",
        "timestamp": 5776
    },
    {
        "message": "U córki całkowita niechęć i brak motywajci. Co wtedy?",
        "name": "Cecylia",
        "timestamp": 5785
    },
    {
        "message": "Jak dzialaj raty?",
        "name": "Artur",
        "timestamp": 5810
    },
    {
        "message": "Przekonał mnie pan.",
        "name": "Łukasz",
        "timestamp": 5830
    },
    {
        "message": "Bogumiła wykupił/a dostęp do Kursu.",
        "name": "Produkacja",
        "timestamp": 5836
    },
    {
        "message": "Czy kurs jest dla 8 klasistow wskazany??",
        "name": "Anita",
        "timestamp": 5858
    },
    {
        "message": "Czy jest jakiś minimalny wiek?",
        "name": "Rafał",
        "timestamp": 5880
    },
    {
        "message": "U mnie syn niechętny niestety",
        "name": "Eliza",
        "timestamp": 5900
    },
    {
        "message": "U nas tak samo",
        "name": "Katarzyna",
        "timestamp": 5915
    },
    {
        "message": "Podobnie. Jednak kupiłam dostęp bo jest na całe życie i wierzę, że w końcu z niego skorzysta.",
        "name": "Bogumiła",
        "timestamp": 5929
    },
    {
        "message": "Tak samo mamy z córką. Jednak te argumenty mnie przekonały, a jak będzie całkowicie niechętna to samemu ten kurs przerobię :)",
        "name": "Adrian",
        "timestamp": 5945
    },
    {
        "message": "Ma to sens",
        "name": "Grzegorz",
        "timestamp": 5965
    },
    {
        "message": "ach Ci nastolatkowie :)",
        "name": "Ewa",
        "timestamp": 6000
    },
    {
        "message": "Czy webinar jest nagrywany?",
        "name": "Bartlomiej",
        "timestamp": 6025
    },
    {
        "message": "U nas z synem było dokłądnie tak samo i przerobił cały kurs nie mówiąc nam nic",
        "name": "Aleksandra",
        "timestamp": 6045
    },
    {
        "message": "Ile osób już jest na kursie?",
        "name": "Beata",
        "timestamp": 6065
    },
    {
        "message": "Czyli dla osób z adhd warto kupować kurs?",
        "name": "Maria",
        "timestamp": 6081
    },
    {
        "message": "Moja córka nie potrafi się samodzielnie uczyć. Czy ten kurs jest dla niej?",
        "name": "Kamila",
        "timestamp": 6100
    },
    {
        "message": "Ale super",
        "name": "Łukasz",
        "timestamp": 6125
    },
    {
        "message": "W takim razie nie mogę się doczekać. Wierzę, żę podjęłam dobrą decyzję:)",
        "name": "Natalia",
        "timestamp": 6130
    },
    {
        "message": "Jestem zainteresowana płatnością na raty.",
        "name": "Kasia",
        "timestamp": 6145
    },
    {
        "message": "Muszę się zbierać. Czy można prosić o prezentację?",
        "name": "Ludmiła",
        "timestamp": 6160
    },
    {
        "message": "tez prosze o slajddy ",
        "name": "Adrainna",
        "timestamp": 6180
    },
    {
        "message": "do kiedy jest dostepna promocja?",
        "name": "Anita",
        "timestamp": 6195
    },
    {
        "message": "czyli dla studentow tez bedzie dzialalo?",
        "name": "Józef",
        "timestamp": 6109
    },
    {
        "message": "Czy ten program jest tylko dla licealistów?",
        "name": "Małgorzata",
        "timestamp": 6210
    },
    {
        "message": "katarzyna wykupił/a dostęp do Kursu.",
        "name": "Produkacja",
        "timestamp": 6213
    },
    {
        "message": "tak poprosimy",
        "name": "Anita",
        "timestamp": 6230
    },
    {
        "message": "Zaraz podeślę.",
        "name": "Marysia z Produkacji",
        "timestamp": 6240
    },
    {
        "message": "Dlaczego minimalny wiek to 12 lat?",
        "name": "Maria",
        "timestamp": 6245
    },
    {
        "message": "Czy 8 klasista moze brac udzial?",
        "name": "Marcin",
        "timestamp": 6247
    },
    {
        "message": "Muszę iśc. DZiękuje za dzisiaj",
        "name": "Marta",
        "timestamp": 6247.55
    },
    {
        "message": "Elżbieta wykupił/a dostęp do Kursu.",
        "name": "Produkacja",
        "timestamp": 6250
    },
    {
        "message": "Ile czasu zajmuje przerobienie kursu?",
        "name": "Krzysztof",
        "timestamp": 6280
    },
    {
        "message": "Slajdy:",
        "name": "Marysia z Produkacji",
        "timestamp": 6282
    },
    {
        "message": "https://produkacja.pl/slajdy",
        "name": "Marysia z Produkacji",
        "timestamp": 6283
    },
    {
        "message": "Dokument BEST Self",
        "name": "Marysia z Produkacji",
        "timestamp": 6288
    },
    {
        "message": "https://docs.google.com/document/d/1muaPqx4UlmK9taEOqH4Qxw8pWY-Eo317eyNV_bo-sL8/edit?usp=sharing",
        "name": "Marysia z Produkacji",
        "timestamp": 6289
    },
    {
        "message": "dziękujemy bardzo",
        "name": "Anna",
        "timestamp": 6300
    },
    {
        "message": "Monika wykupił/a dostęp do Kursu.",
        "name": "Produkacja",
        "timestamp": 6309
    },
    {
        "message": "jak zmotywowac syna do nauki?",
        "name": "Katarzyna",
        "timestamp": 6320
    },
    {
        "message": "Przesyłam jeszcze raz slajdy:",
        "name": "Marysia z Produkacji",
        "timestamp": 6321
    },
    {
        "message": "https://produkacja.pl/slajdy",
        "name": "Marysia z Produkacji",
        "timestamp": 6322
    },
    {
        "message": "I dokument BEST Self",
        "name": "Marysia z Produkacji",
        "timestamp": 6327
    },
    {
        "message": "https://docs.google.com/document/d/1muaPqx4UlmK9taEOqH4Qxw8pWY-Eo317eyNV_bo-sL8/edit?usp=sharing",
        "name": "Marysia z Produkacji",
        "timestamp": 6329
    },
    {
        "message": "wszystko wiem. jestem zdecydowana",
        "name": "Łucja",
        "timestamp": 6340
    },
    {
        "message": "wygląda pan na zmęczonego 😅",
        "name": "Agnieszka",
        "timestamp": 6350
    },
    {
        "message": "Bogumiła wykupił/a dostęp do Kursu.",
        "name": "Produkacja",
        "timestamp": 6354
    },
    {
        "message": "Dziękuję. Kupuję Kurs.",
        "name": "Eliza",
        "timestamp": 6370
    },
    {
        "message": "Wszystko zadziałało bez problemu. Córka ma już dostęp i zaczyna przerabiać kurs.",
        "name": "Elżbieta",
        "timestamp": 6380
    },
    {
        "message": "Dziękuję",
        "name": "Ewa",
        "timestamp": 6390
    },
    {
        "message": "Dziękuję Panu bardzo",
        "name": "Kasia",
        "timestamp": 6397
    },
    {
        "message": "Kupiłam",
        "name": "Bogumiła",
        "timestamp": 6403
    },
    {
        "message": "Dziękuję 🙏",
        "name": "Ludwik",
        "timestamp": 6403.5
    },
    {
        "message": "Jestem zainteresowana ale nie mam w tym momencie dostępnych środków. Czy mogę jeszcze 15min prosić?",
        "name": "Magdalena",
        "timestamp": 6405.5
    },
    {
        "message": "Czy dostaniemy nagrania?",
        "name": "Natalia",
        "timestamp": 6407
    },
    {
        "message": "Ile zostało miejsc jeszcze?",
        "name": "Kasia",
        "timestamp": 6410
    },
    {
        "message": "CIeszę się, że tu trafiłam, Było warto",
        "name": "Małgosia",
        "timestamp": 6413
    },
    {
        "message": "dziękuję panie mikołaju",
        "name": "Eliza",
        "timestamp": 6415
    },
    {
        "message": "było super. dziekuje",
        "name": "Kacper",
        "timestamp": 6418
    },
    {
        "message": "Małgorzata wykupił/a dostęp do Kursu.",
        "name": "Produkacja",
        "timestamp": 6419.4
    },
    {
        "message": "Dziękuję!",
        "name": "Agnieszka",
        "timestamp": 6420.05
    },
    {
        "message": "dziekuje panie Mikołaju",
        "name": "Krzysztof",
        "timestamp": 6420.28
    },
    {
        "message": "dziękuję Panie Mikołaju",
        "name": "Ewelina",
        "timestamp": 6421.03
    },
    {
        "message": "było super, polecam",
        "name": "Piotr",
        "timestamp": 6421.72
    },
    {
        "message": "dziękuję bardzo",
        "name": "Karolina",
        "timestamp": 6421.99
    },
    {
        "message": "dziekuje panie mikolaju, dużo się nauczyłem",
        "name": "Paweł",
        "timestamp": 6422.13
    },
    {
        "message": "dużo wartościowej wiedzy",
        "name": "Magda",
        "timestamp": 6423.36
    },
    {
        "message": "Dziękuję!",
        "name": "Olek",
        "timestamp": 6423.58
    },
    {
        "message": "było super, dziękuję panie Mikołaju",
        "name": "Beata",
        "timestamp": 6424.82
    },
    {
        "message": "było super!",
        "name": "Damian",
        "timestamp": 6424.42
    },
    {
        "message": "dużo wartościowej wiedzy, dziękuję!",
        "name": "Alicja",
        "timestamp": 6424.69
    },
    {
        "message": "dziekuje, wiele się dowiedziałem",
        "name": "Robert",
        "timestamp": 6424.34
    },
    {
        "message": "Dziękuję Panie Mikołaju!",
        "name": "Renata",
        "timestamp": 6424.58
    },
    {
        "message": "było super, dziękuję!",
        "name": "Kamila",
        "timestamp": 6425.04
    },
    {
        "message": "Dziękuję, świetne wskazówki",
        "name": "Weronika",
        "timestamp": 6425.47
    },
    {
        "message": "było super, naprawdę warto",
        "name": "Bartek",
        "timestamp": 6425.79
    },
    {
        "message": "dużo wartościowej wiedzy, dzięki",
        "name": "Justyna",
        "timestamp": 6426.05
    },
    {
        "message": "dziekuje, otworzył mi Pan oczy",
        "name": "Michał",
        "timestamp": 6426.66
    },
    {
        "message": "Dziękuję! Bardzo praktyczne",
        "name": "Hanna",
        "timestamp": 6427.03
    },
    {
        "message": "było super, chętnie powtórzę",
        "name": "Grzegorz",
        "timestamp": 6428.12
    },
    {
        "message": "dużo wartościowej wiedzy, dziękuję Panie Mikołaju",
        "name": "Natalia",
        "timestamp": 6430.45
    },
    {
        "message": "dziekuje, bardzo mi pomogło",
        "name": "Rafał",
        "timestamp": 6432.78
    },
    {
        "message": "było super, świetne przykłady",
        "name": "Zuzanna",
        "timestamp": 6435.27
    },
    {
        "message": "Magdalena wykupił/a dostęp do Kursu.",
        "name": "Produkacja",
        "timestamp": 6436
    },
    {
        "message": "dużo wartościowej wiedzy, jestem wdzięczny",
        "name": "Adrian",
        "timestamp": 6438.12
    },
    {
        "message": "dziękuję Panie Mikołaju, rewelacyjna prezentacja",
        "name": "Ewa",
        "timestamp": 6439.86
    }
] as ChatMessage[]

interface ChatMessage {
    message: string
    name: string
    timestamp: number
}