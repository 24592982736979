import {WebinarDetails} from "../../../../model/user/webinar/WebinarDetails";
import Heading from "../../../common/header/Heading";
import React from "react";
import {TraceService} from "../../../../service/TraceService";
import {TraceType} from "../../../../model/user/general/Trace";
import AnimatedLink from "../../../ui/motion/AnimatedLink";
import {useLocation} from "react-router-dom";

interface WebinarProps {
    webinarDetails: WebinarDetails;
}

export default function WebinarKedUnsuccessfulPurchase({webinarDetails}: WebinarProps) {
    const location = useLocation();

    return (
        <>
            <Heading
                customStyles='mb-6 text-center mw-md-2xl mx-auto'
                title="Zakup nieudany... 😴"
                badgeText="Błąd"
            />

            <div className='mw-md-2xl mx-auto text-center text-white'>
                <h2 className='fs-7 text-danger'>
                    Co dalej?
                </h2>
                <p className='fw-medium mb-8'>
                    Spróbuj ponownie lub skontaktuj się z nami, jeżeli błąd będzie się powtarzał.
                </p>

                <AnimatedLink
                    className="btn btn-lg btn-warning text-success-light shadow mb-8"
                    onClick={() => {
                        TraceService.addTrace(TraceType.BtnClick, 'btn-1 -> Spróbuj ponownie')
                    }}
                    to={location.pathname.includes('podstawy-efektywnego-dzialania') ? '/newsletter/podstawy-efektywnego-dzialania' : (location.pathname.includes('produkt/zakup/kurs-efektywnego-dzialania') ? '/produkt/zakup/kurs-efektywnego-dzialania' : (webinarDetails ? `/webinar/zakup/${webinarDetails.id}` : (location.pathname.includes('rads') ? '/rads/kurs-efektywnego-dzialania' : (location.pathname.includes(`webinar/kurs-efektywnego-dzialania`) ? '/webinar/kurs-efektywnego-dzialania' : '/newsletter/kurs-efektywnego-dzialania'))))}>
                    Spróbuj ponownie
                </AnimatedLink>

                <h2 className='fs-8 mt-8 text-warning'>
                    Pytania / problemy?
                </h2>
                <p className='fw-medium mb-16'>
                    Napisz do nas na adres <a
                    href="mailto:kontakt@produkacja.pl"
                    className="text-success link-success"
                >
                    kontakt@produkacja.pl
                </a>
                </p>
            </div>
        </>
    );
}