import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import {KED_WEBINAR_EXPERIMENT_AB_9} from "../../../util/ExperimentUtil";
import React from "react";
import usePageTracking from "../../../hooks/usePageTracking";
import WebinarOptIn from "../../../components/funnel/optin/WebinarOptIn";
import ABTestingComponent from "../../../components/ab-testing/ABTestingComponent";
import WebinarOptInB from "../../../components/funnel/optin/WebinarOptInB";
import ProdukacjaCopyrightNoteWithPhoneNumber from "../../../components/funnel/ProdukacjaCopyrightNoteWithPhoneNumber";

export default function WebinarOptInPage() {
    usePageTracking();
    return (
        <>
            <Meta
                title="Zapisz się na webinar."
                description="Zarejestruj się na bezpłatny webinar pod tytułem 'Jak zostać NAJLEPSZYM uczniem' (dla rodziców)."
                imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/webinar/webinar-optin-icon.png'
            />
            <MainDesign containerStyles="container pt-10 pt-md-20 pb-10" minHeight='100vh'>
                <ABTestingComponent
                    noCookies={true}
                    ComponentA={<WebinarOptIn/>}
                    ComponentB={<WebinarOptInB/>}
                    experimentId={KED_WEBINAR_EXPERIMENT_AB_9}/>

                <ProdukacjaCopyrightNoteWithPhoneNumber/>
            </MainDesign>
        </>
    );
}
