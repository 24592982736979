import {UserCourse} from "../../../model/admin/UserCourse";
import AdminKedUserItem from "./AdminKedUserItem";
import React from "react";
import {CourseService} from "../../../service/CourseService";
import {useToast} from "../../ui/toast/ToastContext";
import {useNavigate} from "react-router-dom";
import {AvailableCourses} from "../../../model/user/learning-course/AvailableCourses";

interface AdminKedUsersProps {
    courseUsers: UserCourse[]
    course: AvailableCourses
}

export default function AdminCourseUsers(props: AdminKedUsersProps) {
    const {courseUsers, course} = props;
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const {showMessage} = useToast();
    const navigate = useNavigate();

    async function unlockNextWeek(userId: string) {
        setIsSubmitting(true);
        try {
            await CourseService.unlockNextWeek(userId);
            showMessage('Pomyślnie odblokowano.');
            navigate('.', {replace: true});
        } catch (error) {
            showMessage(`Wystąpił błąd: ${JSON.stringify(error)}`)
        }
        setIsSubmitting(false);
    }

    return <>
        <div className='mb-20 row g-8 mw-md-4xl mx-auto'>
            {
                courseUsers
                    .sort((a, b) => new Date(b.boughtAt).getTime() - new Date(a.boughtAt).getTime())
                    .map((kedUser) => (
                        <AdminKedUserItem userCourse={kedUser} isSubmitting={isSubmitting}
                                          unlockNextWeek={unlockNextWeek} courseType={course}/>
                    ))
            }
        </div>
    </>
}