import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import React, {Suspense} from "react";
import {Await, defer, useLoaderData} from "react-router-dom";
import {CourseService} from "../../../service/CourseService";
import {UserCourse} from "../../../model/admin/UserCourse";
import AdminCourseUsers from "../../../components/admin/ked/AdminCourseUsers";
import LoadingPage from "../../common/LoadingPage";
import ErrorContent from "../../../components/common/error/ErrorContent";
import Heading from "../../../components/common/header/Heading";
import AdminKedNavbar from "../../../components/admin/ked/AdminKedNavbar";
import {AvailableCourses} from "../../../model/user/learning-course/AvailableCourses";

interface LoaderData {
    courseUsers: Promise<UserCourse[]>
}

interface AdminCourseUsersPageProps {
    course: AvailableCourses
}

export default function AdminCourseUsersPage(props: AdminCourseUsersPageProps) {
    const {course} = props;
    const {courseUsers} = useLoaderData() as LoaderData;

    return <>
        <Meta title={`${course} - Użytkownicy`}/>
        <MainDesign containerStyles='container pt-20'>
            <Heading title={`Zapisani do ${course}`} badgeText='kursanci'/>
            <AdminKedNavbar/>
            <hr className='text-white'/>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={courseUsers} errorElement={<ErrorContent/>}>
                    {(loadedCourseUsers) => <>
                        <AdminCourseUsers courseUsers={loadedCourseUsers} course={course}/>
                    </>}
                </Await>
            </Suspense>
        </MainDesign>
    </>
}

// Hardcoded for now
async function loadKedUsers(): Promise<UserCourse[]> {
    return await CourseService.getKedUsers(AvailableCourses.KED);
}

async function loadPedUsers(): Promise<UserCourse[]> {
    return await CourseService.getKedUsers(AvailableCourses.PED);
}

export async function adminKedUsersLoader() {
    return defer({
        courseUsers: loadKedUsers()
    })
}

export async function adminPedUsersLoader() {
    return defer({
        courseUsers: loadPedUsers()
    })
}