import {WebinarDetails} from "../../../../model/user/webinar/WebinarDetails";
import Heading from "../../../common/header/Heading";
import React, {useState} from "react";
import {formatDateWithOWord} from "../../../../util/DateUtil";
import WebinarTestimonials from "./WebinarTestimonials";
// If you're using something like React Router, you might use Link or NavLink.
// For an "AnimatedLink", replace with your actual animated link component.
import AnimatedLink from "../../../ui/motion/AnimatedLink";
import {TraceService} from "../../../../service/TraceService";
import {TraceType} from "../../../../model/user/general/Trace";
import {AnimatePresence, motion} from "framer-motion";
import AnimatedButton from "../../../ui/motion/AnimatedButton";
import {useSubmitFormAction} from "../../../../hooks/useSubmitFormAction";
import {Link, useSubmit} from "react-router-dom";
import DependentOnScreenSizeElement from "../../../ui/element/DependentOnScreenSizeElement";
import {DiagnosticService} from "../../../../service/DiagnosticService";
import VideoWithPlayButton from "../../../ui/video/VideoWithPlayButton";

interface WebinarProps {
    webinarDetails: WebinarDetails;
}

export default function WebinarOptInConfirmation({webinarDetails}: WebinarProps) {
    const {isSubmitting} = useSubmitFormAction('w/e', null, (body) => {
        if (webinarDetails && webinarDetails.experimentId) {
            TraceService.addTraceWithExperiment(TraceType.ScheduleSmsReminder, webinarDetails.experimentId, webinarDetails.variation, webinarDetails.source, webinarDetails.id, webinarDetails.email);
        } else {
            DiagnosticService.addDiagnostic({
                fullEvent: webinarDetails,
                error: null,
                additionalInfo: "Experiment id unknown for schedule sms.",
                functionName: "WebinarOptInConfirmation"
            })
        }
        setErrorMessage(null);
        setSuccessMessage(body);
    }, false, undefined, (body) => {
        setSuccessMessage(null);
        setErrorMessage(body);
    }, true);
    // Convert webinarDetails.plannedDate into a Date object:
    const webinarDate = new Date(webinarDetails.plannedDate);
    const now = new Date();

    const submit = useSubmit();

    // Calculate the difference in milliseconds:
    const timeDiffMs = webinarDate.getTime() - now.getTime();
    // Convert to minutes:
    const timeDiffMins = timeDiffMs / (1000 * 60);

    // Check if the webinar is starting soon or already started:
    const isStartingSoon = timeDiffMins <= 15 && timeDiffMins > -90;

    const [phoneNumber, setPhoneNumber] = useState('+48 ');

    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const formatPhoneNumber = (value: any) => {
        // Remove all non-digit characters (except the + at the beginning)
        const digits = value.replace(/\D/g, "");

        // Ensure the prefix is kept
        if (!digits.startsWith("48")) {
            return "+48 ";
        }

        // Extract the 9-digit phone number after +48
        const mainNumber = digits.slice(2, 11); // Ensures only 9 digits max

        // Format as 123-456-789
        let formattedNumber = "";
        if (mainNumber.length > 0) {
            formattedNumber = mainNumber.slice(0, 3);
        }
        if (mainNumber.length > 3) {
            formattedNumber += "-" + mainNumber.slice(3, 6);
        }
        if (mainNumber.length > 6) {
            formattedNumber += "-" + mainNumber.slice(6, 9);
        }

        return `+48 ${formattedNumber}`;
    };

    const handleChange = (e: any) => {
        const newValue = formatPhoneNumber(e.target.value);
        setPhoneNumber(newValue);
    };

    const handleKeyDown = (event: any) => {
        // Prevent deleting the +48 prefix
        if ((event.key === "Backspace" || event.key === "Delete") && phoneNumber.length <= 4) {
            event.preventDefault();
        }

        // Prevent non-numeric input (except backspace)
        if (!/[0-9]/.test(event.key) && event.key !== "Backspace" && event.key !== "Delete") {
            event.preventDefault();
        }
    };

    function submitForm() {
        submit({
            phoneNumber
        }, {method: "POST"})
    }

    const getWarsawTime = (date: Date) => {
        return new Date(
            new Intl.DateTimeFormat('en-US', {
                timeZone: 'Europe/Warsaw',
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
            })
                .format(date)
                .replace(/(\d+)\/(\d+)\/(\d+), (\d+):(\d+):(\d+)/, '$3-$1-$2T$4:$5:$6')
        );
    };

    const startDate = getWarsawTime(new Date(webinarDetails.plannedDate));
    const endDate = new Date(startDate.getTime() + (60 * 60 * 1000 + 30 * 60 * 1000)); // 1.5-hour duration

    const webinarTitle = "Szkolenie: Jak pomóc swojemu dziecku zostać najlepszym uczniem"
    const webinarDescription = `Dołącz do szkolenia klikając w poniższy link:\nhttps://produkacja.pl/webinar/${webinarDetails.id}`

    function formatDateForGoogle(date: Date) {
        // Extract Warsaw local time parts
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}${month}${day}T${hours}${minutes}${seconds}`;
    }

// Google Calendar URL
    const googleCalendarUrl = `https://calendar.google.com/calendar/r/eventedit?text=${encodeURIComponent(webinarTitle)}&dates=${formatDateForGoogle(startDate)}/${formatDateForGoogle(endDate)}&details=${encodeURIComponent(webinarDescription)}&location=Online&ctz=Europe/Warsaw`;

    return (
        <>
            <Heading
                customStyles='mb-6 text-center mw-md-4xl mx-auto'
                title="Potwierdzamy zapis na szkolenie."
                badgeText="Sukces"
            />

            <div className='mw-md-2xl mx-auto text-center text-white'>
                <DependentOnScreenSizeElement mobileElement={<h2 className='fs-9'>
                    Odbędzie się ono <span className='text-success'>
                        {formatDateWithOWord(webinarDetails.plannedDate)}
                    </span>.
                </h2>} desktopElement={<h2 className='fs-8'>
                    Odbędzie się ono <span className='text-success'>
                        {formatDateWithOWord(webinarDetails.plannedDate)}
                    </span>.
                </h2>}/>

                <p className={`fw-medium ${isStartingSoon ? 'mb-8' : 'mb-8'}`}>
                    Szczegóły wysłaliśmy Ci na adres email <i>{webinarDetails.email}</i> <br/>
                    Jeżeli nie widzisz maila, sprawdź folder spam.
                </p>
                {
                    !isStartingSoon && <>
                        <DependentOnScreenSizeElement
                            mobileElement={<h2 className='fs-8'>Obejrzyj ten 1-minutowy film, aby rozwiać
                                wszelkie wątpliwości.</h2>} desktopElement={<h2>Obejrzyj ten 1-minutowy film, aby rozwiać
                            wszelkie
                            wątpliwości.</h2>}/>

                        <div>
                            <VideoWithPlayButton vimeoVideoId='1060116601' id='thanks-for-booking'/>
                        </div>
                        <h3 style={{maxWidth: "500px"}} className='fs-7 mx-auto mb-5'>Chcesz otrzymać SMS'a z
                            przypomnieniem?</h3>
                        <p className='fw-medium text-warning fs-10'><b>70%</b> zapisanych osób zapomina o szkoleniu. 🤯</p>
                        <div style={{maxWidth: "350px"}} className="mx-auto">
                            <AnimatePresence mode="wait">
                                <motion.div
                                    key="step1"
                                    initial={{opacity: 0, x: -50}}
                                    animate={{opacity: 1, x: 0}}
                                    exit={{opacity: 0, x: 50}}
                                    transition={{duration: 0.3}}
                                >
                                    <div className="form-group mb-4 text-start">
                                        <label className="mb-2 fw-bold text-white text-start" htmlFor="phone-input">
                                            Podaj swój numer telefonu:
                                        </label>
                                        <div className="position-relative d-inline-block w-100">
                                            {/* Flag Image */}
                                            <img
                                                src="https://produkacja.s3.eu-central-1.amazonaws.com/web/icon/pl-flag.png"
                                                alt="flaga polski"
                                                style={{
                                                    position: "absolute",
                                                    left: "12px",
                                                    top: "50%",
                                                    transform: "translateY(-50%)",
                                                    height: "24px",
                                                    width: "auto",
                                                }}
                                            />

                                            {/* Phone Input */}
                                            <input
                                                id="phone-input"
                                                type="tel"
                                                name='phone'
                                                className="input-lg form-control text-secondary-dark shadow p-3 ps-12"
                                                value={phoneNumber}
                                                onChange={handleChange}
                                                onKeyDown={handleKeyDown}
                                                maxLength={15}
                                            />
                                        </div>
                                        <div className="text-center mt-4">
                                            <AnimatedButton
                                                type="button" // ensures we do NOT auto-submit on Enter
                                                className="mx-auto btn btn-lg fw-bold btn-success text-success-light shadow w-100"
                                                onClick={submitForm}
                                                disabled={
                                                    isSubmitting || phoneNumber.length < 15 || !!successMessage
                                                }
                                            >
                                                {isSubmitting ? "Trwa zapisywanie..." : 'Potwierdź'}
                                            </AnimatedButton>
                                        </div>
                                        {
                                            successMessage &&
                                            <p className='mb-1 fs-11 fw-medium text-success text-left mt-2'>
                                                {successMessage}
                                            </p>
                                        }
                                        {
                                            errorMessage &&
                                            <p className='mb-1 fs-11 fw-medium text-danger text-left mt-2'>
                                                {errorMessage}
                                            </p>
                                        }

                                        <p className='mb-1 fs-13 fw-medium text-white text-left mt-2'>
                                            <span>Podając numer telefonu akceptujesz </span>
                                            <Link className='text-success link-success'
                                                  to='/polityka-prywatnosci'>
                                                politykę prywatności
                                            </Link>
                                            <span> i zgadzasz się na otrzymywanie wiadomości SMS od organizatora w związku z planowanym wydarzeniem.</span>
                                        </p>
                                    </div>

                                    <h3 style={{maxWidth: "500px"}} className='fs-7 mx-auto mb-5 mt-5'>Oraz:</h3>
                                    <div className="mt-6 d-flex justify-content-center gap-3">
                                        {/* Google Calendar */}
                                        <a href={googleCalendarUrl} target="_blank" rel="noopener noreferrer">
                                            <AnimatedButton className="btn btn-lg btn-success">
                                                Dodaj do Kalendarza Google
                                            </AnimatedButton>
                                        </a>
                                    </div>
                                </motion.div>
                            </AnimatePresence>
                        </div>
                    </>
                }

                {/* Conditionally render either the "Join Now" link or the testimonials */}
                {isStartingSoon ? (
                    <AnimatedLink
                        className="btn btn-lg btn-success text-success-light shadow mb-8"
                        onClick={() => {
                            TraceService.addTrace(TraceType.BtnClick, 'btn-1 -> Zacznij działać')
                        }}
                        to={`/webinar/${webinarDetails.id}`}>
                        Dołącz do webinaru
                    </AnimatedLink>
                ) : (
                    <WebinarTestimonials/>
                )}
            </div>
        </>
    );
}