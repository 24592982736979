import {NewsletterFollowUp} from "../../../../model/user/general/Newsletter";
import React from "react";
import {motion} from "framer-motion";

interface AdminNewsletterFollowUpsStatisticsItemProps {
    followUp: NewsletterFollowUp;
}

function roundTwoDigitsPercent(number: number): string {
    return `${(number * 100).toFixed(1)}%`
}

export default function AdminNewsletterFollowUpsStatisticsItem({
                                                                   followUp,
                                                               }: AdminNewsletterFollowUpsStatisticsItemProps) {
    const metrics = [
        {title: "Suma", value: followUp.sentEmails},
        {
            title: "Otwarcia",
            value: followUp.openedEmails,
            percentage: roundTwoDigitsPercent(followUp.openedEmails / followUp.sentEmails)
        },
        {
            title: "Kliknięcia",
            value: followUp.clickedEmails,
            percentage: roundTwoDigitsPercent(followUp.clickedEmails / followUp.sentEmails)
        },
        {
            title: "Rezygnacje",
            value: followUp.unsubscribes,
            percentage: roundTwoDigitsPercent(followUp.unsubscribes / followUp.sentEmails)
        },
    ];

    return (
        <>
            <motion.div
                className="col-12"
                key={followUp.name}
                initial={{opacity: 0, scale: 0.5}}
                animate={{opacity: 1, scale: 1}}
            >
                <div className="px-10 py-8 bg-light-dark rounded shadow">
                    <div className="row justify-content-between g-4">
                        <div className="col d-flex flex-column justify-content-center">
                            <h3 className="mb-1 font-heading fs-9 text-white">
                                FollowUp: {followUp.name}
                            </h3>
                        </div>
                    </div>
                    <div className="row g-3 mt-3">
                        {metrics.map((metric, index) => (
                            <div
                                key={index}
                                className="col-6 col-md-3 text-center"
                            >
                                <h4 className="mb-1 font-heading text-success fs-7">
                                    {metric.value} {metric.percentage ? `(${metric.percentage})` : ''}
                                </h4>
                                <p className="mb-0 fs-9 text-secondary-light fw-medium">
                                    {metric.title}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </motion.div>
        </>
    );
}