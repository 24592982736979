import {v4 as uuidv4} from "uuid";

const FIRST_JOINED_FROM_KEY = "firstJoinedFrom"

const SESSION_ID_KEY = "sessionId"

export function setFirstJoinedFromIfNotExists(firstJoinedFrom: string): void {
    const previous = getFirstJoinedFrom();

    if (!previous) {
        localStorage.setItem(FIRST_JOINED_FROM_KEY, firstJoinedFrom);
    }
}

export function getFirstJoinedFrom(): string | null {
    return localStorage.getItem(FIRST_JOINED_FROM_KEY);
}

export function generateSessionIdIfNotExists() {
    const sessionId = localStorage.getItem(SESSION_ID_KEY);
    if (!sessionId) {
        localStorage.setItem(SESSION_ID_KEY, uuidv4());
    }
}

export function getSessionId() {
    return localStorage.getItem(SESSION_ID_KEY);
}

export async function sessionIdLoader({request}: { request: Request }): Promise<any> {
    generateSessionIdIfNotExists();
    return null
}