import SlideInWhenVisible from "../../ui/motion/SlideInWhenVisible";
import React from "react";

export default function WhoAreWeMission() {
    return <>
        <div className='text-center py-16'>
            <span className='mb-6 badge bg-success text-white text-uppercase shadow'>
            Nasza misja
            </span>
            <div className='position-relative mb-16 mw-md-5xl mx-auto'>
                <SlideInWhenVisible initial={{y: 50, opacity: 0, scale: 1.5}} delay={0.2} initialX={0}>
                    <h2
                        className='position-relative mb-0 fs-7 fw-medium text-white'
                        style={{zIndex: 1, letterSpacing: '-1px', fontStyle: 'italic'}}
                    >
                        "Pomagamy nastolatkom w odkrywaniu i wykorzystywaniu ich pełnego potencjału, zachęcając do
                        podążania własną drogą życiową i realizowania nawet najbardziej ambitnych planów."
                    </h2>
                    <img
                        className='position-absolute top-0 start-0 mt-n6 ms-n7'
                        src='https://produkacja.s3.eu-central-1.amazonaws.com/web/elements/quote-top-dark.svg'
                        alt='quote'
                    />
                    <img
                        className='position-absolute bottom-0 end-0 mb-n6 me-n7'
                        src='https://produkacja.s3.eu-central-1.amazonaws.com/web/elements/quote-down-dark.svg'
                        alt='quote'
                    />
                </SlideInWhenVisible>
            </div>
        </div>
    </>
}