import Heading from "../../../common/header/Heading";
import {CourseStatistics, CourseWeekAndEpisodes} from "../../../../model/user/learning-course/LearningCourse";
import LearningCourseLeftMenu from "../content/LearningCourseLeftMenu";
import {motion} from "framer-motion";
import LearningCourseEpisodeComponent from "../LearningCourseEpisodeComponent";
import LearningCourseDashboardDescription from "./LearningCourseDashboardDescription";
import React from "react";
import {useSearchParams} from "react-router-dom";
import {EpisodeDashboard} from "../../../../pages/user/learning-course/LearningCourseDashboardPage";
import MandatoryFeedbackCourseModal from "../content/MandatoryFeedbackCourseModal";
import {CourseDetails} from "../../../../model/user/learning-course/CourseDetails";

interface LearningCourseDashboardProps {
    loaded: CourseStatistics & { weekAndEpisodes: CourseWeekAndEpisodes[] } | EpisodeDashboard
    watchesCourseEpisode: boolean
    week: string | null
    episode: string | null
    course: CourseDetails
}

export default function LearningCourseDashboard(props: LearningCourseDashboardProps) {
    const {loaded, watchesCourseEpisode, week, episode, course} = props;

    const [searchParams, setSearchParams] = useSearchParams();


    function updateWeek(newWeek: string) {
        searchParams.set('w', newWeek);
        searchParams.set('e', '1');
        setSearchParams(searchParams);
    }

    return <>
        <>
            {
                !watchesCourseEpisode &&
                <Heading title={`Witaj ${(loaded as unknown as (CourseStatistics & {
                    weekAndEpisodes: CourseWeekAndEpisodes[]
                })[])[0].name}`}
                         badgeText={course.fullName}/>
            }
            <div className='row mt-12' key={watchesCourseEpisode + ''}>
                <div className='col-12 col-md-3'>
                    <LearningCourseLeftMenu wrapperClasses=''
                                            watchesCourseEpisode={watchesCourseEpisode}
                                            lastWatched={watchesCourseEpisode ? {
                                                w: 0,
                                                e: 1
                                            } : (loaded as unknown as (CourseStatistics & {
                                                weekAndEpisodes: CourseWeekAndEpisodes[]
                                            })[])[0].lastWatched}
                                            weekAndEpisodes={(loaded as EpisodeDashboard).courseWeekAndEpisodes}
                                            episode={(loaded as EpisodeDashboard).episode}
                                            updateWeek={updateWeek}
                                            course={course}
                    />
                </div>
                <div className='col-12 col-md-8 mx-auto px-3 px-md-0 pt-8 pt-md-0'>
                    <motion.div className='p-3 p-md-10 p-sm-none bg-light-dark rounded'
                                key={JSON.stringify(loaded)}
                                initial={{opacity: 0, y: 200}}
                                animate={{opacity: 1, y: 0}}
                                transition={{delay: watchesCourseEpisode ? 0.3 : 0}}>
                        {
                            watchesCourseEpisode &&
                            <LearningCourseEpisodeComponent week={Number(week)}
                                                            courseStatistics={(loaded as EpisodeDashboard).course}
                                                            episodeObj={(loaded as EpisodeDashboard).episode}
                                                            episodeNumber={Number(episode)}
                                                            episode={course.structure[Number(week)].episodes[Number(episode) - 1]}
                                                            courseDetails={course}
                            />
                        }
                        {
                            !watchesCourseEpisode &&
                            (() => {
                                const arrayLoaded = loaded as unknown as (CourseStatistics & {
                                    weekAndEpisodes: CourseWeekAndEpisodes[]
                                })[];
                                return (
                                    <LearningCourseDashboardDescription
                                        courseWeekAndEpisodes={arrayLoaded.slice(1) as any}
                                        statistics={arrayLoaded[0]}
                                        course={course}
                                    />
                                );
                            })()
                        }
                    </motion.div>
                </div>
            </div>
        </>
        {
            watchesCourseEpisode && <MandatoryFeedbackCourseModal
                isOpen={!((loaded as EpisodeDashboard).course.completedMandatoryFeedbackForm) && (loaded as EpisodeDashboard).course.weeksUnlocked >= 3}/>
        }
    </>
}