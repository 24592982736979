import {WebinarDetails} from "../../../../model/user/webinar/WebinarDetails";
import Heading from "../../../common/header/Heading";
import React, {useEffect, useMemo, useState} from "react";
import VideoWithPlayButton from "../../../ui/video/VideoWithPlayButton";
import AnimatedLink from "../../../ui/motion/AnimatedLink";
import AdvantageItem from "../../../ui/item/AdvantageItem";

interface WebinarProps {
    webinarDetails: WebinarDetails;
}

export default function WebinarOptInJustInTimeConfirmation({webinarDetails}: WebinarProps) {
    function pad(num: number) {
        return num < 10 ? `0${num}` : `${num}`;
    }

    const [secondsLeft, setSecondsLeft] = useState<number>(151);

    useEffect(() => {
        const tick = () => {
            setSecondsLeft((prev) => (prev === 0 ? 0 : prev - 1));
        };

        tick(); // Run immediately
        const intervalId = setInterval(tick, 1000);

        return () => clearInterval(intervalId);
    }, []);

    // Memoized button to prevent unnecessary re-renders
    const joinButton = useMemo(() => (
        <AnimatedLink to={`/webinar/${webinarDetails.id}?jit=true`}
                      className='btn btn-lg btn-success w-100 shadow mt-4'>
            Dołącz już teraz
        </AnimatedLink>
    ), [webinarDetails.id]); // Only re-render when `webinarDetails.id` changes

    return (
        <>
            <Heading
                customStyles='mb-2 text-center mw-md-4xl mx-auto'
                title="Rejestracja udana!"
                subtitle='Ostatni krok przed uzyskaniem natychmiastowego dostępu do szkolenia.'
            />
            <div className='mw-md-7xl mx-auto bg-dark pt-6 rounded-5'>
                <div className='row mw-md-6xl mx-auto'>
                    <div className='col-12 col-md-6'>
                        <div className='text-center mw-md-4xl mx-auto mb-0 pb-0'>
                        <span className='badge mb-4 bg-success-dark text-success text-uppercase shadow'>
                            Na dzisiejszym szkoleniu:
                        </span>
                        </div>
                        <h2 className='text-center mb-4 mt-0'>Jak pomóc swojemu dziecku zostać <u>NAJLEPSZYM uczniem</u>?
                        </h2>
                        <VideoWithPlayButton vimeoVideoId='1059405730' id={'thank-you'}/>
                    </div>

                    <div className='col-12 col-md-6 ps-4 border-success-light'>
                        <p className="fs-8 fw-bold text-warning mb-3 text-center">
                            Szkolenie rozpoczyna się za:
                        </p>
                        <div className="d-flex justify-content-center gap-8 text-white">
                            {/* HOURS */}
                            <div>
                                <div className="fs-4 fw-bold">
                                    00
                                </div>
                                <div className="fs-9 text-center">Godz.</div>
                            </div>
                            {/* MINUTES */}
                            <div>
                                <div className="fs-4 fw-bold">
                                    {pad(Math.floor(secondsLeft / 60))}
                                </div>
                                <div className="fs-9 text-center">Min.</div>
                            </div>
                            {/* SECONDS */}
                            <div>
                                <div className="fs-4 fw-bold">
                                    {pad(secondsLeft % 60)}
                                </div>
                                <div className="fs-9 text-center">Sek.</div>
                            </div>
                        </div>
                        <p className='fw-medium text-center text-white text-decoration-underline fs-9 mt-4'>Zostań do
                            końca, aby:</p>
                        <AdvantageItem title='Uzyskać dostęp do prezentacji. (Ponad 150 slajdów)'/>
                        <AdvantageItem
                            title='Poznać 5-stopniowy proces, dzięki któremu pomożesz swojemu dziecku w Efektywnym Działaniu raz na zawsze.'/>
                        <AdvantageItem title='Otrzymać miłą niespodziankę 🥳 '/>
                        <p className='text-white mb-0 fw-bold'>Dołącz wcześniej na sekcję pytania & odpowiedzi!
                        </p>
                        <div className='mx-md-24 pb-6'>
                            {joinButton} {/* Use memoized button */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}