import Meta from "../../../components/common/Meta";
import MainDesign from "../../MainDesign";
import {motion} from "framer-motion";
import ProductPageOption from "../../../components/user/ai/pricing/ProductPageOption";

export const offerVariants = {
    hidden: {
        opacity: 0,
        scale: 0.5,
        x: -100,
        y: 50,
        rotate: -10
    },
    visible: (i: number) => ({
        opacity: 1,
        scale: 1,
        x: 0,
        y: 0,
        rotate: 0,
        transition: {
            delay: 0,
            duration: 0.5,
            ease: [0.6, -0.05, 0.01, 0.99],
        },
    }),
};

export default function OfferLandingPage() {
    return <>
        <Meta title='Działaj Efektywniej i Osiągaj Więcej - Produkacja'
              description='Zobacz, co możesz zyskać uzyskując dostęp do Kursu Efektywnego Działania, decydując się na indywidualne konsultacje z Twórcą Produkacji, lub wybierając rozwiązania AI.'/>
        <MainDesign containerStyles='container py-20'>
            <div className='row mw-md-5xl mx-auto'>
                <motion.div
                    custom={0} // Custom prop for delay calculation
                    variants={offerVariants}
                    initial="hidden"
                    animate="visible"
                    className="option-wrapper col-12 col-md-6 mt-5 px-md-12"
                >
                    <ProductPageOption
                        imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnej-nauki/kurs-oferta.png'
                        redirectTo='/oferta/kurs-efektywnego-dzialania' title='Kurs Efektywnego Działania'
                        subtitle='Twórca Produkacji pomaga licealistom w pełnym wykorzystaniu ich potencjału i realizacji dowolnych celów.'/>
                </motion.div>
                <motion.div
                    custom={1} // Increasing the custom value for sequential delay
                    variants={offerVariants}
                    initial="hidden"
                    animate="visible"
                    className="option-wrapper col-12 col-md-6 mt-5 px-6"
                >
                    <ProductPageOption
                        imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/oferta-wspolne/rozwiazania-ai.png'
                        redirectTo='/oferta/chat' title='Edukacyjny Chat'
                        subtitle='Zdobywaj wiedzę łatwo, szybko i wygodnie. Nasze modele AI wspierają Cię na każdym etapie nauki, zapewniając niezbędną wiedzę oraz feedback.'/>
                </motion.div>
            </div>
        </MainDesign>
    </>
}
