import * as React from 'react';
import {useEffect} from 'react';

import {createBrowserRouter, RouterProvider} from 'react-router-dom';

import IndexPage, {loader as randomBlogArticlesLoader} from './pages/Index';
import Login, {action as loginAction} from "./pages/auth/Login";
import Register, {action as registerAction} from "./pages/auth/Register";
import Contact, {action as contactAction} from "./pages/user/Contact";
import RootLayout from "./pages/Root";
import ActivateAccount, {
    action as activateAccountAction,
    loader as activateAccountLoader
} from "./pages/auth/ActivateAccount";
import {Amplify} from "aws-amplify";
import Error404 from "./pages/common/error/Error404";
import {redirectIfLoggedIn, redirectIfNotAdmin, redirectIfNotLoggedIn, tokenLoader} from "./util/AuthUtil";
import {logoutAction} from "./components/ui/header/Header";
import {action as newsletterFormAction} from "./components/user/newsletter/NewsletterForm";
import CancelNewsletter, {
    action as cancelNewsletterFormAction,
    loader as cancelNewsletterLoader
} from "./components/user/newsletter/CancelNewsletter";
import AdminIndex, {loader as adminMetricsLoader} from "./pages/admin/AdminIndex";
import AdminRootLayout from "./pages/admin/AdminRoot";
import AdminSendNewsletterPage, {
    action as adminSendNewsletterAction,
} from "./pages/admin/newsletter/AdminSendNewsletterPage";
import PrivacyPolicyPage from "./pages/user/regulation/PrivacyPolicyPage";
import ResetPassword, {action as resetPasswordAction} from "./pages/auth/ResetPassword";
import ChangePassword, {action as changePasswordAction} from "./pages/auth/ChangePassword";
import ChatBot from "./pages/user/ai/chat/ChatBot";
import {loader as threadsLoader} from "./components/user/ai/chat/Chat";
import {action as purchaseTokensAction} from "./components/user/ai/pricing/chat/ChatPricing";
import BlogPage, {deleteAction as deleteArticleAction, loader as blogArticlesLoader} from "./pages/user/blog/BlogPage";
import BlogPostPage, {loader as blogArticleLoader} from "./pages/user/blog/BlogPostPage";
import AdminCreateOrEditBlogArticlePage, {
    action as createOrEditBlogArticleAction,
    loader as loadArticleToEdit
} from "./pages/admin/blog/AdminCreateOrEditBlogArticlePage";
import AdminBlogPage, {loader as adminBlogArticlesLoader} from "./pages/admin/blog/AdminBlogPage";
import AdminAIPage, {action as adminAIAction, loader as adminAIModelsLoader} from "./pages/admin/ai/AdminAIPage";
import Planner from "./pages/user/marketing/Planner";
import OfferLandingPage from "./pages/user/offer/OfferLandingPage";
import TransactionsPage, {loader as transactionsLoader} from "./pages/user/profile/TransactionsPage";
import {action as sendFeedbackAction} from "./components/user/feedback/FeedbackModal";
import AdminFeedbackPage, {loader as feedbackLoader} from "./pages/admin/feedback/AdminFeedbackPage";
import {HelmetProvider} from "react-helmet-async";
import AdminDiagnosticPage, {loader as diagnosticsLoader} from "./pages/admin/diagnostic/AdminDiagnosticPage";
import ChatLandingPage from "./pages/user/ai/chat/ChatLandingPage";
import LearningCourseEnrollPage, {
    action as learningCourseEnrollAction
} from "./pages/user/learning-course/LearningCourseEnrollPage";
import AboutUsPage from "./pages/user/AboutUsPage";
import ConfirmNewsletterPage from "./pages/user/marketing/ConfirmNewsletterPage";
import RegulationsPage from "./pages/user/regulation/RegulationsPage";
import LoginError from "./pages/auth/LoginError";
import {ToastProvider} from "./components/ui/toast/ToastContext";
import AdminTracesPage, {loader as adminTracesPageLoader} from "./pages/admin/trace/AdminTracesPage";
import ConfirmTransactionPage from "./pages/user/transaction/ConfirmTransactionPage";
import RedirectAfterRegistrationPage from "./pages/user/thank-you/RedirectAfterRegistrationPage";
import {isLoggedInLoader} from "./util/Loader";
import ProductsPage, {loader as productsPageLoader} from "./pages/user/products/ProductsPage";
import LearningCourseDashboardPage, {
    kedLoader,
    pedLoader
} from "./pages/user/learning-course/LearningCourseDashboardPage";
import {action as completeEpisodeAction} from "./components/user/learning-course/LearningCourseEpisodeComponent";
import LearningCoursePurchasePage, {
    action as coursePurchaseAction,
    loader as individualKedOfferLoader
} from "./pages/user/learning-course/LearningCoursePurchasePage";
import LearningCourseSuccessfulPurchasePage from "./pages/user/learning-course/LearningCourseSuccessfulPurchasePage";
import LearningCourseUnsuccessfulPurchasePage
    from "./pages/user/learning-course/LearningCourseUnsuccessfulPurchasePage";
import RegulationsKEDPage from "./pages/user/regulation/RegulationsKEDPage";
import AdminKedExperimentPage, {
    loader as adminKedExperimentResultsLoader
} from "./pages/admin/ked/AdminKedExperimentPage";
import RedirectAfterSchedulingIndividualKEDSessionPage
    from "./pages/user/thank-you/RedirectAfterSchedulingIndividualKEDSessionPage";
import RedirectComponent from "./components/common/RedirectComponent";
import SleepChronotypesTestPage from "./pages/user/SleepChronotypesTestPage";
import FunnelRootLayout from "./pages/funnel/FunnelRoot";
import {funnelKedWebinarOptInAction, funnelMWParentOptInAction} from "./pages/funnel/optin/action"
import AdminKedOfferPage, {action as adminKedOfferAction} from "./pages/admin/ked/AdminKedOfferPage";
import AdminCourseUsersPage, {adminKedUsersLoader, adminPedUsersLoader} from "./pages/admin/ked/AdminCourseUsersPage";
import RegulationsMWPage from "./pages/user/regulation/RegulationsMWPage";
import ExtraordinarySupportPurchasePage, {
    action as extraordinarySupportPurchaseAction,
    loader as extraordinarySupportPurchaseLoader
} from "./pages/user/extraordinary-support/ExtraordinarySupportPurchasePage";
import ExtraordinarySupportSuccessfulPurchasePage
    from "./pages/user/extraordinary-support/ExtraordinarySupportSuccessfulPurchasePage";
import ExtraordinarySupportUnsuccessfulPurchasePage
    from "./pages/user/extraordinary-support/ExtraordinarySupportUnsuccessfulPurchasePage";
import WebinarPage from "./pages/funnel/after-optin/webinar/WebinarPage";
import {
    purchaseKedFromNewsletterAction,
    purchaseKedFromPedCourseSalesPage,
    purchaseKedFromRadsAction,
    purchaseKedFromWebinarSalesPageAction,
    purchasePedFromSalesPageAction,
    purchaseWebinarAction
} from "./pages/funnel/after-optin/webinar/purchaseWebinarAction";
import WebinarOptInPage from "./pages/funnel/optin/WebinarOptInPage";
import WebinarOptInConfirmationPage, {
    action as schedulePhoneNotificationAction
} from "./pages/funnel/after-optin/webinar/WebinarOptInConfirmationPage";
import WebinarKedPurchasePage from "./pages/funnel/after-optin/webinar/WebinarKedPurchasePage";
import WebinarKedSuccessfulPurchasePage from "./pages/funnel/after-optin/webinar/WebinarKedSuccessfulPurchasePage";
import WebinarKedUnsuccessfulPurchasePage from "./pages/funnel/after-optin/webinar/WebinarKedUnsuccessfulPurchasePage";
import {webinarDetailsLoader} from "./pages/funnel/after-optin/webinar/webinarDetailsLoader"
import GrantKedAccessToStudentPage, {
    action as grantKedAccessToStudentAction,
    loader as grantKedAccessToStudentLoader
} from "./pages/auth/GrantKedAccessToStudentPage";
import ProductsHeader from "./components/user/products/ProductsHeader";
import ProductsFooter from "./components/user/products/ProductsFooter";
import CommunityPage, {
    action as configureDiscordAction,
    loader as communityPageLoader
} from "./pages/user/products/CommunityPage";
import CommunityWeeklyMeetingPage, {
    loader as communityWeeklyMeetingsLoader
} from "./pages/user/products/CommunityWeeklyMeetingsPage";
import WebinarSlides from "./pages/funnel/after-optin/webinar/WebinarSlides";
import ChatPurchaseTokensInProducts from "./pages/user/ai/chat/ChatPurchaseTokensInProducts";
import AdminListNewslettersPage, {
    action as adminToggleNewsletterAction,
    loader as adminListNewslettersLoader
} from "./pages/admin/newsletter/AdminListNewslettersPage";
import AdminAddToNewsletterPage, {
    action as adminAddToNewsletterAction
} from "./pages/admin/newsletter/AdminAddToNewsletterPage";
import AdminNewsletterCampaignsStatisticsPage, {
    loader as campaignsLoader
} from "./pages/admin/newsletter/AdminNewsletterCampaignsStatisticsPage";
import AdminNewsletterFollowUpsStatisticsPage, {
    loader as followUpsLoader
} from "./pages/admin/newsletter/AdminNewsletterFollowUpsStatisticsPage";
import WebinarOptInJustInTimeConfirmationPage
    from "./pages/funnel/after-optin/webinar/WebinarOptInJustInTimeConfirmationPage";
import {sessionIdLoader} from "./util/TrackingUtil";
import RegulationsPEDPage from "./pages/user/regulation/RegulationsPEDPage";
import PedPurchasePage from "./pages/funnel/after-optin/ped/PedPurchasePage";
import KedEnrollPage from "./pages/user/products/KedEnrollPage";
import {mandatoryFeedbackCourseAction} from "./components/user/learning-course/content/MandatoryFeedbackCourseModal";
import {AvailableCourses} from "./model/user/learning-course/AvailableCourses";
import {CourseDetails} from "./model/user/learning-course/CourseDetails";

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: process.env.REACT_APP_USER_POOL_ID!!,
            userPoolClientId: process.env.REACT_APP_USER_POOL_APP_CLIENT_ID!!,
            loginWith: {
                oauth: {
                    domain: `${process.env.REACT_APP_ENV}-auth-produkacja.auth.eu-central-1.amazoncognito.com`,
                    scopes: ['email', 'profile', 'openid'],
                    responseType: 'code',
                    redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN_COMMA_SEPARATED!!.split(','),
                    redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT_COMMA_SEPARATED!!.split(',')
                }
            }
        }
    }
})

const router = createBrowserRouter([
    {
        path: '/admin',
        element: <AdminRootLayout/>,
        errorElement: <Error404/>,
        loader: redirectIfNotAdmin,
        id: 'adminRoot',
        children: [
            {
                index: true,
                element: <AdminIndex/>,
                loader: adminMetricsLoader
            },
            {
                path: 'ai',
                element: <AdminAIPage/>,
                loader: adminAIModelsLoader,
                action: adminAIAction
            },
            {
                path: 'newsletter',
                element: <AdminSendNewsletterPage/>,
                action: adminSendNewsletterAction
            },
            {
                path: 'newsletter/lista',
                element: <AdminListNewslettersPage/>,
                loader: adminListNewslettersLoader,
                action: adminToggleNewsletterAction
            },
            {
                path: 'newsletter/dodaj',
                element: <AdminAddToNewsletterPage/>,
                action: adminAddToNewsletterAction
            },
            {
                path: 'newsletter/statystyki/kampanie',
                element: <AdminNewsletterCampaignsStatisticsPage/>,
                loader: campaignsLoader
            },
            {
                path: 'newsletter/statystyki/followups',
                element: <AdminNewsletterFollowUpsStatisticsPage/>,
                loader: followUpsLoader
            },
            {
                path: 'blog',
                element: <AdminBlogPage/>,
                loader: adminBlogArticlesLoader,
                action: deleteArticleAction
            },
            {
                path: 'blog/dodaj',
                element: <AdminCreateOrEditBlogArticlePage actionType="CREATE"/>,
                loader: loadArticleToEdit,
                action: createOrEditBlogArticleAction
            },
            {
                path: 'blog/edytuj/:uuid',
                element: <AdminCreateOrEditBlogArticlePage actionType="EDIT"/>,
                loader: loadArticleToEdit,
                action: createOrEditBlogArticleAction
            },
            {
                path: 'feedback',
                element: <AdminFeedbackPage/>,
                loader: feedbackLoader
            },
            {
                path: 'diagnostyka',
                element: <AdminDiagnosticPage/>,
                loader: diagnosticsLoader
            },
            {
                path: 'traces',
                element: <AdminTracesPage/>,
                loader: adminTracesPageLoader
            },
            {
                path: 'ked',
                element: <AdminKedExperimentPage/>,
                loader: adminKedExperimentResultsLoader
            },
            {
                path: 'ked/oferta',
                element: <AdminKedOfferPage/>,
                action: adminKedOfferAction,
            },
            {
                path: 'ked/kursanci',
                element: <AdminCourseUsersPage course={AvailableCourses.KED}/>,
                loader: adminKedUsersLoader
            },
            {
                path: 'ked/kursanci-ped',
                element: <AdminCourseUsersPage course={AvailableCourses.PED}/>,
                loader: adminPedUsersLoader
            }
        ]
    },
    {
        path: 'qr',
        id: 'qrRoot',
        children: [
            {
                index: true,
                element: <RedirectComponent to='/webinar' from='qr'/>
            }
        ]
    },
    {
        path: '/webinar',
        element: <FunnelRootLayout/>,
        errorElement: <Error404/>,
        loader: sessionIdLoader,
        id: 'webinarRoot',
        children: [
            {
                index: true,
                element: <WebinarOptInPage/>,
                action: funnelKedWebinarOptInAction
            },
            {
                path: 'potwierdzenie/:ksuid',
                element: <WebinarOptInConfirmationPage/>,
                loader: webinarDetailsLoader,
                action: schedulePhoneNotificationAction
            },
            {
                path: 'potwierdzenie-jit/:ksuid',
                element: <WebinarOptInJustInTimeConfirmationPage/>,
                loader: webinarDetailsLoader,
            },
            {
                path: 'zakup/:ksuid',
                element: <WebinarKedPurchasePage/>,
                loader: webinarDetailsLoader,
                action: purchaseWebinarAction
            },
            {
                path: 'zakup/sukces/:ksuid',
                element: <WebinarKedSuccessfulPurchasePage/>,
                loader: webinarDetailsLoader,
            },
            {
                path: 'zakup/blad/:ksuid',
                element: <WebinarKedUnsuccessfulPurchasePage/>,
                loader: webinarDetailsLoader,
            },
            {
                path: 'slajdy',
                element: <WebinarSlides/>,
            },
            {
                path: ':ksuid',
                element: <WebinarPage/>,
                loader: webinarDetailsLoader,
                action: purchaseWebinarAction
            },
            {
                path: 'kurs-efektywnego-dzialania',
                element: <WebinarKedPurchasePage/>,
                loader: webinarDetailsLoader,
                action: purchaseKedFromWebinarSalesPageAction
            },
            {
                path: 'kurs-efektywnego-dzialania/sukces',
                element: <WebinarKedSuccessfulPurchasePage/>,
                loader: webinarDetailsLoader,
            },
            {
                path: 'kurs-efektywnego-dzialania/blad',
                element: <WebinarKedUnsuccessfulPurchasePage/>,
                loader: webinarDetailsLoader,
            },
        ]
    },
    {
        path: '/newsletter',
        element: <FunnelRootLayout/>,
        errorElement: <Error404/>,
        id: 'newsletterRoot',
        loader: sessionIdLoader,
        children: [
            {
                path: 'kurs-efektywnego-dzialania',
                element: <WebinarKedPurchasePage/>,
                loader: webinarDetailsLoader,
                action: purchaseKedFromNewsletterAction
            },
            {
                path: 'kurs-efektywnego-dzialania/sukces',
                element: <WebinarKedSuccessfulPurchasePage/>,
                loader: webinarDetailsLoader,
            },
            {
                path: 'kurs-efektywnego-dzialania/blad',
                element: <WebinarKedUnsuccessfulPurchasePage/>,
                loader: webinarDetailsLoader,
            },
            {
                path: 'podstawy-efektywnego-dzialania',
                element: <PedPurchasePage/>,
                loader: webinarDetailsLoader,
                action: purchasePedFromSalesPageAction
            },
            {
                path: 'podstawy-efektywnego-dzialania/sukces',
                element: <WebinarKedSuccessfulPurchasePage/>,
                loader: webinarDetailsLoader,
            },
            {
                path: 'podstawy-efektywnego-dzialania/blad',
                element: <WebinarKedUnsuccessfulPurchasePage/>,
                loader: webinarDetailsLoader,
            },
        ]
    },
    {
        path: '/rads',
        element: <FunnelRootLayout/>,
        errorElement: <Error404/>,
        id: 'radsRoot',
        loader: sessionIdLoader,
        children: [
            {
                path: 'kurs-efektywnego-dzialania',
                element: <WebinarKedPurchasePage/>,
                loader: webinarDetailsLoader,
                action: purchaseKedFromRadsAction
            },
            {
                path: 'kurs-efektywnego-dzialania/sukces',
                element: <WebinarKedSuccessfulPurchasePage/>,
                loader: webinarDetailsLoader,
            },
            {
                path: 'kurs-efektywnego-dzialania/blad',
                element: <WebinarKedUnsuccessfulPurchasePage/>,
                loader: webinarDetailsLoader,
            },
        ]
    },
    {
        path: '/mistrzowskie-wsparcie',
        element: <FunnelRootLayout/>,
        errorElement: <Error404/>,
        id: 'extraordinarySupportRoot',
        loader: sessionIdLoader,
        children: [
            // {
            //     index: true,
            //     element: <FunnelMWParentOptInPage/>,
            //     action: funnelMWParentOptInAction
            // },
            // {
            //     path: 'spotkanie',
            //     element: <FunnelMWParentVSLPage/>,
            // },
            // {
            //     path: 'spotkanie/umow',
            //     element: <FunnelMWParentCalendlyPage/>,
            // },
            // {
            //     path: 'uczen',
            //     element: <ExtraordinarySupportStudentPage/>
            // },
            // {
            //     path: 'start',
            //     element: <ParentOrStudentSelectPage/>
            // },
            {
                index: true,
                element: <RedirectComponent to='/webinar'/>,
                action: funnelMWParentOptInAction
            },
            {
                path: 'spotkanie',
                element: <RedirectComponent to='/webinar'/>,
            },
            {
                path: 'spotkanie/umow',
                element: <RedirectComponent to='/webinar'/>,
            },
            {
                path: 'uczen',
                element: <RedirectComponent to='/webinar'/>
            },
            {
                path: 'start',
                element: <RedirectComponent to='/webinar'/>
            }
        ]
    },
    {
        path: '/produkt',
        element: <RootLayout customHeader={<ProductsHeader/>} customFooter={<ProductsFooter/>}/>,
        errorElement: <Error404/>,
        loader: tokenLoader,
        shouldRevalidate: () => true,
        id: 'productRoot',
        children: [
            {
                index: true,
                element: <ProductsPage/>,
                loader: productsPageLoader
            },
            {
                path: 'kurs-efektywnego-dzialania',
                element: <LearningCourseDashboardPage course={CourseDetails.KED}/>,
                loader: kedLoader,
                action: completeEpisodeAction
            },
            {
                path: 'podstawy-efektywnego-dzialania',
                element: <LearningCourseDashboardPage course={CourseDetails.PED}/>,
                loader: pedLoader,
                action: completeEpisodeAction
            },
            {
                path: 'kurs-efektywnego-dzialania/spotkanie/dziekuje',
                element: <RedirectAfterSchedulingIndividualKEDSessionPage/>,
            },
            {
                path: 'chat',
                element: <ChatBot/>,
                loader: threadsLoader
            },
            {
                path: 'chat/zakup',
                element: <ChatPurchaseTokensInProducts/>,
                loader: isLoggedInLoader,
                action: purchaseTokensAction
            },
            {
                path: 'spolecznosc',
                element: <CommunityPage/>,
                loader: communityPageLoader,
                action: configureDiscordAction
            },
            {
                path: 'spolecznosc/spotkania',
                element: <CommunityWeeklyMeetingPage/>,
                loader: communityWeeklyMeetingsLoader
            },
            {
                path: 'zakup/kurs-efektywnego-dzialania',
                element: <KedEnrollPage/>,
                loader: redirectIfNotLoggedIn,
                action: purchaseKedFromPedCourseSalesPage
            },
            {
                path: 'zakup/kurs-efektywnego-dzialania/blad',
                element: <WebinarKedUnsuccessfulPurchasePage/>,
                loader: webinarDetailsLoader,
            },
        ]
    },
    {
        path: '/',
        element: <RootLayout/>,
        errorElement: <Error404/>,
        loader: tokenLoader,
        shouldRevalidate: () => true,
        id: 'root',
        children: [
            // @Everyone
            {
                index: true,
                element: <IndexPage/>,
                loader: randomBlogArticlesLoader
            },
            // @Everyone (who is not logged in)
            {
                path: 'logowanie',
                element: <Login/>,
                loader: redirectIfLoggedIn,
                action: loginAction
            },
            // @Everyone (who is not logged in)
            {
                path: 'rejestracja',
                element: <Register/>,
                loader: redirectIfLoggedIn,
                action: registerAction,
            },
            // @Everyone
            {
                path: 'logout',
                action: logoutAction,
                element: <RedirectComponent to='/'/>
            },
            // @Everyone (who is not logged in)
            {
                path: 'reset-hasla',
                loader: redirectIfLoggedIn,
                action: resetPasswordAction,
                element: <ResetPassword/>
            },
            // @Everyone (who is not logged in)
            {
                path: 'zmien-haslo/:uuid',
                loader: redirectIfLoggedIn,
                action: changePasswordAction,
                element: <ChangePassword/>
            },
            // @Everyone
            {
                path: '/aktywuj/:uuid',
                element: <ActivateAccount/>,
                loader: activateAccountLoader,
                action: activateAccountAction
            },
            // @Everyone
            {
                path: 'kontakt',
                element: <Contact/>,
                action: contactAction
            },
            {
                path: '/potwierdz-newsletter',
                element: <ConfirmNewsletterPage/>,
            },
            {
                path: '/dziekuje',
                element: <ConfirmTransactionPage/>,
            },
            // @Everyone (who is logged in)
            {
                path: 'transakcje',
                element: <TransactionsPage/>,
                loader: transactionsLoader
            },
            // @Everyone (who is logged in)
            {
                path: 'chat',
                element: <RedirectComponent to={'/produkt/chat'}/>,
            },
            // @Everyone
            {
                path: 'oferta/chat',
                element: <ChatLandingPage/>,
                loader: isLoggedInLoader,
                action: purchaseTokensAction
            },
            // @Everyone
            {
                path: 'oferta/kurs-efektywnego-dzialania',
                element: <LearningCourseEnrollPage/>,
                action: learningCourseEnrollAction
            },
            // @Everyone, but not visible on the website before call
            {
                path: 'zakup/kurs-efektywnego-dzialania/:ksuid',
                element: <LearningCoursePurchasePage/>,
                loader: individualKedOfferLoader,
                action: coursePurchaseAction
            },
            // @Everyone - used for redirect after successful purchase
            {
                path: 'zakup/kurs-efektywnego-dzialania/sukces',
                element: <LearningCourseSuccessfulPurchasePage/>,
            },
            // @Everyone - used for redirect after successful purchase
            {
                path: 'zakup/kurs-efektywnego-dzialania/blad',
                element: <LearningCourseUnsuccessfulPurchasePage/>,
            },
            // @Everyone, but not visible on the website before call
            {
                path: 'zakup/mistrzowskie-wsparcie',
                element: <ExtraordinarySupportPurchasePage/>,
                loader: extraordinarySupportPurchaseLoader,
                action: extraordinarySupportPurchaseAction
            },
            // @Everyone, but not visible on the website before call
            {
                path: 'zakup/mistrzowskie-wsparcie/:ksuid',
                element: <ExtraordinarySupportPurchasePage/>,
                loader: extraordinarySupportPurchaseLoader,
                action: extraordinarySupportPurchaseAction
            },
            // @Everyone - used for redirect after successful purchase
            {
                path: 'zakup/mistrzowskie-wsparcie/sukces',
                element: <ExtraordinarySupportSuccessfulPurchasePage/>
            },
            // @Everyone - used for redirect after successful purchase
            {
                path: 'zakup/mistrzowskie-wsparcie/blad',
                element: <ExtraordinarySupportUnsuccessfulPurchasePage/>
            },
            // @Everyone
            {
                path: 'oferta',
                element: <OfferLandingPage/>,
            },
            // @Everyone
            {
                path: 'o-nas',
                element: <AboutUsPage/>,
            },
            // @Everyone
            {
                path: 'planer',
                element: <Planner/>,
                action: newsletterFormAction
            },
            // @Everyone
            {
                path: 'newsletter',
                action: newsletterFormAction
            },
            // @Everyone
            {
                path: 'anuluj-newsletter',
                element: <CancelNewsletter/>,
                loader: cancelNewsletterLoader,
                action: cancelNewsletterFormAction
            },
            // @Everyone
            {
                path: 'polityka-prywatnosci',
                element: <PrivacyPolicyPage/>,
            },
            // @Everyone
            {
                path: 'regulamin',
                element: <RegulationsPage/>,
            },
            // @Everyone
            {
                path: 'regulamin-ked',
                element: <RegulationsKEDPage/>,
            },
            {
                path: 'regulamin-mw',
                element: <RegulationsMWPage/>,
            },
            {
                path: 'regulamin-ped',
                element: <RegulationsPEDPage/>,
            },
            // @Everyone
            {
                path: 'blog',
                element: <BlogPage/>,
                loader: blogArticlesLoader,
                action: deleteArticleAction,
            },
            // @Everyone
            {
                path: 'blog/:uuid',
                element: <BlogPostPage/>,
                loader: blogArticleLoader,
            },
            // @Everyone (who is logged in)
            {
                path: 'feedback',
                action: sendFeedbackAction
            },
            {
                path: 'feedbackCourse',
                action: mandatoryFeedbackCourseAction
            },
            // @Everyone
            {
                path: 'login-error',
                element: <LoginError/>
            },
            // @Everyone
            {
                path: 'rejestracja-udana',
                element: <RedirectAfterRegistrationPage/>,
            },
            {
                path: 'yt',
                element: <RedirectComponent to={'/webinar'} from='yt-bio'/>
            },
            {
                path: 'tiktok',
                element: <RedirectComponent to={'/webinar'} from='tiktok'/>
            },
            {
                path: 'ig',
                element: <RedirectComponent to={'/webinar'} from='ig'/>
            },
            {
                path: 'chronotypy',
                element: <SleepChronotypesTestPage cta={false}/>
            },
            {
                path: 'test-na-chronotyp',
                element: <SleepChronotypesTestPage cta={false}/>
            },
            {
                path: 'start',
                element: <RedirectComponent to='/webinar'/>
            },
            {
                path: 'slajdy',
                element: <RedirectComponent
                    to='/webinar/slajdy'/>
            },
            // TODO: Eventually get rid of first one -> Maintained for backwards compatibility
            {
                path: '/aktywuj-ked/:uuid',
                element: <GrantKedAccessToStudentPage/>,
                loader: grantKedAccessToStudentLoader,
                action: grantKedAccessToStudentAction,
                shouldRevalidate: () => false,
            },
            {
                path: '/aktywuj-kurs/:uuid',
                element: <GrantKedAccessToStudentPage/>,
                loader: grantKedAccessToStudentLoader,
                action: grantKedAccessToStudentAction,
                shouldRevalidate: () => false,
            }
        ]
    },
]);

function App() {
    useEffect(() => {
        let error = new URLSearchParams(window.location.hash.substring(1)).get('error_description');
        if (error) {
            if (error.startsWith('PreSignUp failed with error Użytkownik o podanym adresie email istnieje')) {
                window.location.href = '/login-error?error_description=Użytkownik o podanym adresie email istnieje już w bazie danych. Zaloguj się bezpośrednio przy użyciu adresu email.'
            } else {
                window.location.href = '/login-error'
            }
        }
    }, []);

    return <HelmetProvider>
        <ToastProvider>
            <RouterProvider router={router}/>
        </ToastProvider>
    </HelmetProvider>
}

export default App;

export type ActionResponse = {
    body: string
    status: number
}
