import {NewsletterCampaign} from "../../../../model/user/general/Newsletter";
import React from "react";
import {motion} from "framer-motion";
import {formatDate} from "../../../../util/DateUtil";

interface AdminNewsletterFollowUpsStatisticsItemProps {
    campaign: NewsletterCampaign;
}

function roundTwoDigitsPercent(number: number): string {
    return `${(number * 100).toFixed(1)}%`
}

export default function AdminNewsletterCampaignsStatisticsItem({
                                                                   campaign,
                                                               }: AdminNewsletterFollowUpsStatisticsItemProps) {
    const metrics = [
        {title: "Suma", value: campaign.sentEmails},
        {
            title: "Otwarcia",
            value: campaign.openedEmails,
            percentage: roundTwoDigitsPercent(campaign.openedEmails / campaign.sentEmails)
        },
        {
            title: "Kliknięcia",
            value: campaign.clickedEmails,
            percentage: roundTwoDigitsPercent(campaign.clickedEmails / campaign.sentEmails)
        },
        {
            title: "Rezygnacje",
            value: campaign.unsubscribes,
            percentage: roundTwoDigitsPercent(campaign.unsubscribes / campaign.sentEmails)
        },
    ];

    return (
        <>
            <motion.div
                className="col-12"
                key={campaign.id}
                initial={{opacity: 0, scale: 0.5}}
                animate={{opacity: 1, scale: 1}}
            >
                <div className="px-10 py-8 bg-light-dark rounded shadow">
                    <div className="row justify-content-between g-4">
                        <div className="col d-flex flex-column justify-content-center">
                            <h3 className="mb-1 font-heading fs-9 text-white">
                                {campaign.campaignTitle}
                            </h3>
                            <h6 className='mb-1 text-white'>
                                {formatDate(campaign.sentAt)}
                            </h6>
                            <p className='mb-0 fw-medium text-secondary-light'
                               style={{fontSize: '16px'}}>
                                <b>Tytuł</b>: {campaign.rawTitle} | {campaign.preview}
                            </p>
                            <div>
                                {
                                    campaign.types.map((type) => (
                                        <>
                                        <span
                                            className='badge bg-success text-white text-uppercase shadow mt-1'
                                            style={{marginRight: "22px", marginLeft: "-3px"}}>
                                      {type}
                                </span>
                                        </>
                                    ))
                                }
                            </div>
                        </div>
                        {/*<div className='col-12 col-md-auto mt-6'>*/}
                        {/*    <AnimatedButton*/}
                        {/*        className='btn btn-lg btn-success w-100 text-success-light shadow'*/}
                        {/*        onClick={() => {*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        Zobacz szczegóły*/}
                        {/*    </AnimatedButton>*/}
                        {/*</div>*/}
                    </div>
                    <div className="row g-3 mt-3">
                        {metrics.map((metric, index) => (
                            <div
                                key={index}
                                className="col-6 col-md-3 text-center"
                            >
                                <h4 className="mb-1 font-heading text-success fs-7">
                                    {metric.value} {metric.percentage ? `(${metric.percentage})` : ''}
                                </h4>
                                <p className="mb-0 fs-9 text-secondary-light fw-medium">
                                    {metric.title}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </motion.div>
        </>
    );
}