import {NewsletterCampaign} from "../../../model/user/general/Newsletter";
import MainDesign from "../../MainDesign";
import AdminNewsletterNavbar from "../../../components/admin/newsletter/AdminNewsletterNavbar";
import React, {Suspense} from "react";
import Meta from "../../../components/common/Meta";
import Heading from "../../../components/common/header/Heading";
import {NewsletterService} from "../../../service/NewsletterService";
import {Await, defer, useLoaderData} from "react-router-dom";
import AdminNewsletterCampaignsStatistics
    from "../../../components/admin/newsletter/campaign/AdminNewsletterCampaignsStatistics";
import LoadingPage from "../../common/LoadingPage";
import ErrorContent from "../../../components/common/error/ErrorContent";

type LoaderData = {
    newsletterCampaigns: Promise<NewsletterCampaign[]>
}

export default function AdminNewsletterCampaignsStatisticsPage() {
    const {newsletterCampaigns} = useLoaderData() as LoaderData;

    return <>
        <Meta title='Statystyki kampanii'/>
        <MainDesign containerStyles='container py-28'>
            <Heading title={'Statystyki kampanii'}/>
            <AdminNewsletterNavbar/>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={newsletterCampaigns} errorElement={<ErrorContent/>}>
                    {(loadedCampaigns: NewsletterCampaign[]) => <>
                        <AdminNewsletterCampaignsStatistics campaigns={loadedCampaigns}/>
                    </>}
                </Await>
            </Suspense>

        </MainDesign>
    </>
}

async function loadNewsletterCampaigns(): Promise<NewsletterCampaign[]> {
    return await NewsletterService.getNewsletterCampaigns();
}

export async function loader({request}: { request: Request }) {
    return defer({
        newsletterCampaigns: loadNewsletterCampaigns()
    })
}


