import React, {RefObject} from "react";
import {md5} from "js-md5";
import {S3PresignedUrlFields} from "../model/common/S3PresignedUrl";
import {DiagnosticService} from "../service/DiagnosticService";

// !IMG{url}
// !LINK{name,url}
// !NEWLINE
export function renderContentWithPlaceholders(content: string | undefined): React.ReactNode[] {
    if (!content) {
        return [''];
    }

    // Replace !NEWLINE with a placeholder that won't interfere with URLs or other content
    const newlinePlaceholder = '[[NEWLINE]]';
    content = content.replace(/!NEWLINE/g, newlinePlaceholder);

    // Handle !IMG{URL}
    const imgRegex = /!IMG\{([^}]+)\}/g;
    let parts: React.ReactNode[] = content.split(imgRegex).map((part, index) => {
        if (index % 2 === 1) { // URLs are at odd indices
            return <div key={`img-${index}`}><img src={part as string} className="img-fluid" alt="Dynamic"/></div>;
        }
        return part;
    });

    // Handle !LINK{name,url}
    const linkRegex = /!LINK\{([^,]+),([^}]+)\}/g;
    parts = parts.flatMap((part): React.ReactNode[] => {
        if (typeof part === 'string') {
            // Replace all occurrences of !LINK with JSX elements directly in the string
            const segments: React.ReactNode[] = [];
            let lastIndex = 0;
            part.replace(linkRegex, (match, name, url, index) => {
                segments.push(part.slice(lastIndex, index));
                segments.push(<a className='link-success' href={url} key={`link-${url}`}>{name}</a>);
                lastIndex = index + match.length;
                return match;
            });
            if (lastIndex < part.length) {
                segments.push(part.slice(lastIndex));
            }
            return segments;
        }
        return [part];
    });

    // Replace the newline placeholder with <br/> JSX elements
    return parts.flatMap((part): React.ReactNode[] => {
        if (typeof part === 'string') {
            const segments: React.ReactNode[] = [];
            part.split(newlinePlaceholder).forEach((textSegment, idx, arr) => {
                segments.push(textSegment);
                if (idx < arr.length - 1) { // Don't add <br/> after the last segment
                    segments.push(<br key={`br-${idx}`}/>);
                }
            });
            return segments;
        }
        return [part];
    });
}


export function base64toBlob(b64Data: string, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
}

export async function getFileMD5(file: File) {
    return md5(await file.arrayBuffer());
}

export function generateFormDataForPresignedUrl(file: File, fields: S3PresignedUrlFields): FormData {
    const formData = new FormData();
    formData.append("Content-Type", file.type);
    Object.entries(fields).forEach(([k, v]) => {
        formData.append(k, v);
    });
    formData.append("file", file); // must be the last one
    return formData
}

export function handleDirtyForm(isFormDirtyRef: RefObject<boolean>) {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
        if (isFormDirtyRef.current) {
            e.preventDefault();
            e.returnValue = '';
        }
    };

    // Attach event listener if the form is dirty
    if (isFormDirtyRef.current) {
        window.addEventListener('beforeunload', handleBeforeUnload);
    }

    // Return a cleanup function
    return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
    };
}

type QueryParams = {
    [key: string]: string | number | boolean | undefined | null;
}

export function constructUrl(baseUrl: string, queryParams: QueryParams): string {
    // Filter out undefined or null values
    const validParams = Object.entries(queryParams).filter(([_, value]) => value !== undefined && value !== null);

    // Transform the parameters into encoded key-value pairs
    const encodedParams = validParams.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value!!)}`);

    // Concatenate the base URL with the encoded query parameters
    return encodedParams.length > 0 ? `${baseUrl}?${encodedParams.join('&')}` : baseUrl;
}

export function addNewLineTag(message: string) {
    return message.split('\n').map((line, index, array) =>
        index === array.length - 1 ? line :
            <React.Fragment key={index}>{line}<br/></React.Fragment>
    )
}

export function encodeUrlIfExists(url: string | null): string {
    try {
        return url ? encodeURI(url) : '';
    } catch (error) {
        DiagnosticService.addDiagnostic({
            fullEvent: {url},
            error: error,
            functionName: 'encodeUrlIfExists',
            additionalInfo: "error while encoding url for traces"
        })
        return ''
    }
}