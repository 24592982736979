import React from "react";
import {ApiError} from "../../../service/HttpService";
import MainDesign from "../../../pages/MainDesign";
import Meta from "../../common/Meta";
import CancelNewsletterText from "./CancelNewsletterText";
import CancelNewsletterForm from "./CancelNewsletterForm";
import {NewsletterService} from "../../../service/NewsletterService";

export type CancelNewsletterData = {
    email: string,
    type: string,
    newsletterId: string | null
    followUpName: string | null
}
export default function CancelNewsletter() {
    return <>
        <Meta title='Anuluj subskrypcję'/>
        <MainDesign containerStyles='container py-16'>
            <CancelNewsletterText/>
            <CancelNewsletterForm/>
        </MainDesign>
    </>
}

export async function loader({request}: { request: Request }): Promise<CancelNewsletterData> {
    const searchParams = new URL(request.url).searchParams
    const email = searchParams.get('email');
    const type = searchParams.get('type');
    if (!email || !type) {
        throw new Error("Adres email i typ nie mogą być puste.")
    }

    const newsletterId = searchParams.get("newsletterId");
    const followUpName = searchParams.get("followUpName");

    return {
        email: email,
        type: type,
        newsletterId: newsletterId,
        followUpName: followUpName
    };
}

export async function action({request}: { request: Request }) {
    const formData = await request.formData();
    const newsletterData = {
        email: formData.get('email') as string,
        type: formData.get('type') as string,
        reason: formData.get('reason') as string,
        newsletterId: formData.get('newsletterId') as string,
        followUpName: formData.get('followUpName') as string
    };

    try {
        await NewsletterService.cancelNewsletter(newsletterData)
        return {
            status: 200,
            body: 'Pomyślnie anulowano subskrypcję.'
        }
    } catch (error) {
        const apiError = error as ApiError;
        return {
            status: 500,
            body: apiError.message
        }
    }
}