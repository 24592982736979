import {AdminToggleNewsletterRequest, NewsletterEntity} from "../../../model/user/general/Newsletter";
import {NewsletterService} from "../../../service/NewsletterService";
import MainDesign from "../../MainDesign";
import AdminNewsletterNavbar from "../../../components/admin/newsletter/AdminNewsletterNavbar";
import {ApiError} from "../../../service/HttpService";
import {NewsletterType} from "../../../components/user/newsletter/NewsletterForm";
import {Await, defer, useLoaderData} from "react-router-dom";
import LoadingPage from "../../common/LoadingPage";
import ErrorContent from "../../../components/common/error/ErrorContent";
import AdminMailingListTable from "../../../components/admin/newsletter/AdminMailingListTable";
import React, {Suspense} from "react";
import Meta from "../../../components/common/Meta";
import Heading from "../../../components/common/header/Heading";

type LoaderData = {
    mailingList: Promise<NewsletterEntity[]>
}

export default function AdminListNewslettersPage() {
    const {mailingList} = useLoaderData() as LoaderData;

    return <>
        <Meta title='Dodaj do newslettera'/>
        <MainDesign containerStyles='container py-28'>
            <Heading title={'Lista mailingowa'}/>
            <AdminNewsletterNavbar/>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={mailingList} errorElement={<ErrorContent/>}>
                    {(loadedMailingList) => <>
                        <AdminMailingListTable mailingList={loadedMailingList}/>
                    </>}
                </Await>
            </Suspense>
        </MainDesign>
    </>
}

async function loadMailingList(mailingList: string): Promise<NewsletterEntity[]> {
    return await NewsletterService.getMailingList(mailingList as NewsletterType);
}

export async function loader({request}: { request: Request }) {
    const url = new URL(request.url);
    const mailingList = url.searchParams.get('mailingList') ?? NewsletterType.WebinarKedOptInV1;

    return defer({
        mailingList: loadMailingList(mailingList)
    })
}


export async function action({request}: { request: Request }) {
    const formData = await request.formData();

    try {
        const toggleNewsletterRequest = {
            email: formData.get('email') as string,
            type: formData.get('type') as string,
        } as AdminToggleNewsletterRequest;

        const response = await NewsletterService.toggleNewsletter(toggleNewsletterRequest);
        return {
            status: 200,
            body: response.message
        }
    } catch (error) {
        const apiError = error as ApiError;
        return {
            status: 500,
            body: apiError.message
        }
    }
}
