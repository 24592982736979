import {SubscribeNewsletterRequest} from "../../../model/user/general/Newsletter";
import {NewsletterService} from "../../../service/NewsletterService";
import Heading from "../../../components/common/header/Heading";
import MainDesign from "../../MainDesign";
import AdminNewsletterNavbar from "../../../components/admin/newsletter/AdminNewsletterNavbar";
import AdminAddToNewsletter from "../../../components/admin/newsletter/AdminAddToNewsletter";
import {ApiError} from "../../../service/HttpService";
import Meta from "../../../components/common/Meta";

export default function AdminAddToNewsletterPage() {

    return <>
        <Meta title='Dodaj do newslettera'/>
        <MainDesign containerStyles='container py-28'>
            <Heading title='Dodaj manualnie do newslettera'/>
            <AdminNewsletterNavbar/>
            <AdminAddToNewsletter/>
        </MainDesign>
    </>
}

export async function action({request}: { request: Request }) {
    const formData = await request.formData();

    try {
        const subscribeNewsletterRequest = {
            email: formData.get('email') as string,
            source: formData.get('source') as string,
            name: formData.get('name') as string,
            type: formData.get('type') as string,
            manual: true
        } as SubscribeNewsletterRequest;

        const response = await NewsletterService.subscribeNewsletter(subscribeNewsletterRequest);
        return {
            status: 200,
            body: response.message
        }
    } catch (error) {
        const apiError = error as ApiError;
        return {
            status: 500,
            body: apiError.message
        }
    }

}
