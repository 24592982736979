import {NewsletterCampaign} from "../../../../model/user/general/Newsletter";
import React from "react";
import AdminNewsletterCampaignsStatisticsItem from "./AdminNewsletterCampaignsStatisticsItem";

interface AdminNewsletterCampaignsStatisticsProps {
    campaigns: NewsletterCampaign[]
}

export default function AdminNewsletterCampaignsStatistics({campaigns}: AdminNewsletterCampaignsStatisticsProps) {

    return <>
        <div className='mw-md-4xl mx-auto my-10'>
            <div className='mb-20 row g-8'>
                {
                    campaigns.map(campaign => (
                        <AdminNewsletterCampaignsStatisticsItem campaign={campaign}/>
                    ))
                }
            </div>
        </div>
    </>
}