import {WebinarDetails} from "../../../../model/user/webinar/WebinarDetails";
import {WebinarService} from "../../../../service/WebinarService";
import {defer, Params} from "react-router-dom";
import {getWebinarIdFromLocalStorage, setWebinarIdInLocalStorage} from "../../../../util/WebinarUtil";

async function loadWebinarDetails(ksuid: string, ignoreIfNotExists: boolean, jit?: boolean): Promise<WebinarDetails | null> {
    if (ignoreIfNotExists) {
        try {
            return await WebinarService.getWebinarDetails(ksuid, jit);
        } catch (error) {
            return null
        }
    } else {
        return await WebinarService.getWebinarDetails(ksuid, jit);
    }
}

export async function webinarDetailsLoader({request, params}: { request: Request, params: Params<"ksuid"> }) {
    const url = new URL(request.url);
    const searchParams = new URLSearchParams(url.search);

    let ignoreIfNotExists: boolean;
    let webinarId: string | null
    if (params && params.ksuid) {
        webinarId = params.ksuid
        setWebinarIdInLocalStorage(webinarId)
        ignoreIfNotExists = false;
    } else {
        webinarId = getWebinarIdFromLocalStorage();
        ignoreIfNotExists = true
    }

    const jit = !!searchParams.get("jit");

    return defer({
        webinarDetails: webinarId ? loadWebinarDetails(webinarId, ignoreIfNotExists, jit) : null,
    });
}