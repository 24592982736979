import {Await, useLoaderData} from "react-router-dom";
import {WebinarDetails} from "../../../../model/user/webinar/WebinarDetails";
import Meta from "../../../../components/common/Meta";
import MainDesign from "../../../MainDesign";
import React, {Suspense} from "react";
import LoadingPage from "../../../common/LoadingPage";
import ErrorContent from "../../../../components/common/error/ErrorContent";
import WebinarKedPurchase from "../../../../components/funnel/after-optin/webinar/sales-page/WebinarKedPurchase";
import ProdukacjaCopyrightNoteWithPhoneNumber
    from "../../../../components/funnel/ProdukacjaCopyrightNoteWithPhoneNumber";

type LoaderData = {
    webinarDetails: Promise<WebinarDetails>;
};

export default function WebinarKedPurchasePage() {
    const {webinarDetails} = useLoaderData() as LoaderData;

    return <>
        <Meta title="📚 Kurs Efektywnego Działania | Kup Teraz"
              imageUrl='https://produkacja.s3.eu-central-1.amazonaws.com/web/kurs-efektywnego-dzialania/ked-miniaturka-sales-page.png'/>
        <MainDesign containerStyles='container py-12 py-md-20' minHeight='100vh'>
            <Suspense fallback={<LoadingPage styles="pt-10"/>}>
                <Await resolve={webinarDetails} errorElement={<ErrorContent/>}>
                    {(loadedWebinarDetails: WebinarDetails) => <>
                        <WebinarKedPurchase webinarDetails={loadedWebinarDetails} fromWebinar={true}/>
                        <ProdukacjaCopyrightNoteWithPhoneNumber/>
                    </>}
                </Await>
            </Suspense>
        </MainDesign>
    </>
}
