import {Metrics} from "../../model/admin/Metrics";
import AdminNumbersItem from "./AdminNumbersItem";
import Heading from "../common/header/Heading";

interface AdminNumbersProps {
    metrics: Metrics
}

function roundTwoDigitsPercent(number: number): string {
    return `${(number * 100).toFixed(1)}%`
}

export default function AdminNumbers(props: AdminNumbersProps) {
    const {metrics} = props
    return <>
        <Heading title="Ogólne" badgeText="Statystyki"
                 subtitle="Ogólne statystyki związane z wykorzystaniem platformy."/>
        <div className='row g-16 mb-20'>
            <AdminNumbersItem metricValue={metrics.totalUsers} metricTitle="Wszyscy użytkownicy"/>
            <AdminNumbersItem metricValue={metrics.totalDailyExercises} metricTitle="Liczba wszystkich zadań"/>
            <AdminNumbersItem metricValue={metrics.totalBlogArticles} metricTitle="Liczba wszystkich artykułów"/>
            <AdminNumbersItem metricValue={metrics.totalIncomeInPln} metricTitle="Całkowity przychód w PLN"/>
        </div>

        <hr className='text-white'/>
        <Heading title="KED - Webinar V1"
                 subtitle="Statystyki związane z Mistrzowskim Wsparciem dla rodziców."/>
        <div className='row g-16 mb-20'>
            <AdminNumbersItem metricValue={metrics.totalWebinarKedOptInV1Subscribers}
                              metricTitle="Webinar OptIn"/>
            <AdminNumbersItem metricValue={metrics.totalWebinarKedNoShowV1Subscribers}
                              metricTitle="Liczba nieobecnych"/>
            <AdminNumbersItem metricValue={metrics.totalWebinarKedFollowUpV1Subscribers}
                              metricTitle="Liczba obecnych"/>
            <AdminNumbersItem metricValue={metrics.totalWebinarKedPurchaserV1Subscribers}
                              metricTitle="Liczba zakupów (KED)"/>
            <AdminNumbersItem metricValue={metrics.totalPedPurchaserV1Subscribers}
                              metricTitle="Liczba zakupów (PED)"/>

            <AdminNumbersItem metricValue={metrics.totalKedUsers}
                              metricTitle="Liczba członków KED"/>
            <AdminNumbersItem metricValue={metrics.totalPedUsers}
                              metricTitle="Liczba członków PED"/>

        </div>

        <hr className='text-white'/>
        <Heading title="KED - Rodzice dzieci do programu 'MISTRZOWSKIE WSPARCIE'"
                 subtitle="Statystyki związane z Mistrzowskim Wsparciem dla rodziców."/>
        <div className='row g-16 mb-20'>
            <AdminNumbersItem metricValue={metrics.totalMWOptInParentSubscribers}
                              metricTitle="OptIn rodzice MW"/>
            <AdminNumbersItem metricValue={metrics.totalScheduledMWParentSalesCallSubscribers}
                              metricTitle="Umówione spotkania rodzice MW"/>
            {Object.keys(metrics.mwOptInFollowUpsParent).map(key => {
                const followUp = metrics.mwOptInFollowUpsParent[key];
                return (
                    <AdminNumbersItem
                        smallerText={true}
                        key={key}
                        metricValue={`${roundTwoDigitsPercent(followUp.Clicked / followUp.Sent)}/${roundTwoDigitsPercent(followUp.Opened / followUp.Sent)}/${followUp.Sent}`}
                        metricTitle={`C/O/S FE${key}`}
                    />
                );
            })}
        </div>

        <hr className='text-white'/>
        <Heading title="Newslettery"
                 subtitle="Znajdziesz poniżej wszystkie statystyki związane z Newsletterami."/>
        <div className='row g-16 mb-20'>
            <AdminNumbersItem metricValue={metrics.totalNewsletterSubscribers} metricTitle="Subskrybenci newslettera"/>
            <AdminNumbersItem metricValue={metrics.totalPlannerSubscribers} metricTitle="Subskrybenci planeru"/>
            <AdminNumbersItem metricValue={metrics.totalDailyExercisesSubscribers}
                              metricTitle="Subskrybenci zadań z matematyki"/>
            <AdminNumbersItem metricValue={metrics.totalRegisteredUsersNewsletterContentSubscribers}
                              metricTitle="Zarejestrowani + zgoda marketingowa"/>
        </div>

        <hr className='text-white'/>
        <Heading title="AI"
                 subtitle="Statystyki związane ze sztuczną inteligencją."/>
        <div className='row g-16 mb-20'>
            <AdminNumbersItem metricValue={metrics.totalChatThreads} metricTitle="Wątki chatów"/>
            <AdminNumbersItem metricValue={metrics.totalChatMessages} metricTitle="Liczba wiadomości w chacie"/>
            <AdminNumbersItem metricValue={metrics.totalSpeakingThreads} metricTitle="Wątki ze speakerem"/>
            <AdminNumbersItem metricValue={metrics.totalSpeakingMessages} metricTitle="Liczba wiadomości ze speakerem"/>
            <AdminNumbersItem metricValue={metrics.totalTokensUsed} metricTitle="Liczba wykorzystanych tokenów"/>
            <AdminNumbersItem metricValue={Number(metrics.totalCostInUsd.toFixed(5))}
                              metricTitle="Całkowity koszt w $"/>
        </div>
    </>
}