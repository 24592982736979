import React from "react";
import {AnimatePresence, motion} from "framer-motion";
import CustomSelect from "../../../ui/form/CustomSelect";
import {
    CourseEpisode,
    CourseWeekAndEpisodes,
    LastWatchedEpisode
} from "../../../../model/user/learning-course/LearningCourse";
import {Link} from "react-router-dom";
import AnimatedLink from "../../../ui/motion/AnimatedLink";
import {CourseDetails} from "../../../../model/user/learning-course/CourseDetails";

interface LearningCourseLeftMenuProps {
    wrapperClasses: string
    watchesCourseEpisode: boolean
    lastWatched: LastWatchedEpisode
    weekAndEpisodes: CourseWeekAndEpisodes | null
    episode: CourseEpisode | null
    updateWeek: (newWeek: string) => void
    course: CourseDetails
}

const buttonVariants = {
    hidden: {opacity: 0, y: 20},
    visible: (i: number) => ({
        opacity: 1,
        y: 0,
        transition: {
            delay: i * 0.2
        }
    })
};

export default function LearningCourseLeftMenu(props: LearningCourseLeftMenuProps) {
    const {
        wrapperClasses,
        watchesCourseEpisode,
        lastWatched,
        weekAndEpisodes,
        episode,
        updateWeek,
        course
    } = props;

    const courseStructure = course.structure

    const currentWeek = watchesCourseEpisode ? courseStructure[Number(weekAndEpisodes!!.week.week)] : null;

    return <motion.div className={wrapperClasses} key={JSON.stringify(weekAndEpisodes)}>
        <div className="px-2 py-8 rounded text-center mb-8 bg-light-dark">
            <p className="mb-0 text-white fw-medium fs-7">
                {watchesCourseEpisode ? (course.structure.length > 1 ? `Tydzień ${weekAndEpisodes?.week.week}:` : '') : 'Długość kursu:'}
            </p>
            <h3 className='text-warning fs-7 px-1'>
                {watchesCourseEpisode ? currentWeek!!.title : course.length}
            </h3>
        </div>
        {!watchesCourseEpisode &&
            <AnimatedLink
                className='btn btn-xl btn-warning w-100 text-success-light shadow'
                replaceLinkWithAHref={true}
                to={lastWatched ? `?w=${lastWatched.w}&e=${lastWatched.e}` : '?w=0&e=1'}>
                Przejdź do kursu
            </AnimatedLink>
        }
        {watchesCourseEpisode && (
            <>
                {
                    courseStructure.length > 1 && <CustomSelect
                        value={weekAndEpisodes!!.week.week.toString()}
                        customStyles='select-lg'
                        onChange={(event) => {
                            console.log(event.target.value);
                            updateWeek(event.target.value);
                        }}
                    >
                        {courseStructure.filter((course) => (course.episodes.length > 0 && courseStructure.indexOf(course) <= weekAndEpisodes!!.weeksUnlocked)).map((weekItem, count) => (
                            <option value={count.toString()} key={weekItem.title}>
                                Tydzień {count}
                            </option>
                        ))}
                    </CustomSelect>
                }

                <div className='text-white py-4'>
                    <AnimatePresence>
                        {currentWeek!!.episodes.map((episodeItem, index) => {
                            // Use the episode number from the course structure (assumed to be provided in episodeItem.episode)
                            const epNumber = index + 1;
                            // Find the matching progress record from weekAndEpisodes using the global episode number
                            const progress = weekAndEpisodes!!.episodes.find(ep => ep.episode === epNumber);
                            return (
                                <motion.div
                                    key={episodeItem.title}
                                    custom={index}
                                    initial="hidden"
                                    animate="visible"
                                    exit="hidden"
                                    variants={buttonVariants}
                                >
                                    <Link
                                        to={`?w=${weekAndEpisodes!!.week.week}&e=${epNumber}`}
                                        className={`btn btn-lg btn-dark-light w-100 rounded-2 mb-3 text-start fs-10 ${epNumber === episode!!.episode ? 'active fw-bold' : ''}`}
                                    >
                                        {epNumber}. {episodeItem.title} {progress && progress.completed && (
                                        <img
                                            className="me-2"
                                            src="https://produkacja.s3.eu-central-1.amazonaws.com/web/elements/checkbox-green.svg"
                                            alt="checkbox"
                                        />
                                    )}
                                    </Link>
                                </motion.div>
                            );
                        })}
                    </AnimatePresence>
                </div>
            </>
        )}
    </motion.div>
}
